import { Form } from "semantic-ui-react";
import ControlledCurrencyInput from "../../../common/components/ControlledCurrencyInput";

export default function TransferOffer(props) {
    return(
        <Form.Field>
            <label>Transfer offer</label>
            <ControlledCurrencyInput
                value={props.transferOffer}
                setValue={props.setTransferOffer}
                resetValue={props.resetTransferOffer}
                getIncrement={bidIncrementer}
                getDecrement={bidDecrementer}
            />
        </Form.Field>
    )
}

function bidIncrementer(prev, bounds) {
    if(prev >= bounds) return false;

    let increment;
    if(prev < 500000) {
        increment = 50000;
    } else if(prev < 1000000) {
        increment = 100000;
    } else if(prev < 5000000) {
        increment = 250000;
    } else if(prev < 20000000) {
        increment = 500000;
    } else if(prev < 50000000) {
        increment = 1000000;
    } else if(prev < 100000000) {
        increment = 5000000;
    } else {
        increment = 10000000;
    }

    return increment;
}

function bidDecrementer(prev, bounds) {
    if(prev <= bounds) return false;

    let decrement;
    if(prev < 500000) {
        decrement = 50000;
    } else if(prev < 1000000) {
        decrement = 100000;
    } else if(prev < 5000000) {
        decrement = 250000;
    } else if(prev < 20000000) {
        decrement = 500000;
    } else if(prev < 50000000) {
        decrement = 1000000;
    } else if(prev < 100000000) {
        decrement = 5000000;
    } else {
        decrement = 10000000;
    }

    return decrement;
}