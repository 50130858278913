import { generatePath, Redirect, useLocation, useParams } from "react-router";
import { URL } from "../../common/lib/paths";
import { initialFilterState } from "../../player/filter/lib";
import usePersistedState from '../../common/lib/usePersistedState';
import { useEffect } from "react";

/*

    Purpose of this component is to apply a city ID filter and then redirect to player search

    Not sure exactly why I built it like this but probably was a reason

    Could also be re-used in the future for tutorials where a specific search has to be
    applied programmatically

*/

export default function CityPlayers(props) {
    const {cityId} = useParams();
    const location = useLocation();
    const {updatePersistedState} = usePersistedState('playerSearchFilter', initialFilterState);

    useEffect(() => {
        updatePersistedState({...initialFilterState, filterCityId: cityId});
    }, []);

    return(
        <Redirect
            to={{
                pathname: generatePath(URL.transferSearchPlayers),
                search: location.search
            }}
        />
    );
}