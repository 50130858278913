import { useQuery } from "@tanstack/react-query";
import FavRetiredPlayers from "./players/interface";
import axios from "axios";
import FavPlayersToolbarItem from "../toolbar/FavPlayers";
import FavClubsToolbarItem from "../toolbar/FavClubs";
import FavStaffToolbarItem from "../toolbar/FavStaff";
import FavRetiredToolbarItem from "../toolbar/FavRetired";
import { useEffect } from "react";
import { Menu } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router";
import NarrowLayout from "../../common/components/NarrowLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faUserTie } from "@fortawesome/free-solid-svg-icons";
import FavRetiredStaff from "./staff/interface";

export default function FavRetired(props) {
    const history = useHistory();
    const location = useLocation();

    const favRetiredPlayersQuery = useQuery(
        ['getFavRetiredPlayers'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/favRetiredPlayers`)
    );

    const favRetiredStaffQuery = useQuery(
        ['getFavRetiredStaff'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/favRetiredStaff`)
    );

    useEffect(() => {
        props.setToolbar({
            title: 'Favourite retirees',
            navigation: [
                <FavPlayersToolbarItem key={1} />,
                <FavClubsToolbarItem key={2} />,
                <FavStaffToolbarItem key={3} />,
                <FavRetiredToolbarItem key={4} />
            ]
        });
    }, []);

    const view = location.state?.view || 'players';

    return(
        <NarrowLayout>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Menu
                    inverted
                    compact
                    size='small'
                >
                    <Menu.Item
                        active={view === 'players'}
                        onClick={() => history.replace({pathname: location.pathname, state: {...location.state, view: 'players'}})}
                    >
                        <FontAwesomeIcon className='inlineIcon' icon={faUsers} />
                        Players
                    </Menu.Item>
                    <Menu.Item
                        active={view === 'staff'}
                        onClick={() => history.replace({pathname: location.pathname, state: {...location.state, view: 'staff'}})}
                    >
                        <FontAwesomeIcon className='inlineIcon' icon={faUserTie} />
                        Staff
                    </Menu.Item>
                </Menu>
            </div>
            {view === 'players' &&
                <FavRetiredPlayers
                    isLoading={favRetiredPlayersQuery.isLoading}
                    favRetiredPlayers={favRetiredPlayersQuery.data?.data.response}
                />
            }
            {view === 'staff' &&
                <FavRetiredStaff
                    isLoading={favRetiredStaffQuery.isLoading}
                    favRetiredStaff={favRetiredStaffQuery.data?.data.response}
                />
            }
        </NarrowLayout>
    )
}