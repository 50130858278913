
import {Link, generatePath} from 'react-router-dom';
import {FormattedDate, FormattedTime} from 'react-intl';

import Currency from '../../../common/components/Currency';

import {URL} from '../../../common/lib/paths';
import LeagueLink from '../../../league/components/common/Link';
import TeamLink from '../../../team/common/TeamLink';
import CupName from '../../../cup/name/CupName';
import CupRoundName from '../../../cup/RoundName';
import StaffRoleName from '../../../staff/common/RoleName';

const formatValue = function(value, entityType, separation) {
    const separator = (!separation ? "" : ", " );

    let method = 'string';
    let params = {};

    switch(entityType) {
        case "club":
            if(!value.routeName) {
                value.routeName = 'clubProfile';
            }

            if(!value.routeParameterName) {
                value.routeParameterName = 'clubId';
            }

            method = 'entity';
            break;
        case "firstTeam":
            method = 'firstTeam';
        case "youthTeam":
            method = 'youthTeam';
            break;
        case "league":
            method = 'league';
            break;
        case "player":
            if(!value.routeName) {
                value.routeName = 'playerProfile';
            }

            if(!value.routeParameterName) {
                value.routeParameterName = 'playerId'
            }
            method = 'entity';
            break;
        case "staff":
            if(!value.routeName) {
                value.routeName = 'staffProfile';
            }

            if(!value.routeParameterName) {
                value.routeParameterName = 'staffId';
            }

            method = 'entity';
            break;
        case 'cup':
            method = 'cup';
            break;
        case 'roundName':
            method = 'roundName';
            break;
        case 'currency':
            method = 'currency';
            break;
        case 'date':
            method = 'date';
            break;
        case 'date-utc':
            method = 'date-utc';
            break;
        case 'datetime':
            method = 'datetime';
            break;
        case 'roleName':
            method = 'roleName';
            break;
    }

    if(value.routeParameterName) {
        params[value.routeParameterName] = value.routeParameterValue;
    }

    switch(method) {
        case 'entity':
            return(
                <span key={value.routeParameterValue}><Link to={generatePath(URL[value.routeName], params)}>{value.text}</Link>{separator}</span>
            );
        case 'firstTeam':
            return(
                <TeamLink team={{type: 'first', club: {id: value.routeParameterValue, name: value.text}}} />
            );
        case 'youthTeam':
            return(
                <TeamLink team={{type: 'youth', club: {id: value.routeParameterValue, name: value.text}}} />
            );
        case 'league':
            return(
                <LeagueLink league={{id: value.routeParameterValue, name: value.text}} />
            );
        case 'cup':
            return(
                <CupName cup={{name: value.text}} />
            );
        case 'roundName':
            return(
                <CupRoundName roundsFromFinal={value.text} />
            );
        case 'string':
            return value.text;
        case 'currency':
            return(
                <Currency value={value.text} />
            );
        case 'date':
            return(
                <FormattedDate value={value.text} day='numeric' month='short' year='numeric' />
            );
        case 'date-utc':
            return(
                <FormattedDate value={value.text} timeZone='UTC' day='numeric' month='short' year='numeric' />
            );
        case 'datetime':
            return(
                <span><FormattedDate value={value.text} day='numeric' month='short' year='numeric' /> at <FormattedTime value={value.text} hour='numeric' minute='numeric' /></span>
            );
        case 'roleName':
            return(
                <StaffRoleName role={value.text} lowercase={true} />
            );
    }
}

const formatValues = function(values, entityType) {
    let formattedValues = [];

    if(values.length === 1) {
        formattedValues.push(formatValue(values[0], entityType, false));
    } else {
        console.log(values);
        formattedValues = values.map((value, index) => {
            if(index === values.length-1) {
                return formatValue(value, entityType, false);
            } else {
                return formatValue(value, entityType, true);
            }
        });
    }

    return(
        <>
            {formattedValues.map((value, i) => (
                <span key={i}>{value}</span>
            ))}
        </>
    );
}

export {formatValue, formatValues};