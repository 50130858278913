import {Button} from "semantic-ui-react";
import {FormattedMessage, FormattedTime} from 'react-intl';

import DataTable from '../../common/components/DataTable';
import PlayerLink from "../../player/common/Link";
import ClubLink from '../../club/components/common/Link';
import InfoLabel from '../../player/common/InfoLabel';
import Currency from "../../common/components/Currency.js";

import { hsvToRgb, ratingToHsv, getColor } from '../../common/lib/color';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faBirthdayCake, faClock, faCoins, faShieldHalved, faStar, faStreetView } from "@fortawesome/free-solid-svg-icons";
import SUIIconShim from "../../common/components/SUIIconShim";
import PlayerExpander from "../../player/common/PlayerExpander/Index";

import { filterStateReducer, initialFilterState } from "../../player/filter/lib";
import usePersistedState from "../../common/lib/usePersistedState";
import { generatePath, useHistory } from "react-router";
import { URL } from "../../common/lib/paths";

export default function PlayerTransferAuctionsInterface(props) {
    const {persistedState, updatePersistedState} = usePersistedState(props.persistedStateKey || 'playerTransferAuctionsFilter', initialFilterState);
    const history = useHistory();

    const columns = [
        {
            name: 'Name',
            selector: player =>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}} data-tag='allowRowEvents'>
                    <div className='playerName'>
                        <PlayerLink player={player} />
                    </div>
                    <div className='infoLabels'>
                        <InfoLabel
                            player={player}
                            playerContract={player.playerContract}
                        />
                    </div>
                </div>,
            ignoreRowClick: true,
            wrap: false,
            allowOverflow: true,
            compact: true,
            grow: 3,
            style: {
                '&[role="cell"]': {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        },
        {
            name: <FontAwesomeIcon icon={faStreetView} />,
            cell: (player) => <div>{player.position}</div>,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            minWidth: '44px',
            maxWidth: '80px',
            grow: 1,
            conditionalCellStyles: [
                {
                    when: (player) => player.position === 'GK',
                    classNames: ['positionStyles', 'positionStylesGK']
                },
                {
                    when: (player) => ['CB','LB','RB','LWB','RWB'].includes(player.position),
                    classNames: ['positionStyles', 'positionStylesDEF']
                },
                {
                    when: (player) => ['CDM','CM','LM','RM'].includes(player.position),
                    classNames: ['positionStyles', 'positionStylesMID']
                },
                {
                    when: (player) => ['CAM', 'LW', 'RW', 'ST'].includes(player.position),
                    classNames: ['positionStyles', 'positionStylesATK']
                }
            ]
        },
        {
            name: <FontAwesomeIcon icon={faShieldHalved} />,
            cell: player => player.playerContract?.id && <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}><ClubLink club={player.playerContract.club} /></div> || 'Free agent',
            wrap: false,
            allowOverflow: false,
            compact: true,
            grow: 2,
            hide: 650
        },
        {
            name: <FontAwesomeIcon icon={faBirthdayCake} />,
            selector: (player) => player.age,
            wrap: false,
            allowOverflow: true,
            compact: true,
            center: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 450,
            grow: 1
        },
        {
            name: <FontAwesomeIcon icon={faCoins} />,
            cell: player => <Currency value={player.value} />,
            wrap: false,
            allowOverflow: false,
            compact: true,
            grow: 1,
            center: true,
            minWidth: '38px',
            maxWidth: '100px',
            hide: 350
        },
        {
            name: <FontAwesomeIcon icon={faClock} />,
            cell: player => (
                <FormattedMessage
                    {...messages.auctionEndLabel}
                    values={{
                        interval: player.playerContract?.playerTransferAuctionPaid.interval || player.playerTransferAuctionFree.interval
                    }}
                />
            ),
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            grow: 1,
            minWidth: '45px',
            maxWidth: '100px'
        },
        {
            name: <FontAwesomeIcon icon={faStar} />,
            selector: player => player.currentAbility,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            width: '32px',
            conditionalCellStyles: [
                {
                    when: () => true,
                    style: player => ({
                        backgroundColor: hsvToRgb(ratingToHsv(player.currentAbility)),
                        color: getColor(hsvToRgb(ratingToHsv(player.currentAbility)).substr(1)),
                        fontWeight: '700'
                    })
                }
            ]
        }
    ];

    return(
        <>
            <DataTable
                columns={columns}
                data={props.players}
                isLoading={props.isLoading}
                placeholderCount={10}
                // expandableRows
                // expandableRowsComponent={PlayerExpander}
                onRowClicked={(row) => {
                    if(!!row.playerContract.playerTransferAuctionPaid && !!row.playerContract.playerTransferAuctionPaid.id) {
                        history.push(generatePath(URL.playerTransferAuction, {playerTransferAuctionId: row.playerContract.playerTransferAuctionPaid.id}));
                    } else if(!!row.playerTransferAuctionFree) {
                        history.push(generatePath(URL.playerTransferAuctionFree, {playerId: row.id, playerFreeAuctionId: row.playerTransferAuctionFree.id}));
                    }
                }}
            />
            <Button.Group>
                <Button
                    icon
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage'}))}
                    color='black'
                    disabled={persistedState.currentPage === 1}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </SUIIconShim>
                </Button>
                <Button
                    disabled
                    className="opacity-override"
                    color='black'
                    style={{width: '10em'}}
                >
                    Page {persistedState.currentPage}
                </Button>
                <Button
                    icon
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'nextPage', value: {fromCARank: props.players[props.players.length-1].playerContract.playerTransferAuctionPaid.id}}))}
                    color='black'
                    disabled={persistedState.currentPage === persistedState.maxPage}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </SUIIconShim>
                </Button>
            </Button.Group>
        </>
    );
}

const messages = {
    auctionEndLabel: {
        id: 'playerTransferAuctions:endDateLabel',
        defaultMessage: '{interval} hrs',
        description: 'LAbel for the auction end date/time'
    }
}