import { useParams } from "react-router";
import CreatePlayerTransferAuctionBidPaidInterface from "./interface";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function CreatePlayerTransferAuctionBidPaid(props) {
    const {playerTransferAuctionId} = useParams();

    const getPlayerTransferAuction = useQuery(
        ['getPlayerTransferAuction', playerTransferAuctionId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionPaid/${playerTransferAuctionId}`)
    );

    const playerId = getPlayerTransferAuction.data?.data.response.playerContract.player.id;

    const contractDemandsQuery = useQuery(
        ['getPlayerContractDemands', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/contractDemands`),
        {
            enabled: !!playerId
        }
    );

    const clubBudgetQuery = useQuery(
        ['getClubBudgets'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/budgets`)
    );

    const isLoading = getPlayerTransferAuction.isLoading || contractDemandsQuery.isLoading || clubBudgetQuery.isLoading

    return(
        <CreatePlayerTransferAuctionBidPaidInterface
            isLoading={isLoading}
            contractLengthOptions={contractDemandsQuery.data?.data.response.contractLengthOptions}
            defaultContractLengthIndex={contractDemandsQuery.data?.data.response.defaultContractLengthIndex}
            negotiationFactors={contractDemandsQuery.data?.data.response.negotiationFactors}
            existingContract={contractDemandsQuery.data?.data.response.player.playerContract}
            finances={clubBudgetQuery.data?.data.response}
            playerTransferAuctionPaid={getPlayerTransferAuction.data?.data.response}
            player={contractDemandsQuery.data?.data.response.player}
            isInterested={contractDemandsQuery.data?.data?.response.isInterested}
        />
    );
}