import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {useParams} from 'react-router-dom';

import NarrowLayout from '../../common/components/NarrowLayout';
import FixtureInterface from './interface';

const paths = {
    league: 'leagueFixtures',
    cup: 'cupFixtures'
}

export default function FixtureData(props) {
    const {fixtureId} = useParams();

    const {isLoading, data} = useQuery(
        ['getFixture', props.type, fixtureId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/${paths[props.type]}/${fixtureId}`),
        {
            enabled: fixtureId !== undefined
        }
    );

    useEffect(() => {
        props.setToolbar({title: 'Match report', navigation: []});
    }, []);

    useEffect(() => {
        props.setHideScrollbar(true);

        return () => {
            props.setHideScrollbar(false);
        }
    }, []);

    return(
        <NarrowLayout>
            <FixtureInterface
                isLoading={isLoading}
                fixture={data?.data.response}
                matchData={data?.data.response.matchData}
                type={props.type}
                manager={props.manager}
            />
        </NarrowLayout>
    )
}