import useBodyClass from "../useBodyClass";
import { memo, useEffect, useState, useRef } from "react";
import initApp from "./pixiApp";

function PlayHighlight(props) {
    const isInit = useRef(false);
    const mediaPlayer = useRef(null);
    const app = useRef(null);
    const canvasRef = useRef(null);
    const [canvasSize, setCanvasSize] = useState({width: 600, height: 400});
    const [orientationClass, setOrientationClass] = useState([]);

    useBodyClass('fullScreen');

    useEffect(() => {
        if(app.current === null || !app.current.ticker) return;

        if(props.isPaused === true) {
            app.current.ticker.stop();
        } else if(props.isPaused === false) {
            app.current.ticker.start();
        }
    }, [props.isPaused]);

    useEffect(() => {
        if(isInit.current === false || mediaPlayer.current === null || mediaPlayer.current.isInProgress === true || !mediaPlayer.current.app) return;

        if(!!props.highlight) {
            mediaPlayer.current.loadBuffer(props.highlight, props.handleHighlightFinished);
        }
    }, [props.highlight]);

    useEffect(() => {
        if(isInit.current === true) return;
        isInit.current = true;

        const {mediaPlayer: newMediaPlayer, app: newApp} = initApp(canvasRef, setCanvasSize, setOrientationClass, props.handleUpdateCommentary);
        mediaPlayer.current = newMediaPlayer;
        app.current = newApp;
    }, []);

    return(
        <div style={{flex: '1 1 auto', backgroundColor: '#009444', overflow: 'auto'}}>
            <div id='canvasContainer' className={!!props.highlight && !props.isPaused ? 'ui dimmable' : 'ui dimmable dimmed'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', overflow: 'hidden'}}>
                <div className={orientationClass} style={canvasSize}>
                    <canvas ref={canvasRef} style={{width: '100%', height: '100%'}}></canvas>
                </div>
                <div className='ui simple dimmer'></div>
            </div>
        </div>
    );
}

export default memo(PlayHighlight);