import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../../common/lib/paths.js";
import { faHockeyPuck } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_ClubSadium.text",
		defaultMessage: "Stadium",
		description: "Link for club stadium page"
	}
});

export default function ClubEditStadiumToolbarItem(props) {
	return(
		<ToolbarItem
			path={generatePath(URL.editClubStadium)}
			icon={faHockeyPuck}
            messages={messages}
			activeToolbarItemRef={props.activeToolbarItemRef}
		/>
	);
}