import { useEffect } from 'react';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router';
import axios from 'axios';

import ClubProfileToolbarItem from '../toolbar/ClubProfile';
import TeamPlayersToolbarItem from '../toolbar/TeamPlayers';
import TeamFixturesToolbarItem from '../toolbar/TeamFixtures';
import ClubStaffToolbarItem from '../toolbar/ClubStaff';
import ClubTransfersToolbarItem from '../toolbar/ClubTransfers';
import TeamLeagueSeasonsToolbarItem from '../toolbar/TeamLeagueSeasons';
import TeamLeagueToolbarItem from '../toolbar/TeamLeague';
import { FormattedMessage } from 'react-intl';
import CoachDept from './departments/Coach';
import PhysioDept from './departments/Physio';
import ScoutDept from './departments/Scout';
import YouthDept from './departments/Youth';
import NarrowLayout from '../../../common/components/NarrowLayout';
import StaffTrainingBoosts from './staffTrainingBoosts/data';

export default function ClubStaff(props) {
    const {clubId, teamType = 'first'} = useParams();
    
    const coachingDeptQuery = useQuery(
        ['getClubCoachingStaff', clubId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/departments?role=coach`),
        {
            enabled: clubId !== undefined
        }
    );

    const physioDeptQuery = useQuery(
        ['getClubPhysioStaff', clubId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/departments?role=physio`),
        {
            enabled: clubId !== undefined
        }
    );

    const scoutingDeptQuery = useQuery(
        ['getClubScoutingStaff', clubId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/departments?role=scout`),
        {
            enabled: clubId !== undefined
        }
    );

    const youthDeptQuery = useQuery(
        ['getClubYouthStaff', clubId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/departments?role=youth`),
        {
            enabled: clubId !== undefined
        }
    );

    useEffect(() => {
        if(coachingDeptQuery.data?.data?.response) {
            const isOwner = !!props.manager.club && (parseInt(clubId) === props.manager.club.id);

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{clubName: coachingDeptQuery.data.data.response.name}} />,
                navigation: [
                    <ClubProfileToolbarItem clubId={clubId} key={1} />,
                    <TeamPlayersToolbarItem clubId={clubId} teamType={teamType} key={2} />,
                    <TeamFixturesToolbarItem clubId={clubId} teamType={teamType} key={5} />,
                    <TeamLeagueToolbarItem clubId={clubId} teamType={teamType} key={6} />,
                    <ClubStaffToolbarItem clubId={clubId} key={7} />,
                    <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                    <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType={teamType} key={9} />
                ]
            })
        }
    }, [coachingDeptQuery.data?.data?.response]);

    return(
        <NarrowLayout>
            <StaffTrainingBoosts />
            <CoachDept
                isLoading={coachingDeptQuery.isLoading}
                coachingDept={coachingDeptQuery.data?.data.response}
            />
            <PhysioDept
                isLoading={physioDeptQuery.isLoading}
                physioDept={physioDeptQuery.data?.data.response}
            />
            <ScoutDept
                isLoading={scoutingDeptQuery.isLoading}
                scoutingDept={scoutingDeptQuery.data?.data.response}
            />
            <YouthDept
                isLoading={youthDeptQuery.isLoading}
                youthDept={youthDeptQuery.data?.data.response}
            />
        </NarrowLayout>
    );
}

const messages = {
    screenTitle: {
        id: 'todo',
        defaultMessage: '{clubName} staff',
        descripion: 'todo'
    }
}