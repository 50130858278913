import NarrowLayout from '../../../../common/components/NarrowLayout';
import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import TitledSegment from '../../../../common/components/TitledSegment';
import { Button } from 'semantic-ui-react';
import TransferInfo from '../common/TransferInfo';
import ContractSummary from '../../../common/ContractSummary';

export default function PlayerFreeAuctionViewOfferInterface(props) {
    const queryClient = useQueryClient();

    const deletePlayerFreeAuctionOffer = useMutation(
        (playerFreeAuctionBidId) => axios.delete(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionBidFree/${playerFreeAuctionBidId}`),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries('getPlayerFreeAuction');
            }
        }
    );

    function handleWithdrawContractOffer() {
        deletePlayerFreeAuctionOffer.mutate(props.playerFreeAuction.playerTransferAuctionBidFree.id);
    }

    return(
        <NarrowLayout>
            <TransferInfo
                player={props.player}
                playerFreeAuction={props.playerFreeAuction}
            />
            <TitledSegment
                title='Contract offer'
            >
                <p style={{margin: 0}}>This is the contract that you offered.</p>
                <ContractSummary
                    salary={props.playerFreeAuction?.playerTransferAuctionBidFree.contractSalaryOffer}
                    salaryLabel='Salary offer'
                    contractEndDate={props.playerFreeAuction?.playerTransferAuctionBidFree.endSeasonOffer.endDate}
                    contractExpiryLabel='Contract expiry'
                />
                <Button
                    fluid
                    negative
                    onClick={handleWithdrawContractOffer}
                    loading={deletePlayerFreeAuctionOffer.isLoading}
                >
                    Withdraw offer
                </Button>
            </TitledSegment>
        </NarrowLayout>
    )
}