import { useIntl } from "react-intl";
import useBodyClass from "../../useBodyClass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faMinus, faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";

export default function ControlledCurrencyInput(props) {
    const [showFormatted, setShowFormatted] = useState(true);
    const incrementTimer = useRef(null);
    const decrementTimer = useRef(null);

    const intl = useIntl();
    useBodyClass('noSelect');

    return(
        <div style={{display: 'flex'}}>
            <div className="ui fluid action labeled input transferBid" style={{flex: '1 1 auto'}}>
                <div className="ui label label">
                    <FontAwesomeIcon icon={faCoins} />
                </div>
                <input
                    type="text"
                    value={showFormatted ? intl.formatNumber(props.value, {notation: 'compact', compactDisplay: 'short', maximumFractionDigits: props.maximumFractionDigits || undefined}) : props.value}
                    disabled={props.value === null}
                    onChange={(e) => props.setValue(sanitise(e.target.value))}
                    onFocus={() => setShowFormatted(false)}
                    onBlur={() => setShowFormatted(true)}
                />
                <button
                    className="ui icon button currencyInputControl"
                    onMouseDown={handleDecreaseOfferHold.bind(this)}
                    onTouchStart={handleDecreaseOfferHold.bind(this)}
                    onTouchEnd={clearDecreaseOfferHold.bind(this)}
                    onMouseOut={clearDecreaseOfferHold.bind(this)}
                    onMouseUp={clearDecreaseOfferHold.bind(this)}
                    type='button'
                >
                    <FontAwesomeIcon icon={faMinus} />
                </button>
                <button
                    className="ui icon button currencyInputControl"
                    onTouchStart={handleIncreaseOfferHold.bind(this)}
                    onMouseDown={handleIncreaseOfferHold.bind(this)}
                    onTouchEnd={clearIncreaseOfferHold.bind(this)} 
                    onMouseOut={clearIncreaseOfferHold.bind(this)}
                    onMouseUp={clearIncreaseOfferHold.bind(this)}
                    type='button'
                >
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
            <button
                className="ui icon button"
                type="button"
                onClick={props.resetValue}
                style={{flex: '0 0 auto', marginLeft: '0.5em', marginRight: 0}}
            >
                <FontAwesomeIcon icon={faRefresh} />
            </button>
        </div>
    );

    function handleIncreaseOfferHold() {
        incrementTimer.current = setInterval(increaseOffer, 125);
        increaseOffer();
    }

    function increaseOffer() {
        props.setValue((prevValue) => handleIncreaseOffer(prevValue))
    }

    function handleIncreaseOffer(prev) {
        const increment = props.getIncrement(prev, props.maximum);

        console.log(increment);

        if(!increment) {
            clearIncreaseOfferHold();
            return props.maximum || prev;
        }

        return Math.round(prev + increment);
        return floorToNearest(prev, increment) + increment;
    }

    function clearIncreaseOfferHold() {
        clearInterval(incrementTimer.current);
    }

    function handleDecreaseOfferHold() {
        decrementTimer.current = setInterval(decreaseOffer, 125);
        decreaseOffer();
    }

    function decreaseOffer() {
        props.setValue((prevValue) => handleDecreaseOffer(prevValue));
    }

    function handleDecreaseOffer(prev) {
        const decrement = props.getDecrement(prev, props.minimum || 0);

        if(!decrement) {
            console.log('oop');
            clearDecreaseOfferHold();
            return props.minimum || 0;
        }

        return Math.round(prev - decrement);
        return ceilToNearest(prev, decrement) - decrement;
    }

    function clearDecreaseOfferHold() {
        clearInterval(decrementTimer.current);
    }
}

function floorToNearest(numToRound, numToRoundTo) {
    return Math.floor(numToRound / numToRoundTo) * numToRoundTo;
}

function ceilToNearest(numToRound, numToRoundTo) {
    return Math.ceil(numToRound / numToRoundTo) * numToRoundTo;
}

function sanitise(value) {
    return value.replace(/[^0-9 | ^.]/g, '');
}