import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { useParams } from "react-router";
import PlayerContractOfferInterface from "./interface";
import { useEffect } from "react";
import ShortenedPlayerName from "../../common/ShortenedName";

import Profile from "../../toolbar/Profile.js";
import History from "../../toolbar/History.js";
import PlayerContractOfferNew from '../../toolbar/Contract.js';
import { Message } from "semantic-ui-react";

export default function PlayerContractOffer(props) {
    const {playerId} = useParams();

    const contractDemandsQuery = useQuery(
        ['getPlayerContractDemands', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/contractDemands`)
    );

    const clubBudgetQuery = useQuery(
        ['getClubBudgets'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/budgets`)
    );

    useEffect(() => {
        if(!!contractDemandsQuery.data?.data.response.player) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={contractDemandsQuery.data?.data.response.player} /> contract renewal</>,
                navigation: [
                    <Profile key={1} playerId={playerId} />,
                    <PlayerContractOfferNew key={7} playerId={playerId} isMyPlayer={true} />,
                    <History key={5} playerId={playerId} />,
                ]
            })
        }
    }, [contractDemandsQuery.data?.data.response]);

    if(contractDemandsQuery.data?.data.response.key === 'LENGTH_MAX_CONSTRAINT') {
        return(
            <Message
                negative
            >
                <Message.Header>Contract length limit</Message.Header>
                <Message.Content>This player will not consider a new contract as they already have the maximum contract length.</Message.Content>
            </Message>
        )
    }

    return(
        <PlayerContractOfferInterface
            isLoading={contractDemandsQuery.isLoading || clubBudgetQuery.isLoading}
            isInterested={contractDemandsQuery.data?.data?.response.isInterested}
            player={contractDemandsQuery.data?.data?.response.player}
            negotiationFactors={contractDemandsQuery.data?.data.response.negotiationFactors}
            contractLengthOptions={contractDemandsQuery.data?.data.response.contractLengthOptions}
            defaultContractLengthIndex={contractDemandsQuery.data?.data.response.defaultContractLengthIndex}
            existingContract={contractDemandsQuery.data?.data.response.player.playerContract}
            finances={clubBudgetQuery.data?.data.response}
        />
    );
}