import {Segment, Dropdown} from 'semantic-ui-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export function LeagueTacticSelector(props) {
    const queryClient = useQueryClient();

    const leagueFixtureTacticQuery = useQuery(
        ['getLeagueFixtureTactic', props.fixtureId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagueFixtures/${props.fixtureId}/teamTactic`)
    );

    const setLeagueFixtureTactic = useMutation(
        (teamTacticId) => axios.patch(`${process.env.REACT_APP_APPHOST}/leagueFixtures/${props.fixtureId}`, {
            teamTacticId
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getLeagueFixtureTactic')
            }
        }
    )

    const getTeamTacticsQuery = useQuery(
        ['getTeamTactics', leagueFixtureTacticQuery.data?.data.response.teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/teams/${leagueFixtureTacticQuery.data?.data.response.teamType}/teamTactics`),
        {
            enabled: !!leagueFixtureTacticQuery.data?.data.response
        }
    );

    return (
        <TacticSelector
            isLoading={leagueFixtureTacticQuery.isLoading || getTeamTacticsQuery.isLoading}
            teamTactics={getTeamTacticsQuery.data?.data.response.teamTactics || []}
            selectedTeamTacticId={leagueFixtureTacticQuery.data?.data.response.id}
            handleSelectTactic={setLeagueFixtureTactic.mutate}
        />
    );
}

export function CupTacticSelector(props) {
    const queryClient = useQueryClient();

    const leagueFixtureTacticQuery = useQuery(
        ['getCupFixtureTactic', props.fixtureId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/cupFixtures/${props.fixtureId}/teamTactic`)
    );

    const setLeagueFixtureTactic = useMutation(
        (teamTacticId) => axios.patch(`${process.env.REACT_APP_APPHOST}/cupFixtures/${props.fixtureId}`, {
            teamTacticId
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getCupFixtureTactic')
            }
        }
    )

    const getTeamTacticsQuery = useQuery(
        ['getTeamTactics', leagueFixtureTacticQuery.data?.data.response.teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/teams/${leagueFixtureTacticQuery.data?.data.response.teamType}/teamTactics`),
        {
            enabled: !!leagueFixtureTacticQuery.data?.data.response
        }
    );

    return (
        <TacticSelector
            isLoading={leagueFixtureTacticQuery.isLoading || getTeamTacticsQuery.isLoading}
            teamTactics={getTeamTacticsQuery.data?.data.response.teamTactics || []}
            selectedTeamTacticId={leagueFixtureTacticQuery.data?.data.response.id}
            handleSelectTactic={setLeagueFixtureTactic.mutate}
        />
    );
}

function TacticSelector(props) {
    return(
        <Segment>
            <p>Select a saved tactic for this fixture</p>
            <Dropdown
                selection
                options={props.teamTactics.map((tactic) => (
                    {
                        value: tactic.id,
                        key: tactic.id,
                        text: `Tactic ${tactic.name} ${tactic.isActive === true ? '(active)' : ''}`
                    }
                ))}
                value={props.selectedTeamTacticId}
                onChange={(e, {value}) => props.handleSelectTactic(value)}
            />
        </Segment>
    );
}