import { useEffect } from "react";
import ClubFacilitiesInterface from "./interface";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import ViewClubStadiumToolbarItem from "../viewStadium/ViewStadiumToolbarItem";
import ViewClubFacilitiesToolbarItem from "./ViewFacilitiesToolbarItem";
import { useParams } from "react-router";

export default function ClubFacilities(props) {
    const {clubId} = useParams();

    const getClubFacilities = useQuery(
        ['getClubFacilities', props.manager.club.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/clubFacilities`)
    );

    const coachingDeptQuery = useQuery(
        ['getClubCoachingStaff', props.manager.club.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/departments?role=coach`)
    );

    const physioDeptQuery = useQuery(
        ['getClubPhysioStaff', props.manager.club.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/departments?role=physio`)
    );

    const youthDeptQuery = useQuery(
        ['getClubYouthStaff', props.manager.club.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/departments?role=youth`)
    );
    
    
    useEffect(() => {
        if(!!getClubFacilities.data?.data.response) {
            props.setToolbar({
                navigation: [
                    <ViewClubStadiumToolbarItem clubId={clubId} />,
                    <ViewClubFacilitiesToolbarItem clubId={clubId} />
                ],
                title: `${getClubFacilities.data?.data.response.name} facilities`
            })
        }
    }, [getClubFacilities.data?.data.response]);

    const isLoading = getClubFacilities.isLoading || coachingDeptQuery.isLoading || physioDeptQuery.isLoading || youthDeptQuery.isLoading;

    return(
        <ClubFacilitiesInterface
            isLoading={isLoading}
            clubFacilities={getClubFacilities.data?.data.response.clubFacilities}
            coachDept={coachingDeptQuery.data?.data.response}
            physioDept={physioDeptQuery.data?.data.response}
            youthDept={youthDeptQuery.data?.data.response}
        />
    )
}