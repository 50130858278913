import {Loader, Table, Button, Checkbox} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {useHistory, generatePath} from 'react-router';

import MessageSubjectInterface from '../message/MessageSubject/interface';

import {URL} from '../../../common/lib/paths';
import { formatValues } from './lib';
import WideLayout from '../../../common/components/WideLayout';
import SUIIconShim from '../../../common/components/SUIIconShim';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faCheckDouble, faTrash } from '@fortawesome/free-solid-svg-icons';
import MessageErrorBoundary from './MessageErrorBoundary';
import { useState } from 'react';
import NarrowLayout from '../../../common/components/NarrowLayout';
import { useTutorial } from '../../../tutorial/hooks/useTutorial';
import MessageSenderInterface from '../message/MessageSender/interface';

export default function InboxInterface(props) {
	const history = useHistory();
	const [selectMode, setSelectMode] = useState(false);
	const [selectedMessageIds, setSelectedMessageIds] = useState([]);
	const { goNextStep, checkTutorial } = useTutorial();

	const isTutorial1 = checkTutorial('messages', 1);
	const isTutorial2 = checkTutorial('messages', 3);
	const isTutorial3 = checkTutorial('messages', 4);

	if(props.isLoading) {
		return(
			<Loader active />
		);
	}

	const renderNoMessagesRow = () => {
		if(props.messages.length === 0) {
			return(
				<Table.Row>
					<Table.Cell colSpan={2}><FormattedMessage {...messages.noMessages} /></Table.Cell>
				</Table.Row>
			)
		} else {
			return null;
		}
	}

	const handleSelectMessage = (messageId) => {
		if(isTutorial1) {
			goNextStep();
			return;
		}

		if(selectMode === true) {
			if(selectedMessageIds.includes(messageId)) {
				setSelectedMessageIds((s) => s.filter((i) => i !== messageId));
			} else {
				setSelectedMessageIds((s) => [...s, messageId]);
			}
			
		} else {
			history.push(generatePath(URL.message, {messageId: messageId}));
		}
	}

	function handleSelectAll() {
		if(isTutorial2) {
			goNextStep();
		}

		if(selectedMessageIds.length > 0) {
			setSelectedMessageIds([]);
		} else {
			setSelectedMessageIds(props.messages.map((m) => m.id))
		}

		if(selectMode === false) {
			setSelectMode(true);
		}
	}

	function handleDelete() {
		if(isTutorial3) {
			goNextStep();
		}
		
		props.deleteSelectedMessages(selectedMessageIds)
		setSelectMode(false);
	}

	function handleSelectModeClick() {
		if(isTutorial2) {
			goNextStep();
		}

		setSelectMode(s => !s);
	}

	return(
		<>
			<NarrowLayout>
				<div style={{marginBottom: '1em', overflow: 'hidden', width: '100%'}}>
					<Button.Group size='small' floated='left'>
						<Button
							disabled={props.messages.length === 0}
							icon
							onClick={handleSelectModeClick.bind(this)}
							color='black'
						>
							<SUIIconShim>
								<FontAwesomeIcon icon={faCheck} />
							</SUIIconShim>
						</Button>
						<Button
							disabled={props.messages.length === 0}
							icon
							onClick={() => handleSelectAll()}
							color='black'
							className={isTutorial2 && 'glow'}
						>
							<SUIIconShim>
								<FontAwesomeIcon icon={faCheckDouble} />
							</SUIIconShim>
						</Button>
					</Button.Group>
					<Button
						disabled={selectedMessageIds.length === 0}
						icon
						floated='right'
						loading={props.deleteInProgress}
						onClick={() => handleDelete()}
						className={isTutorial3 && 'glow'}
						size='small'
						color='black'
					>
						<SUIIconShim>
							<FontAwesomeIcon icon={faTrash} />
						</SUIIconShim>
					</Button>
				</div>
				<Table selectable compact celled unstackable>
					<Table.Header>
						<Table.Row>
							{selectMode === true && <Table.HeaderCell />}
							<Table.HeaderCell><FormattedMessage {...messages.fromLabel} /></Table.HeaderCell>
							<Table.HeaderCell><FormattedMessage {...messages.subjectLabel} /></Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{renderNoMessagesRow()}
						{props.messages.map((message) => (
							<MessageErrorBoundary key={message.id} id={message.id} type={message.type}>
								<Table.Row
									key={message.id}
									onClick={handleSelectMessage.bind(this, message.id)}
									style={message.read ? {cursor: 'pointer'} : {cursor: 'pointer', backgroundColor: '#F9FAFB'}}
									className={isTutorial1 && 'glow'}
								>
									{selectMode === true &&
										<Table.Cell width={1}>
											<Checkbox checked={selectedMessageIds.includes(message.id)} style={{display: 'block'}} />
										</Table.Cell>
									}
									<Table.Cell width={selectMode ? 5 : 6} style={columnStyle}><MessageSenderInterface message={message} /></Table.Cell>
									<Table.Cell width={8} style={columnStyle}><MessageSubjectInterface message={message} /></Table.Cell>
								</Table.Row>
							</MessageErrorBoundary>
						))}
					</Table.Body>
				</Table>
			</NarrowLayout>
		</>
	)
}

const columnStyle = {
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	overflow: "hidden",
	maxWidth: '1px'
}

const messages = {
	dateLabel: {
		id: "Layout_Messages.dateLabel",
		defaultMessage: "Date",
		description: "Label for the header of the column which contains the date that the message was sent"
	},
	fromLabel: {
		id: "Layout_Messages.fromLabel",
		defaultMessage: "From",
		description: "Label for the header of the column which contains the name of the sender of the message"
	},
	subjectLabel: {
		id: "Layout_Messages.subjectLabel",
		defaultMessage: "Subject",
		description: "Label for the header of the column which contains the subject of the message"
	},
	messageLabel: {
		id: "Layout_Messages.messageLabel",
		defaultMessage: "Message",
		description: "Label for the header of the column which contains the message text"
	},
	deleteAllMessages: {
		id: "Layout_Messages.deleteAllMessages",
		defaultMessage: "Delete all",
		description: "Label for the button which deletes all messages"
	},
	newMessage: {
		id: "Layout_Messages.newMessage",
		defaultMessage: "New",
		description: "Label for the button which creates a new message"
	},
	noMessages: {
		id: "Layout_Messages.noMessages",
		defaultMessage: "You have no messages",
		description: "Error which explains that the user does not have any messages"
	}
}