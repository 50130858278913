import { faCheck, faPlus, faStar, faStreetView } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useState} from 'react';
import { Button, Modal, Table, Label} from 'semantic-ui-react';
import InfoLabel from '../../../player/common/InfoLabel';
import {formations} from 'shared-messages';
import ShortenedPlayerName from '../../../player/common/ShortenedName';
import { useTutorial } from '../../../tutorial/hooks/useTutorial';
import PositionLabel from '../../../player/common/position/PositionLabel';

export default function PlayerRegistrationSelector(props) {
    const [selectedPlayerRegistrationId, setSelectedPlayerRegistrationId] = useState(props.selectedPlayerRegistrationId || null);
    const {checkTutorial} = useTutorial();
    
    const isTutorial1 = checkTutorial('training', 3);

    if(!props.playerRegistrations) return null;

    return(
        <Modal
            onClose={() => props.setOpen(false)}
            open={props.open}
        >
            <Modal.Header className='headerFixPadding'>Select player</Modal.Header>
            <Modal.Content scrolling>
                <Table
                    unstackable
                    selectable
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell collapsing><FontAwesomeIcon icon={faStreetView} /></Table.HeaderCell>
                            <Table.HeaderCell collapsing><FontAwesomeIcon icon={faStar} /></Table.HeaderCell>
                            
                            {props.additionalColumns?.map((column) => (
                                <Table.HeaderCell collapsing><FontAwesomeIcon icon={column[0]} /></Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {props.playerRegistrations.map((playerRegistration) => 
                            <Table.Row
                                onClick={() => setSelectedPlayerRegistrationId(playerRegistration.id)}
                                positive={selectedPlayerRegistrationId === playerRegistration.id}
                                disabled={playerRegistration.playerContract.clubId !== props.manager.club.id}
                            >
                                <Table.Cell>
                                    <PositionLabel
                                        teamTacticPosition={playerRegistration.teamTacticPosition}
                                    />
                                    <ShortenedPlayerName player={playerRegistration.playerContract.player} />
                                    <InfoLabel
                                        player={playerRegistration.playerContract.player}
                                        playerContract={playerRegistration.playerContract}
                                    />
                                    {selectedPlayerRegistrationId === playerRegistration.id && <FontAwesomeIcon icon={faCheck} className='inlineIconMirror' />}
                                </Table.Cell>
                                <Table.Cell>{playerRegistration.playerContract.player.position}</Table.Cell>
                                <Table.Cell>{playerRegistration.playerContract.player.currentAbility}</Table.Cell>
                                {props.additionalColumns?.map((column) => (
                                    <Table.Cell key={column[1]}>{playerRegistration.playerContract.player[column[1]]}</Table.Cell>
                                ))}
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)}>Cancel</Button>
                <Button
                    positive
                    onClick={() => props.handleSelectPlayerRegistration(selectedPlayerRegistrationId, isTutorial1)}
                    className={isTutorial1 && 'glow'}
                >
                    Select player
                </Button>
            </Modal.Actions>
        </Modal>
    )
}