import {Grid, Image, Header, Menu, Segment, Loader, Divider, Button, Message} from 'semantic-ui-react';
import {FormattedMessage, FormattedDate} from 'react-intl';
import {generatePath, useHistory, useLocation} from 'react-router';

import PlayerLink from '../../player/common/Link';
import LeagueLink from '../../league/components/common/Link';
import ClubLink from '../../club/components/common/Link';
import CupLink from '../../cup/link/CupLink';
import CupRoundName from '../../cup/RoundName';

import Stats from './views/Stats';
import Lineups from './views/Lineups';
import Summary from './views/Summary';
import Ratings from './views/Ratings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faFutbol, faAward, faTrophy, faPlay, faStar } from '@fortawesome/free-solid-svg-icons';
import {useTutorial} from '../../tutorial/hooks/useTutorial';
import colours from '../../common/colours';
import { CupTacticSelector, LeagueTacticSelector } from './tacticSelectors';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { URL } from '../../common/lib/paths';
import { Link } from 'react-router-dom';
import SUIIconShim from '../../common/components/SUIIconShim';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import { reduceKeyEvents } from './lib';
import TitledSegment from '../../common/components/TitledSegment';
import FixtureList from '../../fixtures/FixtureList';
import LoadingCards from '../../fixtures/LoadingCards';

export default function FixtureInterface(props) {
    if(!!props.isLoading) {
        return(
            <div>
                <FixturePreview
                    isLoading={true}
                />
            </div>
        );
    }

    if(props.fixture.status === 'complete') {
        const highlightsAvailable = props.matchData.highlightDataAvailable === true || props.matchData.highlightCount > 0;

        if(!!props.fixture.resultMask) {
            return(
                <div>
                    <FixturePreview
                        fixture={props.fixture}
                        isLoading={props.isLoading}
                        type={props.type}
                    />
                    <ViewResultOrWatchHighlights
                        isLoading={props.isLoading}
                        fixture={props.fixture}
                        type={props.type}
                        highlightsAvailable={highlightsAvailable}
                        resultMask={props.fixture.resultMask}
                    />
                </div>
            );
        } else {
            return(
                <div>
                    <FixtureResultSummary
                        fixture={props.fixture}
                        playerData={props.matchData?.playerData}
                        keyEventData={props.matchData?.keyEventData}
                        teamStatData={props.matchData?.teamStatData}
                        isLoading={props.isLoading}
                        type={props.type}
                        highlights={highlightsAvailable}
                    />
                    <FixtureResultInfo
                        fixture={props.fixture}
                        matchData={props.matchData}
                        isLoading={props.isLoading}
                    />
                </div>
            )
        }
    } else if(props.fixture.status === 'planned') {
        return(
            <div>
                <FixturePreview
                    fixture={props.fixture}
                    isLoading={props.isLoading}
                    type={props.type}
                />
                {props.fixture.homeTeam.club.id === props.manager.club.id || props.fixture.awayTeam.club.id === props.manager.club.id ?
                    props.type === 'league' ?
                        <LeagueTacticSelector
                            fixtureId={props.fixture.id}
                        />
                        :
                        <CupTacticSelector
                            fixtureId={props.fixture.id}
                        />
                    :
                    null
                }
                <HeadToHead
                    manager={props.manager}
                    team1Id={props.fixture.homeTeam.id}
                    team2Id={props.fixture.awayTeam.id}
                />
            </div>
        )
    } else if(props.fixture.status === 'forfeit') {
        return(
            <div>
                <FixtureResultSummary
                    fixture={props.fixture}
                    playerData={props.matchData?.playerData}
                    keyEventData={props.matchData?.keyEventData}
                    teamStats={props.matchData?.teamStats}
                    isLoading={props.isLoading}
                    type={props.type}
                    highlights={false}
                />
            </div>
        )
    } else {
        throw new Error(`unrecognised fixture status: ${props.fixture.status}`);
    }
}

function FixturePreview(props) {
    return(
        <Segment>
            {props.isLoading ?
                <div style={{height: '250px'}}>
                    <Loader active />
                </div>
                :
                <Grid style={{margin: 0}}>
                    <Grid.Row style={{padding: 0}}>
                        <Grid.Column width={16} textAlign='center'>
                            {props.type === 'league' && 
                                <>
                                    <Header as='h4' style={{margin: 0}}><LeagueLink league={props.fixture.leagueFixtureRound.LS.league} /></Header>
                                    <Header as='h4' style={{margin: 0}}><FormattedDate value={props.fixture.leagueFixtureRound.gameJob.startDate} month='long' day='numeric' hour='numeric' /></Header>
                                </>
                            }
                            {props.type === 'cup' &&
                                <>
                                    <Header as='h4' style={{margin: 0}}>
                                        <FontAwesomeIcon icon={faTrophy} className='inlineIcon' color={colours.gold} />
                                        <CupLink cup={props.fixture.cupFixtureRound.CS.cup} />
                                    </Header>
                                    <Header as='h4' style={{margin: 0}}>
                                        <CupRoundName roundsFromFinal={props.fixture.cupFixtureRound.roundsFromFinal} />
                                    </Header>
                                    <Header as='h4' style={{margin: 0}}><FormattedDate value={props.fixture.cupFixtureRound.gameJob.startDate} month='long' day='numeric' hour='numeric' /></Header>
                                </>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6} style={{padding: 0}}>
                            <Image
                                src={`${process.env.PUBLIC_URL}/crests/${props.fixture.homeTeam.club.badgeNumber}.png`}
                                style={{
                                    filter: 'drop-shadow(1px 1px 3px #000)'
                                }}
                                size='tiny'
                                centered
                            />
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='center' verticalAlign='middle' style={{padding: 0}}>
                            <Header as='h2'>
                                <FormattedMessage {...messages.fixtureTitleSeparatorNotPlayed} />
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={6} style={{padding: 0}}>
                            <Image
                                src={`${process.env.PUBLIC_URL}/crests/${props.fixture.awayTeam.club.badgeNumber}.png`}
                                style={{
                                    filter: 'drop-shadow(1px 1px 3px #000)'
                                }}
                                size='tiny'
                                centered
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{padding: 0}}>
                        <Grid.Column width={6} textAlign='center' style={{padding: 0}}>
                            <Header as='h4'><ClubLink club={props.fixture.homeTeam.club} /></Header>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='center'></Grid.Column>
                        <Grid.Column width={6} textAlign='center' style={{padding: 0}}>
                            <Header as='h4'><ClubLink club={props.fixture.awayTeam.club} /></Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </Segment>
    )
}

function FixtureResultSummary(props) {
    if(!props.isLoading && props.fixture.status === 'complete') {
        var keyEvents = reduceKeyEvents(props.keyEventData, props.playerData);
    }

    return(
        <Segment>
            {props.isLoading ?
                <div style={{height: '250px'}}>
                    <Loader active />
                </div>
                :
                <Grid style={{margin: 0}}>
                    <Grid.Row style={{padding: 0}}>
                        <Grid.Column width={16} textAlign='center'>
                            {props.type === 'league' && 
                                <>
                                    <Header as='h4' style={{margin: 0}}><LeagueLink league={props.fixture.leagueFixtureRound.LS.league} /></Header>
                                    <Header as='h4' style={{margin: 0}}><FormattedDate value={props.fixture.leagueFixtureRound.gameJob.startDate} month='long' day='numeric' hour='numeric' /></Header>
                                    {props.highlights === true &&
                                        <Link to={generatePath(URL.leagueFixtureHighlights, {fixtureId: props.fixture.id})}>
                                            <SUIIconShim className='inlineIcon'>
                                                <FontAwesomeIcon icon={faCirclePlay} />
                                            </SUIIconShim>
                                            Watch highlights
                                        </Link>
                                    }
                                </>
                            }
                            {props.type === 'cup' &&
                                <>
                                    <Header as='h4' style={{margin: 0}}>
                                        <FontAwesomeIcon icon={faTrophy} className='inlineIcon' color={colours.gold} />
                                        <CupLink cup={props.fixture.cupFixtureRound.CS.cup} />
                                    </Header>
                                    <Header as='h4' style={{margin: 0}}>
                                        <CupRoundName roundsFromFinal={props.fixture.cupFixtureRound.roundsFromFinal} />
                                    </Header>
                                    <Header as='h4' style={{margin: 0}}><FormattedDate value={props.fixture.cupFixtureRound.gameJob.startDate} month='long' day='numeric' hour='numeric' /></Header>
                                    {props.highlights === true &&
                                        <Link to={generatePath(URL.cupFixtureHighlights, {fixtureId: props.fixture.id})}>
                                            <SUIIconShim className='inlineIcon'>
                                                <FontAwesomeIcon icon={faCirclePlay} />
                                            </SUIIconShim>
                                            Watch highlights
                                        </Link>
                                    }
                                </>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6} style={{padding: 0}}>
                            <Image
                                src={`${process.env.PUBLIC_URL}/crests/${props.fixture.homeTeam.club.badgeNumber}.png`}
                                style={{
                                    filter: 'drop-shadow(1px 1px 3px #000)'
                                }}
                                size='tiny'
                                centered
                            />
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='center' verticalAlign='middle' style={{padding: 0}}>
                            <Header as='h2'>
                                {props.fixture.status === 'complete' ?
                                    <FormattedMessage
                                        {...messages.fixtureTitleSeparatorPlayed}
                                        values={{
                                            homeTeamScore: props.fixture.scoreHome.toString(),
                                            awayTeamScore: props.fixture.scoreAway.toString()
                                        }}
                                    />
                                    :
                                    <FormattedMessage {...messages.fixtureTitleSeparatorNotPlayed} />
                                }
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={6} style={{padding: 0}}>
                            <Image
                                src={`${process.env.PUBLIC_URL}/crests/${props.fixture.awayTeam.club.badgeNumber}.png`}
                                style={{
                                    filter: 'drop-shadow(1px 1px 3px #000)'
                                }}
                                size='tiny'
                                centered
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{padding: 0}}>
                        <Grid.Column width={6} textAlign='center' style={{padding: 0}}>
                            <Header as='h4'><ClubLink club={props.fixture.homeTeam.club} /></Header>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='center'></Grid.Column>
                        <Grid.Column width={6} textAlign='center' style={{padding: 0}}>
                            <Header as='h4'><ClubLink club={props.fixture.awayTeam.club} /></Header>
                        </Grid.Column>
                    </Grid.Row>
                    {props.fixture.status === 'complete' ?
                        <Divider style={{marginBottom: 0}} />
                        :
                        null
                    }
                    {props.fixture.status === 'complete' && (keyEvents.home.goalEvents.length > 0 || keyEvents.away.goalEvents.length > 0) ?
                        <Grid.Row style={{paddingBottom: 0}}>
                            <Grid.Column width={7} textAlign='right' style={{paddingLeft: 0, paddingRight: '0.5em'}}>
                                {
                                    keyEvents.home.goalEvents.map((goalEventGroup) => 
                                        <p className='fixtureKeyEventContainer'>
                                            <Link to={{pathname: generatePath(URL.playerProfile, {playerId: goalEventGroup.playerId})}}>{goalEventGroup.playerName}</Link>&nbsp;<span>{goalEventGroup.goalTimes.map((goalTime, index) => `${goalTime}'${goalEventGroup.isPenalty[index] === true ? ' (P)' : ''}`).join(', ')}</span>
                                        </p>
                                    )
                                }
                            </Grid.Column>
                            <Grid.Column width={2} style={{padding: 0}} textAlign='center'>
                                <FontAwesomeIcon icon={faFutbol} />
                            </Grid.Column>
                            <Grid.Column width={7} textAlign='left' style={{paddingRight: 0,paddingLeft: '0.5em'}}>
                                {
                                    keyEvents.away.goalEvents.map((goalEventGroup) => 
                                        <p className='fixtureKeyEventContainer'>
                                            <span>{goalEventGroup.goalTimes.map((goalTime, index) => `${goalTime}'${goalEventGroup.isPenalty[index] === true ? ' (P)' : ''}`).join(', ')}</span>&nbsp;<Link to={{pathname: generatePath(URL.playerProfile, {playerId: goalEventGroup.playerId})}}>{goalEventGroup.playerName}</Link>
                                        </p>
                                    )
                                }
                            </Grid.Column>
                        </Grid.Row>
                        :
                        null
                    }
                    {props.fixture.status === 'complete' && (keyEvents.home.redCardEvents.length > 0 || keyEvents.away.redCardEvents.length > 0) ?
                        <Grid.Row>
                            <Grid.Column width={7} textAlign='right' style={{paddingLeft: 0, paddingRight: '0.5em'}}>
                                {
                                    keyEvents.home.redCardEvents.map((redCardEvent) =>
                                        <p style={{margin: 0}}>
                                            <PlayerLink player={{id: redCardEvent.playerId, fullName: redCardEvent.playerName}} />
                                            &nbsp;<span>{`${redCardEvent.time}'`}</span>
                                        </p>
                                    )
                                }
                            </Grid.Column>
                            <Grid.Column width={2} style={{padding: 0}} textAlign='center'>
                                <FontAwesomeIcon icon={faSquare} color='#DB2828' />
                            </Grid.Column>
                            <Grid.Column width={7} textAlign='left' style={{paddingRight: 0,paddingLeft: '0.5em'}}>
                                {
                                    keyEvents.away.redCardEvents.map((redCardEvent) =>
                                        <p className='fixtureKeyEventContainer'>
                                            <span>{`${redCardEvent.time}'`}&nbsp;</span>
                                            <PlayerLink player={{id: redCardEvent.playerId, fullName: redCardEvent.playerName}} />
                                        </p>
                                    )
                                }
                            </Grid.Column>
                        </Grid.Row>
                        :
                        null
                    }
                    {props.fixture.status === 'complete' ?
                        <Grid.Row style={{paddingBottom: 0}}>
                            <Grid.Column width={7} textAlign='right' style={{paddingLeft: 0, paddingRight: '0.5em'}}>
                                {props.teamStatData.MOTM.side === 'home' ? <PlayerLink player={{id: props.playerData[props.teamStatData.MOTM.side][props.teamStatData.MOTM.playerIndex].playerId, fullName: `${props.playerData[props.teamStatData.MOTM.side][props.teamStatData.MOTM.playerIndex].initial}. ${props.playerData[props.teamStatData.MOTM.side][props.teamStatData.MOTM.playerIndex].lastName}`}} /> : null}
                            </Grid.Column>
                            <Grid.Column width={2} style={{padding: 0}} textAlign='center'>
                                <FontAwesomeIcon icon={faStar} color={colours.gold} />
                            </Grid.Column>
                            <Grid.Column width={7} textAlign='left' style={{paddingRight: 0,paddingLeft: '0.5em'}}>
                                {props.teamStatData.MOTM.side === 'away' ? <PlayerLink player={{id: props.playerData[props.teamStatData.MOTM.side][props.teamStatData.MOTM.playerIndex].playerId, fullName: `${props.playerData[props.teamStatData.MOTM.side][props.teamStatData.MOTM.playerIndex].initial}. ${props.playerData[props.teamStatData.MOTM.side][props.teamStatData.MOTM.playerIndex].lastName}`}} /> : null}
                            </Grid.Column>
                        </Grid.Row>
                        :
                        null
                    }
                    {props.fixture.status === 'forfeit' ?
                        <Grid.Row columns={1} style={{paddingBottom: 0}}>
                            <Grid.Column style={{textAlign: 'center'}}>
                                {props.fixture.scoreHome > props.fixture.scoreAway ?
                                    <Header as='h5'>{props.fixture.awayTeam.club.name} forfeitted the fixture as they did not have enough players</Header>
                                    :
                                    <Header as='h5'>{props.fixture.homeTeam.club.name} forfeitted the fixture as they did not have enough players</Header>
                                }
                            </Grid.Column>
                        </Grid.Row>
                        :
                        null
                    }
                    {props.fixture.penaltyScoreHome !== null && props.fixture.penaltyScoreAway !== null ?
                        <Grid.Row columns={1} style={{paddingBottom: 0}}>
                            <Grid.Column style={{textAlign: 'center'}}>
                                {props.fixture.penaltyScoreHome > props.fixture.penaltyScoreAway ?
                                    <Header as='h6'>{props.fixture.homeTeam.club.name} won {props.fixture.penaltyScoreHome}-{props.fixture.penaltyScoreAway} on penalties</Header>
                                    :
                                    <Header as='h6'>{props.fixture.awayTeam.club.name} won {props.fixture.penaltyScoreHome}-{props.fixture.penaltyScoreAway} on penalties</Header>
                                }
                            </Grid.Column>
                        </Grid.Row>
                        :
                        null
                    }
                </Grid>
            }
        </Segment>
    );
}

function FixtureResultInfo(props) {
    const history = useHistory();
    const location = useLocation();
    const {checkTutorial, getSearchParamsString} = useTutorial();

    const isTutorial1 = checkTutorial('fixtures', 5);

    if(props.fixture.status !== 'complete') {
        throw new Error('invalid fixture status');
    }

    if(!!props.isLoading) return null;

    return (
        <>
            <Menu pointing secondary fluid widths={4} attached='top' style={{backgroundColor: '#FFF'}}>
                <Menu.Item name='Stats' active={location.state?.view === 'stats' || !location.state} onClick={() => history.replace({pathname: location.pathname, state: {view: 'stats'}})} />
                <Menu.Item name='Summary' active={location.state?.view === 'summary'} onClick={() => history.replace({pathname: location.pathname, state: {view: 'summary'}})} />
                <Menu.Item name='Ratings' active={location.state?.view === 'ratings'} onClick={() => history.replace({pathname: location.pathname, state: {view: 'ratings', side: 'home'}})} />
                <Menu.Item name='Line ups' className={isTutorial1 && 'glow'} active={location.state?.view === 'lineups'} onClick={() => history.replace({pathname: location.pathname, state: {view: 'lineups'}, search: isTutorial1 && getSearchParamsString('fixtures', 6)})} />
            </Menu>
            <Segment attached='bottom' style={getSegmentStyles()} color={(location.state?.view === 'lineups' ? 'black' : null)} inverted={(location.state?.view === 'lineups' ? true : false)}>
                {location.state?.view === 'ratings' && !props.isLoading ?
                    <Ratings
                        playerData={props.matchData?.playerData}
                        playerStatData={props.matchData?.playerStatData}
                        tacticData={props.matchData?.tacticData}
                        teamStatData={props.matchData?.teamStatData}
                        isLoading={props.isLoading}
                        homeClubName={props.fixture.homeTeam.club.name}
                        awayClubName={props.fixture.awayTeam.club.name}
                    />
                    :
                    null
                }
                {(location.state?.view === 'stats' || !location.state) && !props.isLoading ?
                    <Stats
                        playerData={props.matchData?.playerData}
                        playerStatData={props.matchData?.playerStatData}
                        teamStatData={props.matchData?.teamStatData}
                        isLoading={props.isLoading}
                    />
                    :
                    null
                }
                {location.state?.view === 'lineups' && !props.isLoading ?
                    <Lineups
                        tacticData={props.matchData?.tacticData}
                        playerData={props.matchData?.playerData}
                        playerStatData={props.matchData?.playerStatData}
                        homeTeamName={props.fixture?.homeTeam.club?.name}
                        awayTeamName={props.fixture?.awayTeam.club?.name}
                        isLoading={props.isLoading}
                    />
                    :
                    null
                }
                {location.state?.view === 'summary' && !props.isLoading ?
                    <Summary
                        playerData={props.matchData?.playerData}
                        keyEventData={props.matchData?.keyEventData}
                        isLoading={props.isLoading}
                    />
                    :
                    null
                }
            </Segment>
        </>
    );

    function getSegmentStyles() {
        const baseStyles = {width: '100%', paddingTop: '1px', paddingBottom: 0}

        if(location.state?.view === 'lineups') {
            baseStyles.paddingLeft = 0;
            baseStyles.paddingRight = 0;
        }

        return baseStyles;
    }
}

const type = {
    'cup': 'cupFixtureResultMasks',
    'league': 'leagueFixtureResultMasks'
}

function ViewResultOrWatchHighlights(props) {
    const queryClient = useQueryClient();
    const history = useHistory();

    const deleteResultMask = useMutation(
        (body) => axios.delete(`${process.env.REACT_APP_APPHOST}/${type[props.type]}/${props.resultMask.id}`, body),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getFixture');
            }
        }
    );

    if(!!props.isLoading) return null;

    return (
        <Segment>
            <Header as='h4' textAlign='center'>Match Result</Header>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={() => deleteResultMask.mutate()}>View result</Button>
                <Button
                    primary
                    disabled={!props.highlightsAvailable}
                    onClick={() => history.push(generatePath(props.type === 'cup' ? URL.cupFixtureHighlights : URL.leagueFixtureHighlights, {fixtureId: props.fixture.id}))}
                >
                    <FontAwesomeIcon icon={faCirclePlay} className='inlineIcon' />
                    Highlights
                </Button>
            </div>
            {props.highlightsAvailable === false ?
                <Message negative>Highlights are not available for this fixture</Message>
                :
                null
            }
        </Segment>
    );
}

function HeadToHead(props) {
    const getFixtureHistoryQuery = useQuery(
        ['getFixtureHistory', props.team1Id, props.team2Id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/fixtureHistory?team1Id=${props.team1Id}&team2Id=${props.team2Id}`)
    );

    return(
        <TitledSegment
            title={'Head to head'}
        >
            {getFixtureHistoryQuery.isLoading ?
                <LoadingCards keys={[1, 2, 3, 4, 5]} />
                :
                <FixtureList
                    fixtures={getFixtureHistoryQuery.data.data.response}
                    view='historical'
                />
            }
        </TitledSegment>
    )
}

const messages = {
    notEnoughPlayers: {
        id: "Page_Fixture.notEnoughPlayers",
        defaultMessage: "The match did not start because neither team had enough players.",
        description: "Error message which is shown if both teams do not have enough players to complete the fixture"
    },
    notEnoughPlayersOneClub: {
        id: "Page_Fixture.notEnoughPlayersOneClub",
        defaultMessage: "{clubName} forfeited the match 3-0 as they did not have enough players.",
        description: "Error message which is shown if one team does not have enough players to complete the fixture"
    },
    notEnoughPlayersErrorHeader: {
        id: "Page_Fixture.notEnoughPlayersErrorHeader",
        defaultMessage: "This match was abandoned",
        description: "Header of the error message which is shown if the match was abandoned"
    },
    fixtureTitleSeparatorNotPlayed: {
        id: "Page_Fixture.fixtureTitleSeparatorNotPlayed",
        defaultMessage: "vs.",
        description: "Separator for the fixture title (i.e. Manchester v Arsenal) when fixture has not been played yet"
    },
    fixtureTitleSeparatorPlayed: {
        id: "Page_Fixture.fixtureTitleSeparatorPlayed",
        defaultMessage: "{homeTeamScore} - {awayTeamScore}",
        description: "Separator for the fixture title (i.e. Manchester 1 - 2 Arsenal) when fixture has been played"
    },
    kickOff: {
        id: "Page_Fixture.kickOff",
        defaultMessage: "Kick off",
        description: "Name for the event at the start of the fixture when the match begins (0 minutes)"
    },
    halfTime: {
        id: "Page_Fixture.halfTime",
        defaultMessage: "Half time",
        description: "Name for the break in the match at 45 minutes"
    },
    fullTime: {
        id: "Page_Fixture.fullTime",
        defaultMessage: "Full time",
        description: "Name for the end of the game (90 minutes)"
    },
    leagueName: {
        id: "Page_Fixture.leagueName",
        defaultMessage: "League {leagueName}",
        description: "Name for the competition of the fixture"
    },
    lineUpsHeader: {
        id: "Page_Fixture.lineUpsHeader",
        defaultMessage: "Line ups",
        description: "Header for the table which contains the home and away team line ups"
    },
    attendance: {
        id: "Page_Fixture.attendance",
        defaultMessage: 'Attendance: {attendance}',
        description: 'Label for the attendance for a fixture'
    }
}