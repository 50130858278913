import { useEffect } from "react";
import EditClubStadiumToolbarItem from "../editStadium/EditStadiumToolbarItem";
import EditClubFacilitiesToolbarItem from "./EditFacilitiesToolbarItem";
import ClubFacilitiesInterface from "./interface";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Loader } from "semantic-ui-react";
import { generatePath, Redirect } from "react-router";
import { URL } from "../../../common/lib/paths";

export default function EditClubFacilities(props) {
    const queryClient = useQueryClient();

    const getClubFacilities = useQuery(
        ['getClubFacilities', props.manager.club.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.manager.club.id}/clubFacilities`)
    );

    const getClubFinances = useQuery(
        ['getClubFinances'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/finances`)
    );

    const coachingDeptQuery = useQuery(
        ['getClubCoachingStaff', props.manager.club.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.manager.club.id}/departments?role=coach`)
    );

    const physioDeptQuery = useQuery(
        ['getClubPhysioStaff', props.manager.club.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.manager.club.id}/departments?role=physio`)
    );

    const youthDeptQuery = useQuery(
        ['getClubYouthStaff', props.manager.club.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.manager.club.id}/departments?role=youth`)
    );
    
    useEffect(() => {
        if(!!getClubFacilities.data?.data.response) {
            props.setToolbar({
                navigation: [
                    <EditClubStadiumToolbarItem />,
                    <EditClubFacilitiesToolbarItem />
                ],
                title: `${getClubFacilities.data?.data.response.name} facilities`
            })
        }
    }, [getClubFacilities.data?.data.response]);

    const updateClubFacilitiesRating = useMutation(
        (newRating) => axios.patch(`${process.env.REACT_APP_APPHOST}/clubs/clubFacilities`, {rating: newRating}),
        {
            onSuccess: () => queryClient.invalidateQueries('getClubFacilities')
        }
    );

    /*

        TODO
            2. Handle build in progress.
                Considering graphics for this should show immediately after confirming - so does this need to be a separate page?

    */

    const isLoading = getClubFacilities.isLoading || getClubFinances.isLoading || coachingDeptQuery.isLoading || physioDeptQuery.isLoading || youthDeptQuery.isLoading;

    if(isLoading === true) {
        return(
            <Loader active />
        )
    } else if(!!getClubFacilities.data?.data.response.clubFacilities.clubFacilitiesUpgrade) {
        return(
            <Redirect
                to={generatePath(URL.viewClubFacilities, {clubId: props.manager.club.id})}
            />
        )
    } else {
        return(
            <ClubFacilitiesInterface
                manager={props.manager}
                isLoading={isLoading}
                clubFacilities={getClubFacilities.data?.data.response.clubFacilities}
                clubFinances={getClubFinances.data?.data.response}
                updateClubFacilitiesRating={updateClubFacilitiesRating.mutate.bind(this)}
                coachDept={coachingDeptQuery.data?.data.response}
                physioDept={physioDeptQuery.data?.data.response}
                youthDept={youthDeptQuery.data?.data.response}
            />
        )
    }
}