import axios from "axios";
import { useEffect } from "react";
import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams } from "react-router";
import NarrowLayout from "../../common/components/NarrowLayout";
import FixturesInterface from "../../fixtures/interface";
import LeagueName from "../../league/components/common/Name";
import FixtureDateSelector from "./FixtureDateSelector";
import ClubProfileToolbarItem from "../../club/components/toolbar/ClubProfile";
import TeamPlayersToolbarItem from "../../club/components/toolbar/TeamPlayers";
import TeamFixturesToolbarItem from "../../club/components/toolbar/TeamFixtures";
import TeamLeagueToolbarItem from "../../club/components/toolbar/TeamLeague";
import ClubStaffToolbarItem from "../../club/components/toolbar/ClubStaff";
import ClubTransfersToolbarItem from "../../club/components/toolbar/ClubTransfers";
import TeamLeagueSeasonsToolbarItem from "../../club/components/toolbar/TeamLeagueSeasons";
import { FormattedMessage } from "react-intl";

export default function LeagueSeasonFixtures(props) {
    const location = useLocation();
    const {clubId, teamType} = useParams();

    const leagueSeasonId = location.state?.leagueSeasonId;
    
    const leagueSeasonQuery = useQuery(
        ['getLeagueSeason', leagueSeasonId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagueSeasons/${leagueSeasonId}`),
        {
            enabled: leagueSeasonId !== undefined
        }
    );

    const leagueSeasonFixturesQuery = useQuery(
        ['getLeagueSeasonFixtures', leagueSeasonId, location.state?.fixtureRounds],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagueSeasons/${leagueSeasonId}/fixtures?fixtureRounds=${location.state?.fixtureRounds}`),
        {
            enabled: leagueSeasonId !== undefined && !!location.state?.fixtureRounds
        }
    );

    useEffect(() => {
        if(!!leagueSeasonQuery.data?.data?.response && !!location.state?.fixtureStatus) {
            const isMyLS = location.state?.leagueSeasonId === props.manager.club.firstTeam.currentTLS.LS.id || location.state?.leagueSeasonId === props.manager.club.youthTeam.currentTLS.LS.id;

            if(!!clubId && !!teamType) {
                var navigation = [
                    <ClubProfileToolbarItem clubId={clubId} key={1} />,
                    <TeamPlayersToolbarItem clubId={clubId} teamType={teamType} key={2} />,
                    <TeamFixturesToolbarItem clubId={clubId} teamType={teamType} key={5} />,
                    <TeamLeagueToolbarItem clubId={clubId} teamType={teamType} key={6} />,
                    <ClubStaffToolbarItem clubId={clubId} key={7} />,
                    <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                    <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType={teamType} key={9} />
                ]
            } else {
                var navigation = [];
            }

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle[location.state.fixtureStatus]} values={{leagueName: <LeagueName league={leagueSeasonQuery.data.data.response.league} />}} />,
                navigation
            });
        }
    }, [leagueSeasonQuery.data?.data?.response, location.state?.fixtureStatus]);

    return(
        <NarrowLayout>
            <FixtureDateSelector />
            <FixturesInterface
                manager={props.manager}
                isLoading={leagueSeasonFixturesQuery.isLoading}
                fixtures={leagueSeasonFixturesQuery.data?.data?.response}
                highlightTeamIds={[props.manager?.club?.firstTeam.id, props.manager?.club?.youthTeam.id]}
            />
        </NarrowLayout>
    );
}

const messages = {
    screenTitle: {
        planned: {
            id: 'test',
            defaultMessage: '{leagueName} fixtures',
            description: 'todo'
        },
        complete: {
            id: 'test',
            defaultMessage: '{leagueName} results',
            description: 'todo'
        }
    }
}