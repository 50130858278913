import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl, FormattedDate, defineMessages } from "react-intl";
import DataTable from "../../../../../common/components/DataTable";
import WideLayout from "../../../../../common/components/WideLayout";
import LeagueLink from "../../../../../league/components/common/Link";

export default function TLSHistoryInterface(props) {
    const intl = useIntl();
    
    const columns = [
		{
			name: intl.formatMessage(messages.seasonLabel),
			cell: clubLeagueSeason => <FormattedDate value={clubLeagueSeason.LS.season.startDate} day='numeric' month='short' year='2-digit' />,
			wrap: false,
			allowOverflow: false,
			compact: true,
			grow: 2
		},
		{
			name: intl.formatMessage(messages.leagueLabel),
			cell: clubLeagueSeason => <LeagueLink league={clubLeagueSeason.LS.league} />,
			wrap: false,
			allowOverflow: false,
			compact: true,
			grow: 3
		},
		{
			name: intl.formatMessage(messages.positionLabel),
			cell: clubLeagueSeason => {
				return(
					<>
						{clubLeagueSeason.position}
						{clubLeagueSeason.promoted === true ?
							<FontAwesomeIcon icon={faArrowUp} color='#21BA45' className='inlineIconMirror' />
							:
							null
						}
						{clubLeagueSeason.relegated === true ?
							<FontAwesomeIcon icon={faArrowDown} color='#DB2828' className='inlineIconMirror' />
							:
							null
						}
					</>
				)
			},
			wrap: false,
			allowOverflow: false,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px'
		},
		{
			name: intl.formatMessage(messages.gamesPlayedLabel),
			selector: 'gamesPlayed',
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 500
		},
		{
			name: intl.formatMessage(messages.gamesWonLabel),
			selector: 'wins',
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 580
		},
		{
			name: intl.formatMessage(messages.gamesDrawnLabel),
			selector: 'draws',
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 580
		},
		{
			name: intl.formatMessage(messages.gamesLostLabel),
			selector: 'losses',
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 580
		},
		{
			name: intl.formatMessage(messages.goalsScoredLabel),
			selector: 'goalsScored',
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 750
		},
		{
			name: intl.formatMessage(messages.goalsConcededLabel),
			selector: 'goalsConceded',
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 750
		},
		{
			name: intl.formatMessage(messages.goalDifferenceLabel),
			cell: clubLeagueSeason => (clubLeagueSeason.goalsScored+(clubLeagueSeason.goalsConceded*-1)),
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 340
		},
		{
			name: intl.formatMessage(messages.pointsLabel),
			selector: 'points',
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
		}
	];

    return(
		<WideLayout>
			<DataTable
				columns={columns}
				data={props.TLS}
				isLoading={props.isLoading}
				placeholderCount={5}
			/>
		</WideLayout>
    )
}

const messages = defineMessages({
	seasonLabel: {
		id: "Layout_ClubHistory.seasonLabel",
		defaultMessage: "Season",
		description: "Header for the column in the club history which states the season number"
	},
	leagueLabel: {
		id: "Layout_ClubHistory.leagueLabel",
		defaultMessage: "League",
		description: "Header for the column in the club history which states the league name"
	},
	positionLabel: {
		id: "Layout_LeagueTable.positionLabel",
		defaultMessage: "Pos",
		description: "Header for column in the league table which states the position of the club. 6 characters maximum (abbreviations allowed)"
	},
	gamesPlayedLabel: {
		id: "Layout_LeagueTable.playedLabel",
		defaultMessage: "P",
		description: "Header for the column in the league table which states how many games the club has played. 6 characters maximum (abbreviations allowed)"
	},
	gamesWonLabel: {
		id: "league:table:gamesWonLabel",
		defaultMessage: "W",
		description: "Header for the column in the league table which states how many games the club has won. 6 characters maximum (abbreviations allowed)"
	},
	gamesDrawnLabel: {
		id: "league:table:gamesDrawnLabel",
		defaultMessage: "D",
		description: "Header for the column in the league table which states how many games the club has drawn. 6 characters maximum (abbreviations allowed)"
	},
	gamesLostLabel: {
		id: "league:table:gamesLostLabel",
		defaultMessage: "L",
		description: "Header for the column in the league table which states how many games the club has lost. 6 characters maximum (abbreviations allowed)"
	},
	goalsScoredLabel: {
		id: 'league:table:goalsScoredLabel',
		defaultMessage: 'F',
		description: 'Header for the column in the league table which states how many goals a team has scored'
	},
	goalsConcededLabel: {
		id: 'league:table:goalsConcededLabel',
		defaultMessage: 'A',
		description: 'Header for the column in the league table which states how many goals a team has conceded'
	},
	goalDifferenceLabel: {
		id: "Layout_LeagueTable.goalDifferenceLabel",
		defaultMessage: "+/-",
		description: "Header for the column in the league table which states the difference between how many goals the club has scored and conceded. 6 characters maximum (abbreviations allowed)"
	},
	pointsLabel: {
		id: "Layout_LeagueTable.pointsLabel",
		defaultMessage: "Pts",
		description: "Header for the column in the league table which states how many points the club has. 6 characters maximum (abbreviations allowed)"
	}
});