function hsvToRgb(hsv) {
    let h = hsv[0], s = hsv[1], v = hsv[2];

    var rgb, i, data = [];
    if (s === 0) {
        rgb = [v,v,v];
    } else {
        h = h / 60;
        i = Math.floor(h);
        data = [v*(1-s), v*(1-s*(h-i)), v*(1-s*(1-(h-i)))];
        switch(i) {
            case 0:
                rgb = [v, data[2], data[0]];
                break;
            case 1:
                rgb = [data[1], v, data[0]];
                break;
            case 2:
                rgb = [data[0], v, data[2]];
                break;
            case 3:
                rgb = [data[0], data[1], v];
                break;
            case 4:
                rgb = [data[2], data[0], v];
                break;
            default:
                rgb = [v, data[0], data[1]];
                break;
        }
    }

    return '#' + rgb.map(function(x){
        return ("0" + Math.round(x*255).toString(16)).slice(-2);
    }).join('');
}

function ratingToHsv(rating) {
    //rating = rating * 5;

    return [
        Math.floor((rating) * 120 / 100),
        1,
        1
    ]
}

function getColor(hexcolor) {
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
}

const positionColorsOld = {
    'GK': '#5E37C1',
    'CB': '#51B2AC',
    'LB': '#51B2AC',
    'RB': '#51B2AC',
    'LWB': '#51B2AC',
    'RWB': '#51B2AC',
    'CDM': '#42B3CA',
    'CM': '#42B3CA',
    'LM': '#42B3CA',
    'RM': '#42B3CA',
    'CAM': '#4283CA',
    'LW': '#4283CA',
    'RW': '#4283CA',
    'ST': '#4283CA'
}

const groupedColoursOld = {
    GK: '#1B5E20',
    MID: '#880E4F',
    DEF: '#8E5E0B',
    ATK: '#07389F'
}

const groupedColours = {
    GK: '#71b764',
    DEF: '#BEB67E',
    MID: '#7FB1B7',
    ATK: '#c68a74'
}

const positionColors = {
    'GK': groupedColours.GK,
    'CB': groupedColours.DEF,
    'LB': groupedColours.DEF,
    'RB': groupedColours.DEF,
    'LWB': groupedColours.DEF,
    'RWB': groupedColours.DEF,
    'CDM': groupedColours.MID,
    'CM': groupedColours.MID,
    'LM': groupedColours.MID,
    'RM': groupedColours.MID,
    'CAM': groupedColours.ATK,
    'LW': groupedColours.ATK,
    'RW': groupedColours.ATK,
    'ST': groupedColours.ATK
}

export {
    hsvToRgb,
    ratingToHsv,
    getColor,
    positionColors,
    groupedColours
}