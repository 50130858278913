import {Grid} from 'semantic-ui-react';
import find from 'lodash/find';
import reverse from 'lodash/reverse';
import PlayerShirt from '../components/PlayerShirt';
import cloneDeep from 'lodash/cloneDeep';

const rows = [
    [5,11,17,23,29],
    [4,10,16,22,28],
    [3,9,15,21,27],
    [2,8,14,20,26],
    [1,7,13,19,25],
    [12]
];

function buildPitchFromLineup(
    startingLineup,
    teamMentality,
    handleShirtClick,
    reversePitch,
    glowIndex
) {
    if(startingLineup.length === 0) return null;

    let rowMap = reversePitch === true ? reverse(cloneDeep(rows)) : cloneDeep(rows);

    const lineupMap = {};
    for(let i=0; i<startingLineup.length; i++) {
        if(!!startingLineup[i].pitchHalfSectionIndex) {
            lineupMap[startingLineup[i].pitchHalfSectionIndex] = i
        }
    }

    for(let row = 0; row<rowMap.length; row++) {
        for(let section = 0; section<rowMap[row].length; section++) {
            // if(!!lineupMap[rowMap[row][section]]) {
                rowMap[row][section] = startingLineup[lineupMap[rowMap[row][section]]]
            // } else {
                // rowMap[row][section] = null;
            // }
        }
    }

    const pitchMap = rowMap.map(
        (row, rowIndex) => row.reduce(
            (r2, lineup, sectionIndex) => {
                if(!lineup) {
                    if(
                        sectionIndex === 2 &&
                        !!rowMap[rowIndex][1] &&
                        !!rowMap[rowIndex][3]
                    ) {
                        return r2;
                    } else {
                        r2.push(
                            <Grid.Column width={3}>&nbsp;</Grid.Column>
                        );
                        return r2;
                    }
                }

                const player = lineup.player && Array.isArray(lineup.player) ? find(lineup.player, (player) => player.startedMatch) : lineup.player;

                console.log(player);

                r2.push(
                    <PlayerShirt
                        //glow={lineup.positionIndex === glowIndex}
                        glow={false}
                        position={lineup.position}
                        occupied={!!player}
                        condition={player?.condition}
                        mentality={lineup.mentality || teamMentality}
                        playerNaturalPosition={player?.position}
                        injured={player?.injuredUntil || false}
                        playerName={player?.lastName}
                        rating={player?.currentAbilityInPlayingPosition || player?.currentAbility}
                        reverse={reversePitch}
                        wasSubbed={lineup.player && Array.isArray(lineup.player) && lineup.player.length > 1}
                        handleShirtClick={() => handleShirtClick(lineup.positionIndex, lineup.playerId, lineup.teamTacticPositionIndex)}
                        goals={player?.goals}
                        assists={player?.assists}
                    />
                )

                return r2;
            }, []
        )
    )

    for(let i=0;i<6;i++) {
        if(pitchMap[i].length === 0) {
            pitchMap[i].push(
                <Grid.Column width={3}>&nbsp;</Grid.Column>
            )
        }
    }

    return pitchMap;
}

export default buildPitchFromLineup;