import {useRef} from 'react';
import {useIntl} from 'react-intl';
import { faCoins, faMinus, faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBodyClass from '../../useBodyClass';

function CurrencyInput(props) {
    const intl = useIntl();
    useBodyClass('noSelect');
    const incrementTimer = useRef(null);
    const decrementTimer = useRef(null);

    if(props.isLoading) return null;

    let formatBid = true;

    function handleFocus() {
        props.currencyInputEl.current.value = props.currencyInputEl.current.getAttribute('rawvalue');
        formatBid = false;
    }

    function handleBlur() {
        setRawValue(parseInt(props.currencyInputEl.current.value.replace(/[^0-9 | ^.]/g, '')) || 0)
        props.currencyInputEl.current.value = formatTransferBid(props.currencyInputEl.current.getAttribute('rawvalue'));
        formatBid = true;
    }

    function handleIncreaseOfferHold(event) {
        // console.log('click');
        increaseOffer();
        incrementTimer.current = setInterval(increaseOffer, 125);
    }

    function setRawValue(rawValue) {
        props.currencyInputEl.current.setAttribute('rawvalue', rawValue);
    }

    function resetValue() {
        props.currencyInputEl.current.value = props.initialValue;
        handleBlur();
    }

    function increaseOffer() {
        // console.log('increasing from', props.currencyInputEl.current.getAttribute('rawvalue'));
        setRawValue(handleIncreaseOffer(props.currencyInputEl.current.getAttribute('rawvalue')));
        props.currencyInputEl.current.value = formatBid ? formatTransferBid(props.currencyInputEl.current.getAttribute('rawvalue')) : props.currencyInputEl.current.getAttribute('rawvalue');
    }

    function decreaseOffer() {
        setRawValue(handleDecreaseOffer(props.currencyInputEl.current.getAttribute('rawvalue')));
        props.currencyInputEl.current.value = formatBid ? formatTransferBid(props.currencyInputEl.current.getAttribute('rawvalue')) : props.currencyInputEl.current.getAttribute('rawvalue');
    }

    function handleDecreaseOfferHold(event) {
        decreaseOffer();
        decrementTimer.current = setInterval(decreaseOffer, 125);
    }

    function clearIncreaseOfferHold(event) {
        clearInterval(incrementTimer.current);
    }

    function clearDecreaseOfferHold(event) {
        clearInterval(decrementTimer.current);
    }

    function formatTransferBid(prevTransferBid) {
        return intl.formatNumber(prevTransferBid, {notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 2});
    }

    function handleIncreaseOffer(prev) {
        const increment = props.getIncrement(prev, props.maximum);

        // console.log(increment);

        if(!increment) {
            clearIncreaseOfferHold();
            return props.maximum || prev;
        }

        return floorToNearest(prev, increment) + increment;
    }

    function handleDecreaseOffer(prev) {
        const decrement = props.getDecrement(prev, props.minimum || 0);

        if(!decrement) {
            clearDecreaseOfferHold();
            return props.minimum || 0;
        }

        return ceilToNearest(prev, decrement) - decrement;
    }

    return(
        <div style={{display: 'flex'}}>
            <div className="ui fluid action labeled input transferBid" style={{flex: '1 0 auto'}}>
                <div className="ui label label">
                    <FontAwesomeIcon icon={faCoins} />
                </div>
                <input
                    type="text"
                    defaultValue={formatTransferBid(props.currencyInputEl.current?.getAttribute('rawvalue') || props.initialValue || 0)}
                    rawvalue={props.currencyInputEl.current?.getAttribute('rawvalue') || props.initialValue || 0}
                    ref={props.currencyInputEl}
                    onFocus={() => handleFocus()}
                    onBlur={() => handleBlur()}
                />
                <button
                    className="ui icon button currencyInputControl"
                    onMouseDown={handleDecreaseOfferHold.bind(this)}
                    onTouchStart={handleDecreaseOfferHold.bind(this)}
                    onTouchEnd={clearDecreaseOfferHold.bind(this)}
                    onMouseOut={clearDecreaseOfferHold.bind(this)}
                    onMouseUp={clearDecreaseOfferHold.bind(this)}
                    type='button'
                >
                    <FontAwesomeIcon icon={faMinus} />
                </button>
                <button
                    className="ui icon button currencyInputControl"
                    onTouchStart={handleIncreaseOfferHold.bind(this)}
                    onMouseDown={handleIncreaseOfferHold.bind(this)}
                    onTouchEnd={clearIncreaseOfferHold.bind(this)} 
                    onMouseOut={clearIncreaseOfferHold.bind(this)}
                    onMouseUp={clearIncreaseOfferHold.bind(this)}
                    type='button'
                >
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
            <button
                className="ui icon button"
                type="button"
                onClick={resetValue.bind(this)}
                style={{flex: '0 0 auto', marginLeft: '0.5em'}}
            >
                <FontAwesomeIcon icon={faRefresh} />
            </button>
        </div>
    )
}

function floorToNearest(numToRound, numToRoundTo) {
    return Math.floor(numToRound / numToRoundTo) * numToRoundTo;
}

function ceilToNearest(numToRound, numToRoundTo) {
    return Math.ceil(numToRound / numToRoundTo) * numToRoundTo;
}

function bidIncrementer(prev, bounds) {
    if(prev >= bounds) return false;

    let increment;
    if(prev < 500000) {
        increment = 50000;
    } else if(prev < 1000000) {
        increment = 100000;
    } else if(prev < 5000000) {
        increment = 250000;
    } else if(prev < 20000000) {
        increment = 500000;
    } else if(prev < 50000000) {
        increment = 1000000;
    } else if(prev < 100000000) {
        increment = 5000000;
    } else {
        increment = 10000000;
    }

    return increment;
}

function bidDecrementer(prev, bounds) {
    if(prev <= bounds) return false;

    let decrement;
    if(prev < 500000) {
        decrement = 50000;
    } else if(prev < 1000000) {
        decrement = 100000;
    } else if(prev < 5000000) {
        decrement = 250000;
    } else if(prev < 20000000) {
        decrement = 500000;
    } else if(prev < 50000000) {
        decrement = 1000000;
    } else if(prev < 100000000) {
        decrement = 5000000;
    } else {
        decrement = 10000000;
    }

    return decrement;
}

function salaryIncrementer(prev) {
    let increment;
    if(prev < 50000) {
        increment = 2500;
    } else if(prev < 100000) {
        increment = 5000;
    } else if(prev < 500000) {
        increment = 25000;
    } else if(prev < 1000000) {
        increment = 50000;
    } else if(prev < 5000000) {
        increment = 100000;
    } else if(prev < 10000000) {
        increment = 250000;
    } else {
        increment = 500000;
    }

    return increment;
}

function salaryDecrementer(prev) {
    if(prev <= 0) return false;

    let decrement;
    if(prev < 50000) {
        decrement = 2500;
    } else if(prev < 100000) {
        decrement = 5000;
    } else if(prev < 500000) {
        decrement = 25000;
    } else if(prev < 1000000) {
        decrement = 50000;
    } else if(prev < 5000000) {
        decrement = 100000;
    } else if(prev < 10000000) {
        decrement = 250000;
    } else {
        decrement = 500000;
    }

    return decrement;
}

export {
    CurrencyInput,
    bidIncrementer,
    bidDecrementer,
    salaryDecrementer,
    salaryIncrementer
}