const sizes = {
    pitch: {
        x: 100,
        y: 60
    },
    ballRadius: 5
}

const orientations = {
    LEFT: 'left',
    RIGHT: 'right'
}

export {
    sizes,
    orientations
}