import { Segment } from "semantic-ui-react";

export default function FacilityDescription(props) {
    return(
        <Segment

        >
            <p>{descriptions[props.rating]}</p>
        </Segment>
    )
}

const descriptions = {
    0: 'A training pitch and that\'s it. Every team has to start somewhere...',
    1: 'Changing rooms and storage shed brings the club\'s training facilities up to a basic standard.',
    2: 'The addition of indoor training facilities allows players to train in all weather. The changing rooms are extended to provide a dedicated treatment room.',
    3: 'An additional training pitch allows your first and youth teams to train separately. A smaller pitch allows tactical drills. The indoor training facilities are extended.',
    4: 'Addition of an an academy center ensure the off-the-field development of your youth players. Addition of recreational facilities for player morale. An additional smaller pitch is added for more tactical and technical drills.',
    5: 'Office facilites are added for your commercial teams to move into. And finally your staff have somewhere to park.'
}