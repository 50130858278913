import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "toolbarItem:firstTeamTactics",
		defaultMessage: "First team",
		description: "Link for first team tactics page"
	}
});

export default function FirstTeamTacticsToolbarItem(props) {
	return(
		<ToolbarItem
			path={generatePath(URL.teamTactics, {teamType: 'first'})}
			icon={faUser} messages={messages}
		/>
	);
}