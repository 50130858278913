import { useParams } from "react-router";
import ViewClubFacilitiesToolbarItem from "../viewFacilities/ViewFacilitiesToolbarItem";
import ViewStadiumInterface from "./interface";
import ViewClubStadiumToolbarItem from "./ViewStadiumToolbarItem";
import { useEffect } from "react";

export default function ViewClubStadium(props) {
    const {clubId} = useParams();

    useEffect(() => {
        props.setToolbar({
            navigation: [
                <ViewClubStadiumToolbarItem clubId={clubId} />,
                <ViewClubFacilitiesToolbarItem clubId={clubId} />
            ],
            title: `View club stadium`
        })
    }, []);

    return(
        <ViewStadiumInterface />
    )
}