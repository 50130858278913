import axios from "axios";
import { useEffect } from "react";
import {useQuery} from '@tanstack/react-query';
import {useParams, Redirect, generatePath, useLocation} from 'react-router-dom';
import PlayerHistoryInterface from "./interface.js";

import Profile from "../../toolbar/Profile.js";
import History from "../../toolbar/History.js";
import TransferOffer from "../../toolbar/Transfer.js";
import ContractOffer from "../../toolbar/Contract.js";
import ShortenedPlayerName from '../../common/ShortenedName.js';
import { URL } from "../../../common/lib/paths.js";

export default function PlayerHistorySeason(props) {
    const {playerId} = useParams();
    const location = useLocation();

    const getPlayerSeasonHistoryQuery = useQuery(
        ['getPlayerTeamLeagueSeasons', playerId, false, false],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/playerTeamLeagueSeasons`)
    );

    const getPlayerMeta = useQuery(
        ['getPlayerMeta', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}?meta=true`)
    );

    useEffect(() => {
        if(!!getPlayerMeta.data?.data.response?.id) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={getPlayerMeta.data.data.response} /> history</>,
                navigation: [
                    <Profile key={1} playerId={playerId} />,
                    <ContractOffer key={4} playerId={playerId} isMyPlayer={getPlayerMeta.data.data.response.isMyPlayer} />,
                    <TransferOffer key={3} playerId={playerId} isMyPlayer={getPlayerMeta.data.data.response.isMyPlayer} />,
                    <History key={5} playerId={playerId} />,
                ]
            });
        }
    }, [getPlayerMeta.data?.data.response]);

    if(getPlayerMeta.isLoading === false && getPlayerMeta.data.data.response.key === 'RESOURCE_DOES_NOT_EXIST') {
        return(
            <Redirect to={{pathname: generatePath(URL.retiredPlayerHistory, {playerId: playerId}), state: location.state}} />
        );
    }

    return(
        <PlayerHistoryInterface
            isLoading={getPlayerSeasonHistoryQuery.isLoading}
            playerClubLeagueSeasons={getPlayerSeasonHistoryQuery.data?.data.response || []}
        />
    );
}