import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { useParams} from 'react-router';
import { useEffect } from "react";
import ShortenedPlayerName from "../../common/ShortenedName";
import ViewProposedPlayerTransferOfferPaidInterface from "./proposed/interface";
import ViewReceivedPlayerTransferOfferPaidInterface from './received/interface';
import { Loader } from "semantic-ui-react";

const includeStr = 'includes=playerContract,person'

export default function ViewPlayerTransferOfferPaid(props) {
    const {playerTransferOfferPaidId} = useParams();
    
    const getPlayerTransferOfferQuery = useQuery(
        ['getPlayerTransferOffer', playerTransferOfferPaidId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferOfferPaid/${playerTransferOfferPaidId}`)
    );

    const getPlayerQuery = useQuery(
        ['getPlayer', getPlayerTransferOfferQuery.data?.data.response.playerContract.playerId, includeStr],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${getPlayerTransferOfferQuery.data?.data.response.playerContract.playerId}/?${includeStr}`),
        {
            enabled: !!getPlayerTransferOfferQuery.data?.data.response.playerContract.playerId
        }
    );

    useEffect(() => {
        if(getPlayerQuery.data?.data?.response) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={getPlayerQuery.data.data.response} /> transfer offer</>,
                navigation: []
            })
        }
    }, [getPlayerQuery.data?.data?.response]);

    const isLoading = getPlayerTransferOfferQuery.isLoading || getPlayerQuery.isLoading;

    if(isLoading) {
        return(
            <Loader active />
        );
    } else {
        const isMyPlayer = props.manager.club.id === getPlayerTransferOfferQuery.data.data.response.playerContract.club.id;

        if(isMyPlayer === true) {
            return(
                <ViewReceivedPlayerTransferOfferPaidInterface
                    isLoading={isLoading}
                    playerTransferOffer={getPlayerTransferOfferQuery.data?.data?.response}
                    player={getPlayerQuery.data?.data.response}
                    isMyPlayer={isMyPlayer}
                />
            )
        } else {
            return(
                <ViewProposedPlayerTransferOfferPaidInterface
                    isLoading={isLoading}
                    playerTransferOffer={getPlayerTransferOfferQuery.data?.data?.response}
                    player={getPlayerQuery.data?.data.response}
                    isMyPlayer={isMyPlayer}
                />
            )
        }
    }
}