import {Button, Loader, Statistic} from 'semantic-ui-react';
import { generatePath, useHistory } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { URL } from '../../../../common/lib/paths';
import TransferInfo from '../../playerTransferOrLoanOffer/playerTransferOffer/common/TransferInfo';
import TitledSegment from '../../../../common/components/TitledSegment';
import { faExchange } from '@fortawesome/free-solid-svg-icons';
import NarrowLayout from '../../../../common/components/NarrowLayout';
import ContractSummary from '../../../common/ContractSummary';
import Currency from '../../../../common/components/Currency';

export default function ViewProposedPlayerTransferOfferPaidInterface(props) {
    const history = useHistory();

    const withdrawPlayerTransferOfferMutation = useMutation(() => 
        axios.patch(
            `${process.env.REACT_APP_APPHOST}/playerTransferOfferPaid/${props.playerTransferOffer.id}`,
            {
                status: 'withdrawn'
            }
        ),
        {
            onSuccess: (data) => history.replace(generatePath(URL.viewPlayerTransferOfferPaidHistory, {playerTransferOfferPaidHistoryId: data.data.response.id}))
        }
    );

    if(props.isLoading) return (<Loader active />);

    return(
        <NarrowLayout>
            <TransferInfo
                message={messages.intro}
                player={props.player}
                fromClub={props.playerTransferOffer?.playerContract.club}
                toClub={props.playerTransferOffer?.club}
                submittedOfferCount={props.player.playerContract.activeOfferLoan+props.player.playerContract.activeOfferPaid}
                playerTransferOfferPaid={props.playerTransferOffer}
            />
            <TitledSegment
                title='Transfer offer'
                icon={faExchange}
            >
                <Statistic className='newStatistic'>
                    <Statistic.Value>
                        <Currency value={props.playerTransferOffer.compensation} />
                    </Statistic.Value>
                    <Statistic.Label>
                        Transfer bid
                    </Statistic.Label>
                </Statistic>
                <ContractSummary
                    small={true}
                    noPadding={true}
                    salary={props.playerTransferOffer.contractSalaryOffer}
                    salaryLabel='Salary offer'
                    contractEndDate={props.playerTransferOffer.endSeasonOffer.endDate}
                    contractExpiryLabel='Contract expiry'
                />
                <Button
                    negative
                    style={{marginTop: '1em'}}
                    fluid
                    onClick={handleWithdrawPlayerTransferOffer}
                    loading={withdrawPlayerTransferOfferMutation.isLoading}
                    disabled={withdrawPlayerTransferOfferMutation.isLoading}
                >
                    Withdraw bid
                </Button>
            </TitledSegment>
        </NarrowLayout>
    );

    function handleWithdrawPlayerTransferOffer() {
        withdrawPlayerTransferOfferMutation.mutate();
    }
}

const messages = {
    intro: {
        id: 'playerTransferOfferPaid:intro',
        defaultMessage: '{playerName} is currently under contract at {fromClubName}. If they accept your offer, an auction will begin and the player will sign for the highest bidder.',
        description: 'todo'
    },
    pageHeader: {
        id: "Page_TransferProposal.pageHeader",
        defaultMessage: "Transfer offer",
        description: "Header for the transfer offer page where the user can review a transfer offer in progress (accept/reject/withdraw)"
    },
    cardHeader: {
        id: "Page_TransferProposal.cardHeader",
        defaultMessage: "Transfer offer for {personName}",
        description: "Header for the card on the transfer offer page which contains the transfer offer details"
    },
    overview: {
        id: "Page_TransferProposal.overview_2",
        defaultMessage: "{proposingClubName} have offered {compensation} to {receivingClubName} for {personName}.",
        description: "Text describing the overview of the transfer where the proposing club have offered a sum of money to sign a player/staff from another club"
    },
    pending: {
        id: "Page_TransferProposal.description_1",
        defaultMessage: "{receivingClubName} have until {deadline} to respond to the offer.",
        description: "Text describing the status of the transfer where the club who have received the bid have not yet responded to it"
    },
    button_accept: {
        id: "Page_TransferProposal.button_accept",
        defaultMessage: "Accept",
        description: "Label for the button which accepts the transfer offer"
    },
    button_reject: {
        id: "Page_TransferProposal.button_reject",
        defaultMessage: "Reject",
        description: "Label for the button which rejects the transfer offer"
    }
}