import { Grid, Loader, Message, Placeholder, Statistic } from "semantic-ui-react";
import TitledSegment from "../../../common/components/TitledSegment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faTimeline } from "@fortawesome/free-solid-svg-icons";
import colours from "../../../common/colours";
import ClubLink from "../../../club/components/common/Link";
import PlayerLink from "../../common/Link";
import { FormattedDate, FormattedTime } from "react-intl";
import Component_Currency from "../../../common/components/Currency";
import NarrowLayout from "../../../common/components/NarrowLayout";

export default function PlayerTransferAuctionFreeHistoryInterface(props) {
    if(props.isLoading) return(<Loader active />);

    return(
        <NarrowLayout>
            <TitledSegment
                title='Free agent offer'
                noPadding={true}
            >
                <Grid className='gridReset transferAuctionBidStatus'>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Statistic size='mini'>
                                <Statistic.Value>
                                    {props.playerTransferAuctionFreeHistory.playerTransferAuctionBidFreeHistory.length}
                                </Statistic.Value>
                                <Statistic.Label>
                                    Bids
                                </Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                        <Grid.Column>
                            <Statistic
                                size='mini'
                                style={{width: '100%'}}
                            >
                                <Statistic.Value>
                                    <ClubLink club={props.winningBid.club} />
                                </Statistic.Value>
                                <Statistic.Label>
                                    Winning club
                                </Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <span>
                                <FontAwesomeIcon icon={faCircleExclamation} color={colours.negative} /> This auction ended on <FormattedDate value={props.playerTransferAuctionFreeHistory.endDate} />
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </TitledSegment>
            {props.myBid?.isWinner === true ?
                <Message positive><PlayerLink player={props.playerTransferAuctionFreeHistory.player} /> accepted your offer</Message>
                :
                <Message negative><PlayerLink player={props.playerTransferAuctionFreeHistory.player} /> rejected your offer</Message>
            }
            <TitledSegment
                title='Other offers'
                icon={faTimeline}
                isLoading={props.isLoading}
                placeholder={BidHistoryPlaceholder}
            >
                <div className='bidHistory'>
                    {props.playerTransferAuctionFreeHistory.playerTransferAuctionBidFreeHistory?.map((bid) => (
                        <Bid bid={bid} key={bid.id} />
                    ))}
                </div>
            </TitledSegment>
        </NarrowLayout>
    );
}

function Bid(props) {
    const styles = {
        container: {
            display: 'flex',
            alignItems: 'center'
        },
        cola: {
            flex: '1 1 auto'
        },
        colb: {
            flex: '0 1 auto'
        },
        club: {
            float: 'right',
            clear: 'right'
        },
        date: {
            float: 'right',
            clear: 'right'
        }
    }

    return(
        <div style={styles.container}>
            <div style={styles.cola}>
                <h3><Component_Currency value={props.bid.contractSalaryOffer} /></h3>
                <span>Until <FormattedDate value={props.bid.endSeasonOffer.endDate} /></span>
            </div>
            <div style={styles.colb}>
                <span style={styles.club}><ClubLink club={props.bid.club} /></span>
                <span style={styles.date}><FormattedDate value={props.bid.createdAt} day='numeric' month='short' /></span>
            </div>
        </div>
    )
}

function BidHistoryPlaceholder() {
    return(
        <Placeholder>
            <Placeholder.Header>
                <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
                <Placeholder.Line />
            </Placeholder.Paragraph>
        </Placeholder>
    )
}