import {FormattedNumber} from "react-intl";

export default function Currency(props) {
    return(
        <FormattedNumber value={props.value} style="currency" currency="GBP" currencyDisplay="symbol" notation='compact' compactDisplay="short" maximumFractionDigits={props.maximumFractionDigits || undefined} />
    );
}

// export default class Component_Currency extends Component {
//     constructor(props) {
//         super(props);

//         let amendedValue;
//         let appendString;
//         if(props.value >= 1000000000) {
//             amendedValue = Math.round(props.value / 1000000000) * 1000000000;
//             amendedValue = amendedValue / 1000000000;
//             appendString = 'B';
//         } else if(props.value >= 1000000) {
//             amendedValue = Math.round(props.value / 100000) * 100000;
//             amendedValue = amendedValue / 1000000;
//             appendString = "M"
//         } else if(props.value > 999) {
//             amendedValue = Math.floor(props.value / 1000) * 1000;
//             amendedValue = amendedValue / 1000;
//             appendString = "K"
//         } else if(props.value >= 0) {
//             amendedValue = props.value;
//             appendString = "";
//         }

//         // TODO - format negative numbers in the same way

//         this.state = {
//             formattedValue: amendedValue,
//             appendString: appendString
//         }
//     }

//     formatCurrency(formattedString) {
//         let style = {};
//         if(formattedString.replace(/[^0-9-]+/g,"") < 0) {
//             style.color = "red"
//         }

//         return(
//             <span style={style}>{formattedString+this.state.appendString}</span>
//         )
//     }

//     render() {
//         //how should we format it?
        
//         // 1,000,000 or greater
//         // round to nearest 100,000
//         // divide by 1,000,000
//         // append "m"
//         // ex -
//         // 1,129,376 -> 1,100,000 -> 1.1 -> 1.1m
//         // 1,299,105 -> 1,300,000 -> 1.3 -> 1.3m
        
//         // less than 999,999 and greater than 999
//         // round down to nearest 1,000
//         // divide by 1,000
//         // append "k"
//         // ex -
//         // 999,999 -> 999,000 -> 999 -> 999k
//         // 50,500 -> 50,000 -> 50 -> 50k
//         // 1,900 -> 1,000 -> 1 -> 1k

//         //999 or less
//         //show as it is

//         //always remove decimals

//         return (
//             <FormattedNumber value={this.state.formattedValue} style="currency" currency="GBP" currencyDisplay="symbol" minimumFractionDigits={0} maximumFractionDigits={0} children={this.formatCurrency.bind(this)} />
//         );
//     }
// }