import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import {Grid, Table, Placeholder} from 'semantic-ui-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward, faCalendarDays, faFutbol, faListOl, faPeopleArrows, faShieldHalved, faShirt, faSquare, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import ClubLink from "../../../club/components/common/Link";
import LeagueLink from "../../../league/components/common/Link";
import { FormattedDate } from "react-intl";

export default function PlayerExpanderStats(props) {
    const {isLoading, data} = useQuery(
        ['getCurrentPCLS', props.playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${props.playerId}/playerTeamLeagueSeasons?current`),
        {
            enabled: props.playerId !== undefined
        }
    );

    return(
        <Interface
            isLoading={isLoading}
            PCLS={data?.data.response}
        />
    );
}

function Interface(props) {
    if(props.PCLS === null) {
        return(
            <p style={{textAlign: 'center'}}>No data available yet, please check back later.</p>
        )
    }

    function getTableRow(label, getValue) {
        return(
            <Table.Row>
                <Table.Cell collapsing style={{fontWeight: 'bold'}}>{label}</Table.Cell>
                <Table.Cell textAlign="right" style={{maxWidth: 0, display: 'table-cell'}} className='noTextwrap'>
                    {props.isLoading ?
                        <Placeholder><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                        :
                        getValue(props.PCLS)
                        
                    }
                </Table.Cell>
            </Table.Row>
        )
    }

    return(
        <Grid style={{width: '100%', margin: 0}}>
            <Grid.Row style={{padding: 0}}>
                <Grid.Column width={8} style={{borderRight: '1px solid #2224261a'}}>
                    <Table basic='very' compact='very' unstackable style={{width: '100%'}} className='attrTable' singleLine={true}>
                        <Table.Body>
                            {getTableRow(<FontAwesomeIcon icon={faShirt} />, (PCLS) => PCLS.appearances)}
                            {getTableRow(<FontAwesomeIcon icon={faFutbol} />, (PCLS) => PCLS.goalsScored)}
                            {getTableRow(<FontAwesomeIcon icon={faPeopleArrows} />, (PCLS) => PCLS.assists)}
                            {getTableRow(<FontAwesomeIcon icon={faThumbsUp} />, (PCLS) => PCLS.averageMatchRating)}
                            {getTableRow(<FontAwesomeIcon icon={faAward} />, (PCLS) => PCLS.totalMOTM)}
                            {getTableRow(<FontAwesomeIcon icon={faSquare} color='#DB2828' />, (PCLS) => PCLS.redCards)}
                            {getTableRow(<FontAwesomeIcon icon={faSquare} color='#FBBD08' />, (PCLS) => PCLS.yellowCards)}
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Table basic='very' compact='very' unstackable style={{width: '100%'}} className='attrTable'>
                        <Table.Body>
                            {getTableRow(<FontAwesomeIcon icon={faShieldHalved} />, (PCLS) => <ClubLink club={PCLS.TLS.team.club} nation={false} />)}
                            {getTableRow(<FontAwesomeIcon icon={faListOl} />, (PCLS) => <LeagueLink league={PCLS.TLS.LS.league} />)}
                            {getTableRow(<FontAwesomeIcon icon={faCalendarDays} />, (PCLS) => <FormattedDate value={PCLS.TLS.LS.season.startDate} day='numeric' month='short' />)}
                            {getTableRow('Shts/90', (PCLS) => PCLS.totalShots90)}
                            {getTableRow('Chncs/90', (PCLS) => PCLS.chancesCreated90)}
                            {getTableRow('Sht acc.', (PCLS) => `${Math.round(PCLS.shotAccuracy*100)}%`)}
                            {getTableRow('Sht con.', (PCLS) => `${Math.round(PCLS.shotConversion*100)}%`)}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}