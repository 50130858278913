import { staffName } from "../../../common/dataTableColumns/staff/staffName";
import { staffRole } from "../../../common/dataTableColumns/staff/staffRole";
import DataTable from "../../../common/components/DataTable";

export default function FavRetiredStaff(props) {
    const columns = [
        staffName((staff) => staff),
        staffRole((staff) => staff)
    ];

    return(
        <DataTable
            columns={columns}
            data={props.favRetiredStaff}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    );
}