import { useQuery } from "@tanstack/react-query";
import RetiredStaffInterface from "./interface";
import { useParams } from "react-router";
import axios from "axios";
import NarrowLayout from "../../common/components/NarrowLayout";
import ShortenedPlayerName from "../../player/common/ShortenedName";

import RetiredStaffProfileToolbarItem from '../toolbar/Profile';
import RetiredStaffHistoryToolbarItem from '../toolbar/History';
import { useEffect } from "react";

export default function RetiredStaffProfile(props) {
    const {staffId} = useParams();

    const getRetiredStaffQuery = useQuery(
        ['getRetiredStaff', staffId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/retiredStaff/${staffId}`)
    );

    useEffect(() => {
        if(!!getRetiredStaffQuery.data?.data.response) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={getRetiredStaffQuery.data.data.response} /> history</>,
                navigation: [
                    <RetiredStaffProfileToolbarItem staffId={staffId} />,
                    <RetiredStaffHistoryToolbarItem staffId={staffId} />,
                ]
            });
        }
    }, [getRetiredStaffQuery.data?.data.response]);

    return(
        <NarrowLayout>
            <RetiredStaffInterface
                isLoading={getRetiredStaffQuery.isLoading}
                staff={getRetiredStaffQuery.data?.data.response}
            />
        </NarrowLayout>
    )
}