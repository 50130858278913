import axios from "axios";
import StaffTrainingBoostsInterface from "./interface";
import { useQuery } from "@tanstack/react-query";

export default function StaffTrainingBoosts(props) {
    const getStaffTrainingBoosts = useQuery(
        ['getClubStaffTrainingBoosts'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/staffTrainingBoosts`)
    );

    if(getStaffTrainingBoosts.isLoading) {
        return null;
    }

    return getStaffTrainingBoosts.data?.data.response.map((staffTrainingBoost) => (
        <StaffTrainingBoostsInterface
            staffTrainingBoost={staffTrainingBoost}
        />
    ));
}