import { Header } from "semantic-ui-react";
import ClubStadiumInterface from "./interface";
import ClubEditStadiumToolbarItem from "./EditStadiumToolbarItem";
import ClubEditFacilitiesToolbarItem from "../editFacilities/EditFacilitiesToolbarItem";
import { useEffect } from "react";

export default function EditClubStadium(props) {
    // return(
    //     <ClubStadiumInterface />
    // )

    useEffect(() => {
        props.setToolbar({
            navigation: [
                <ClubEditStadiumToolbarItem />,
                <ClubEditFacilitiesToolbarItem />
            ],
            title: 'Stadium'
        })
    }, []);

    return (
        <Header as='h2'>Coming soon!!</Header>
    )
}