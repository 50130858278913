import {useState, useEffect} from 'react';
import {generatePath, Redirect, useParams} from 'react-router';
import {Form, Button, Message, Grid} from 'semantic-ui-react';
import {FormattedMessage, defineMessages} from 'react-intl';

import { URL } from '../../../../../common/lib/paths';
import TitledSegment from '../../../../../common/components/TitledSegment';
import { faExchange } from '@fortawesome/free-solid-svg-icons';
import TransferInfo from '../common/TransferInfo';
import BudgetSummary from '../../../../../club/components/common/BudgetSummary';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import TransferOffer from '../../../common/TransferOffer';
import ContractLengthSelection from '../../../../playerContract/common/new/ContractLengthSelection';
import ContractSummary from '../../../../common/ContractSummary';
import ContractValue from '../../../../playerContract/common/new/ContractValue';
import SalaryOffer from '../../../../playerContract/common/new/SalaryOffer';
import StaffBriefing from '../../../../playerContract/common/new/StaffBriefing';

export default function CreatePlayerTransferOfferInterface(props) {
    const [salaryOffer, setSalaryOffer] = useState(null);
    const [transferOffer, setTransferOffer] = useState(null);
    const [selectedContractLengthIndex, setSelectedContractLengthIndex] = useState(null);
    const [isNegotiating, setIsNegotiating] = useState(false);
    
    const {playerId} = useParams();
    const queryClient = useQueryClient();

    const submitBidMutation = useMutation(({playerContractId, compensation, salaryOffer, endSeasonIdOffer}) =>
        axios.post(`${process.env.REACT_APP_APPHOST}/playerTransferOfferPaid`, {
            playerContractId: parseInt(playerContractId),
            compensation: parseInt(compensation),
            contractSalaryOffer: parseInt(salaryOffer),
            contractEndSeasonIdOffer: endSeasonIdOffer
        }), {
        onSuccess: (data) => {
            queryClient.invalidateQueries('getPlayerPlayerTransferOffer');
        }
    });

    useEffect(() => {
        if(transferOffer === null && props.player?.value !== undefined) {
            setTransferOffer(props.player.value);
        }
    }, [props.player]);

    const getSalaryDemandQuery = useQuery(
        ['getPlayerSalaryDemand', playerId, selectedContractLengthIndex],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/contractDemands?proposedContractEndSeasonId=${props.contractLengthOptions[selectedContractLengthIndex].seasonId}`),
        {
            enabled: selectedContractLengthIndex !== null
        }
    );

    useEffect(() => {
        if(selectedContractLengthIndex === null && props.defaultContractLengthIndex !== undefined) {
            setSelectedContractLengthIndex(props.defaultContractLengthIndex);
        }
    }, [props.defaultContractLengthIndex]);

    useEffect(() => {
        if(salaryOffer === null && getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand !== undefined) {
            setSalaryOffer(getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand);
        }
    }, [getSalaryDemandQuery.data?.data.response]);

    if(props.player?.playerContract === null) {
        return(
            <Redirect to={generatePath(URL.playerTransferAuctionFree, {playerId: playerId})} />
        );
    }

    if(props.isTransferBlocked === true) {
        return(
            <Message negative>
                <Message.Header>Transfer blocked</Message.Header>
                <Message.Content>You cannot submit a transfer offer for this player because they are not currently available for transfer.</Message.Content>
            </Message>
        );
    }

    if(props.isInterested === false) {
        return(
            <Message negative>
                <Message.Header>Player not interested</Message.Header>
                <Message.Content>You cannot submit a transfer offer for this player because they are not currently interested in joining your club.</Message.Content>
            </Message>
        );
    }

    return(
        <>
            <TransferInfo
                message={messages.intro}
                player={props.player}
                club={props.player?.playerContract.club}
                submittedOfferCount={props.player.playerContract.activeOfferLoan+props.player.playerContract.activeOfferPaid}
            />
            <BudgetSummary
                finances={props.finances}
                isEditable={true}
            />
            <TitledSegment
                title='Transfer offer'
                icon={faExchange}
            >
                <Form
                    isSubmitting={submitBidMutation.isLoading}
                    onSubmit={handleSubmit}
                >
                    <TransferOffer
                        transferOffer={transferOffer}
                        setTransferOffer={setTransferOffer}
                        resetTransferOffer={resetTransferOffer.bind(this)}
                    />
                    <ContractLengthSelection
                        isLoading={props.isLoading}
                        contractLengthOptions={props.contractLengthOptions}
                        selectedContractLengthIndex={selectedContractLengthIndex}
                        setSelectedContractLengthIndex={handleSelectContractEndSeason.bind(this)}
                    />
                    <ContractSummary
                        salary={getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand}
                        salaryLabel='Salary demand'
                        contractEndDate={getSalaryDemandQuery.data?.data.response.proposedContractEndSeason.endDate}
                        contractExpiryLabel='Contract expiry'
                    />
                    <ContractValue
                        baseSalaryDemand={getSalaryDemandQuery.data?.data.response.baseSalaryDemand || 0}
                        salaryOffer={salaryOffer || 0}
                        isLocked={getSalaryDemandQuery.data?.data.response.baseSalaryDemand === null}
                    />
                    {isNegotiating === true ?
                        <>
                            <SalaryOffer
                                salaryOffer={salaryOffer}
                                setSalaryOffer={setSalaryOffer}
                                resetSalaryOffer={resetSalaryOffer.bind(this)}
                                increment={getSalaryDemandQuery.data?.data.response.salaryIncrement}
                            />
                            <Button
                                primary
                                fluid
                                disabled={submitBidMutation.isLoading}
                                loading={submitBidMutation.isLoading}
                                type='submit'
                                size='tiny'
                            >
                                Submit offer
                            </Button>
                        </>
                        :
                        <Grid className='gridReset'>
                            <Grid.Row columns={2} style={{padding: 0}}>
                                <Grid.Column style={{paddingRight: '0.5em', paddingLeft: 0}}>
                                    <Button
                                        size='tiny'
                                        fluid
                                        onClick={() => setIsNegotiating(true)}
                                    >
                                        Negotiate
                                    </Button>
                                </Grid.Column>
                                <Grid.Column style={{paddingLeft: '0.5em', paddingRight: 0}}>
                                    <Button
                                        size='tiny'
                                        fluid
                                        primary
                                        onClick={handleSubmit.bind(this)}
                                    >
                                        Submit offer
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    }
                </Form>
                {props.submitBidError &&
                    <Message negative>
                        <FormattedMessage
                            {...(submitBidErrorMessages[props.submitBidError.response?.key] || submitBidErrorMessages['unknown'])}
                            values={{key: props.submitBidError.response?.key || '-1'}}
                        />
                    </Message>
                }
            </TitledSegment>
            <TitledSegment
                title='Staff briefing'
            >
                <StaffBriefing
                    isLoading={props.isLoading}
                    negotiationFactors={props.negotiationFactors}
                    existingContract={props.existingContract}
                />
            </TitledSegment>
        </>
    );

    function handleSelectContractEndSeason(endSeasonId) {
        if(endSeasonId === selectedContractLengthIndex) return;

        setSelectedContractLengthIndex(endSeasonId);
        setSalaryOffer(null);
    }

    function resetTransferOffer() {
        setTransferOffer(props.player.value);
    }

    function resetSalaryOffer() {
        setSalaryOffer(getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand);
    }

    function handleSubmit() {
        submitBidMutation.mutate({
            playerContractId: props.player.playerContract.id,
            compensation: parseInt(transferOffer),
            salaryOffer: parseInt(salaryOffer),
            endSeasonIdOffer: parseInt(props.contractLengthOptions[selectedContractLengthIndex].seasonId)
        });
    }
}

const messages = defineMessages({
    intro: {
        id: 'playerTransferOfferPaid:intro',
        defaultMessage: '{playerName} is currently under contract at {fromClubName}. If they accept your offer, an auction will begin and the player will sign for the highest bidder.',
        description: 'todo'
    }
});

const submitBidErrorMessages = defineMessages({
    'unknown': {
        id: 'player:transferOffer:create:error:general',
        defaultMessage: 'An unknown error occured, please report this ({key})',
        description: 'An unknown error occured'
    },
    'PENDING_TRANSFER': {
        id: 'player:transferOffer:create:error:pendingTransfer',
        defaultMessage: 'You cannot bid for this player due to another transfer offer',
        description: 'Error message shown if a transfer bid cannot be submitted due to it clashing with another existing bid'
    },
    'INSUFFICIENT_TRANSFER_BUDGET': {
        id: 'player:transferOffer:create:error:transferBudget',
        defaultMessage: 'Your transfer budget is not sufficient for this offer. Try lowering your bid.',
        description: 'Error message shown if a transfer bid cannot be submitted due to the club not being able to afford the offer (transfer budget)'
    },
    'INSUFFICIENT_WAGE_BUDGET': {
        id: 'player:transferOffer:create:error:wageBudget',
        defaultMessage: 'Your wage budget is not sufficient for this offer. Try lowering your contract offer.',
        description: 'Error message shown if a transfer bid cannot be submitted due to the club not being able to afford the offer (Wage budget)'
    },
    'TOO_MANY_PLAYERS': {
        id: 'player:transferOffer:create:error:tooManyPlayers',
        defaultMessage: 'You cannot bid for this player because you have too many players',
        description: 'Error message shown if a transfer bid cannot be submitted because the club already has the maximum number of players'
    },
    'NOT_ENOUGH_PLAYERS': {
        id: 'player:transferOffer:create:error:tooFewPlayers',
        defaultMessage: 'You cannot bid for this player because their club would be left with too few players',
        description: 'Error message shown if a transfer bid cannot be submitted because the receiving club has too few players'
    },
    'PLAYER_RETIRING': {
        id: 'player:transferOffer:create:error:playerRetiring',
        defaultMessage: 'You cannot bid for this player because they are retired or retiring',
        description: 'Error message shown if a transfer bid cannot be submitted because the player is retired/retiring'
    },
    'YOUTH_BLOCK': {
        id: 'player:transferOffer:create:error:youthPlayer',
        defaultMessage: 'Transfer offers are not allowed for youth players',
        description: 'Error message shown if a transfer bid cannot be submitted because the player is a youth player'
    },
    'PLAYER_LOAN': {
        id: 'player:transferOffer:create:error:playerLoan',
        defaultMessage: 'You cannnot make a bid for a player who is on loan at another club',
        description: 'Error message shown when a bid is made for a player who is on loan'
    },
    'TRANSFER_BLOCK': {
        id: 'player:transferOffer:create:error:transferBlock',
        defaultMessage: 'You cannot bid for this player as they have only recently joined their club.',
        description: 'Error message shown if you try to bid for a player who has only recently moved clubs'
    },
    'REPUTATION_MISMATCH': {
        id: 'player:transferOffer:create:error:reputationAbs',
        defaultMessage: 'This player is not interested in joining your club',
    },
    'FREQUENCY_ERROR': {
        id: 'player:transferOffer:create:error:pendingBid',
        defaultMessage: 'You have already submitted a bid for this player, please wait for the other club to respond.'
    },
    'AUCTION_IN_PROGRESS': {
        id: 'player:transferOffer:create:error:auctionInProgress',
        defaultMessage: 'You cannot bid for this player as there is currently an auction in progress'
    },
    'MODIFY_RESOURCE_PERMISSION_DENIED': {
        id: 'player:transferOffer:create:error:permission',
        defaultMessage: 'The player\'s current club have blocked offers for this player.'
    },
    'INVALID_COMPENSATION': {
        id: 'player:transferOffer:create:error:compensation',
        defaultMessage: 'The bid value is invalid. Please try again.'
    },
    'LENGTH_MAX_CONSTRAINT': {
        id: 'player:transferOffer:create:error:maxContractLengthExceeded',
        defaultMessage: 'The contract you have offered is too long. Please try again with a shorter offer.'
    },
    'CONTRACT_VALUE_LOW': {
        id: 'player:transferOffer:create:error:contractValueLow',
        defaultMessage: 'The player has rejected your contract offer. Please try again with a better contract offer.'
    },
    'CONTRACT_VALUE_HIGH': {
        id: 'player:transferOffer:create:error:contractValueHigh',
        defaultMessage: 'The board feel this contract offer is too generous. Please try again with a lower contract offer.'
    },
    'OWN_PLAYER': {
        id: 'player:transferOffer:create:error:ownPlayer',
        defaultMessage: 'You cannot bid for your own player.'
    }
});