import TitledSegment from "../../../common/components/TitledSegment";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import ClubLink from "../../../club/components/common/Link";
import PlayerLink from "../../common/Link";
import { Button, Loader, Message } from "semantic-ui-react";
import { useMemo } from "react";
import { URL } from "../../../common/lib/paths";
import { useMutation } from '@tanstack/react-query';
import { generatePath, useHistory } from "react-router";
import axios from "axios";

export default function ViewPlayerTransferOfferLoanInterface(props) {
    const history = useHistory();
    const proposingClubLink = useMemo(() => !!props.playerTransferOfferLoan ? <ClubLink inline={true} club={props.playerTransferOfferLoan.club} /> : null, [props.playerTransferOfferLoan]);
    const receivingClubLink = useMemo(() => !!props.playerTransferOfferLoan ? <ClubLink inline={true} club={props.playerTransferOfferLoan.playerContract.club} /> : null, [props.playerTransferOfferLoan]);
    const playerLink = useMemo(() => !!props.playerTransferOfferLoan ? <PlayerLink inline={true} player={props.playerTransferOfferLoan.playerContract.player} /> : null, [props.playerTransferOfferLoan]);

    const withdrawPlayerLoanOfferMutation = useMutation(
        () => axios.patch(
            `${process.env.REACT_APP_APPHOST}/playerTransferOfferLoan/${props.playerTransferOfferLoan.id}`,
            {
                status: 'withdrawn'
            }
        ),
        {
            onSuccess: (data) => history.replace(generatePath(URL.viewPlayerTransferOfferLoanHistory, {playerTransferOfferLoanHistoryId: data.data.response.id}))
        }
    );

    const acceptPlayerLoanOfferMutation = useMutation(
        () => axios.patch(
            `${process.env.REACT_APP_APPHOST}/playerTransferOfferLoan/${props.playerTransferOfferLoan.id}`,
            {
                status: 'accepted'
            }
        ),
        {
            onSuccess: (data) => history.replace(generatePath(URL.viewPlayerTransferOfferLoanHistory, {playerTransferOfferLoanHistoryId: data.data.response.id}))
        }
    );

    const rejectPlayerLoanOfferMutation = useMutation(
        () => axios.patch(
            `${process.env.REACT_APP_APPHOST}/playerTransferOfferLoan/${props.playerTransferOfferLoan.id}`,
            {
                status: 'rejected'
            }
        ),
        {
            onSuccess: (data) => history.replace(generatePath(URL.viewPlayerTransferOfferLoanHistory, {playerTransferOfferLoanHistoryId: data.data.response.id}))
        }
    );

    function handleWithdrawPlayerLoanOffer() {
        withdrawPlayerLoanOfferMutation.mutate();
    }

    function handleAcceptPlayerLoanOffer() {
        acceptPlayerLoanOfferMutation.mutate();
    }

    function handleRejectPlayerLoanOffer() {
        rejectPlayerLoanOfferMutation.mutate();
    }


    if(props.isLoading) return(<Loader active />);

    if(!props.playerTransferOfferLoan) return (
        <Message
            negative
        >
            That transfer offer could not be found. Perhaps it has already been accepted or rejected?
        </Message>
    )

    return(
        <TitledSegment
            title='Loan offer'
        >
            <div>
                <p>
                    <FormattedMessage
                        {...messages.intro}
                        values={{
                            proposingClubName: proposingClubLink,
                            playerName: playerLink
                        }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        {...messages.pending}
                        values={{
                            receivingClubName: receivingClubLink,
                            deadline: <><FormattedDate value={props.playerTransferOfferLoan.deadline} /> <FormattedTime value={props.playerTransferOfferLoan.deadline} /></>
                        }}
                    />
                </p>
                {props.isMyPlayer === true && <p>If you accept this offer the player will move on loan immediately and any other offers will be cancelled.</p>}
            </div>
            {props.isMyPlayer === true ? 
                <Button.Group
                    fluid
                    widths={2}
                >
                    <Button
                        positive
                        onClick={handleAcceptPlayerLoanOffer}
                        isLoading={acceptPlayerLoanOfferMutation.isLoading}
                        isDisabled={acceptPlayerLoanOfferMutation.isLoading}
                    >
                        Accept
                    </Button>
                    <Button
                        negative
                        onClick={handleRejectPlayerLoanOffer}
                        isLoading={rejectPlayerLoanOfferMutation.isLoading}
                        isDisabled={rejectPlayerLoanOfferMutation.isLoading}
                    >
                        Reject
                    </Button>
                </Button.Group>
                :
                <Button
                    negative
                    fluid
                    onClick={handleWithdrawPlayerLoanOffer}
                    isLoading={withdrawPlayerLoanOfferMutation.isLoading}
                    isDisabled={withdrawPlayerLoanOfferMutation.isLoading}
                >
                    Withdraw
                </Button>
            }
        </TitledSegment>
    )
}

const messages = {
    intro: {
        id: 'playerLoanOffer:view:intro',
        defaultMessage: '{proposingClubName} have offered to take {playerName} on loan until the end of the season',
        description: 'Intro for a loan offer'
    },
    pending: {
        id: 'playerLoanOffer:view:pending',
        defaultMessage: '{receivingClubName} must respond to the offer before {deadline}',
        description: "Text describing the status of the loan offer where the club who have received the bid have not yet responded to it"
    }
}