const pitchSectionPositions = {
    12: 'GK',
    1: 'LB',
    7: 'CB',
    13: 'CB',
    19: 'CB',
    25: 'RB',
    2: 'LWB',
    8: 'CDM',
    14: 'CDM',
    20: 'CDM',
    26: 'RWB',
    3: 'LM',
    9: 'CM',
    15: 'CM',
    21: 'CM',
    27: 'RM',
    4: 'LW',
    10: 'CAM',
    16: 'CAM',
    22: 'CAM',
    28: 'RW',
    11: 'ST',
    17: 'ST',
    23: 'ST'
}

export default pitchSectionPositions;