import { Grid } from "semantic-ui-react";
import NarrowLayout from "../../common/components/NarrowLayout";
import {PlayerTrainingBoost, BonusBoost} from "./interface";

export default function PlayerTrainingBoosts(props) {
    if(props.isLoading) {
        return null;
    }

    return(
        <Grid.Row columns='equal' style={{padding: 0, maxWidth: '750px', margin: '0 auto'}}>
            {props.playerTrainingBoosts.map((playerTrainingBoost) => 
                <Grid.Column style={{padding: '0 0.5em 0 0'}}>
                    <NarrowLayout>
                        <PlayerTrainingBoost
                            key={playerTrainingBoost.id}
                            playerTrainingBoost={playerTrainingBoost}
                            playerRegistrations={props.playerRegistrations}
                            teamId={props.teamId}
                            manager={props.manager}
                        />
                    </NarrowLayout>
                </Grid.Column>
            )}
            {props.pointsRequiredForBonusBoost !== null &&
                <Grid.Column style={{padding: '0 0 0 0.5em'}}>
                    <NarrowLayout>
                        <BonusBoost
                            pointsRequired={props.pointsRequiredForBonusBoost}
                        />
                    </NarrowLayout>
                </Grid.Column>
            }
        </Grid.Row>
    );
}