import {Button} from "semantic-ui-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import DataTable from '../../common/components/DataTable';

import SUIIconShim from "../../common/components/SUIIconShim";
import { filterStateReducer, initialFilterState } from "../../player/filter/lib";
import usePersistedState from "../../common/lib/usePersistedState";
import { useEffect } from "react";
import { playerName } from "../../common/dataTableColumns/player/playerName";
import { playerPosition } from "../../common/dataTableColumns/player/playerPosition";
import { clubName } from "../../common/dataTableColumns/club/clubName";
import { personAge } from "../../common/dataTableColumns/person/personAge";
import { currency } from "../../common/dataTableColumns/currency";
import { date } from "../../common/dataTableColumns/date";
import { currentAbility } from "../../common/dataTableColumns/currentAbility";

export default function ScoutingInterface(props) {
    const {persistedState, updatePersistedState} = usePersistedState('scoutingFilter', initialFilterState);

    const columns = [
        playerName(
            (scoutReport) => scoutReport.player,
            (scoutReport) => scoutReport.player.playerContract,
            () => false
        ),
        playerPosition(
            (scoutReport) => scoutReport.player.position
        ),
        clubName(
            (scoutReport) => scoutReport.player.playerContract?.club,
            600
        ),
        personAge(
            (scoutReport) => scoutReport.player.age,
            560
        ),
        currency(
            'playerValue',
            (scoutReport) => scoutReport.player.value,
            425,
            false,
            {
                minWidth: '38px',
                maxWidth: '100px'
            }
        ),
        date(
            'dueDate',
            (scoutReport) => scoutReport.dueDate || scoutReport.updatedAt,
            false,
            350
        ),
        currentAbility(
            (scoutReport) => scoutReport.player.currentAbility
        )
    ]

    useEffect(() => {
        if(
            props.scoutReports?.length === 0
        ) {
            updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage', isMaxPage: true}));
        }
    }, [props.scoutReports?.length]);

    return(
        <>
            <DataTable
                columns={columns}
                data={props.scoutReports}
                isLoading={props.isLoading}
                placeholderCount={5}
                // expandableRows
                // expandableRowsComponent={PlayerExpander}
            />
            <Button.Group>
                <Button
                    icon
                    disabled={persistedState.currentPage === 1}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage'}))}
                    color='black'
                ><SUIIconShim><FontAwesomeIcon icon={faArrowLeft} /></SUIIconShim></Button>
                <Button
                    disabled
                    className="opacity-override"
                    color='black'
                    style={{width: '10em'}}
                >
                    Page {persistedState.currentPage}
                </Button>
                <Button
                    icon
                    disabled={persistedState.currentPage === persistedState.maxPage}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'nextPage', value:{fromCARank: persistedState.currentPage}}))}
                    color='black'
                ><SUIIconShim><FontAwesomeIcon icon={faArrowRight} /></SUIIconShim></Button>
            </Button.Group>
        </>
    );
}