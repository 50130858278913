import GradientBar from "../../../../common/components/GradientBar";

export default function ContractValue(props) {  
    if(props.isLoading) return null;

    const contractValue = ((props.salaryOffer-props.baseSalaryDemand)/props.baseSalaryDemand)*100;

    return (
        <GradientBar
            value={50+contractValue}
            direction='right'
            leftLabel='Cheap'
            rightLabel='Expensive'
            isLocked={props.isLocked}
        />
    );
};