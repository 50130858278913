import {useEffect} from "react";
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

import Players from '../toolbar/Players';
import Staff from '../toolbar/Staff';
import History from '../toolbar/History';
import Scouting from '../toolbar/Scouting';

import FilterButton from "../../player/filter/button/interface.js";
import WideLayout from "../../common/components/WideLayout";
import { Button } from "semantic-ui-react";
import SUIIconShim from "../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons";
import useCompare from "../../player/compare/useCompare";

import {initialFilterState, getFilterQueryParams, isFilterActive, getFilterStateFromPersistedState, filterStateReducer} from '../../player/filter/lib';
import usePersistedState from "../../common/lib/usePersistedState";
import PlayerTransferAuctionsInterface from "./interface.js";
import Auctions from "../toolbar/Auction";
import OffersNew from "../toolbar/Offers";

function fetchPlayers(filterState) {
    const filterParams = getFilterQueryParams(filterState, undefined);

    return axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionPaid?${filterParams.join('&')}`);
}

export default function PlayerTransferAuctions(props) {
    const {persistedState, updatePersistedState} = usePersistedState('playerTransferAuctionsFilter', initialFilterState);
    const {addPlayerIds} = useCompare();

    useEffect(() => {
        props.setToolbar({
            title: 'Player auctions',
            navigation: [
                <OffersNew />,
                <Players key={2} />,
                <Staff key={3} />,
                <Auctions key={7} />,
                <Scouting key={4} />,
                <History key={6} />
            ]
        });
    }, []);

    const {isLoading, data} = useQuery(
        [
            'playerTransferAuctions',
            ...getFilterStateFromPersistedState(persistedState)
        ],
        () => fetchPlayers(persistedState),
        {
            keepPreviousData: true
        }
    );

    useEffect(() => {
        if(
            data?.data?.response.length === 0
        ) {
            updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage', isMaxPage: true}));
        }
    }, [data?.data?.response.length]);

    return (
        <WideLayout>
            <FilterButton
                loading={isLoading}
                isFilterActive={isFilterActive(persistedState)}
                persistedStateKey='playerTransferAuctionsFilter'
            />
            <Button
                disabled={isLoading}
                color='black'
                style={{backgroundColor: 'rgba(15, 16, 17)'}}
                floated='right'
                icon
                onClick={() => addPlayerIds(data.data.response.map((player) => player.id))}
            >
                <SUIIconShim>
                    <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} transform={{rotate: 45}} />
                </SUIIconShim>
            </Button>
            <PlayerTransferAuctionsInterface
                isLoading={isLoading}
                players={data?.data?.response}
                persistedStateKey='playerTransferAuctionsFilter'
            />
        </WideLayout>
    );
}