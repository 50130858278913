function reduceKeyEvents(keyEventData, playerData, minTime) {
    return keyEventData.reduce((keyEvents, keyEvent) => {
        if(minTime !== undefined && keyEvent.time > minTime) return keyEvents;

        const player = playerData[keyEvent.side][keyEvent.primaryPlayerIndex];

        if(keyEvent.type === 0) {
            //goal
            keyEvents[keyEvent.side].totalGoals++;
            const previousGoalIndex = keyEvents[keyEvent.side].goalPlayerIndex.indexOf(player.playerId)
            if(previousGoalIndex !== -1) {
                //not first goal of match
                keyEvents[keyEvent.side].goalEvents[previousGoalIndex].goalTimes.push(keyEvent.time);
                keyEvents[keyEvent.side].goalEvents[previousGoalIndex].isPenalty.push(keyEvent.isPenalty);
            } else {
                //first goal of match
                keyEvents[keyEvent.side].goalEvents.push({
                    type: 'goal',
                    playerId: player.playerId,
                    playerName: `${player.initial}. ${player.lastName}`,
                    isPenalty: [keyEvent.isPenalty],
                    goalTimes: [keyEvent.time],
                });
                keyEvents[keyEvent.side].goalPlayerIndex.push(player.playerId);
            }
        } else if(keyEvent.type === 1) {
            //red card
            keyEvents[keyEvent.side].redCardEvents.push({
                type: 'redCard',
                playerId: player.playerId,
                playerName: `${player.initial}. ${player.lastName}`,
                time: keyEvent.time
            });
        }

        return keyEvents;
    }, {home: {totalGoals: 0, goalPlayerIndex: [], goalEvents: [], redCardEvents: []}, away: {totalGoals: 0, goalPlayerIndex: [], goalEvents: [], redCardEvents: []}});
}

export {reduceKeyEvents}