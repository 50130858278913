import React from "react";
import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../../common/lib/paths.js";
import { faCity} from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "club:toolbar:facilities",
		defaultMessage: "Facilities",
		description: "Link for club facilities page"
	}
});

export default function ViewClubFacilitiesToolbarItem(props) {
	return(
		<ToolbarItem
			path={{
				pathname: generatePath(URL.viewClubFacilities, {clubId: props.clubId})
			}}
			icon={faCity}
            messages={messages}
			activeToolbarItemRef={props.activeToolbarItemRef}
			genericPath={URL.viewClubFacilities}
		/>
	);
}

