import { defineMessages, FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import TitledSegment from "../../../../../common/components/TitledSegment";
import ShortenedPlayerName from "../../../../common/ShortenedName";
import { Grid, Message } from "semantic-ui-react";
import LoadedStatistic from "../../../../../common/components/LoadedStatistic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClubLink from "../../../../../club/components/common/Link";
import Currency from "../../../../../common/components/Currency";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import PlayerLink from "../../../../common/Link";

export default function TransferInfo(props) {
    console.log(props.player);

    return(
        <TitledSegment
            title='Transfer info'
        >
            <FormattedMessage
                {...props.message}
                values={{
                    fromClubName: props.fromClub ? <ClubLink inline={true} club={props.fromClub} /> : '',
                    toClubName: props.toClub ? <ClubLink inline={true} club={props.toClub} /> : '',
                    playerName: props.player ? <PlayerLink inline={true} player={props.player} /> : ''
                }}
            />
            <Grid className='gridReset'>
                <Grid.Row columns={2} style={{padding: '1em 0 0 0'}}>
                    <Grid.Column style={{padding: 0}}>
                        <LoadedStatistic
                            label='Player value'
                            value={<Currency value={props.player.value} />}
                        />
                    </Grid.Column>
                    <Grid.Column style={{padding: 0}}>
                        <LoadedStatistic
                            label='Submitted offers'
                            value={<><span>{props.submittedOfferCount || 0}</span> <span style={{fontSize: '10px'}}>clubs</span></>}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {!!props.playerTransferOfferPaid ?
                <Message warning className='boldLabel large'>
                    <FontAwesomeIcon icon={faWarning} className='inlineIcon' />
                    <ClubLink nation={false} inline={true} club={props.fromClub} /> must respond by <FormattedTime value={props.playerTransferOfferPaid.deadline} /> on <FormattedDate value={props.playerTransferOfferPaid.deadline} month='short' day='numeric' weekday='short' />
                </Message>
                :
                null
            }
        </TitledSegment>
    );
}