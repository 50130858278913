import { defineMessages } from "react-intl";
import DataTable from "../../common/components/DataTable";
import WideLayout from "../../common/components/WideLayout";

import { seasonName } from "../../common/dataTableColumns/date";
import { clubName } from "../../common/dataTableColumns/club/clubName";
import { leagueName } from "../../common/dataTableColumns/leagueName";

export default function StaffHistoryInterface(props) {
    const columns = [
        seasonName(
            'seasonName',
            (STLS) => STLS.season.startDate,
            false,
            undefined
        ),
        clubName(
            (STLS) => STLS.club,
            undefined
        ),
        leagueName(
            (STLS) => STLS.league,
            undefined
        )
    ]

    return(
        <WideLayout>
            <DataTable
                columns={columns}
                data={props.STLS}
                isLoading={props.isLoading}
                placeholderCount={3}
            />
        </WideLayout>
    )
}

const messages = defineMessages({
    seasonColumnLabel: {
        id: "Layout_PlayerHistory.seasonLabel",
        defaultMessage: "Season",
        description: "Header for the column in the player history table which states the season number"
    },
    leagueNameColumnLabel: {
        id: "Layout_PlayerHistory.leagueLabel",
        defaultMessage: "League",
        description: "Header for the column in the player history table which states the league name"
    },
    clubNameColumnLabel: {
        id: "Layout_PlayerHistory.clubNameLabel",
        defaultMessage: "Club",
        description: "Header for the 'club name' column in the player history table"
    }
});