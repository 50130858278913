import axios from "axios";
import { generatePath, Redirect, useParams } from "react-router";
import {useQuery, useQueryClient, useMutation} from '@tanstack/react-query';
import {useEffect} from 'react';
import StaffProfileInterface from "./interface";
import ShortenedName from "../../player/common/ShortenedName";

import Profile from '../toolbar/Profile';
import ContractOffer from '../toolbar/ContractOffer';
import TransferOffer from '../toolbar/TransferOffer';
import History from '../toolbar/History';
import NarrowLayout from "../../common/components/NarrowLayout";
import { URL } from "../../common/lib/paths";

export default function StaffProfile(props) {
    const {staffId} = useParams();
    const queryClient = useQueryClient();

    const {isLoading, data} = useQuery(
        ['getStaffProfile', staffId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/staff/${staffId}`)
    );

    const createFavStaff = useMutation(() => axios.post(`${process.env.REACT_APP_APPHOST}/favStaff`, {
        staffId: parseInt(staffId)
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('getStaffProfile');
        }
    });

    const deleteFavStaff = useMutation(() => axios.delete(`${process.env.REACT_APP_APPHOST}/favStaff?staffId=${staffId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries('getStaffProfile');
        }
    });

    useEffect(() => {
        if(!!data && data.data.success) {
            const isMyStaff = data?.data.response.staffContract && !!props.manager.club && data.data.response.staffContract.club.id === props.manager.club.id

            props.setToolbar({
                title: <><ShortenedName player={data.data.response} /> profile</>,
                navigation: [
                    <Profile
                        key={1}
                        staffId={staffId}
                    />,
                    <ContractOffer
                        key={2}
                        staffId={staffId}
                        isMyStaff={isMyStaff}
                    />,
                    <TransferOffer
                        key={4}
                        staffId={staffId}
                        isMyStaff={isMyStaff}
                    />,
                    <History
                        key={3}
                        staffId={staffId}
                    />,
                ]
            })
        }
    }, [staffId, isLoading]);

    const deleteContract = useMutation((staffContractId) =>
        axios.delete(`${process.env.REACT_APP_APPHOST}/staffContracts/${staffContractId}`), {
            onSuccess: () => {
                queryClient.invalidateQueries('getStaffProfile');
            },
            onError: (error) =>  {
                console.warn(error);
            }
        }
    );

    const handleReleasedPlayer = (staffContractId) => {
        deleteContract.mutate(staffContractId);
    }

    if(isLoading === false && data.data.response.key === 'RESOURCE_DOES_NOT_EXIST') {
        return(
            <Redirect to={{pathname: generatePath(URL.retiredStaffProfile, {staffId: staffId})}} />
        );
    }

    return(
        <NarrowLayout>
            <StaffProfileInterface
                isLoading={isLoading}
                staff={data?.data?.response}
                isMyStaff={data?.data.response.staffContract && !!props.manager.club && data.data.response.staffContract.club.id === props.manager.club.id}
                isFavStaff={data?.data?.response.isFavStaff}
                handleToggleFavStaff={handleToggleFavStaff.bind(this)}
                toggleFavSTaffLoading={createFavStaff.isLoading || deleteFavStaff.isLoading}
                handleReleasedStaff={handleReleasedPlayer}
                releasePlayerLoading={deleteContract.isLoading}
                releaseStaffSuccess={deleteContract.isSuccess}
                releaseStaffIsError={deleteContract.isError}
                releaseStaffErrorMessage={deleteContract.error?.response?.data}
            />
        </NarrowLayout>
    );

    function handleToggleFavStaff() {
        if(data.data.response.isFavStaff === false) {
            createFavStaff.mutate();
        } else if(data.data.response.isFavStaff === true) {
            deleteFavStaff.mutate();
        }
    }
}