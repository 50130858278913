import { Application, Assets, Sprite, Graphics, Text, Container } from "pixi.js";
import {MediaPlayer} from 'shared-messages';

export default function initApp(canvasRef, setCanvasSize, setOrientationClass, setCommentary) {
    const app = new Application();
    const mediaPlayer = new MediaPlayer(false, setCommentary);
    let resizeListener;

    app.init({
        eventMode: 'none',
        interactiveChildren: false,
        background: '#268b07',
        canvas: canvasRef.current,
        // resizeTo: canvasRef.current.parentElement,
        antialias: true,
        resolution: 2 //todo test this actually has a benefit
    }).then(() => {
        Assets.load('pitch').then(createPitch.bind(undefined, app));
        const s = getContainerSize3();
        console.log(s);
        app.renderer.resize(s.width, s.height, 2);
        resizeListener = initResizeListener(canvasRef, app, setCanvasSize, setOrientationClass);
        mediaPlayer.createGraphics(app, Graphics, Text, Container);
    });

    Assets.add({
        alias: 'pitch',
        src: '/pitch_full.png'
    });

    Assets.backgroundLoad(['pitch']);

    setOrientationClass(getOrientation());
    setCanvasSize(getContainerSize3());

    return {mediaPlayer, app};
}

function initResizeListener(canvasRef, app, setCanvasSize, setOrientationClass) {
    const resizeListener = handleResize.bind(undefined, canvasRef, app, setCanvasSize, setOrientationClass)
    window.addEventListener('resize', () => setTimeout(resizeListener, 100));

    return resizeListener;
}

function handleResize(canvasRef, app, setCanvasSize, setOrientationClass) {
    console.log('resize');

    const s = getContainerSize3();
    console.log(s);
    setCanvasSize(s);

    // app.resize();

    app.renderer.resize(s.width, s.height, 2);

    app.stage.removeChildAt(0);
    const texture = Assets.get('pitch');
    createPitch(app, texture);
    setOrientationClass(getOrientation());

    console.log(app);
}

function createPitch(app, texture) {
    console.log('setting up pitch');
    const pitch = new Sprite({texture, eventMode: 'none'});

    pitch.setSize(app.canvas.width/2, app.canvas.height/2);

    setPitchOrientation(texture);

    app.stage.addChildAt(pitch, 0);
}

function getOrientation() {
    if(window.innerWidth >= window.innerHeight) {
        return 'landscape';
    } else {
        return 'portrait';
    }
}

function getContainerSize() {
    console.log('size');

    let containerWidth;
    let containerHeight;

    const windowAspectRatio = window.innerWidth / window.innerHeight;

    if(window.innerWidth >= window.innerHeight) {
        //landscape
        if(windowAspectRatio > 5/3) {
            //e.g. 600x300 (2:1) vs. 600x360 (5:3)
            //constrain to 100% Height such that
            // 500x300 (5:3)
            containerWidth = window.innerHeight * (5/3);
            containerHeight = window.innerHeight;
        } else if(windowAspectRatio <= 5/3) {
            //e.g. 600x450 (4:3) vs 600x360 (5:3)
            //constrain to 100% Width such that
            // 600x360 (5:3)
            containerWidth = window.innerWidth;
            containerHeight = window.innerWidth * (3/5);
        }
    } else {
        //portrait
        if(windowAspectRatio < 3/5) {
            //e.g. 300x600 (1:2) vs 360x600 (3:5)
            //constrain to 100% Width such that
            // 300x500 (3:5)
            containerWidth = window.innerWidth;
            containerHeight = window.innerWidth * (5/3);
        } else if(windowAspectRatio >= 3/5) {
            //e.g. 450x600 (3:4) vs 360x600 (3:5)
            //constrain to 100% Height such that
            // 360x600 (3:5)
            containerWidth = window.innerHeight * (3/5);
            containerHeight = window.innerHeight;
        }
    }

    return {width: containerWidth, height: containerHeight};
}

/*

    Objective of this function is to size the canvas optimally within the flex container

*/
function getContainerSize2() {
    let containerWidth;
    let containerHeight;

    const container = document.getElementById('canvasContainer');

    const containerAspectRatio = container.offsetWidth / container.offsetHeight;

    console.log(container.offsetWidth);
    console.log(container.offsetHeight);

    if(container.offsetWidth >= container.offsetHeight) {
        //landscape
        if(containerAspectRatio > 5/3) {
            console.log('a');
            //e.g. 600x300 (2:1) vs. 600x360 (5:3)
            //constrain to 100% Height such that
            // 500x300 (5:3)
            containerWidth = container.offsetWidth * (5/3);
            containerHeight = container.offsetHeight;
        } else if(containerAspectRatio <= 5/3) {
            console.log('b');
            //e.g. 600x450 (4:3) vs 600x360 (5:3)
            //constrain to 100% Width such that
            // 600x360 (5:3)
            containerWidth = container.offsetWidth;
            containerHeight = container.offsetWidth * (3/5);
        }
    } else {
        //portrait
        if(containerAspectRatio < 3/5) {
            //e.g. 300x600 (1:2) vs 360x600 (3:5)
            //constrain to 100% Width such that
            // 300x500 (3:5)
            containerWidth = container.offsetWidth;
            containerHeight = container.offsetWidth * (5/3);
        } else if(containerAspectRatio >= 3/5) {
            //e.g. 450x600 (3:4) vs 360x600 (3:5)
            //constrain to 100% Height such that
            // 360x600 (3:5)
            containerWidth = container.offsetHeight * (3/5);
            containerHeight = container.offsetHeight;
        }
    }

    return {width: containerWidth, height: containerHeight};
}

function getContainerSize3() {
    const container = document.getElementById('canvasContainer');
    const screenWidth = container.offsetWidth;
    const screenHeight = container.offsetHeight;

    console.log(screenWidth);
    console.log(screenHeight);

    const isLandscape = screenWidth >= screenHeight;
    const targetAspectRatio = isLandscape ? 5 / 3 : 3 / 5;
    const screenAspectRatio = screenWidth / screenHeight;
    let canvasWidth, canvasHeight;

    if (screenAspectRatio > targetAspectRatio) {
        // Screen is wider than target aspect ratio
        canvasHeight = screenHeight;
        canvasWidth = canvasHeight * targetAspectRatio;
    } else {
        // Screen is narrower than target aspect ratio
        canvasWidth = screenWidth;
        canvasHeight = canvasWidth / targetAspectRatio;
    }

    // Round the canvas dimensions to the nearest integer
    canvasWidth = Math.round(canvasWidth);
    canvasHeight = Math.round(canvasHeight);

    return { width: canvasWidth, height: canvasHeight };
}

function setPitchOrientation(texture) {
    console.log('orient');

    const orientation = getOrientation();

    if(orientation === 'landscape') {
        texture.rotate = 0;
    } else {
        texture.rotate = 2;
    }
    texture.update();
}