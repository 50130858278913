import { useQuery } from "@tanstack/react-query";
import { getFilterStateFromPersistedState, getFilterQueryParams } from '../../staff/filter/lib';
import axios from "axios";

export default function useGetStaff(filterState, maxReputationAbs, favStaff, enabled) {
    return useQuery(
        [
            'searchStaff',
            ...getFilterStateFromPersistedState(filterState)
        ],
        () => fetchStaff(filterState, maxReputationAbs, favStaff),
        {
            keepPreviousData: true,
            enabled
        }
    );
}

function fetchStaff(filterState, maxReputationAbs, favStaff) {
    const filterParams = getFilterQueryParams(filterState, maxReputationAbs, favStaff);

    return axios.get(`${process.env.REACT_APP_APPHOST}/staff?${filterParams.join('&')}`);
}