import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faShirt, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

import DataTable from '../../common/components/DataTable';

import sortByLineup from "./sorters/sortByLineup";
import PlayerExpander from "../common/PlayerExpander/Index";
import usePersistedState from "../../common/lib/usePersistedState";

import colours from '../../common/colours';
import { playerName } from "../../common/dataTableColumns/player/playerName";
import { playerPosition } from "../../common/dataTableColumns/player/playerPosition";
import AssitsIcon from '../common/AssistIcon';
import { currentAbility } from "../../common/dataTableColumns/currentAbility";
import { statColumn } from "../../common/dataTableColumns/player/PTLSStat";
import { condition } from "../../common/dataTableColumns/player/condition";
import { useTutorial } from '../../tutorial/hooks/useTutorial';

export default function PlayersSelectionView(props) {
    const {tutorialKey, tutorialStep, checkTutorial, getSearchParamsString} = useTutorial();

    const isTutorial = checkTutorial('players', 3);

    const {
        persistedState,
        updatePersistedState
    } = usePersistedState(
        props.isOwner ? 'squadSelectionViewSort_1' : 'squadSelectionViewSort_0',
        props.isOwner ?
            {
                column: 'playerName',
                direction: 'asc'
            }
            :
            {
                column: 'playerCurrentAbility',
                direction: 'desc'
            }
    );

    const columns = [
        playerName(
            (playerReg) => playerReg.playerContract.player,
            (playerReg) => playerReg.playerContract,
            (playerReg) => playerReg,
            (playerReg) => playerReg.teamTacticPosition,
            undefined,
            (rowData, i) => isTutorial ? getSearchParamsString(tutorialKey, tutorialStep+1) : undefined,
            (rowData, i) => isTutorial && i === 0 && 'glow',
            sortByLineup.bind(this, props.ownerClubFormation, (playerReg) => playerReg.playerContract.player)
        ),
        playerPosition(
            (playerReg) => playerReg.playerContract.player.position
        ),
        statColumn(
            'playerAppearances',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.appearances,
            <FontAwesomeIcon icon={faShirt} />,
            500
        ),
        statColumn(
            'playerGoalsScored',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.goalsScored,
            <FontAwesomeIcon icon={faFutbol} />,
            550
        ),
        statColumn(
            'playerAssists',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.assists,
            <AssitsIcon />,
            600
        ),
        statColumn(
            'playerAvgMatchRating',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.averageMatchRating,
            <FontAwesomeIcon icon={faThumbsUp} />,
            650
        ),
        condition(
            (playerReg) => playerReg.playerContract.player.condition,
            450,
            props.isOwner === false
        ),
        currentAbility(
            (playerReg) => playerReg.playerContract.player.currentAbility
        )
    ];

    return(
        <DataTable
            columns={columns}
            data={props.playerRegistrations}
            isLoading={props.isLoading}
            placeholderCount={14}
            // expandableRows
            // expandableRowsComponent={PlayerExpander}
            defaultSortFieldId={persistedState.column}
            defaultSortAsc={persistedState.direction === 'asc'}
            keyField='id'
            onSort={(column, direction) => updatePersistedState({column: column.id, direction})}
        />
    )
}