import { faWarning } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormattedTime } from "react-intl"
import { Grid, Message, Statistic } from "semantic-ui-react"
import ClubLink from "../../../club/components/common/Link"
import Component_Currency from "../../../common/components/Currency"
import TitledSegment from "../../../common/components/TitledSegment"
import PlayerLink from "../../common/Link"

export default function PlayerTransferAuctionStatus(props) {
    return(
        <TitledSegment
            title='Auction status'
        >
            {!props.isLoading && <p><PlayerLink player={props.playerTransferAuction.playerContract.player} inline={true} /> is currently under auction. After the time shown below, the player will sign for the winning club.</p>}
            <Grid className='gridReset transferAuctionBidStatus'>
                <Grid.Row columns={2} style={{padding: 0}}>
                    <Grid.Column style={{paddingLeft: 0, paddingRight: '0.5em'}}>
                        <Statistic
                            className='newStatistic small labelNormal'
                        >
                            <Statistic.Value>
                                {!props.isLoading && <Component_Currency value={props.playerTransferAuction?.playerTransferAuctionTopBidPaid.currentBid} />}
                            </Statistic.Value>
                            <Statistic.Label>
                                Highest bid
                            </Statistic.Label>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column style={{paddingRight: 0, paddingLeft: '0.5em'}}>
                        <Statistic
                            className='newStatistic small labelNormal'
                        >
                            <Statistic.Value>
                                {!props.isLoading && <ClubLink nation={false} club={props.playerTransferAuction?.playerTransferAuctionTopBidPaid.club} /> }
                            </Statistic.Value>
                            <Statistic.Label>
                                Winning club
                            </Statistic.Label>
                        </Statistic>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {!props.isLoading && props.playerTransferAuction.isFinished === true &&
                <Message negative className='boldLabel large'>
                    <FontAwesomeIcon icon={faWarning} className='inlineIcon' />
                    This auction has ended
                </Message>
            }
            {!props.isLoading && props.playerTransferAuction.isFinished === false &&
                <Message warning className='boldLabel large'>
                    <Message.Content>
                        <p style={{margin: 0}}>{props.playerTransferAuction.interval} hours remaining</p>
                        <p style={{margin: 0}}>Auction ends <FormattedTime value={props.playerTransferAuction.endDateMin} /> - <FormattedTime value={props.playerTransferAuction.endDateMax} /></p>
                    </Message.Content>
                </Message>
            }
        </TitledSegment>
    )
}