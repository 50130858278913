import { Grid, Header } from "semantic-ui-react";
import { groupedColours } from "../../../common/lib/color";
import SimpleRatingBar from "../../../common/components/SimpleRatingBar";

export default function AttributeTable(props) {
    if(props.position === 'GK') {
        throw new Error('GK has no attributes');
    }

    return(
        <Grid style={style.grid}>
            <Grid.Row columns={3}>
                <Grid.Column style={style.column}>
                    <div style={{...style.valueContainer, backgroundColor: groupedColours.DEF}} className='trait2'>
                        <div style={style.value}>{props.player.defendRating}</div>
                    </div>
                    <Header as='h5' className='boldLabel large' style={style.label}>Defend</Header>
                </Grid.Column>
                <Grid.Column style={style.column}>
                    <div style={{...style.valueContainer, backgroundColor: groupedColours.MID}} className='trait2'>
                        <div style={style.value}>{props.player.createRating}</div>
                    </div>
                    <Header as='h5' className='boldLabel large' style={style.label}>Attack</Header>
                </Grid.Column>
                <Grid.Column style={style.column}>
                    <div style={{...style.valueContainer, backgroundColor: groupedColours.ATK}} className='trait2'>
                        <div style={style.value}>{props.player.finishRating}</div>
                    </div>
                    <Header as='h5' className='boldLabel large' style={style.label}>Finish</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} style={{paddingTop: 0}}>
                <Grid.Column>
                    <SimpleRatingBar value={props.player.ratingRatio} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

const style = {
    grid: {
        padding: 0,
        margin: 0
    },
    column: {
        
    },
    label: {
        margin: 0,
        textAlign: 'center'
    },
    valueContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        width: '100%',
        maxWidth: '5em',
        aspectRatio: '1',
        padding: '1em',
        overflow: 'hidden',
        margin: '0 auto',
        position: 'relative'
    },
    value: {
        fontSize: '3rem',
        fontWeight: 900,
        color: '#000'
    }
}