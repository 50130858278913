import { playerName } from "../../../common/dataTableColumns/player/playerName";
import { playerPosition } from "../../../common/dataTableColumns/player/playerPosition";
import DataTable from "../../../common/components/DataTable";

export default function FavRetiredPlayers(props) {
    const columns = [
        playerName((player) => player, () => null, () => null),
        playerPosition((player) => player.position)
    ]

    return(
        <DataTable
            columns={columns}
            data={props.favRetiredPlayers}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    );
}