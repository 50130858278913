import { Form } from "semantic-ui-react";
import ControlledCurrencyInput from "../../../../common/components/ControlledCurrencyInput";

export default function SalaryOffer(props) {
    return(
        <Form.Field>
            <label>Salary offer</label>
            <ControlledCurrencyInput
                value={props.salaryOffer}
                setValue={props.setSalaryOffer}
                resetValue={props.resetSalaryOffer}
                getIncrement={() => props.increment}
                getDecrement={() => props.increment}
            />
        </Form.Field>
    );
}