import { defineMessages, FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import TitledSegment from "../../../../common/components/TitledSegment";
import ShortenedPlayerName from "../../../common/ShortenedName";
import { Grid, Message } from "semantic-ui-react";
import LoadedStatistic from "../../../../common/components/LoadedStatistic";
import SUIIconShim from "../../../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

export default function TransferInfo(props) {
    return(
        <TitledSegment
            title='Transfer info'
        >
            <FormattedMessage
                {...messages.intro}
                values={{
                    playerName: props.player ? <ShortenedPlayerName player={props.player} /> : ''
                }}
            />
            <Grid className='gridReset'>
                <Grid.Row columns={2} style={{padding: '1em 0 0 0'}}>
                    <Grid.Column style={{padding: 0}}>
                        <LoadedStatistic
                            label='Time remaining'
                            value={<><span>{props.playerFreeAuction?.hoursUntilEndDate || 12}</span> <span style={{fontSize: '10px'}}>hours</span></>}
                        />
                    </Grid.Column>
                    <Grid.Column style={{padding: 0}}>
                        <LoadedStatistic
                            label='Submitted offers'
                            value={<><span>{props.playerFreeAuction?.playerTransferAuctionBidFreeCount || 0}</span> <span style={{fontSize: '10px'}}>clubs</span></>}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {!!props.playerFreeAuction ?
                <Message warning className='boldLabel large'>
                    <FontAwesomeIcon icon={faWarning} className='inlineIcon' />
                    Decision due <FormattedDate value={props.playerFreeAuction.endDateMin} month='short' day='numeric' weekday='short' /> <FormattedTime value={props.playerFreeAuction.endDateMin} /> - <FormattedTime value={props.playerFreeAuction.endDateMax} />
                </Message>
                :
                null
            }
        </TitledSegment>
    );
}

const messages = defineMessages({
    intro: {
        id: 'playerFreeAuction:notStarted',
        defaultMessage: '{playerName} is available on a free transfer. After the time shown below, the player will consider all submitted offers and decide which club to join.',
        description: 'Message when a player is a free agent but an auction has not yet started'
    }
});