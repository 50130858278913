import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import ViewPlayerTransferOfferPaid from "../../../playerTransferOfferPaid/proposed/interface";

export default function ViewPlayerTransferOffer(props) {
    const getPlayerTransferOfferQuery = useQuery(
        ['getPlayerTransferOffer', props.playerTransferOfferId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferOfferPaid/${props.playerTransferOfferId}`)
    );

    return(
        <ViewPlayerTransferOfferPaid
            isLoading={getPlayerTransferOfferQuery.isLoading}
            playerTransferOffer={getPlayerTransferOfferQuery.data?.data?.response}
            isMyPlayer={props.manager.club.id === getPlayerTransferOfferQuery.data?.data?.response?.playerContract.club.id}
            player={props.player}
        />
    )
}