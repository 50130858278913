import { defineMessages } from "@formatjs/intl";
import { compileMessage } from "./util.js";

const messages = {
    'staff:contractExpired': defineMessages({
        subject: {
            id: "messageSubject:staff:contractExpired",
            defaultMessage: "{staffName} contract expired",
            description: "Subject for the system message which a user receives when a staff contract has expired and they get released from the club"
        },
        body: {
            id: "messageBody:staff:contractExpired",
            defaultMessage: "The contract of staff member {staffName} has expired and they have now left the club.",
            description: "Message body for the system message which a user receives when a staff contract has expired and they get released from the club"
        }
    }),
    'favStaff:contractExpired': defineMessages({
        subject: {
            id: "messageSubject:favStaff:contractExpired",
            defaultMessage: "{staffName} contract expired",
            description: "Subject for the system message which a user receives when a favourite staff contract has expired and they get released from the club"
        },
        body: {
            id: "messageBody:favStaff:contractExpired",
            defaultMessage: "The contract of staff member {staffName}, who is on your favourites, has expired and they have left {clubName}. They are now a free agent.",
            description: "Message body for the system message which a user receives when a favourite staff contract has expired and they get released from the club"
        }
    }),
    'staff:retiring': defineMessages({
        subject: {
            id: "messageSubject:staff:retiring",
            defaultMessage: "I've decided to retire",
            description: "Subject for the system message which a user receives when their staff announces his retirement"
        },
        body: {
            id: "messageBody:staff:retiring",
            defaultMessage: "After much thought I have decided that, at the age of {age}, this season will be my last and I will retire at the end of this season.",
            description: "Message body for the system message which a user receives when their pstafflayer announces his retirement"
        }
    }),
    'favStaff:retiring': defineMessages({
        subject: {
            id: "messageSubject:favStaff:retiring",
            defaultMessage: "{staffName} announces retirement",
            description: "Subject for the system message which a user receives when a favourite staff announces his retirement"
        },
        body: {
            id: "messageBody:favStaff:retiring",
            defaultMessage: "{staffName}, who is on your favourites, has announced that this season will be their last in the game and they will retire at the end of the season aged {age}.",
            description: "Message body for the system message which a user receives when a favourite staff announces his retirement"
        }
    }),
    'staff:retired': defineMessages({
        subject: {
            id: "messageSubject:staff:retired",
            defaultMessage: "{staffName} retires",
            description: "Subject for the system message which a user receives when their staff retires"
        },
        body: {
            id: "messageBody:staff:retired",
            defaultMessage: "After a long career, {clubName} staff member {staffName} has today retired at the age of {age}.",
            description: "Message body for the system message which a user receives when their staff retires"
        }
    }),
    'favStaff:retired': defineMessages({
        subject: {
            id: "messageSubject:favStaff:retired",
            defaultMessage: "{staffName} retires",
            description: "Subject for the system message which a user receives when a favourite staff retires"
        },
        body: {
            id: "messageBody:favStaff:retired",
            defaultMessage: "After a long career staff member {staffName}, who is on your favourites, has today retired at the age of {age}.",
            description: "Message body for the system message which a user receives when a favourite staff retires"
        }
    }),
    'staff:joinedClub:permanent:free': defineMessages(compileMessage({
        subject: {
            id: 'staff:joinedClub:permanent:free',
            defaultMessage: "Staff member {staffName} joins {toClubName}",
            description: "Subject for the system message template when a staff joins a club on a free transfer"
        },
        body: {
            id: 'staff:joinedClub:permanent:free',
            defaultMessage: "{toClubName} have today announced a new appointment to their {staffDepartment} department by signing {staffName} on a free transfer.\n\nHe has signed a {contractSalary} per season contract until {contractEndDate}.",
            description: "Body for the system message template when a staff joins a club on a free transfer"
        }
    })),
    'staff:regen': defineMessages(compileMessage({
        subject: {
            id: 'messageSubject:staff:regen',
            defaultMessage: '{staffName} becomes a {roleName}',
        },
        body: {
            id: 'messageBody:staff:regen',
            defaultMessage: 'Former player {staffName} has today announced his transition into a {roleName} role following his retirement as a player at the end of last season.',
        }
    }))
}

export default messages;