import { useHistory, useLocation } from "react-router";
import WideLayout from "../../../common/components/WideLayout";
import { Menu, Table } from "semantic-ui-react";
import { teamName } from "../../../common/dataTableColumns/team/teamName";

import assistIcon from '../../common/assists-icon.png';
import appearancesIcon from '../../../common/appearances-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faThumbsUp, faTrophy, faSquare } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { useMediaQuery } from "react-responsive";
import DataTable from "../../../common/components/DataTable";
import { mean } from "lodash";
import { dateRange, seasonName } from "../../../common/dataTableColumns/date";

export default function PlayerHistoryOverallInterface(props) {
    const history = useHistory();
    const location = useLocation();
    const useExpanderControl = useMediaQuery({
        query: '(max-width: 599px)'
    });

    const columns = [
        teamName(
            (row) => ({id: row.teamId, type: row.teamType, club: {id: row.clubId, name: row.clubName, nation: {id: row.nationId, nationGroupShortcode: row.nationGroupShortcode}}}),
            'Total',
            false
        ),
        seasonName(
            'startSeasonName',
            (row) => row.startDate,
            false,
            950,
            true,
            'From'
        ),
        seasonName(
            'endSeasonName',
            (row) => row.endDate,
            false,
            950,
            true,
            'To'
        ),
        {
            name: <img src={appearancesIcon} style={{width: '16px', height: '16px'}} />,
            cell: PTLS => PTLS.appearances,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 350,
            grow: 1
        },
        {
            name: <FontAwesomeIcon icon={faFutbol} />,
            cell: PTLS => PTLS.goalsScored,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 350,
            grow: 1
        },
        {
            name: <img src={assistIcon} style={{width: '16px', height: '16px'}} />,
            cell: PTLS => PTLS.assists,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 370,
            grow: 1
        },
        {
            name: <FontAwesomeIcon icon={faTrophy} />,
            cell: PTLS => PTLS.totalMOTM,
            wrap: false,
            allowOverflow: false,
            center: true,
            compact: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 450,
            grow: 1
        },
        {
            name: <FontAwesomeIcon icon={faSquare} color='#FBBD08' />,
            cell: PTLS => PTLS.yellowCards,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 520,
            grow: 1
        },
        {
            name: <FontAwesomeIcon icon={faSquare} color='#DB2828' />,
            cell: PTLS => PTLS.redCards,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 600,
            grow: 1
        }
    ];

    const totals = useMemo(() => {
        if(!props.history) return [];

        const total = props.history.reduce((total, PTLS) => {
            total.appearances += PTLS.appearances;
            total.goalsScored += PTLS.goalsScored;
            total.assists += PTLS.assists;
            total.averageMatchRating.push(PTLS.averageMatchRating);
            total.totalMOTM += PTLS.totalMOTM;
            total.yellowCards += PTLS.yellowCards;
            total.redCards += PTLS.redCards;
            
            return total;
        }, {
            appearances: 0,
            goalsScored: 0,
            assists: 0,
            averageMatchRating: [],
            totalMOTM: 0,
            yellowCards: 0,
            redCards: 0,
            isTotal: true
        });

        total.averageMatchRating = mean(total.averageMatchRating);

        return [total];
    }, [props.history]);

    return(
        <WideLayout>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Menu
                    inverted
                    compact
                    size='tiny'
                >
                    <Menu.Item onClick={() => history.replace({pathname: location.pathname, state: {...location.state, view: 'season'}})}>Seasons</Menu.Item>
                    <Menu.Item active>Overall</Menu.Item>
                </Menu>
            </div>
            <DataTable
                columns={columns}
                data={[...props.history, ...totals]}
                isLoading={props.isLoading}
                placeholderCount={3}
                expandableRows={useExpanderControl}
                expandableRowsComponent={PlayerSeasonHistoryExpander}
                conditionalRowStyles={[
                    {
                        when: row => row.isTotal === true,
                        style: {
                            fontWeight: 'bold'
                        }
                    }
                ]}
            />
        </WideLayout>
    )
}

function PlayerSeasonHistoryExpander(props) {
    return(
        <Table
            unstackable
            definition
            compact='very'
            striped
            columns={2}
        >
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Appearances</Table.Cell>
                    <Table.Cell>{props.data.appearances}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Goals</Table.Cell>
                    <Table.Cell>{props.data.goalsScored}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Assists</Table.Cell>
                    <Table.Cell>{props.data.assists}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Yellow cards</Table.Cell>
                    <Table.Cell>{props.data.yellowCards}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Red cards</Table.Cell>
                    <Table.Cell>{props.data.redCards}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    )
}