import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faIdBadge } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_PlayerProfile.text",
		defaultMessage: "Profile",
		description: "Link for player profile page"
	}
});

export default function RetiredPlayerProfile(props) {
	return(
		<ToolbarItem
			path={{
				pathname: generatePath(URL.retiredPlayerProfile, {playerId: props.playerId})
			}} 
			icon={faIdBadge}
			messages={messages}
			genericPath={URL.retiredPlayerProfile}
		/>
	);
}