import { faMedkit } from "@fortawesome/free-solid-svg-icons";
import DepartmentStaffList from "./StaffList";
import TitledSegment from "../../../../common/components/TitledSegment";
import { Grid, Table, TableCell, TableRow } from "semantic-ui-react";
import RatingBar from "../../../../common/components/RatingBar";

export default function CoachDept(props) {
    if(props.isLoading) return null;

    return(
        <TitledSegment
            title={'Physio Department'}
            icon={faMedkit}
            noPadding={true}
        >
            <Grid style={{margin: 0}}>
                <PhysioDeptOverview physioDept={props.physioDept} />
                <DepartmentStaffList
                    role={'physio'}
                    staffContracts={props.physioDept.staffContracts}
                />
            </Grid>
        </TitledSegment>
    );
}

function PhysioDeptOverview(props) {
    return(
        <Grid.Row columns={1}>
            <Grid.Column>
                <p>Physios help to prevent players getting injured in training and matches, and speed up injury recovery.</p>
                <Table
                    basic='very'
                    unstackable
                    compact
                >
                    <TableRow>
                        <TableCell width={6} style={{fontWeight: 'bold'}}>Injury prevention</TableCell>
                        <TableCell width={6}><RatingBar value={props.physioDept.injuryPreventionRating} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={6} style={{fontWeight: 'bold'}}>Injury recovery</TableCell>
                        <TableCell width={6}><RatingBar value={props.physioDept.injuryRecoveryRating} /></TableCell>
                    </TableRow>
                </Table>
            </Grid.Column>
        </Grid.Row>
    )
}