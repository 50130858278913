import { memo, useEffect, useRef, useState } from "react";
import PlayHighlight from "./PlayHighlight";
import { Grid, Header, Segment } from "semantic-ui-react";
import { faFutbol, faPause, faRightFromBracket, faSquare } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group-react-18";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reduceKeyEvents } from "../fixture/components/lib";
import {commentaryMessages} from 'shared-messages';
import { useHistory } from "react-router";

function PlayHighlights(props) {
    const [currentHighlight, setCurrentHighlight] = useState(null);
    const {tickCount, tick, startTick, pauseTick} = useTick(props.highlights, handleHighlightDue.bind(this));
    const [commentary, setCommentary] = useState({text: '.', color: '#000', type: -1});
    const [isPaused, setIsPaused] = useState(false);

    //clear timer on unmount
    useEffect(() => {
        startTick();
        return () => pauseTick();
    }, []);

    useEffect(() => {
        const dueHighlightIndex = props.highlights.findIndex((h) => h.time === tickCount)
        if(dueHighlightIndex !== -1) {
            //don't schedule next tick
            pauseTick();
            handleHighlightDue(dueHighlightIndex);
        } else if(tickCount >= 91) {
            pauseTick();
        }
    }, [tickCount]);

    const orientation = window.innerWidth >= window.innerHeight ? 'landscape' : 'portrait';

    return(
        <>
            <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
                <HighlightDecorations
                    tickCount={tickCount}
                    fixture={props.fixture}
                    highlightInProgress={!!currentHighlight}
                    setIsPaused={setIsPaused}
                    isPaused={isPaused}
                >
                    {orientation === 'landscape' ?
                        <div style={{width: '50%', height: '100%', display: 'inline', float: 'right'}}>
                            <CommentaryBar commentary={commentary} />
                        </div>
                        :
                        null
                    }
                </HighlightDecorations>
                <PlayHighlight
                    highlight={props.highlights[currentHighlight]?.buffer.data}
                    handleHighlightFinished={handleHighlightFinished.bind(this)}
                    handleUpdateCommentary={handleUpdateCommentary.bind(this)}
                    isPaused={isPaused}
                />
                {orientation === 'portrait' ?
                    <div style={{flex: '0 0 auto'}}>
                        <CommentaryBar commentary={commentary} />
                    </div>
                    :
                    null
                }
            </div>
        </>
    )

    function handleHighlightFinished() {
        setTimeout(() => {
            setCommentary({text: '.', color: '#000', type: -1});
            setCurrentHighlight(null);
            tick(1500);            
        }, 2500);
    }

    function handleHighlightDue(dueHighlightIndex) {
        setCurrentHighlight(dueHighlightIndex);
    }

    function handleUpdateCommentary(commentaryItem) {
        setCommentary({type: commentaryItem.type, text: templateString(commentaryMessages[commentaryItem.type], commentaryItem), color: commentaryItem.color});

        function templateString(str, params) {
            // Replace each placeholder in the string with the corresponding property from the `params` object
            return str.replace(/{(\w+)}/g, (match, key) => {
                // Check if the property exists in the params object
                if (key in params) {
                    return params[key];
                }
                // If the key doesn't exist in the params, return the match unchanged
                return match;
            });
        }
    }
}

const HighlightDecorations = memo(function(props) {
    const keyEvents = reduceKeyEvents(props.fixture.matchData.keyEventData, props.fixture.matchData.playerData, props.tickCount >= 91 ? undefined : props.tickCount-1);

    return(
        <div style={{flex: '0 0 auto', position: 'relative'}}>
            <div style={{width: '100%', height: '100%'}}>
                <div style={props.children === null ? {width: '100%', height: '100%', backgroundColor: '#192d4d'} : {width: '50%', height: '100%', backgroundColor: '#192d4d', display: 'inline', float: 'left'}}>
                    <div style={{display: 'inline-block', width: 'calc(50% - 50px)', height: '100%', overflow: 'auto', textAlign: 'left', minWidth: 0}}>
                        <div style={{display: 'flex', alignItems: 'center', height: '100%', padding: '0 0.5em'}}>
                            <Header as='h3' style={{color: '#FFF', margin: 0, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'right'}}>{props.fixture.homeTeam.club.name}</Header>
                        </div>
                    </div>
                    <div style={{display: 'inline-block', width: '20px', height: '100%', overflow: 'auto', backgroundColor: '#FFF', textAlign: 'center'}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                            <Header as='h2' style={{margin: 0}}>{keyEvents.home.totalGoals}</Header>
                        </div>
                    </div>
                    <div style={{display: 'inline-block', overflow: 'auto', width: '60px', height: '100%', overflow: 'auto', textAlign: 'center', cursor: 'pointer'}} className='primaryHover' onClick={() => props.setIsPaused((p) => !p)}>
                        <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <Header as='h4' style={{color: '#FFF', margin: 0, width: '100%'}}>
                                {props.isPaused ?
                                    <FontAwesomeIcon icon={faPause} size='xl' />
                                    :
                                    formatTimer(props.tickCount)
                                }
                            </Header>
                        </div>
                    </div>
                    <div style={{display: 'inline-block', width: '20px', height: '100%', overflow: 'auto', backgroundColor: '#FFF', textAlign: 'center'}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                            <Header as='h2' style={{margin: 0}}>{keyEvents.away.totalGoals}</Header>
                        </div>
                    </div>
                    <div style={{display: 'inline-block', width: 'calc(50% - 50px)', height: '100%', overflow: 'auto', textAlign: 'right', minWidth: 0}}>
                        <div style={{display: 'flex', alignItems: 'center', height: '100%', padding: '0 0.5em'}}>
                            <Header as='h3' style={{color: '#FFF', margin: 0, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left'}}>{props.fixture.awayTeam.club.name}</Header>
                        </div>
                    </div>
                </div>
                {props.children}
            </div>
            <KeyInfo
                highlightInProgress={props.highlightInProgress}
                keyEvents={keyEvents}
                isPaused={props.isPaused}
            />
        </div>
    );

    function formatTimer(tickCount) {
        if(tickCount >= 91) {
            return 'FT';
        } else if(tickCount < 10) {
            return `0${tickCount}:00`;
        } else {
            return `${tickCount}:00`;
        }
    }
});

const CommentaryBar = memo(function(props) {
    return(
        <div style={{padding: '0.5em 0.5em', width: '100%', height: '100%', backgroundColor: props.commentary.color, textAlign: 'center', overflow: 'hidden'}} className={props.commentary.type === 0 ? 'commentary-bar celebrate' : 'commentary-bar'}>
            <Header as='h3'>{props.commentary.text}</Header>
        </div>
    )
});

const KeyInfo = memo(function(props) {
    const history = useHistory();

    return(
        <CSSTransition
            in={
                (
                    props.highlightInProgress === false &&
                    (
                        props.keyEvents.home.goalEvents.length > 0 ||
                        props.keyEvents.away.goalEvents.length > 0 ||
                        props.keyEvents.home.redCardEvents.length > 0 ||
                        props.keyEvents.away.redCardEvents.length > 0
                    )
                ) ||
                props.isPaused === true
            }
            timeout={300}
            classNames="simOverlay"
            mountOnEnter={true}
            unmountOnExit={true}
            appear={true}
        >
            <div>
                <Segment className='simOverlaySegment'>
                    <Grid style={{margin: 0}}>
                        {(props.keyEvents.home.goalEvents.length > 0 || props.keyEvents.away.goalEvents.length > 0) &&
                            <Grid.Row>
                                <Grid.Column width={7} textAlign='right' style={{paddingLeft: 0, paddingRight: '0.5em'}}>
                                    {
                                        props.keyEvents.home.goalEvents.map((goalEventGroup) => 
                                            <p style={{margin: 0}}>{goalEventGroup.playerName} <span style={{fontWeight: 'bold'}}>{goalEventGroup.goalTimes.map((goalTime, index) => `${goalTime}'${goalEventGroup.isPenalty[index] === true ? ' (P)' : ''}`).join(', ')}</span></p>
                                        )
                                    }
                                </Grid.Column>
                                <Grid.Column width={2} style={{padding: 0}} textAlign='center'>
                                    <FontAwesomeIcon icon={faFutbol} />
                                </Grid.Column>
                                <Grid.Column width={7} textAlign='left' style={{paddingRight: 0, paddingLeft: '0.5em'}}>
                                    {
                                        props.keyEvents.away.goalEvents.map((goalEventGroup) => 
                                            <p style={{margin: 0}}><span style={{fontWeight: 'bold'}}>{goalEventGroup.goalTimes.map((goalTime, index) => `${goalTime}'${goalEventGroup.isPenalty[index] === true ? ' (P)' : ''}`).join(' ')}</span> {goalEventGroup.playerName}</p>
                                        )
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        }
                        {(props.keyEvents.home.redCardEvents.length > 0 || props.keyEvents.away.redCardEvents.length > 0) &&
                            <Grid.Row>
                                <Grid.Column width={7} textAlign='right' style={{paddingLeft: 0, paddingRight: '0.5em'}}>
                                    {
                                        props.keyEvents.home.redCardEvents.map((redCardEvent) => 
                                            <p>{redCardEvent.playerName} {redCardEvent.time}'</p>
                                        )
                                    }
                                </Grid.Column>
                                <Grid.Column width={2} style={{padding: 0}} textAlign='center'>
                                    <FontAwesomeIcon icon={faSquare} color='#DB2828' />
                                </Grid.Column>
                                <Grid.Column width={7} textAlign='left' style={{paddingRight: 0, paddingLeft: '0.5em'}}>
                                    {
                                        props.keyEvents.away.redCardEvents.map((redCardEvent) => 
                                            <p>{redCardEvent.time}' {redCardEvent.playerName}</p>
                                        )
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        }
                        <Grid.Row>
                            <Grid.Column width={16} textAlign="center">
                                <a href="javascript:void(0)" onClick={() => history.goBack()}>
                                    <FontAwesomeIcon icon={faRightFromBracket} className='inlineIcon' />
                                    Exit highlights
                                </a>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>
        </CSSTransition>
    )
});

function useTick(highlights, handleHighlightDue) {
    const [tickCount, setTickCount] = useState(0);
    const timeoutRef = useRef(null);

    function tick(nextTickInterval = 200) {
        setTickCount((prevTickCount) => prevTickCount + 1);
        timeoutRef.current = setTimeout(tick, nextTickInterval);
    }

    function startTick() {
        if(timeoutRef.current !== null) return;

        timeoutRef.current = setTimeout(tick, 200);
    }

    function pauseTick() {
        if(timeoutRef.current === null) return;

        clearInterval(timeoutRef.current);
        timeoutRef.current = null;
    }

    return {tickCount, startTick, pauseTick, tick}
}

export default memo(PlayHighlights);