import { useReducer} from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

import {Button, Form, Dimmer, Divider, Grid, Checkbox} from "semantic-ui-react";
import StaffRoleName from "../../common/RoleName";
import usePersistedState from "../../../common/lib/usePersistedState";
import {initialFilterState as IFS, filterStateReducer as FSR} from '../lib';
import { useIntl } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {useTutorial} from '../../../tutorial/hooks/useTutorial';
import NationGroupNameWithFlag from "../../../nationGroup/common/NationGroupNameWithFlag";

const roles = [
    'coach',
    'physio',
    'scout',
    'youth'
];

export default function StaffSearchFilterOverlay(props) {
    const {persistedState, updatePersistedState} = usePersistedState(props.persistedStateKey, IFS);
    const intl = useIntl();
    const {checkTutorial, goNextStep} = useTutorial();

    const seasonsQuery = useQuery(
        ['getSeasons', 4],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/seasons?bounds=4`)
    );

    const nationsQuery = useQuery(
        ['getNationGroups'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nationGroups`)
    );

    const initialFilterState = {
        filterRole: persistedState.filterRole,
        filterNationShortcode: persistedState.filterNationShortcode,
        filterRating: persistedState.filterRating,
        filterContractStatus: persistedState.filterContractStatus,
        filterContractLength: persistedState.filterContractLength,
        filterAge: persistedState.filterAge,
        filterUninterested: persistedState.filterUninterested
    };

    const [filterState, filterDispatch] = useReducer(filterStateReducer, initialFilterState);

    function handleFilterUninterestedSet() {
        filterDispatch({action: 'filterUninterested'});
        isTutorial2 && goNextStep();
    }

    function handleFilterRoles(e, {value}) {
        filterDispatch({
            action: 'filterRoles',
            value: value || null
        });
    }

    function handleFilterNationShortcode(e, {value}) {
        filterDispatch({action: 'filterNationShortcode', value: value || null});
    }

    function handleRatingSliderSet(render, handle, value, un, percent) {
        filterDispatch({action: 'filterRating', value: {
            minimum: parseInt(value[0]),
            maximum: parseInt(value[1])
        }});
    }

    function handleAgeSliderSet(render, handle, value, un, percent) {
        filterDispatch({action: 'filterAge', value: {
            minimum: parseInt(value[0]),
            maximum: parseInt(value[1])
        }});
    }

    function handleContractStatusClick() {
        filterDispatch({action: 'filterContractStatus'});
        isTutorial1 && goNextStep();
    }

    function handleFilterExpiry(e, {value}) {
        filterDispatch({action: 'filterContractLength', value: value || null});
    }

    function handleApplyFilterClick() {
        updatePersistedState(FSR(persistedState, {
            name: 'updateFilters',
            value: filterState
        }));
        isTutorial3 && goNextStep();
    }

    function handleCloseFilterClick() {
        updatePersistedState(FSR(persistedState, {
            name: 'toggleFilterOverlay'
        }));
    }

    const isTutorial1 = checkTutorial('staff', 4);
    const isTutorial2 = checkTutorial('staff', 5);
    const isTutorial3 = checkTutorial('staff', 6);

    return(
        <div style={styles.overlayContainer}>
            <Dimmer active={true} page={true} />
            <div style={styles.overlay} className='transferSearchFilterOverlayTarget'>
                <div style={styles.dimmer}></div>
                <div style={styles.filters}>
                    <Form style={styles.form}>
                        <Form.Group widths='equal' unstackable style={{flexWrap: 'nowrap'}}>
                            <Form.Dropdown
                                fluid
                                label='Role'
                                placeholder='All roles'
                                selection
                                options={
                                    roles.map((role) => ({key: role, text: <StaffRoleName role={role} />, value: role}))
                                }
                                value={filterState.filterRole}
                                onChange={handleFilterRoles}
                                selectOnBlur={false}
                                clearable
                            />
                            <Form.Dropdown
                                selection
                                fluid
                                clearable
                                selectOnBlur={false}
                                placeholder='All nations'
                                label='Nation'
                                loading={nationsQuery.isLoading}
                                disabled={nationsQuery.isLoading}
                                options={nationsQuery.data?.data.response.map((nation) => (
                                    {
                                        key: nation.shortcode,
                                        value: nation.shortcode,
                                        text: (<NationGroupNameWithFlag responsiveClass='nationFlag' shortcode={nation.shortcode} />)
                                    }
                                )) || []}
                                onChange={handleFilterNationShortcode}
                                value={filterState.filterNationShortcode}
                            />
                        </Form.Group>
                        <Divider horizontal />
                        <Form.Field>
                            <label>Rating</label>
                            <Nouislider
                                range={{min: 0, max: 100}}
                                start={[
                                    filterState.filterRating.minimum,
                                    filterState.filterRating.maximum
                                ]}
                                step={1}
                                connect
                                tooltips={[{to:sliderToNumb, from:sliderFromStr}, {to:sliderToNumb, from:sliderFromStr}]}
                                onSet={handleRatingSliderSet.bind(this)}
                            />
                        </Form.Field>
                        <Divider horizontal />
                        <Form.Field>
                                <label>Age</label>
                                <Nouislider
                                    range={{min: 30, max: 60}}
                                    start={[
                                        filterState.filterAge.minimum,
                                        filterState.filterAge.maximum
                                    ]}
                                    step={1}
                                    connect
                                    tooltips={[{to:sliderToNumb, from:sliderFromStr}, {to:sliderToNumb, from:sliderFromStr}]}
                                    onSet={handleAgeSliderSet.bind(this)}
                                />
                            </Form.Field>
                        <Divider horizontal />
                        <Form.Group widths='equal' unstackable style={{flexWrap: 'nowrap'}}>
                            <Form.Field>
                                <label>Contract status</label>
                                <Button
                                    toggle
                                    active={filterState.filterContractStatus}
                                    onClick={handleContractStatusClick.bind(this)}
                                    size="small"
                                    fluid
                                    className={isTutorial1 && 'glow'}
                                >
                                    Free agent
                                </Button>
                            </Form.Field>
                            <Form.Field>
                                <label>Contract expiry</label>
                                <Form.Dropdown
                                    selection
                                    fluid
                                    clearable
                                    selectOnBlur={false}
                                    placeholder="All contract lengths"
                                    loading={seasonsQuery.isLoading}
                                    disabled={seasonsQuery.isLoading || filterState.filterContractStatus}
                                    options={seasonsQuery.data?.data.response.map((season) => (
                                        {
                                            key: season.id,
                                            value: season.id,
                                            text: intl.formatDate(season.endDate, {month: 'short', day: 'numeric', year: 'numeric'})
                                        }
                                    )) || []}
                                    onChange={handleFilterExpiry}
                                    value={filterState.filterContractLength}
                                    upward={true}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Divider horizontal />
                        <Form.Group widths="equal" unstackable style={{flexWrap: "nowrap"}}>
                            <Form.Field>
                                <Checkbox
                                    label='Interested staff only'
                                    checked={filterState.filterUninterested}
                                    onChange={handleFilterUninterestedSet.bind(this)}
                                    className={isTutorial2 && 'glow'}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Divider horizontal style={{margin: '0.5rem'}} />
                    </Form>
                </div>
                <div style={styles.submit}>
                    <Divider />
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Button fluid onClick={handleCloseFilterClick.bind(this)}>Cancel</Button>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Button
                                    positive
                                    fluid
                                    onClick={handleApplyFilterClick.bind(this)}
                                    className={isTutorial3 && 'glow'}
                                >Apply</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

function filterStateReducer(currentFilterState, action) {
    let newFilterState = {
        filterRole: currentFilterState.filterRole,
        filterNationShortcode: currentFilterState.filterNationShortcode,
        filterRating: currentFilterState.filterRating,
        filterContractStatus: currentFilterState.filterContractStatus,
        filterContractLength: currentFilterState.filterContractLength,
        filterAge: currentFilterState.filterAge,
        filterUninterested: currentFilterState.filterUninterested
    }

    switch(action.action) {
        case 'filterRoles':
            newFilterState.filterRole = action.value;
            break;
        case 'filterRating':
            newFilterState.filterRating = action.value;
            break;
        case 'filterNationShortcode':
            newFilterState.filterNationShortcode = action.value;
            break;
        case 'filterContractStatus':
            newFilterState.filterContractStatus = !currentFilterState.filterContractStatus;
            break;
        case 'filterContractLength':
            newFilterState.filterContractLength = action.value;
            break;
        case 'filterAge':
            newFilterState.filterAge = action.value;
            break; 
        case 'filterUninterested':
            newFilterState.filterUninterested = !currentFilterState.filterUninterested;
            break;
    }

    return newFilterState;
}

const styles = {
    overlayContainer: {
        position: "fixed",
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
        bottom: 0,
        zIndex: 99999
    },
    overlay: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-end",
        alignContent: "stretch",
        alignItems: "stretch"
    },
    dimmer: {
        order: 0,
        flex: "1 1 auto",
        alignSelf: "auto",
        zIndex: -1
    },
    filters: {
        order: 0,
        flex: "0 1 auto",
        alignSelf: "auto",
        overflow: "auto",
        maxHeight: "min-content",
        backgroundColor: "#FFFFFF"
    },
    form: {
        padding: "1rem 1rem 0 1rem"
    },
    submit: {
        padding: "0 1rem 1rem 1rem",
        order: 0,
        flex: "0 0 auto",
        alignSelf: "auto",
        backgroundColor: "#FFFFFF",
    }
};

function sliderToNumb(val) {
    return parseInt(val);
}

function sliderFromStr(val) {
    return parseInt(val);
}