import axios from "axios";
import { useEffect } from "react";
import ScoutingInterface from "./interface";
import {useQuery} from '@tanstack/react-query';
import {useHistory, useLocation} from 'react-router';
import {Button} from 'semantic-ui-react';

import Players from '../toolbar/Players';
import Staff from '../toolbar/Staff';
import History from '../toolbar/History';
import {default as ScoutingToolbar} from '../toolbar/Scouting';
import WideLayout from "../../common/components/WideLayout";
import FilterButton from "../../player/filter/button/interface";
import { getFilterQueryParams, initialFilterState, isFilterActive, getFilterStateFromPersistedState } from "../../player/filter/lib";
import ScoutingOverview from "./Overview";
import useCompare from "../../player/compare/useCompare";
import SUIIconShim from "../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons";
import usePersistedState from "../../common/lib/usePersistedState";
import Auctions from "../toolbar/Auction";
import OffersNew from "../toolbar/Offers";
import { useTutorial } from "../../tutorial/hooks/useTutorial";

function fetchScoutReports(filterState, location) {
    const filterParams = getFilterQueryParams(filterState, undefined);

    return axios.get(`${process.env.REACT_APP_APPHOST}/scoutReports?status=${location.state.status}&${filterParams.join('&')}`);
}

export default function Scouting(props) {
    const location = useLocation();
    const history = useHistory();
    const {persistedState} = usePersistedState('scoutingFilter', initialFilterState);
    const {addPlayerIds} = useCompare();
    const {checkTutorial, goNextStep} = useTutorial();

    const scoutDepartmentQuery = useQuery(
        ['getClubScoutingStaff', props.manager.club.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.manager.club.id}/departments?role=scout`)
    );

    const scoutReportCountQuery = useQuery(
        ['countScoutReports'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/scoutReports/count`),
        {
            enabled: props.manager?.club?.id !== undefined
        }
    );

    const scoutReportsQuery = useQuery(
        [
            'getScoutReports',
            ...getFilterStateFromPersistedState(persistedState),
            location.state?.status
        ],
        () => fetchScoutReports(persistedState, location),
        {
            keepPreviousData: true,
            enabled: !!location.state
        }
    );

    useEffect(() => {
        props.setToolbar({
            title: 'Scout reports',
            navigation: [
                <OffersNew />,
                <Players key={2} />,
                <Staff key={3} />,
                <Auctions key={7} />,
                <ScoutingToolbar key={4} />,
                <History key={6} />
            ]
        });
    }, []);

    useEffect(() => {
        if(!location.state?.status) {
            history.replace({
                pathname: location.pathname,
                state: {
                    ...location.state,
                    status: 'completed'
                },
                search: location.search
            })
        }
    }, [location.state]);

    const isLoading = scoutReportsQuery.isLoading || scoutDepartmentQuery.isLoading || scoutReportCountQuery.isLoading;
    const isTutorial1 = checkTutorial('transfers', 14);

    return(
        <WideLayout>
            <ScoutingOverview
                isLoading={isLoading}
                department={scoutDepartmentQuery.data?.data.response}
                counts={scoutReportCountQuery.data?.data.response}
            />
            <FilterButton
                loading={isLoading}
                isFilterActive={isFilterActive(persistedState)}
                persistedStateKey='scoutingFilter'
            />
            <Button
                disabled={isLoading}
                color='black'
                style={{backgroundColor: 'rgba(15, 16, 17)'}}
                floated='right'
                icon
                onClick={handleCompareClick.bind(this)}
                className={isTutorial1 && 'glow'}
            >
                <SUIIconShim>
                    <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} transform={{rotate: 45}} />
                </SUIIconShim>
            </Button>
            <ScoutingInterface
                isLoading={isLoading}
                scoutReports={scoutReportsQuery.data?.data.response}
            />
        </WideLayout>
    )

    function handleCompareClick() {
        addPlayerIds(scoutReportsQuery.data.data.response.map((report) => report.player.id));

        if(isTutorial1 === true) {
            goNextStep();
        }
    }
}

/*

    Complete scout reports
        player name
        position
        club
        completion date
        current ability
        view icon (direct to scouting tab of player stack)

    pending scout report
        player name
        position
        club
        estimated completion date
        current ability
        cancel icon (direct to scouting tab of player stack)

*/