import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
    text: {
        id: "Component_Toolbar_PlayerHistory.text",
        defaultMessage: "History",
        description: "Link for player history page"
    }
});

export default function RetiredStaffHistory(props) {
    return(
        <ToolbarItem
            path={generatePath(URL.retiredStaffHistory, {staffId: props.staffId})}
            icon={faCalendarDays}
            messages={messages}
        />
    );
}