import axios from "axios";
import { useEffect } from "react";
import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams } from "react-router";
import LeagueName from "../../league/components/common/Name";
import LeagueSeasonHistoryInterface from "./interface";
import { FormattedMessage } from "react-intl";
import ClubProfileToolbarItem from "../../club/components/toolbar/ClubProfile";
import TeamPlayersToolbarItem from "../../club/components/toolbar/TeamPlayers";
import TeamFixturesToolbarItem from "../../club/components/toolbar/TeamFixtures";
import TeamLeagueToolbarItem from "../../club/components/toolbar/TeamLeague";
import ClubStaffToolbarItem from "../../club/components/toolbar/ClubStaff";
import ClubTransfersToolbarItem from "../../club/components/toolbar/ClubTransfers";
import TeamLeagueSeasonsToolbarItem from "../../club/components/toolbar/TeamLeagueSeasons";

export default function LeagueSeasonHistory(props) {
    const location = useLocation();
    const {clubId, teamType} = useParams();

    const leagueSeasonsQuery = useQuery(
        ['getAllLeagueSeasons', props.leagueId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagues/${props.leagueId}/leagueSeasons?includeTLS=true&filterChampions=true`)
    );

    useEffect(() => {
        if(!!leagueSeasonsQuery.data?.data?.response) {
            const isMyLS = location.state?.leagueSeasonId === props.manager.club.firstTeam.currentTLS.LS.id || location.state?.leagueSeasonId === props.manager.club.youthTeam.currentTLS.LS.id;

            if(!!clubId && !!teamType) {
                var navigation = [
                    <ClubProfileToolbarItem clubId={clubId} key={1} />,
                    <TeamPlayersToolbarItem clubId={clubId} teamType={teamType} key={2} />,
                    <TeamFixturesToolbarItem clubId={clubId} teamType={teamType} key={5} />,
                    <TeamLeagueToolbarItem clubId={clubId} teamType={teamType} key={6} />,
                    <ClubStaffToolbarItem clubId={clubId} key={7} />,
                    <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                    <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType={teamType} key={9} />
                ]
            } else {
                var navigation = [];
            }

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{leagueName: <LeagueName league={leagueSeasonsQuery.data.data.response} />}} />,
                navigation
            }); 
        }
    }, [leagueSeasonsQuery.data]);

    return(
        <LeagueSeasonHistoryInterface
            isLoading={leagueSeasonsQuery.isLoading}
            leagueSeasons={leagueSeasonsQuery.data?.data?.response.LS}
        />
    );
}

const messages = {
    screenTitle: {
        id: 'test',
        defaultMessage: '{leagueName} history',
        description: 'todo'
    }
}