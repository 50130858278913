import { URL } from './paths';
import Home from "../../news/data.js";
import Inbox from '../../messages/components/inbox/data';
import Message from '../../messages/components/message/data';
import NewManager from "../../manager/components/new-manager/data.js";
import Jobs from "../../manager/components/jobs/firstJob/data.js";
import Squad from "../../club/components/players/data.js";
import Training from '../../training/data';
import League from '../../leagueSeason/resolver_leagueId.js';
import TeamLeague from '../../leagueSeason/resolver_clubId.js';
import ClubStaff from "../../club/components/staff/data.js";
import ClubFixtures from "../../club/components/fixtures/data.js";
import ClubProfile from "../../club/components/profile/data.js";
import TeamHistory from '../../club/components/history/interface.js';
import PlayerSearch from "../../transfer/playerSearch/data";
import PlayerProfile from "../../player/profile/PlayerIDProvider";
import PlayerContractOffer from '../../player/playerContract/renew/data';
import PlayerHistory from '../../player/history/router';
import PlayerPromotion from "../../player/promotion/data.js";
import PlayerCompare from '../../player/compare/data';
import StaffProfile from "../../staff/profile/data.js";
import RetiredStaffProfile from '../../retiredStaff/profile/data';
import StaffHistory from "../../staff/history/data.js";
import RetiredStaffHistory from '../../retiredStaff/history/data';
import StaffContractOffer from '../../staff/staffContract/data';
import StaffTransferRouter from '../../staff/staffTransferAuctionFree/router';
import StaffTransferAuctionFree from "../../staff/staffTransferAuctionFree/data.js";
import StaffTransferAuctionFreeHistory from '../../staff/staffTransferAuctionFreeHistory/data.js';
import StaffSearch from '../../transfer/staffSearch/data';
import Fixture from '../../fixture/components/data';
import Tactics from '../../tactics/data';
import TacticsBuilder from '../../tactics/builder/data.js';
import Register from '../../user/register/data';
import Login from '../../user/login/data';
import Logout from '../../user/logout/data';
import TransferHistory from '../../transfer/history/data';
import NationList from '../../world/components/nations/data';
import WorldClubs from '../../world/components/clubs/data.js';
import WorldCups from '../../world/components/cups/data.js';
import Cup from '../../cup/index.js';
import CupFixtures from '../../cup/fixtures/data.js';
import Nation from '../../nation/profile/data';
import NationLeagues from '../../nation/leagues/data';
import NationClubs from '../../nation/clubs/data';
import NationPlayers from '../../nation/players/data';
import Scouting from '../../transfer/scouting/data';
import CityProfile from "../../city/profile/data.js";
import CityPlayers from "../../city/players/data.js";
import PlayerTransferOrLoanOffer from '../../player/transferOffer/playerTransferOrLoanOffer/data';
import PlayerAuction from '../../player/transferOffer/router';
import ViewPlayerTransferOfferPaid from '../../player/transferOffer/playerTransferOfferPaid/data.js';
import ViewPlayerTransferOfferPaidHistory from "../../player/transferOffer/playerTransferOfferPaidHistory/data.js";
import ViewPlayerTransferOfferLoan from '../../player/transferOffer/playerTransferOfferLoan/data.js';
import ViewPlayerTransferOfferLoanHistory from '../../player/transferOffer/playerTransferOfferLoanHistory/data.js';
import PlayerTransferOffers from "../../transfer/transferProposals/data.js";
import PlayerTransferAuctions from '../../transfer/playerTransferAuctions/data';
import PlayerTransferAuction from '../../player/transferOffer/playerTransferAuctionPaid/data.js';
import PlayerFreeAuction from "../../player/transferOffer/playerTransferAuctionFree/router";
import ClubTransferHistory from "../../club/components/transferHistory/data.js";
import PlayerTransferAuctionFreeHistory from "../../player/transferOffer/playerTransferAuctionFreeHistory/data.js";
import PlayerTransferAuctionPaidHistory from "../../player/transferOffer/playerTransferAuctionPaidHistory/data.js";
import Budget from "../../club/components/budget.js";
import ClubFinances from "../../club/components/finances/data.js";
import EditClubStadium from "../../club/components/editStadium/data.js";
import ViewClubStadium from '../../club/components/viewStadium/data.js';
import TutorialMenu from "../../tutorial/menu/TutorialMenu.js";
import MessagesTutorial from '../../tutorial/loaders/MessagesTutorial.js';
import FinancesTutorial from '../../tutorial/loaders/FinancesTutorial';
import NewsTutorial from '../../tutorial/loaders/NewsTutorial';
import PlayersTutorial from '../../tutorial/loaders/PlayersTutorial';
import TacticsTutorial from '../../tutorial/loaders/TacticsTutorial';
import TrainingTutorial from '../../tutorial/loaders/TrainingTutorial';
import ClubsTutorial from '../../tutorial/loaders/ClubsTutorial';
import StaffTutorial from '../../tutorial/loaders/StaffTutorial';
import FixturesTutorial from '../../tutorial/loaders/FixturesTutorial';
import LeagueTutorial from '../../tutorial/loaders/LeagueTutorial';
import WorldTutorial from '../../tutorial/loaders/WorldTutorial';
import IntroTutorial from '../../tutorial/loaders/IntroTutorial';
import TransfersTutorial from '../../tutorial/loaders/TransfersTutorial';
import FavPlayers from '../../favourites/players/data.js';
import FavClubs from '../../favourites/clubs/data.js';
import FavStaff from '../../favourites/staff/data.js';
import PlayerTeamLeagueSeasonsInterface from '../../leagueSeason/playerTeamLeagueSeason/interface.js';
import StatsTableData from '../../cup/playerTeamCupSeason/view/data.js';
import CupSeasonHistory from '../../cup/history/data.js';

import WorldRecords from "../../world/components/records/menu.js";
import PlayerAppearanceRecords from '../../world/components/records/players/appearances';
import PlayerAssistRecords from '../../world/components/records/players/assists';
import PlayerCleanSheetRecords from '../../world/components/records/players/cleanSheets';
import PlayerGoalsScoredRecords from '../../world/components/records/players/goalsScored';
import PlayerGoalContributionRecords from '../../world/components/records/players/goalContributions';
import PlayerCurrentAbilityRecords from '../../world/components/records/players/currentAbility';
import PlayerTransferFeeRecords from '../../world/components/records/players/transferFee';
import ClubWinRateRecords from '../../world/components/records/clubs/winRate.js';
import ClubRatingRecords from '../../world/components/records/clubs/reputationAbs.js';
import ClubBalanceRecords from '../../world/components/records/clubs/balance.js';
import Highlights from '../../highlights/FindHighlights.js';
import EditClubFacilities from '../../club/components/editFacilities/data.js';
import ViewClubFacilities from '../../club/components/viewFacilities/data.js';
import RetiredPlayer from '../../retiredPlayer/profile/data.js';
import RetiredPlayerHistory from '../../retiredPlayer/history/router.js';
import FavRetired from '../../favourites/retired/data.js';
import CreatePlayerTransferAuctionBidPaid from '../../player/transferOffer/playerTransferAuctionPaid/createBid/data.js';

export const routeList = [
    {
        exact: true,
        path: URL.budget,
        component: Budget,
        needsManager: false
    },
    {
        exact: true,
        path: URL.home,
        component: Home,
        needsManager: false,
        passManagerChange: false,
    },
    {
        exact: false,
        path: URL.newManager,
        component: NewManager,
        needsManager: true,
        passManagerChange: true,
    },
    {
        exact: false,
        path: URL.jobs,
        component: Jobs,
        needsManager: true,
        passClubChange: true
    },
    {
        exact: true,
        path: URL.messages,
        component: Inbox,
        needsManager: true,
    },
    {
        exact: false,
        path: URL.message,
        component: Message,
        needsManager: true,
    },
    {
        exact: false,
        path: URL.playerProfile,
        component: PlayerProfile,
        needsManager: true
    },
    {
        exact: false,
        path: URL.playerContractOfferNew,
        component: PlayerContractOffer,
        needsManager: true
    },
    {
        exact: false,
        path: URL.playerHistory,
        component: PlayerHistory,
        needsManager: true,
        needsRouterContext: true,
    },
    {
        exact: false,
        path: URL.playerAuction,
        component: PlayerAuction,
        needsManager: true
    },
    {
        exact: false,
        path: URL.playerTransferOrLoanOffer,
        component: PlayerTransferOrLoanOffer,
        needsManager: true
    },
    {
        exact: false,
        path: URL.viewPlayerTransferOfferPaid,
        component: ViewPlayerTransferOfferPaid,
        needsManager: true
    },
    {
        exact: false,
        path: URL.viewPlayerTransferOfferPaidHistory,
        component: ViewPlayerTransferOfferPaidHistory,
        needsManager: true,
    },
    {
        exact: false,
        path: URL.viewPlayerTransferOfferLoan,
        component: ViewPlayerTransferOfferLoan,
        needsManager: true
    },
    {
        exact: false,
        path: URL.viewPlayerTransferOfferLoanHistory,
        component: ViewPlayerTransferOfferLoanHistory,
        needsManager: true
    },
    {
        exact: false,
        path: URL.playerPromotion,
        component: PlayerPromotion,
        needsManager: true
    },
    {
        exact: true,
        path: URL.playerCompare,
        component: PlayerCompare,
        needsManager: true
    },
    {
        exact: false,
        path: URL.clubProfile,
        component: ClubProfile,
        needsManager: true,
    },
    {
        exact: false,
        path: URL.teamPlayers,
        component: Squad,
        needsManager: true,
    },
    {
        exact: false,
        path: URL.teamFixtures,
        component: ClubFixtures,
        needsManager: true,
    },
    {
        exact: false,
        path: URL.editClubStadium,
        component: EditClubStadium,
        needsManager: true
    },
    {
        exact: false,
        path: URL.viewClubStadium,
        component: ViewClubStadium,
        needsManager: false
    },
    {
        exact: false,
        path: URL.editClubFacilities,
        component: EditClubFacilities,
        needsManager: true,
    },
    {
        exact: false,
        path: URL.viewClubFacilities,
        component: ViewClubFacilities,
        needsManager: false
    },
    {
        exact: false,
        path: URL.clubStaff,
        component: ClubStaff,
        needsManager: true
    },
    {
        exact: true,
        path: URL.teamTactics,
        component: Tactics,
        needsManager: true
    },
    {
        exact: true,
        path: URL.teamTacticsBuilder,
        component: TacticsBuilder,
        needsManager: true
    },
    {
        exact: false,
        path: URL.teamTraining,
        component: Training,
        needsManager: true,
    },
    {
        exact: false,
        path: URL.teamHistory,
        component: TeamHistory,
        needsManager: false,
    },
    {
        exact: false,
        path: URL.clubFinances,
        component: ClubFinances,
        needsManager: true 
    },
    {
        exact: true,
        path: URL.clubPlayerTransferOffers,
        component: PlayerTransferOffers,
        needsManager: true
    },
    {
        exact: false,
        path: URL.favPlayers,
        component: FavPlayers,
        needsManager: true,
    },
    {
        exact: false,
        path: URL.favClubs,
        component: FavClubs,
        needsManager: true
    },
    {
        exact: false,
        path: URL.favStaff,
        component: FavStaff,
        needsManager: true
    },
    {
        exact: false,
        path: URL.favRetired,
        component: FavRetired,
        needsManager: true
    },
    {
        exact: false,
        path: URL.clubTransferHistory,
        component: ClubTransferHistory,
        needsManager: true
    },
    {
        exact: false,
        path: URL.league,
        component: League,
        needsManager: true
    },
    {
        exact: false,
        path: URL.teamLeague,
        component: TeamLeague,
        needsManager: true
    },
    {
        exact: true,
        path: URL.transferSearchPlayers,
        component: (props) => <PlayerSearch {...props} favourites={false} />,
        needsManager: true,
    },
    {
        exact: false,
        path: URL.transferSearchStaff,
        component: StaffSearch,
        needsManager: true
    },
    {
        exact: true,
        path: URL.transferScouting,
        component: Scouting,
        needsManager: true
    },
    {
        exact: true,
        path: URL.playerTransferAuctions,
        component: PlayerTransferAuctions,
        needsManager: true
    },
    {
        exact: true,
        path: URL.playerTransferAuctionFree,
        component: PlayerFreeAuction,
        needsManager: true
    },
    {
        exact: true,
        path: URL.playerTransferAuctionFreeHistory,
        component: PlayerTransferAuctionFreeHistory,
        needsManager: true
    },
    {
        exact: true,
        path: URL.playerTransferAuction,
        component: PlayerTransferAuction,
        needsManager: true
    },
    {
        exact: false,
        path: URL.playerTransferAuctionBid,
        component: CreatePlayerTransferAuctionBidPaid,
        needsManager: true
    },
    {
        exact: true,
        path: URL.playerTransferAuctionPaidHistory,
        component: PlayerTransferAuctionPaidHistory,
        needsManager: true
    },
    // {
    //     exact: false,
    //     path: URL.managerProfile,
    //     component: ,
    //     needsManager: ,
    //     needsClub: ,
    //     needsRouterContext: ,
    //     passManagerChange:,
    //     passClubChange: 
    // },
    // {
    //     exact: false,
    //     path: URL.managerHistory,
    //     component: ,
    //     needsManager: ,
    //     needsClub: ,
    //     needsRouterContext: ,
    //     passManagerChange:,
    //     passClubChange: 
    // },
    {
        exact: false,
        path: URL.staffProfile,
        component: StaffProfile,
        needsManager: true

    },
    {
        exact: false,
        path: URL.retiredStaffProfile,
        component: RetiredStaffProfile,
        needsManager: true
    },
    {
        exact: false,
        path: URL.staffContractOffer,
        component: StaffContractOffer,
        needsManager: true,
    },
    {
        exact: false,
        path: URL.staffTransferOffer,
        component: StaffTransferRouter,
        needsManager: true
    },
    {
        exact: false,
        path: URL.staffTransferAuctionFree,
        component: StaffTransferAuctionFree,
        needsManager: true
    },
    {
        exact: false,
        path: URL.staffTransferAuctionFreeHistory,
        component: StaffTransferAuctionFreeHistory,
        needsManager: true
    },
    {
        exact: false,
        path: URL.staffHistory,
        component: StaffHistory,
        needsManager: false,
    },
    {
        exact: false,
        path: URL.retiredStaffHistory,
        component: RetiredStaffHistory,
        needsManager: false  
    },
    {
        exact: true,
        path: URL.leagueFixture,
        component: (props) => <Fixture {...props} type='league' />,
        needsManager: false,
        hideScrollbar: true
    },
    {
        exact: true,
        path: URL.leagueFixtureHighlights,
        component: (props) => <Highlights {...props} type='league' />,
        needsManager: false,
        hideScrollbar: true,
        hideScaffold: true
    },
    {
        exact: true,
        path: URL.cupFixture,
        component: (props) => <Fixture {...props} type='cup' />,
        needsManager: false,
        hideScrollbar: true
    },
    {
        exact: true,
        path: URL.cupFixtureHighlights,
        component: (props) => <Highlights {...props} type='cup' />,
        needsManager: false,
        hideScrollbar: true,
        hideScaffold: true
    },
    // {
    //     exact: false,
    //     path: URL.settings,
    //     component: ,
    //     needsManager: ,
    //     needsClub: ,
    //     needsRouterContext: ,
    //     passManagerChange:,
    //     passClubChange: 
    // },
    {
        exact: false,
        path: URL.register,
        component: Register,
    },
    {
        exact: false,
        path: URL.login,
        component: Login,
        needsManager: true,
        passLogin: true,
        passFCMToken: true,
        passDeviceId: true
    },
    {
        exact: false,
        path: URL.logout,
        component: Logout,
        passLogout: true,
        passRefreshToken: true
    },
    {
        exact: false,
        path: URL.transferHistory,
        component: TransferHistory,
        needsManager: true,
    },
    {
        exact: true,
        path: URL.nationList,
        component: NationList,
        needsManager: false
    },
    {
        exact: true,
        path: URL.worldClubs,
        component: WorldClubs,
        needsManager: true
    },
    {
        exact: true,
        path: URL.worldCups,
        component: WorldCups,
        needsManager: true
    },
    {
        exact: true,
        path: URL.worldRecords,
        component: WorldRecords
    },
    {
        exact: true,
        path: URL.playerAppearanceRecords,
        component: PlayerAppearanceRecords
    },
    {
        exact: true,
        path: URL.playerAssistRecords,
        component: PlayerAssistRecords
    },
    {
        exact: true,
        path: URL.playerCleanSheetRecords,
        component: PlayerCleanSheetRecords
    },
    {
        exact: true,
        path: URL.playerGoalsScoredRecords,
        component: PlayerGoalsScoredRecords
    },
    {
        exact: true,
        path: URL.playerGoalContributionRecords,
        component: PlayerGoalContributionRecords
    },
    {
        exact: true,
        path: URL.playerCurrentAbilityRecords,
        component: PlayerCurrentAbilityRecords
    },
    {
        exact: true,
        path: URL.playerTransferFeeRecords,
        component: PlayerTransferFeeRecords
    },
    {
        exact: true,
        path: URL.clubWinRateRecords,
        component: ClubWinRateRecords
    },
    {
        exact: true,
        path: URL.clubRatingRecords,
        component: ClubRatingRecords
    },
    {
        exact: true,
        path: URL.clubBalanceRecords,
        component: ClubBalanceRecords
    },
    {
        exact: true,
        path: URL.nation,
        component: Nation
    },
    {
        exact: false,
        path: URL.nationLeagues,
        component: NationLeagues
    },
    {
        exact: false,
        path: URL.nationClubs,
        component: NationClubs
    },
    {
        exact: false,
        path: URL.nationPlayers,
        component: NationPlayers
    },
    {
        exact: true,
        path: URL.city,
        component: CityProfile
    },
    {
        exact: false,
        path: URL.cityPlayers,
        component: CityPlayers
    },
    {
        exact: false,
        path: URL.cupFixtures,
        component: (props) => <Cup><CupFixtures {...props} /></Cup>
    },
    {
        exact: false,
        path: URL.cupStats,
        component: (props) => (
            <Cup>
                <PlayerTeamLeagueSeasonsInterface {...{...props, persistedStateKey: 'playerTeamCupSeasons'}}>
                    <StatsTableData {...{...props, persistedStateKey: 'playerTeamCupSeasons'}} />
                </PlayerTeamLeagueSeasonsInterface>
            </Cup>
        )
    },
    {
        exact: false,
        path: URL.cupHistory,
        component: CupSeasonHistory
    },
    {
        exact: true,
        path: URL.tutorialMenu,
        component: TutorialMenu
    },
    {
        exact: false,
        path: URL.messagesTutorial,
        component: MessagesTutorial
    },
    {
        exact: false,
        path: URL.financesTutorial,
        component: FinancesTutorial
    },
    {
        exact: false,
        path: URL.newsTutorial,
        component: NewsTutorial
    },
    {
        exact: false,
        path: URL.playersTutorial,
        component: PlayersTutorial
    },
    {
        exact: false,
        path: URL.tacticsTutorial,
        component: TacticsTutorial
    },
    {
        exact: false,
        path: URL.trainingTutorial,
        component: TrainingTutorial
    },
    {
        exact: false,
        path: URL.clubsTutorial,
        component: ClubsTutorial
    },
    {
        exact: false,
        path: URL.staffTutorial,
        component: StaffTutorial
    },
    {
        exact: false,
        path: URL.fixturesTutorial,
        component: FixturesTutorial
    },
    {
        exact: false,
        path: URL.leagueTutorial,
        component: LeagueTutorial
    },
    {
        exact: false,
        path: URL.worldTutorial,
        component: WorldTutorial
    },
    {
        exact: false,
        path: URL.introTutorial,
        component: IntroTutorial
    },
    {
        exact: false,
        path: URL.transfersTutorial,
        component: TransfersTutorial
    },
    {
        exact: false,
        path: URL.retiredPlayerProfile,
        component: RetiredPlayer
    },
    {
        exact: false,
        path: URL.retiredPlayerHistory,
        component: RetiredPlayerHistory
    }
];