import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Header } from "semantic-ui-react";
import CupRoundName from "../cup/RoundName";
import { FormattedDate, FormattedTime } from "react-intl";
import TeamLink from "../team/common/TeamLink";
import { generatePath, Link } from "react-router-dom";
import { URL } from "../common/lib/paths";
import CupName from "../cup/name/CupName";
import LeagueName from "../league/components/common/Name";

export default function FixtureList(props) {
    return(
        <Grid style={styles.fixtureCardGrid}>
            {props.fixtures.map((fixture, i) => (
                <>
                    <Grid.Row
                        columns={1}
                        style={i === 0 ? styles.fixtureCardRowA1 : styles.fixtureCardRowA}
                    >
                        <Grid.Column
                            style={styles.fixtureCardRowAColumn1}
                        >
                            <Header as='h5'>
                                {props.view !== 'historical' && fixture.type === 'promotion_playoff' ? 'Promotion play-off final - ' : null}
                                {props.view !== 'historical' && fixture.type === 'relegation_playoff' ? 'Relegation play-off final - ' : null}
                                {props.view === 'historical' ?
                                    <span>
                                        {fixture.type === 'league' ? <Link to={{pathname: generatePath(URL.league, {leagueId: fixture.league.id}), state: {leagueSeasonId: fixture.fixtureRound.LSId}}}><LeagueName league={fixture.league} /></Link> : null}
                                        {fixture.type === 'cup' ? <Link to={{pathname: generatePath(URL.cupFixtures, {cupId: fixture.cup.id}), state: {cupSeasonId: fixture.fixtureRound.CSId}}}><CupName cup={fixture.cup} /></Link> : null}
                                        &nbsp;-&nbsp;
                                    </span>
                                    :
                                    null
                                }
                                {fixture.type === 'cup' && props.view !== 'cupFixtures' && props.view !== 'historical' ?
                                    <span><FontAwesomeIcon icon={faTrophy} className='inlineIcon' /> Mug of Nations <CupRoundName roundsFromFinal={fixture.fixtureRound.roundsFromFinal} /> </span>
                                    :
                                    null
                                }
                                {props.view === 'historical' ?
                                    <FormattedDate value={fixture.fixtureRound.gameJob.startDate || fixture.fixtureRound.referenceDate} year='numeric' month='short' day='numeric' />
                                    :
                                    <FormattedTime value={fixture.fixtureRound.gameJob.startDate || fixture.fixtureRound.referenceDate} hour='numeric' />
                                }
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                        style={styles.fixtureCardRowB}
                    >
                        <Grid.Column
                            width={6}
                            style={styles.fixtureCardRowBColumn1}
                        >
                            <TeamLink
                                team={fixture.homeTeam}
                                className={props.tutorialFixtureId === fixture.id && !!props.tutorialNotClubId && props.tutorialNotClubId !== fixture.homeTeam.club.id && 'glow'}
                                search={props.tutorialFixtureId === fixture.id && !!props.tutorialNotClubId && props.tutorialNotClubId !== fixture.homeTeam.club.id && props.tutorialNextStepSearchString}
                            />
                        </Grid.Column>
                        <Grid.Column
                            width={4}
                            style={styles.fixtureCardRowBColumn2}
                        >
                                <Link
                                    to={{
                                        pathname: generatePath(fixture.type === 'cup' ? URL.cupFixture : URL.leagueFixture, {fixtureId: fixture.id}),
                                        search: props.tutorialFixtureId === fixture.id && props.tutorialNextStepSearchString
                                    }}
                                    className={props.tutorialFixtureId === fixture.id && 'glow'}
                                >
                                    {fixture.status !== 'planned' ?
                                        fixture.isMasked === true ?
                                            `\u00A0? - ?\u00A0`
                                            :
                                            `\u00A0${fixture.scoreHome} - ${fixture.scoreAway}\u00A0`
                                        :
                                        '\u00A0vs.\u00A0'
                                    }
                                </Link>
                        </Grid.Column>
                        <Grid.Column
                            width={6}
                            style={styles.fixtureCardRowBColumn3}
                        >
                            <TeamLink
                                team={fixture.awayTeam}
                                className={props.tutorialFixtureId === fixture.id && !!props.tutorialNotClubId && props.tutorialNotClubId !== fixture.homeTeam.club.id && 'glow'}
                                search={props.tutorialFixtureId === fixture.id && !!props.tutorialNotClubId && props.tutorialNotClubId !== fixture.homeTeam.club.id && props.tutorialNextStepSearchString}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {fixture.penaltyScoreHome !== null && fixture.penaltyScoreAway !== null &&
                        <Grid.Row
                            style={styles.fixtureCardRowC}
                            columns={1}
                        >
                            <Grid.Column style={styles.fixtureCardRowAColumn1}>
                                <Header as='h5'>{fixture.penaltyScoreHome} - {fixture.penaltyScoreAway} on penalties</Header>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </>
            ))}
        </Grid>
    );
}

const styles = {
    fixtureCardHeader: {
        textAlign: 'center',
        margin: 0
    },
    fixtureCardGrid: {
        margin: 0
    },
    fixtureCardRowA1: {
        padding: 0
    },
    fixtureCardRowA: {
        padding: 0,
        paddingTop: '1em'
    },
    fixtureCardRowAColumn1: {
        textAlign: 'center'
    },
    fixtureCardRowB: {
        padding: 0
    },
    fixtureCardRowBColumn1: {
        textAlign: 'right',
        paddingRight: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fixtureCardRowBColumn2: {
        textAlign: 'center',
        padding: 0
    },
    fixtureCardRowBColumn3: {
        paddingLeft: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fixtureCardRowC: {
        paddingTop: 0
    }
}