import {Table, Divider} from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faSquare, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import colours from '../../../common/colours';
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

const styles = {
    scorer: {
        margin: 0
    },
    assist: {
        margin: 0,
        color: '#767676'
    }
}

export default function Summary(props) {
    const [keyEventData, setKeyEventData] = useState(null);

    useEffect(() => {
        if(keyEventData !== null) return;

        const newKeyEventData = cloneDeep(props.keyEventData);
        newKeyEventData.push({type: 3, time: 0});
        newKeyEventData.push({type: 4, time: 45});
        newKeyEventData.push({type: 5, time: 90});
        newKeyEventData.sort((a, b) => a.time - b.time);

        setKeyEventData(newKeyEventData);
    }, [props.keyEventData]);

    if(!keyEventData) return null;

    return(
        <Table basic='very' compact='very' unstackable>
            <Table.Body>
                {keyEventData.map((keyEvent) => {
                    if(keyEvent.side === 'home') {
                        return (
                            <Table.Row style={{border: 'none'}}>
                                <Table.Cell verticalAlign='middle' textAlign='left' width={1} style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    <span style={{fontWeight: 'bold'}}>{keyEvent.time}'</span>
                                </Table.Cell>
                                <Table.Cell verticalAlign='middle' width={1} style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    {keyEvent.type === 0  && <FontAwesomeIcon icon={faFutbol} />}
                                    {keyEvent.type === 1  && <FontAwesomeIcon icon={faSquare} color={colours.negative} />}
                                </Table.Cell>
                                <Table.Cell colSpan={3} textAlign='left' style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    {keyEvent.type === 0 &&
                                        <>
                                            <p style={styles.scorer}>{props.playerData[keyEvent.side][keyEvent.primaryPlayerIndex].name}</p>
                                            <small style={styles.assist}>Assist {props.playerData[keyEvent.side][keyEvent.secondaryPlayerIndex].name}</small>                                                                        
                                        </>
                                    }
                                    {keyEvent.type === 1 &&
                                        <p style={styles.scorer}>{props.playerData[keyEvent.side][keyEvent.primaryPlayerIndex].name}</p>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )
                    } else if(keyEvent.side === 'away') {
                        return (
                            <Table.Row style={{borderBottom: 'none'}}>
                                <Table.Cell colSpan={3} textAlign='right' style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    {keyEvent.type === 0 &&
                                        <>
                                            <p style={styles.scorer}>{props.playerData[keyEvent.side][keyEvent.primaryPlayerIndex].name}</p>
                                            <small style={styles.assist}>Assist {props.playerData[keyEvent.side][keyEvent.secondaryPlayerIndex].name}</small>                                                                        
                                        </>
                                    }
                                    {keyEvent.type === 1 &&
                                        <p style={styles.scorer}>{props.playerData[keyEvent.side][keyEvent.primaryPlayerIndex].name}</p>
                                    }
                                </Table.Cell>
                                <Table.Cell verticalAlign='middle' width={1} style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    {keyEvent.type === 0  && <FontAwesomeIcon icon={faFutbol} />}
                                    {keyEvent.type === 1  && <FontAwesomeIcon icon={faSquare} color={colours.negative} />}
                                </Table.Cell>
                                <Table.Cell verticalAlign='middle' textAlign='right' width={1} style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    <span style={{fontWeight: 'bold'}}>{keyEvent.time}'</span>
                                </Table.Cell>
                            </Table.Row>
                        )
                    } else {
                        return (
                            <Table.Row style={{borderBottom: 'none'}}>
                                <Table.Cell colSpan={5} textAlign='center'  style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    <Divider horizontal style={{width: '100%', fontSize: 'inherit', fontWeight: 'inherit', letterSpacing: 'inherit', textTransform: 'none'}}>
                                        <FontAwesomeIcon icon={faStopwatch} className='inlineIcon' color='#767676' />
                                        {keyEvent.type === 3 && <span style={{color: '#767676'}}>Kick off</span>}
                                        {keyEvent.type === 4 && <span style={{color: '#767676'}}>Half time</span>}
                                        {keyEvent.type === 5 && <span style={{color: '#767676'}}>Full time</span>}
                                    </Divider>
                                </Table.Cell>
                            </Table.Row>
                        );
                    }
                })}
            </Table.Body>
        </Table>
    );
}