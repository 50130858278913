const commentaryMessages = {
    0: 'GOAL!!! {playerName}!!',
    1: 'The shot misses the goal',
    2: '{playerName} saves!',
    3: '{playerName} shoots from close range',
    4: '{playerName} shoots from distance',
    5: 'Curling shot by {playerName}',
    6: '{playerName} shoots with power',
    7: '{playerName} passes it forwards',
    8: '{playerName} passes the ball',
    9: '{playerName} with the through ball',
    10: '{playerName} passes it into space',
    11: '{playerName} crosses the ball',
    12: '{playerName} tries to beat his man',
    13: '{playerName} holds possession',
    14: '{playerName} is running with the ball',
    15: 'Great tackle by {playerName}',
    16: '{playerName} intercepts the pass',
    17: '{playerName} is standing over the ball',
    18: '{playerName} prepares to take the corner',
    19: '{playerName} steps up to take the penalty',
    20: '{playerName} takes the free kick',
    21: '{playerName} takes the corner'
}

export default commentaryMessages;