import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { Button, Grid, Message, Statistic } from "semantic-ui-react";
import NarrowLayout from "../../../../common/components/NarrowLayout";
import TransferInfo from "../../playerTransferOrLoanOffer/playerTransferOffer/common/TransferInfo";
import TitledSegment from "../../../../common/components/TitledSegment";
import Currency from "../../../../common/components/Currency";
import { faExchange } from "@fortawesome/free-solid-svg-icons";
import { defineMessages } from "react-intl";
import { URL } from "../../../../common/lib/paths";
import { generatePath, useHistory } from "react-router";

export default function ViewReceivedPlayerTransferOfferPaidInterface(props) {
    const [error, setError] = useState(null);
    const history = useHistory()
    
    const rejectPlayerTransferOfferMutation = useMutation(() => 
        axios.patch(
            `${process.env.REACT_APP_APPHOST}/playerTransferOfferPaid/${props.playerTransferOffer.id}`,
            {
                status: 'rejected'
            }
        ),
        {
            onSuccess: (data) => history.replace(generatePath(URL.viewPlayerTransferOfferPaidHistory, {playerTransferOfferPaidHistoryId: data.data.response.id}))
        }
    );

    const acceptPlayerTransferOfferMutation = useMutation(() => 
        axios.patch(
            `${process.env.REACT_APP_APPHOST}/playerTransferOfferPaid/${props.playerTransferOffer.id}`,
            {
                status: 'accepted'
            }
        ),
        {
            onSuccess: (data) => {
                history.replace(generatePath(URL.playerTransferAuction, {playerTransferAuctionId: data.data.response}))
            },
            onError: (error) => {
                setError(error.response.data.response.key);
            }
        }
    );

    return(
        <NarrowLayout>
            <TransferInfo
                message={messages.intro}
                player={props.player}
                fromClub={props.playerTransferOffer.playerContract.club}
                toClub={props.playerTransferOffer.club}
                submittedOfferCount={props.player.playerContract.activeOfferLoan+props.player.playerContract.activeOfferPaid}
                playerTransferOfferPaid={props.playerTransferOffer}
            />
            <TitledSegment
                title='Transfer offer'
                icon={faExchange}
            >
                <Statistic className='newStatistic'>
                    <Statistic.Value>
                        <Currency value={props.playerTransferOffer.compensation} />
                    </Statistic.Value>
                    <Statistic.Label>
                        Transfer bid
                    </Statistic.Label>
                </Statistic>
                {error === 'INSUFFICIENT_CONTRACT_LENGTH' && <Message negative>There isn't enough time remaining on this player's contract to begin an auction. Extend the player's contract and then try again.</Message>}
                <Grid className='gridReset'>
                    <Grid.Row style={{padding: 0}} columns={2}>
                        <Grid.Column style={{paddingLeft: 0, paddingRight: '0.5em'}}>
                            <Button
                                negative
                                fluid
                                onClick={handleRejectPlayerTransferOffer}
                                disabled={rejectPlayerTransferOfferMutation.isLoading}
                                loading={rejectPlayerTransferOfferMutation.isLoading}
                            >
                                Reject offer
                            </Button>
                        </Grid.Column>
                        <Grid.Column style={{paddingRight: 0, paddingLeft: '0.5em'}}>
                            <Button
                                positive
                                fluid
                                onClick={handleAcceptPlayerTransferOffer}
                                disabled={acceptPlayerTransferOfferMutation.isLoading}
                                loading={acceptPlayerTransferOfferMutation.isLoading}
                            >
                                Accept offer
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </TitledSegment>
        </NarrowLayout>
    );

    function handleAcceptPlayerTransferOffer() {
        acceptPlayerTransferOfferMutation.mutate();
    }

    function handleRejectPlayerTransferOffer() {
        rejectPlayerTransferOfferMutation.mutate();
    }
}

const messages = defineMessages({
    intro: {
        id: 'playerTransferOfferPaid:received:intro',
        defaultMessage: '{toClubName} have made an offer for {playerName}. If you accept the offer, an auction will begin and the player will sign for the highest bidder.',
        description: 'todo'
    }
});