import {memo, useEffect, useState} from 'react';
import PlayHighlights from "./PlayHighlights";
import axios from 'axios';
import { Loader } from 'semantic-ui-react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

const paths = {
    league: 'leagueFixtures',
    cup: 'cupFixtures'
}

function LoadHighlights(props) {
    const [highlights, setHighlights] = useState(null);
    const {fixtureId} = useParams();

    useEffect(() => {
        const requests = [];

        for(let i=0; i<props.highlights.length; i++) {
            requests.push(
                axios.get(`${process.env.REACT_APP_APPHOST}/${props.type}FixtureHighlights/${props.highlights[i].id}`, {responseType: 'arraybuffer'})
            )
        }

        Promise.all(requests).then((results) => {
            setHighlights(results.map((res,i) => ({time: props.highlights[i].time, id: props.highlights[i].id, buffer: res})));
        });
    }, [props.highlightIds]);

    const fixtureQuery = useQuery(
        ['getFixture', props.type, fixtureId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/${paths[props.type]}/${fixtureId}`)
    );

    if(!highlights || fixtureQuery.isLoading) {
        return(
            <Loader active />
        )
    } else {
        return (
            <PlayHighlights
                highlights={highlights}
                fixture={fixtureQuery.data.data.response}
            />
        )
    }
}

export default memo(LoadHighlights);