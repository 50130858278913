import { useQuery } from '@tanstack/react-query';
import usePersistedState from "../../../../common/lib/usePersistedState";
import axios from "axios";
import ClubStaffTransferAuctionFreeInterface from "./interface";

export default function ClubStaffTransferAuctionFree(props) {
    const {persistedState} = usePersistedState('myTransferOffers', {view: 'paidAuctions', activeOnly: false});

    const getClubStaffTransferAuctionFree = useQuery(
        [
            'getClubStaffTransferAuctionFree',
            persistedState.activeOnly
        ],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/staffTransferAuctionFree?activeOnly=${persistedState.activeOnly}`)
    );

    return(
        <ClubStaffTransferAuctionFreeInterface
            isLoading={getClubStaffTransferAuctionFree.isLoading}
            staffTransferAuctionFree={getClubStaffTransferAuctionFree.data?.data?.response}
            manager={props.manager}
        />
    )
}