import { useParams } from "react-router";
import PlayerHistoryInterface from "../../../player/history/season/interface";
import axios from "axios";

import RetiredPlayerProfileToolbarItem from '../../toolbar/Profile';
import RetiredPlayerHistoryToolbarItem from '../../toolbar/History';
import { useEffect } from "react";
import ShortenedPlayerName from "../../../player/common/ShortenedName";
import { useQuery } from "@tanstack/react-query";

export default function RetiredPlayerSeasonHistory(props) {
    const {playerId} = useParams();

    const {isLoading, data, isSuccess} = useQuery(
        ['getRetiredPlayerTeamLeagueSeasons', playerId, false],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/retiredPlayers/${playerId}/playerTeamLeagueSeasons`)
    );

    useEffect(() => {
        if(!!data && isSuccess) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={data.data.response} /> history</>,
                navigation: [
                    <RetiredPlayerProfileToolbarItem playerId={playerId} />,
                    <RetiredPlayerHistoryToolbarItem playerId={playerId} />,
                ]
            });
        }
    }, [data?.data.response]);

    return(
        <PlayerHistoryInterface
            isLoading={isLoading}
            playerClubLeagueSeasons={data?.data?.response.PTLS || []}
        />
    )
}