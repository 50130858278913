import {useIntl} from 'react-intl';

export default function StaffRoleName(props) {
    const intl = useIntl();

    const message = intl.formatMessage(props.plural === true ? plural[props.role] : singular[props.role]);

    if(props.lowercase === true) {
        return message.toLowerCase();
    } else {
        return message;
    }
}

const singular = {
    coach: {
        id: 'staff:roleNames:coach',
        defaultMessage: 'Coach',
        description: 'Name for the staff member responsible for training players'
    },
    youth: {
        id: 'staff:roleNames:youth',
        defaultMessage: 'Youth coach',
        description: 'Name for the staff member responsible for youth players'
    },
    physio: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Physio',
        description: 'Name for the staff member responsible for injured players'
    },
    scout: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Scout',
        description: 'Name for the staff member responsible for injured players'
    }
}

const plural = {
    coach: {
        id: 'staff:roleNames:coach',
        defaultMessage: 'Coaches',
        description: 'Name for the staff member responsible for training players'
    },
    youth: {
        id: 'staff:roleNames:youth',
        defaultMessage: 'Youth coaches',
        description: 'Name for the staff member responsible for youth players'
    },
    physio: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Physios',
        description: 'Name for the staff member responsible for injured players'
    },
    scout: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Scouts',
        description: 'Name for the staff member responsible for injured players'
    }
}