import { faArrowLeft, faArrowRight, faCalendar, faCoins, faFutbol, faMitten, faPeopleArrows, faShirt, faStreetView } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../../../../common/components/DataTable";
import NarrowLayout from "../../../../../common/components/NarrowLayout";
import TitledSegment from "../../../../../common/components/TitledSegment";
import PlayerLink from "../../../../../player/common/Link";
import Currency from "../../../../../common/components/Currency";
import { FormattedDate } from "react-intl";


export default function PTLSHistoryInterface(props) {
    return(
        <NarrowLayout>
            <TitledSegment
                title='Most appearances'
                icon={faShirt}
                noPadding={true}
            >
                <DataTable
                    columns={appearancesColumns}
                    data={props.PTLS?.topAppearances}
                    isLoading={props.isLoading}
                    placeholderCount={3}
                />
            </TitledSegment>
            <TitledSegment
                title='Most goals'
                icon={faFutbol}
                noPadding={true}
            >
                <DataTable
                    columns={goalsColumns}
                    data={props.PTLS?.topScorers}
                    isLoading={props.isLoading}
                    placeholderCount={3}
                />
            </TitledSegment>
            <TitledSegment
                title='Most assists'
                icon={faPeopleArrows}
                noPadding={true}
            >
                <DataTable
                    columns={assistsColumns}
                    data={props.PTLS?.topAssists}
                    isLoading={props.isLoading}
                    placeholderCount={3}
                />
            </TitledSegment>
            <TitledSegment
                title='Most clean sheets'
                icon={faMitten}
                noPadding={true}
            >
                <DataTable
                    columns={cleanSheetsColumns}
                    data={props.PTLS?.topCleanSheets}
                    isLoading={props.isLoading}
                    placeholderCount={3}
                />
            </TitledSegment>
            <TitledSegment
                title='Top incoming transfers'
                icon={faArrowLeft}
                noPadding={true}
            >
                <DataTable
                    columns={transferColumns}
                    data={props.PTLS?.topIncomingTransfers}
                    isLoading={props.isLoading}
                    placeholderCount={3}
                />
            </TitledSegment>
            <TitledSegment
                title='Top outgoing transfers'
                icon={faArrowRight}
                noPadding={true}
            >
                <DataTable
                    columns={transferColumns}
                    data={props.PTLS?.topOutgoingTransfers}
                    isLoading={props.isLoading}
                    placeholderCount={3}
                />
            </TitledSegment>
        </NarrowLayout>
    );
}

const baseColumns = [
    {
        name: 'Name',
        cell: (player) => <PlayerLink player={player} />,
        compact: true,
        grow: 2
    },
    {
        name: <FontAwesomeIcon icon={faStreetView} />,
        cell: (player) => player.position,
        compact: true,
        grow: 1,
        width: '38px',
        center: true
    }
];

const transferColumns = [
    ...baseColumns,
    {
        name: <FontAwesomeIcon icon={faCalendar} />,
        cell: (player) => <FormattedDate value={player.createdAt} day='numeric' month='short' />,
        compact: true,
        width: '70px',
        center: true
    },
    {
        name: <FontAwesomeIcon icon={faCoins} />,
        cell: (player) => <Currency value={player.playerTransferHistory.compensation} />,
        compact: true,
        width: '45px',
        center: true
    }
]

const appearancesColumns = [
    ...baseColumns,
    {
        name: <FontAwesomeIcon icon={faShirt} />,
        cell: (player) => player.totalAppearances,
        compact: true,
        width: '38px',
        center: true
    }
];

const goalsColumns = [
    ...baseColumns,
    {
        name: <FontAwesomeIcon icon={faFutbol} />,
        cell: (player) => player.totalGoals,
        compact: true,
        width: '38px',
        center: true
    }
];

const assistsColumns = [
    ...baseColumns,
    {
        name: <FontAwesomeIcon icon={faPeopleArrows} />,
        cell: (player) => player.totalAssists,
        compact: true,
        width: '38px',
        center: true
    }
];

const cleanSheetsColumns = [
    ...baseColumns,
    {
        name: <FontAwesomeIcon icon={faMitten} />,
        cell: (player) => player.totalCleanSheets,
        compact: true,
        width: '38px',
        center: true
    }
];