import {useQuery, useQueryClient, useMutation} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useParams} from 'react-router';
import axios from "axios";
import { FormattedMessage } from 'react-intl';

import TrainingInterface from "./interface";
import TeamName from '../team/common/TeamName';
import PlayerTrainingBoosts from './trainingBoosts/data';
import FirstTeamTrainingToolbarItem from './toolbar/FirstTeam';
import YouthTeamTrainingToolbarItem from './toolbar/YouthTeam';
import { fetchTeamMeta } from '../team/common/queries';
import { Header } from 'semantic-ui-react';

export default function Training(props) {
    const {teamType} = useParams();
    const queryClient = useQueryClient();

    const getSquadQuery = useQuery(
        ['getSquad', 'training'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.manager.club.id}/teams/${teamType}/playerRegistrations?ownershipClaim=true&context=training`)
    );

    const getLoanedOutQuery = useQuery(
        ['getSquad', 'training', 'loanedOut'],
        () =>axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.manager.club.id}/teams/${teamType}/playerRegistrations?ownershipClaim=true&context=training&loanedOut=true`),
        {
            enabled: teamType === 'first'
        }
    );

    const getTeamMetaQuery = useQuery(
        ['getTeamMeta', props.manager.club.id, teamType],
        () => fetchTeamMeta(props.manager.club.id, teamType)
    );

    const getPlayerTrainingBoostsQuery = useQuery(
        ['getPlayerTrainingBoosts', teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/teams/${teamType}/playerTrainingBoosts`)
    );

    const updateTrainingFocus = useMutation(
        ({playerContractId, trainingFocus}) =>
            axios.patch(
                `${process.env.REACT_APP_APPHOST}/playerContracts/${playerContractId}`,
                {
                    trainingFocus
                }
            )
        , {
            onSuccess: () => {
                queryClient.invalidateQueries('getSquad');
            }
        }
    );

    useEffect(() => {
        if(!!getTeamMetaQuery.data?.data.response) {
            const navigation = [
                <FirstTeamTrainingToolbarItem />,
                <YouthTeamTrainingToolbarItem />
            ];

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{teamName: <TeamName nation={true} team={getTeamMetaQuery.data.data.response} />}} />,
                navigation
            });
        }
    }, [getTeamMetaQuery.data?.data.response]);

    const handleUpdateTrainingFocus = function(playerContractId, trainingFocus) {
        updateTrainingFocus.mutate({playerContractId, trainingFocus});
    }

    return(
        <div>
            <PlayerTrainingBoosts
                isLoading={getPlayerTrainingBoostsQuery.isLoading}
                playerTrainingBoosts={getPlayerTrainingBoostsQuery.data?.data?.response.playerTrainingBoosts}
                pointsRequiredForBonusBoost={getPlayerTrainingBoostsQuery.data?.data?.response.pointsRequiredForBonusBoost}
                playerRegistrations={getSquadQuery.data?.data?.response}
                manager={props.manager}
            />
            <TrainingInterface
                isLoading={getSquadQuery.isLoading}
                playerRegistrations={getSquadQuery.data?.data?.response}
                clubId={props.manager.club.id}
                updateTrainingFocus={handleUpdateTrainingFocus}
            />
            {teamType === 'first' &&
                <TrainingInterface
                    isLoading={getLoanedOutQuery.isLoading}
                    playerRegistrations={getLoanedOutQuery.data?.data.response}
                    clubId={props.manager.club.id}
                    updateTrainingFocus={handleUpdateTrainingFocus}
                />   
            }
        </div>
    );
}

const messages = {
    screenTitle: {
        id: 'teamTactics:screenTitle',
        defaultMessage: '{teamName} training',
        description: 'Title for the screen showing a teams training'
    }
}