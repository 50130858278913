import { useIntl } from "react-intl"

export default function FacilitiesRating(props) {
    const intl = useIntl();

    return intl.formatMessage(facilitiesRatings[props.facilitiesRating])
}

const facilitiesRatings = {
    0: {
        id: 'club:facilities:poor',
        defaultMessage: 'Poor',
        description: 'Facility rating of 0/5'
    },
    1: {
        id: 'club:facilities:poor',
        defaultMessage: 'Basic',
        description: 'Facility rating of 1/5'
    },
    2: {
        id: 'club:facilities:poor',
        defaultMessage: 'Average',
        description: 'Facility rating of 2/5'
    },
    3: {
        id: 'club:facilities:poor',
        defaultMessage: 'Good',
        description: 'Facility rating of 3/5'
    },
    4: {
        id: 'club:facilities:poor',
        defaultMessage: 'Great',
        description: 'Facility rating of 4/5'
    },
    5: {
        id: 'club:facilities:poor',
        defaultMessage: 'World class',
        description: 'Facility rating of 5/5' 
    }
}