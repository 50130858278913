import { Button, Grid, Header, Loader, Modal, Segment, Table } from "semantic-ui-react";
import SUIIconShim from "../../../../common/components/SUIIconShim";
import { faCakeCandles, faCheck, faFireFlameCurved, faStar, faStreetView, faUser, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ShortenedPlayerName from "../../../../player/common/ShortenedName";
import StaffRoleName from "../../../../staff/common/RoleName";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

export default function StaffTrainingBoostsInterface(props) {
    const queryClient = useQueryClient();
    const location = useLocation();
    const history = useHistory();
    
    const setStaffTrainingBoost = useMutation(
        (staffContractId) => axios.patch(`${process.env.REACT_APP_APPHOST}/staffTrainingBoosts/${props.staffTrainingBoost.id}`, {
            staffContractId
        }), {
            onSuccess: () => {
                queryClient.invalidateQueries('getClubStaffTrainingBoosts')
            }
        }
    );

    return(
        <>
            <Segment textAlign="center">
                <Header icon>
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faFireFlameCurved} color='#db2828' />
                    </SUIIconShim>
                    Training course
                </Header>
                <p>Select a staff member to send on a training course</p>
                {!props.staffTrainingBoost.staff ?
                    <Button
                        primary
                        onClick={() => triggerModal(props.staffTrainingBoost.id)}
                    >
                        Select staff
                    </Button>
                    :
                    <>
                        <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)', textAlign: 'left'}}>
                            <ShortenedPlayerName player={props.staffTrainingBoost.staff} />
                            <FontAwesomeIcon icon={faUserTie} style={{float: 'right'}} />
                        </Header>
                        <Segment attached className='attachedInvertedSegment' style={{marginBottom: '1em'}}>
                            <Grid style={{width: '100%', margin: 0}}>
                                <Grid.Row style={{padding: 0}}>
                                    <Grid.Column width={8}>
                                        <Table basic='very' compact='very' unstackable style={{width: '100%'}}>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={faStreetView} /></Table.Cell>
                                                    <Table.Cell>
                                                        <StaffRoleName role={props.staffTrainingBoost.staff.role} />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={faStar} /></Table.Cell>
                                                    <Table.Cell>
                                                        {props.staffTrainingBoost.staff.currentAbility}
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={faCakeCandles} /></Table.Cell>
                                                    <Table.Cell>
                                                        Age {props.staffTrainingBoost.staff.age}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                        <Button
                            primary
                            onClick={() => triggerModal(props.staffTrainingBoost.id)}
                        >
                            Change staff
                        </Button>
                    </>
                }
            </Segment>
            <StaffTrainingSelector
                open={location.state?.boostModal === props.staffTrainingBoost.id}
                setOpen={triggerModal}
                handleSelectStaffContract={handleSelectStaffContract}
                selectedStaffContractId={props.staffTrainingBoost.staffContractId}
            />
        </>
    );

    function handleSelectStaffContract(staffContractId) {
        setStaffTrainingBoost.mutate(staffContractId);
        triggerModal(false);
    }

    function triggerModal(open) {
        history.push({
            pathname: location.pathname,
            state: {
                ...location.state,
                boostModal: open
            }
        });
    }
}

function StaffTrainingSelector(props) {
    const [selectedStaffContractId, setSelectedStaffContractId] = useState(props.selectedStaffContractId || null);

    const clubStaffQuery = useQuery(
        ['getClubStaff'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/staff`),
        {
            enabled: !!props.open
        }
    );

    return(
        <Modal
            onClose={() => props.setOpen(false)}
            open={props.open}
        >
            <Modal.Header className='headerFixPadding'>Select staff</Modal.Header>
            <Modal.Content scrolling>
                <p>Greyed out staff have already reached their maximum rating and cannot be trained</p>
                {clubStaffQuery.isLoading ?
                    <Loader active />
                    :
                    <Table
                        unstackable
                        selectable
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell collapsing><FontAwesomeIcon icon={faStreetView} /></Table.HeaderCell>
                                <Table.HeaderCell collapsing><FontAwesomeIcon icon={faStar} /></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {clubStaffQuery.data.data.response.map((staff) => (
                                <Table.Row
                                    onClick={() => setSelectedStaffContractId(staff.staffContract.id)}
                                    positive={staff.staffContract.id === selectedStaffContractId}
                                    disabled={!staff.isTrainable}
                                >
                                    <Table.Cell>
                                        <ShortenedPlayerName player={staff} />
                                        {staff.staffContract.id === selectedStaffContractId && <FontAwesomeIcon icon={faCheck} className='inlineIconMirror' />}
                                    </Table.Cell>
                                    <Table.Cell collapsing>
                                        <StaffRoleName role={staff.role} />
                                    </Table.Cell>
                                    <Table.Cell collapsing>
                                        {staff.currentAbility}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                }
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)}>Cancel</Button>
                <Button
                    positive
                    onClick={() => props.handleSelectStaffContract(selectedStaffContractId)}
                >
                    Select staff
                </Button>
            </Modal.Actions>
        </Modal>
    )
}