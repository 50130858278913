import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function GradientBar(props) {  
    if(props.isLoading) return null;

    const validatedValue = Math.min(Math.max(props.value, 0), 100);

    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginBottom: '1em',
        position: 'relative'
      };
    
      const labelContainerStyle = {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: "5px",
      };
    
      const gradientStyle = {
        background: `linear-gradient(to ${props.direction}, #21ba45, #eaae00, #db2828)`,
        height: "20px",
        width: "100%",
        borderRadius: "5px",
        position: "relative",
      };
    
      const arrowStyle = {
        position: "absolute",
        top: "85%",
        left: `${validatedValue}%`,
        transform: "translate(-50%, -50%)",
        width: 0,
        height: 0,
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        borderBottom: "14px solid black",
        transition: "left 0.3s ease"
      };

      const lockedStyle = {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6',
        zIndex: 100,
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    
      return (
        <div style={containerStyle}>
          {props.isLocked && <div style={lockedStyle}><span className='boldLabel'><FontAwesomeIcon className='inlineIcon' icon={faLock} /> Scout player to unlock contract value</span></div>}
          <div style={labelContainerStyle}>
            <span className='boldLabel'>{props.leftLabel}</span>
            <span className='boldLabel'>{props.rightLabel}</span>
          </div>
          <div style={gradientStyle}>
            {!props.isLocked && <div style={arrowStyle}></div>}
          </div>
        </div>
      );
  };