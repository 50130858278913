import React, {useEffect, useMemo} from "react";
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios from "axios";
import {useParams} from 'react-router-dom';

import ClubProfile_interface from "./interface.js";
import NarrowLayout from "../../../common/components/NarrowLayout.js";
import ClubProfileToolbarItem from "../toolbar/ClubProfile.js";
import TeamPlayersToolbarItem from "../toolbar/TeamPlayers.js";
import TeamFixturesToolbarItem from "../toolbar/TeamFixtures.js";
import ClubStaffToolbarItem from "../toolbar/ClubStaff.js";
import ClubTransfersToolbarItem from "../toolbar/ClubTransfers.js";
import TeamLeagueSeasonsToolbarItem from "../toolbar/TeamLeagueSeasons.js";
import TeamLeagueToolbarItem from "../toolbar/TeamLeague";
import { FormattedMessage } from "react-intl";
import useGetClub from "./useGetClub";

export default function ClubProfile(props) {
    const {clubId, teamType = 'first'} = useParams();
    const queryClient = useQueryClient();

    const isOwner = !!props.manager.club && (parseInt(clubId) === props.manager.club.id);

    const clubQuery = useGetClub(clubId, isOwner)

    const TLSQuery = useQuery(
        ['getTeamLeagueSeasons'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/teams/first/leagueSeasons?includeActiveSeason=true&includeTLS=true`),
        {
            enabled: clubId !== undefined
        }
    );

    const TCSQuery = useQuery(
        ['getTeamCupSeasons'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/teams/first/cupSeasons?includeTCS=false&filterChampions=true`),
        {
            enabled: clubId !== undefined
        }
    );

    const createFavClub = useMutation(() => axios.post(`${process.env.REACT_APP_APPHOST}/favClubs`, {
        clubId: parseInt(clubId)
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('getClub');
        }
    });

    const deleteFavClub = useMutation(() => axios.delete(`${process.env.REACT_APP_APPHOST}/favClubs?clubId=${clubId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries('getClub');
        }
    });

    useEffect(() => {
        if(!!clubQuery.data?.data?.response) {
            const isOwner = !!props.manager.club && (parseInt(clubId) === props.manager.club.id);

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{clubName: clubQuery.data.data.response.name}} />,
                navigation: [
                    <ClubProfileToolbarItem clubId={clubId} key={1} />,
                    <TeamPlayersToolbarItem clubId={clubId} teamType={teamType} key={2} />,
                    <TeamFixturesToolbarItem clubId={clubId} teamType={teamType} key={5} />,
                    <TeamLeagueToolbarItem clubId={clubId} teamType={teamType} key={6} />,
                    <ClubStaffToolbarItem clubId={clubId} key={7} />,
                    <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                    <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType={teamType} key={9} />
                ]
            });
        }
    }, [clubId, clubQuery.data])

    const championTLS = useMemo(() => {
        if(!TLSQuery.data?.data?.response?.TLS) return [];
        
        return TLSQuery.data.data.response.TLS.filter((TLS) => TLS.champions === true);
    }, [TLSQuery.data]);


    return (
        <NarrowLayout>
            <ClubProfile_interface
                clubQueryIsLoading={clubQuery.isLoading}
                club={clubQuery.data?.data?.response}
                TLSQueryIsLoading={TLSQuery.isLoading}
                TLS={TLSQuery.data?.data?.response?.TLS}
                championTLS={championTLS}
                championTCS={TCSQuery.data?.data.response.TCS}
                isOwner={isOwner}
                handleToggleFavClub={handleToggleFavClub.bind(this)}
                toggleFavClubLoading={createFavClub.isLoading || deleteFavClub.isLoading}
                isFavClub={clubQuery.data?.data.response.isFavClub}
            />
        </NarrowLayout>
    );

    function handleToggleFavClub() {
        if(clubQuery.data.data.response.isFavClub === false) {
            createFavClub.mutate();
        } else {
            deleteFavClub.mutate();
        }
    }
}

const messages = {
    screenTitle: {
        id: 'todo',
        defaultMessage: '{clubName} profile',
        descripion: 'todo'
    }
}