import {Suspense, useEffect, useState} from 'react';
import {Switch, Route, useHistory, generatePath, useLocation} from "react-router-dom";
import {useQuery} from '@tanstack/react-query';
import {Message, Transition, Image, Header, Loader} from 'semantic-ui-react';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { PushNotifications } from '@capacitor/push-notifications';
import {Capacitor} from '@capacitor/core';
import {Storage} from '@capacitor/storage';
import {nanoid} from 'nanoid';
import {SwitchTransition, CSSTransition} from 'react-transition-group-react-18';

import { URL } from './common/lib/paths';
import {routeList} from "./common/lib/routes.js";

import Sidebar from "./menu/sidebar/Sidebar.js";
import CompareWidget from './player/compare/widget';
import Toolbar from "./menu/toolbar/Toolbar.js";
import Error from './Error';
import PageTracking from './usePageTracking';

import logo from './menu/sidebar/logo8.png';

import 'flag-icons/css/flag-icons.min.css';
import { TutorialProvider } from './tutorial/hooks/useTutorial.js';
import TutorialWidget from './tutorial/widget/TutorialWidget.js';
import useUser from './common/useUser.js';

let refreshToken, accessToken, deviceId;

async function updateRefreshToken(newRefreshToken) {
    console.log('updating refresh token');

    await Storage.set({
        key: 'refreshToken',
        value: newRefreshToken
    });

    refreshToken = newRefreshToken;
}

async function updateAccessToken(newAccessToken) {
    console.log('updating access token');

    await Storage.set({
        key: 'accessToken',
        value: newAccessToken
    });

    accessToken = newAccessToken;
}

async function destroyTokens() {
    await Storage.remove({key: 'refreshToken'});
    refreshToken = undefined;

    await Storage.remove({key: 'accessToken'});
    accessToken = undefined;

    console.log('destroyed tokens');
}

const myLoader = (
    <>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <div
                style={{
                    order: 0,
                    flex: '1 1 auto',
                    width: '100%'
                }}
            >
                <Image
                    style={{
                        height: '180px',
                        margin: '0 auto'
                    }}
                    src={logo}
                    className='loadingLogo'
                />
                <Header as='h1' style={{color: '#fff', textAlign: 'center', margin: '4rem 0 0 0'}}>Loading...</Header>
            </div>
        </div>
    </>
);

export default function App() {
    useEffect(() => {
        axios.interceptors.request.use(
            (config) => {
                //console.log('attaching access token to req');
                if(accessToken !== null) {
                    config.headers['Authorization'] = `Bearer: ${accessToken}`;
                }
                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );
        
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            async function(error) {
                const originalRequest = error.config;
                console.log(error.response);

                if(
                    error.response.status === 401 &&
                    error.response.data === 'reauth'
                ) {
                    console.log('reauth required');
                    handleLogout();
                    return Promise.resolve();
                } else if(
                    refreshToken !== null &&
                    error.response.status === 403
                ) {
                    console.log('refreshing access token');
        
                    return axios.post(`${process.env.REACT_APP_APPHOST}/token`,
                        {token: refreshToken}).then(async (res) => {
                            console.log(res);

                            if(res.status === 200) {
                                await updateAccessToken(res.data.response);

                                console.log('access token refreshed successfully');
                                return axios(originalRequest);
                            } else {
                                handleLogout();
                                return Promise.resolve();
                            }
                        })
                }

                return Promise.reject(error);
            }
        );

        setHttpInterceptorsInitialised(true);
    }, []);

    const [toolbar, setToolbar] = useState({navigation: [], title: ''});
    const [hideScrollbar, setHideScrollbar] = useState(true);
    const [notification, setNotification] = useState(null);
    const [FCMToken, setFCMToken] = useState(null);
    const [httpInterceptorsInitialised, setHttpInterceptorsInitialised] = useState(false);
    let notificationTimer;
    const history = useHistory();
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const userQuery = useUser(isLoggedIn);

    useEffect(() => {
        async function getTokens() {
            let {value: retrievedRefreshToken} = await Storage.get({key: 'refreshToken'});
            refreshToken = retrievedRefreshToken;
        
            let {value: retrievedAccessToken} = await Storage.get({key: 'accessToken'});
            accessToken = retrievedAccessToken;

            const {value: retrievedDeviceId} = await Storage.get({key: 'deviceId'});
            if(!retrievedDeviceId) {
                console.log('generating deviceId...');
                
                const newDeviceId = nanoid();

                console.log(newDeviceId);

                await Storage.set({
                    key: 'deviceId',
                    value: newDeviceId
                });

                deviceId = newDeviceId;
            } else {
                console.log('retrieved existing device id');
                console.log(retrievedDeviceId);
                deviceId = retrievedDeviceId;
            }

            if(refreshToken !== null) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        }

        getTokens();
    }, [])

    useEffect(() => {
        if(isLoggedIn === false &&
            location.pathname !== URL.login &&
            location.pathname !== URL.register
        ) {
            console.log('redirecting to login');
            history.push(URL.login);
        }
    }, [isLoggedIn]);

    // useEffect(() => {
    //     if(!userQuery.data?.data?.response?.id) {
    //         ReactGA.set({userId: null});
    //     } else {
    //         ReactGA.set({userId: userQuery.data?.data?.response?.id});
    //     }
    // }, [userQuery.data?.data?.response?.id])

    // useEffect(() => {
    //     if(Capacitor.isPluginAvailable('PushNotifications')) {
    //         PushNotifications.requestPermissions().then(result => {
    //             if(result.receive === 'granted') {
    //                 PushNotifications.register();
    //             } else {
    //                 alert('notifications not accepted');
    //             }
    //         });
            
    //         PushNotifications.addListener('registration', (token) => {
    //             console.log('Push registration successful, token ID = ' + token.value);
    //             setFCMToken(token.value)
    //         });
            
    //         PushNotifications.addListener('registrationError', (error) => {
    //             console.log('Error on registration: ' + JSON.stringify(error));
    //         });
            
    //         //notif received when app open
    //         PushNotifications.addListener(
    //             'pushNotificationReceived',
    //             (notification) => {
    //                 console.log('Push received: ' + JSON.stringify(notification));
    //                 createNotification(notification.title, notification.data.messageId);
    //             },
    //         );
            
    //         //notif received when app closed
    //         PushNotifications.addListener(
    //             'pushNotificationActionPerformed',
    //             (action) => {
    //                 console.log('push action received' + JSON.stringify(action));
    //                 history.push(generatePath(URL.message, {messageId: action.notification.data.messageId}));
    //             },
    //         );
    //     }

    //     document.addEventListener('ionBackButton', (ev) => {
    //         ev.detail.register(10, history.goBack);
    //     });
    // }, []);

    // useEffect(() => {
    //     if(!!FCMToken && !!isLoggedIn) {
    //         console.log('updating client registration...');

    //         axios.patch(`${process.env.REACT_APP_APPHOST}/clients/${deviceId}`, {
    //             FCMToken: {
    //                 operation: 'replace',
    //                 value: FCMToken
    //             }
    //         });
    //     }
    // }, [FCMToken, isLoggedIn]);

    const createNotification = (text, messageId) => {
        clearTimeout(notificationTimer);

        setNotification({text: text, messageId: messageId});

        notificationTimer = setTimeout(() => {setNotification(null)}, 5000);
    }

    const renderContent = () => {
        //console.log(userQuery);

        if(!httpInterceptorsInitialised) {
            console.log('waiting for axios to finish initialising');
            return myLoader;
        }

        if(isLoggedIn === null) {
            console.log('waiting for tokens to be fetched');
            return myLoader;
        }

        if(userQuery.isLoading && userQuery.fetchStatus !== 'idle') {
            console.log(userQuery);
            console.log('waiting for user query to load');
            return myLoader;
        }

        if(userQuery.isError) {
            return(
                <Message negative>Something went wrong (request)</Message>
            );
        }

        if(!!userQuery.data && !userQuery.data.data.success) {
            return(
                <Message negative>Something went wrong (server)</Message>
            );
        }

        return buildRoutes(
            location,
            routeList,
            !!userQuery.data && userQuery.data.data.response,
            setToolbar,
            setHideScrollbar,
            handleNewClub,
            handleLogin,
            handleLogout,
            FCMToken
        );
    }

    const handleLogin = async (tokens) => {
        await updateRefreshToken(tokens.refreshToken);
        await updateAccessToken(tokens.accessToken);
        setIsLoggedIn(true);
    }

    const handleLogout = async () => {
        console.log('do logout');

        await destroyTokens();
        setIsLoggedIn(false);
        userQuery.remove();

        console.log('done logout');
    }

    const handleNewClub = () => {
        console.log('handle new club');
        userQuery.refetch();
        history.push(generatePath(URL.messages));
    }

    return(
        <TutorialProvider>
            <div style={styles.wrapper}>
                <Sidebar
                    manager={userQuery.data?.data?.response}
                    isLoading={userQuery.isLoading && userQuery.fetchStatus !== 'idle'}
                />
                <div style={styles.contentContainer} className='mainContent'>
                    <div style={styles.toolbar}>
                        <Toolbar
                            toolbar={toolbar}
                            isLoading={userQuery.isLoading && userQuery.fetchStatus !== 'idle'}
                        />
                    </div>
                    <Error>
                        <div style={styles.content} className={hideScrollbar && 'hideScrollbar'}>
                            {renderContent()}
                        </div>
                    </Error>
                </div>
                <Transition visible={notification !== null} animation='fly up' duration={500}>
                    <Message
                        floating
                        onClick={() => {
                            if(!!notification.messageId) {
                                history.push(generatePath(URL.message, {messageId: notification.messageId}));
                            }
                        }}
                        style={{
                            position: 'absolute',
                            bottom: '2em',
                            left: '2em',
                            right: '2em'
                        }}
                    >
                        {notification && notification.text}
                    </Message>
                </Transition>
                <CompareWidget />
            </div>
            <TutorialWidget />
        </TutorialProvider>
    );
}

function buildRoutes(location, routeList, manager, setToolbar, setHideScrollbar, handleNewClub, handleLogin, handleLogout, FCMToken) {
    const routes = routeList.map((route, i) => {
        return(
            <Route
                path={route.path}
                exact={route.exact}
                key={i}
            >
                <PageTracking>
                    <route.component
                        setToolbar={setToolbar}
                        setHideScrollbar={setHideScrollbar}
                        manager={manager}
                        handleNewClub={handleNewClub}
                        handleLogin={handleLogin}
                        handleLogout={handleLogout}
                        FCMToken={FCMToken}
                        deviceId={deviceId}
                        refreshToken={refreshToken}
                    />
                </PageTracking>
            </Route>
        );
    });

    return(
        <div className='mainContentContainer'>
            <Suspense fallback={<Loader />}>
                <SwitchTransition>
                    <CSSTransition
                        key={location.pathname}
                        classNames='mainPage'
                        timeout={150}
                    >
                        <Switch location={location}>
                            {routes}
                        </Switch>
                    </CSSTransition>
                </SwitchTransition>
            </Suspense>
        </div>
    )
}

const styles = {
    pageWrapper: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignContent: "stretch",
        alignItems: "flex-start",
        height: '100%',
        width: '100%'
    },
    wrapper: {
        height: '100%',
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignContent: "stretch",
        alignItems: "flex-start",
    },
    contentContainer: {
        order: 0,
        flex: "1 1 auto",
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignContent: "stretch",
        alignItems: "flex-start",
        overflow: "auto"
    },
    toolbar: {
        width: "100%",
        order: 0,
        flex: "0 1 auto",
        alignSelf: "auto"
    },
    content: {
        width: "100%",
        height: "100%",
        overflow: "auto",
        position: 'relative'
    }
}