import { Button, Loader, Message, Segment, Table } from "semantic-ui-react";
import { useState } from "react";
import NarrowLayout from "../../../common/components/NarrowLayout";
import SUIIconShim from "../../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import FacilitiesRating from "../common/FacilitiesRating";
import Currency from "../../../common/components/Currency";
import {facilityUpgradeDurationDays, facilityUpgradeCost} from 'shared-messages';
import RatingBar from "../../../common/components/RatingBar";
import StarRating from "../../../common/components/StarRating";
import { generatePath, useHistory } from "react-router";
import { URL } from "../../../common/lib/paths";
import ClubFaciltiesViewer from "../viewFacilities/ClubFacilitiesViewer";
import FacilityDescription from "../viewFacilities/FacilityDescription";

export default function ClubFacilitiesInterface(props) {
    const [rotation, setRotation] = useState(true);
    const [selectedRating, setSelectedRating] = useState(props.clubFacilities.rating);

    if(props.isLoading) {
        return (
            <Loader active />
        );
    }

    return(
        <div>        
            <ClubFaciltiesViewer
                rotation={rotation}
                rating={selectedRating}
            />
            <NarrowLayout>
                <Button.Group
                    size='small'
                    fluid
                >
                    <Button
                        icon
                        onClick={handlePreviousRating.bind(this)}
                        style={{flex: 0}}
                    >
                        <SUIIconShim><FontAwesomeIcon icon={faArrowLeft} /></SUIIconShim>
                    </Button>
                    <Button disabled className="opacity-override" style={{flex: 1}}>
                        <FacilitiesRating facilitiesRating={selectedRating} />
                    </Button>
                    <Button
                        icon
                        onClick={handleNextRating.bind(this)}
                        style={{flex: 0}}
                    ><SUIIconShim><FontAwesomeIcon icon={faArrowRight} /></SUIIconShim></Button>
                </Button.Group>
                <FacilityDescription rating={selectedRating} />
                <Table
                    definition
                    unstackable
                    verticalAlign="middle"
                >
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={8}>
                                Rating
                            </Table.Cell>
                            <Table.Cell width={8} textAlign="right">
                                <StarRating rating={selectedRating} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={8}>
                                Build cost
                            </Table.Cell>
                            <Table.Cell width={8} textAlign="right">
                                <Currency value={facilityUpgradeCost[selectedRating]} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={8}>
                                Build duration
                            </Table.Cell>
                            <Table.Cell width={8} textAlign="right">
                                {facilityUpgradeDurationDays[selectedRating]} days
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={8}>
                                Player training
                            </Table.Cell>
                            <Table.Cell width={8}>
                                <RatingBar value={calculateRating(props.coachDept.staffRatings.trainingRating, selectedRating)} baseline={props.coachDept.trainingRating} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={8}>
                                Player conditioning
                            </Table.Cell>
                            <Table.Cell width={8}>
                            <RatingBar value={calculateRating(props.coachDept.staffRatings.conditioningRating, selectedRating)} baseline={props.coachDept.conditioningRating} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={8}>
                                Injury prevention
                            </Table.Cell>
                            <Table.Cell width={8}>
                                <RatingBar value={calculateRating(props.physioDept.staffRatings.injuryPreventionRating, selectedRating)} baseline={props.physioDept.injuryPreventionRating} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={8}>
                                Injury recovery
                            </Table.Cell>
                            <Table.Cell width={8}>
                                <RatingBar value={calculateRating(props.physioDept.staffRatings.injuryRecoveryRating, selectedRating)} baseline={props.physioDept.injuryRecoveryRating} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={8}>
                                Youth training
                            </Table.Cell>
                            <Table.Cell width={8}>
                                <RatingBar value={calculateRating(props.youthDept.staffRatings.youthTrainingRating, selectedRating)} baseline={props.youthDept.youthTrainingRating} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={8}>
                                Youth recruitment
                            </Table.Cell>
                            <Table.Cell width={8}>
                                <RatingBar value={calculateRating(props.youthDept.staffRatings.youthRecruitmentRating, selectedRating)} baseline={props.youthDept.youthRecruitmentRating} />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <FacilityUpgradeButton
                    selectedRating={selectedRating}
                    clubFacilities={props.clubFacilities}
                    clubFinances={props.clubFinances}
                    updateClubFacilitiesRating={props.updateClubFacilitiesRating}
                />
                <FacilityDowngradeButton
                    selectedRating={selectedRating}
                    clubFacilities={props.clubFacilities}
                    clubFinances={props.clubFinances}
                    updateClubFacilitiesRating={props.updateClubFacilitiesRating}
                />
                <ViewFacilityButton
                    selectedRating={selectedRating}
                    clubFacilities={props.clubFacilities}
                    manager={props.manager}
                />
            </NarrowLayout>
        </div>
    );

    function handlePreviousRating() {
        setSelectedRating((previousRating) => {
            if(previousRating <= 0) {
                return 5;
            } else {
                return previousRating-1;
            }
        });
    }

    function handleNextRating() {
        setSelectedRating((previousRating) => {
            if(previousRating >= 5) {
                return 0;
            } else {
                return previousRating+1;
            }
        });
    }

    function calculateRating(staffRating, facilityRating) {
        if(facilityRating === null) {
            return staffRating;
        }

        return (staffRating * 0.5) + (normaliseFacilityRating(facilityRating) * 0.5);

        function normaliseFacilityRating(
            facilityRating
        ) {
            return 50 + (facilityRating * 10); //0-100 range
        }
    }
}

function FacilityUpgradeButton(props) {
    if(props.selectedRating !== props.clubFacilities.rating+1) {
        return null;
    }

    if(facilityUpgradeCost[props.selectedRating] > props.clubFinances.remainingTransferBudget) {
        return(
            <Message negative><p>You do not currently have sufficient budget to complete this upgrade.</p></Message>
        );
    }

    return(
        <Button
            fluid
            positive
            onClick={() => props.updateClubFacilitiesRating(props.selectedRating)}
        >
            Upgrade for <Currency value={facilityUpgradeCost[props.selectedRating]} />
        </Button>
    );
}

function FacilityDowngradeButton(props) {
    if(props.selectedRating !== props.clubFacilities.rating-1) {
        return null;
    }

    return(
        <Button
            fluid
            negative
            onClick={() => props.updateClubFacilitiesRating(props.selectedRating)}
        >
            Downgrade for <Currency value={facilityUpgradeCost[props.clubFacilities.rating] * 0.5} /> refund
        </Button>
    )
}

function ViewFacilityButton(props) {
    const history = useHistory();

    if(props.selectedRating !== props.clubFacilities.rating) {
        return null;
    }

    return(
        <Button
            fluid
            primary
            onClick={() => history.push(generatePath(URL.viewClubFacilities, {clubId: props.manager.club.id}))}
        >
            View current facilities
        </Button>
    )
}