import { useEffect, useMemo } from "react"
import { generatePath, useHistory, useLocation } from "react-router";
import { Grid, Header, Menu, Placeholder, Segment } from "semantic-ui-react";
import { groupBy } from "lodash";
import { FormattedDate, FormattedTime } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import CupRoundName from "../cup/RoundName";
import TeamLink from "../team/common/TeamLink";
import { Link } from "react-router-dom";
import { URL } from "../common/lib/paths";
import { useTutorial } from "../tutorial/hooks/useTutorial";
import dayjs from 'dayjs';
import FixtureList from "./FixtureList";
import LoadingCards from "./LoadingCards";

export default function FixturesInterface(props) {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(!location.state?.fixtureStatus && !!props.fixtures) {
            if(
                props.fixtures.planned.length > 0
            ) {
                var d = 'planned';
            } else {
                var d = 'complete';
            }
        
            history.replace({pathname: location.pathname, state: {...location.state, fixtureStatus: d}})
        }
    }, [location.state?.fixtureStatus, props.fixtures]);

    const fixtures = useMemo(() => {
        if(!props.fixtures) return;

        return {
            complete: groupBy(props.fixtures.complete, fixtureGrouper),
            planned: groupBy(props.fixtures.planned, fixtureGrouper)
        }
    }, [props.fixtures]);

    const {checkTutorial, getSearchParamsString} = useTutorial();

    const isTutorial1 = checkTutorial('fixtures', 1);
    const isTutorial2 = checkTutorial('fixtures', 4);

    let tutorialFixtureId, tutorialNextStepSearchString, tutorialNotClubId;
    if(isTutorial1 === true) {
        tutorialFixtureId = fixtures.planned[0].id;
        tutorialNotClubId = props.manager.club.id;
        tutorialNextStepSearchString = getSearchParamsString('fixtures', 2);
    } else if(isTutorial2 === true) {
        tutorialFixtureId = fixtures.complete[0].id;
        tutorialNextStepSearchString = getSearchParamsString('fixtures', 5);
    }

    return (
        <>
            {props.isLoading || !location.state?.fixtureStatus ?
                <LoadingCards keys={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} />
                :
                Object.keys(fixtures[location.state.fixtureStatus]).length > 0 ?
                    Object.keys(fixtures[location.state.fixtureStatus]).map((date) => (
                        <FixtureCard
                            key={fixtures[location.state.fixtureStatus][date].id}
                            date={dayjs(date, 'DD/MM/YYYY').toDate()}
                            fixtures={fixtures[location.state.fixtureStatus][date]}
                            highlightClubId={props.highlightClubId}
                            tutorialFixtureId={tutorialFixtureId}
                            tutorialNotClubId={tutorialNotClubId}
                            tutorialNextStepSearchString={tutorialNextStepSearchString}
                            view={props.view}
                        />
                    ))
                    :
                    <Segment><p>No fixtures found</p></Segment>
            }
        </>
    )
}

function FixtureCard(props) {
    return(
        <Segment>
            <Header as='h4' style={styles.fixtureCardHeader}>
                <FormattedDate value={props.date} month='long' day='numeric' weekday='long' />
            </Header>
            <FixtureList
                {...props}
            />
        </Segment>
    )
}

function fixtureGrouper(fixture) {
    return dayjs(fixture.fixtureRound.gameJob.startDate).format('DD/MM/YYYY');
}

const styles = {
    fixtureCardHeader: {
        textAlign: 'center',
        margin: 0
    },
    fixtureCardGrid: {
        margin: 0
    },
    fixtureCardRowA: {
        padding: 0,
        paddingTop: '1em'
    },
    fixtureCardRowAColumn1: {
        textAlign: 'center'
    },
    fixtureCardRowB: {
        padding: 0
    },
    fixtureCardRowBColumn1: {
        textAlign: 'right',
        paddingRight: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fixtureCardRowBColumn2: {
        textAlign: 'center',
        padding: 0
    },
    fixtureCardRowBColumn3: {
        paddingLeft: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fixtureCardRowC: {
        paddingTop: 0
    }
}