import {useEffect} from 'react';
import { useParams } from "react-router";
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import StaffHistoryInterface from "./interface";
import ShortenedName from "../../player/common/ShortenedName";

import Profile from '../toolbar/Profile';
import ContractOffer from '../toolbar/ContractOffer';
import History from '../toolbar/History';
import TransferOffer from '../toolbar/TransferOffer';

export default function StaffHistory(props) {
    const {staffId} = useParams();

    const {isLoading, data} = useQuery(['getStaffTeamLeagueSeasons', staffId], () =>
    axios.get(`${process.env.REACT_APP_APPHOST}/staff/${staffId}/staffTeamLeagueSeasons`),
        {
            enabled: staffId !== undefined
        }
    );

    useEffect(() => {
        if(!!data && data.data.success) {
            const isMyStaff =
                data.data.response.staffContract &&
                !!props.manager.club &&
                data.data.response.staffContract.clubId === props.manager.club.id

            props.setToolbar({
                title: <><ShortenedName player={data.data.response} /> history</>,
                navigation: [
                    <Profile
                        key={1}
                        staffId={staffId}
                    />,
                    <ContractOffer
                        key={2}
                        staffId={staffId}
                        isMyStaff={isMyStaff}
                    />,
                    <TransferOffer
                        key={4}
                        staffId={staffId}
                        isMyStaff={isMyStaff}
                    />,
                    <History
                        key={3}
                        staffId={staffId}
                    />
                ]
            })
        }
    }, [staffId, isLoading]);

    return(
        <StaffHistoryInterface
            isLoading={isLoading}
            STLS={data?.data?.response?.STLS}
        />
    );
}