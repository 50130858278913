import { faStreetView } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {positionColors} from '../../lib/color';

const positionSortOrder = [
    'GK',
    'CB',
    'LB',
    'RB',
    'LWB',
    'RWB',
    'CDM',
    'CM',
    'LM',
    'RM',
    'CAM',
    'LW',
    'RW',
    'ST'
]

function positionSorter(a, b) {
    const aIndex = positionSortOrder.indexOf(a.playerContract.player.position);
    const bIndex = positionSortOrder.indexOf(b.playerContract.player.position);

    if(aIndex > bIndex) {
        return 1;
    } else if(aIndex < bIndex) {
        return -1;
    } else {
        if(a.playerContract.player.currentAbility > b.playerContract.player.currentAbility) {
            return -1
        } else if(a.playerContract.player.currentAbility < b.playerContract.player.currentAbility) {
            return 1;
        } else {
            return 0;
        }
    }
}

function playerPosition(
    positionResolver,
    hide
) {
    return {
        id: 'playerPosition',
        name: <FontAwesomeIcon icon={faStreetView} />,
        cell: (row) => {
            const pos = positionResolver(row);
            const bg = positionColors[pos];

            return(
                <div
                    style={{...s2, backgroundColor: bg}}
                >
                    {pos}
                </div>
            )
        },
        sortable: true,
        sortFunction: positionSorter,
        wrap: false,
        allowOverflow: false,
        center: true,
        compact: true,
        width: '38px',
        hide,
        grow: 1
    }
}

const s = {
    fontWeight: '900',
    color: '#000',
    fontSize: '10px',
    borderRadius: '4px',
    height: '20px',
    width: '34px',
    justifyContent: 'center'
}

const s2 = {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    fontWeight: '900',
    color: '#000',
    fontSize: '12px',
}

export {
    playerPosition
}