import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router";
import PlayerFreeAuctionCreateOfferInterface from "./interface";

export default function PlayerFreeAuctionCreateOffer(props) {
    const {playerId} = useParams();

    const contractDemandsQuery = useQuery(
        ['getPlayerContractDemands', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/contractDemands`)
    );

    const clubBudgetQuery = useQuery(
        ['getClubBudgets'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/budgets`)
    );

    return(
        <PlayerFreeAuctionCreateOfferInterface
            isLoading={contractDemandsQuery.isLoading || clubBudgetQuery.isLoading}
            contractLengthOptions={contractDemandsQuery.data?.data.response.contractLengthOptions}
            defaultContractLengthIndex={contractDemandsQuery.data?.data.response.defaultContractLengthIndex}
            negotiationFactors={contractDemandsQuery.data?.data.response.negotiationFactors}
            existingContract={contractDemandsQuery.data?.data.response.player.playerContract}
            finances={clubBudgetQuery.data?.data.response}
            playerFreeAuction={props.playerFreeAuction}
            player={contractDemandsQuery.data?.data.response.player}
            isInterested={contractDemandsQuery.data?.data?.response.isInterested}
        />
    );
}