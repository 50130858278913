import {Grid, Header, Loader, Placeholder} from 'semantic-ui-react';
import clone from 'lodash/clone';
import useDimensions from 'react-cool-dimensions';
import {useHistory, generatePath} from 'react-router';

import pitchTop from '../pitch-top2.png';
import pitchBottom from '../pitch-bottom2.png';
import {URL} from '../../../common/lib/paths';
import buildPitchFromLineup from '../../../common/lib/pitch';
import { useMemo } from 'react';
import pitchSectionPositions from '../../../common/lib/player';

const styles = {
    container: {
        position: 'relative',
        color: 'rgba(0,0,0,.87)'
    },
    pitch: {
        width: '100%',
        position: 'absolute'
    },
    row: {
        padding: 0
    },
}

export default function Lineups(props) {
    const history = useHistory();

    const { observe: pitchTopRef, height: pitchTopHeight } = useDimensions();
    const { observe: pitchBottomRef, height: pitchBottomHeight } = useDimensions();

    const handleShirtClick = (positionIndex, playerId) => {
        history.push(generatePath(URL.playerProfile, {playerId: playerId}));
    }

    const pitchMaps = useMemo(() => {
        if(!props.tacticData) return null;

        const homeData = clone(props.tacticData.home.positions);
        const awayData = clone(props.tacticData.away.positions);

        for(const pos of homeData) {
            pos.position = pitchSectionPositions[pos.pitchHalfSectionIndex];

            if(pos.playerIndexes.length === 0) {
                pos.player = null;
            } else {
                const player = props.playerData.home[pos.playerIndexes[0]];
                const playerStat = props.playerStatData.home.find((p) => p.playerIndex === pos.playerIndexes[0]);

                pos.player = {
                    condition: playerStat.startCondition,
                    position: player.naturalPosition,
                    lastName: player.lastName,
                    currentAbilityInPlayingPosition: playerStat.currentAbilityInPlayingPosition,
                    goals: playerStat.goals,
                    assists: playerStat.assists
                }
            }
        }

        for(const pos of awayData) {
            pos.position = pitchSectionPositions[pos.pitchHalfSectionIndex];

            if(pos.playerIndexes.length === 0) {
                pos.player = null;
            } else {
                const player = props.playerData.away[pos.playerIndexes[0]];
                const playerStat = props.playerStatData.away.find((p) => p.playerIndex === pos.playerIndexes[0]);

                pos.player = {
                    condition: playerStat.startCondition,
                    position: player.naturalPosition,
                    lastName: player.lastName,
                    currentAbilityInPlayingPosition: playerStat.currentAbilityInPlayingPosition,
                    goals: playerStat.goals,
                    assists: playerStat.assists
                }
            }
        }

        return {
            home: buildPitchFromLineup(
                homeData,
                undefined, //TODO - fix mentality
                handleShirtClick.bind(this),
                true
            ),
            away: buildPitchFromLineup(
                awayData,
                undefined, //TODO - fix mentality
                handleShirtClick.bind(this),
                false
            )
        }
    }, [props.tacticData, props.playerData, props.playerStatData]);

    return (
        <>
        {!props.isLoading ?
            <>
                <Header as='h4' textAlign='center' style={{margin: '1em 0'}}>{props.homeTeamName}</Header>
            </>
            :
            <Placeholder>
                <Placeholder.Header />
                <Placeholder.Line />
            </Placeholder>
        }
        <div style={styles.container}>
            <img src={pitchTop} style={styles.pitch} ref={pitchTopRef} />
            {!props.isLoading ?
                <Grid textAlign='center' style={{overflow: 'hidden', width: '100%', margin: 0, height: `${pitchTopHeight}px`}}>
                    {pitchMaps.home.map((row) => {
                        if(row.length === 0) return null
    
                        return(
                            <Grid.Row style={styles.row} columns={row.length} verticalAlign='top'>
                                {row}
                            </Grid.Row>
                        );
                    })}
                </Grid>
                :
                <Loader active />
            }
        </div>
        <div style={{...styles.container, ...{marginTop: '-2px'}}}>
            <img src={pitchBottom} style={styles.pitch} ref={pitchBottomRef} />
            {!props.isLoading ?
                <Grid textAlign='center' style={{overflow: 'hidden', width: '100%', margin: 0, height: `${pitchBottomHeight}px`}}>
                    {pitchMaps.away.map((row) => {
                        if(row.length === 0) return null

                        return(
                            <Grid.Row style={styles.row} columns={row.length} verticalAlign='bottom'>
                                {row}
                            </Grid.Row>
                        );
                    })}
                </Grid>
                :
                null
            }
        </div>
        {!props.isLoading ?
            <>
                <Header as='h4' textAlign='center' style={{margin: '1em 0'}}>{props.awayTeamName}</Header>
            </>
            :
            <Placeholder>
                <Placeholder.Header />
                <Placeholder.Line />
            </Placeholder>
        }
        </>
    )
}