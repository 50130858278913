import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import RetiredPlayerInterface from "./interface";
import axios from "axios";
import { useParams } from "react-router";
import { useEffect } from "react";
import ShortenedPlayerName from "../../player/common/ShortenedName";
import RetiredPlayerProfile from "../toolbar/Profile";
import RetiredPlayerHistory from "../toolbar/History";

export default function RetiredPlayer(props) {
    const {playerId} = useParams();
    const queryClient = useQueryClient();

    const {data, isLoading} = useQuery(
        ['getRetiredPlayer', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/retiredPlayers/${playerId}`)
    );

    const createFavRetiredPlayer = useMutation(() => axios.post(`${process.env.REACT_APP_APPHOST}/favRetiredPlayers`, {
        playerId: parseInt(playerId)
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('getRetiredPlayer');
        }
    });

    const deleteFavRetiredPlayer = useMutation(() => axios.delete(`${process.env.REACT_APP_APPHOST}/favRetiredPlayers?playerId=${playerId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries('getRetiredPlayer');
        }
    });

    const handleToggleFavRetiredPlayer = () => {
        if(data.data.response.isFavRetiredPlayer === false) {
            createFavRetiredPlayer.mutate();
        } else if(data.data.response.isFavRetiredPlayer === true) {
            deleteFavRetiredPlayer.mutate();
        }
    }

    useEffect(() => {
        if(!!data?.data.response) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={data.data.response} /> history</>,
                navigation: [
                    <RetiredPlayerProfile playerId={playerId} />,
                    <RetiredPlayerHistory playerId={playerId} />,
                ]
            });
        }
    }, [data?.data.response]);

    return (
        <RetiredPlayerInterface
            isLoading={isLoading}
            player={data?.data.response}
            handleToggleFavRetiredPlayer={handleToggleFavRetiredPlayer.bind(this)}
            toggleFavPlayerLoading={createFavRetiredPlayer.isLoading || deleteFavRetiredPlayer.isLoading}
        />
    )
}