import { faCakeCandles, faFlag, faShieldHalved, faStar, faStreetView, faUser } from "@fortawesome/free-solid-svg-icons";
import TitledSegment from "../../common/components/TitledSegment";
import CityLink from "../../city/common/CityLink";
import ShortenedPlayerName from "../../player/common/ShortenedName";
import { FormattedDate } from "react-intl";
import { Button, Grid, Placeholder, Table } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { positionColors } from "../../common/lib/color";
import NationGroupLinkWithFlag from "../../nationGroup/common/NationGroupLinkWithFlag";
import NarrowLayout from "../../common/components/NarrowLayout";
import StaffRoleName from "../../staff/common/RoleName";
import { generatePath, Link } from "react-router-dom";
import { URL } from "../../common/lib/paths";
import SUIIconShim from "../../common/components/SUIIconShim";

export default function RetiredPlayerInterface(props) {
    return (
        <NarrowLayout>
            <div style={{overflow: 'hidden', marginTop: '1em'}}>
            <Button
                icon
                toggle
                disabled={props.isLoading}
                loading={props.toggleFavPlayerLoading}
                active={props.player?.isFavRetiredPlayer}
                onClick={props.handleToggleFavRetiredPlayer}
                floated='right'
                color='black'
            >
                <SUIIconShim>
                    <FontAwesomeIcon icon={faStar} fixedWidth />
                </SUIIconShim>
            </Button>
            </div>
            <TitledSegment
                title={!props.isLoading && `${props.player.firstName} ${props.player.lastName}`}
                isLoading={props.isLoading}
                icon={faUser}
                noPadding={true}
            >
                <Grid style={{width: '100%', margin: 0, padding: '0.5em 0 1em 0'}}>
                    <Grid.Row style={{padding: 0}}>
                        <Grid.Column width={8} style={{borderRight: '1px solid #2224261a'}}>
                            <Table basic='very' compact='very' unstackable style={{width: '100%'}} className='attrTable'>
                                <Table.Body>
                                    {getTableRow(faStreetView, (player) => <div style={{display: 'inline-block', textAlign: 'center', width: '34px', height: '20px', borderRadius: '4px', fontSize: '12px', fontWeight: '900', color: '#000', backgroundColor: positionColors[player.position]}}>{player.position}</div>)}
                                    {getTableRow(faFlag, (player) => <NationGroupLinkWithFlag shortcode={player.nation.nationGroupShortcode} />)}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Table basic='very' compact='very' unstackable style={{width: '100%'}} className='attrTable'>
                                <Table.Body>
                                    {getTableRow(faCakeCandles, (player) => <span>{player.ageAtRetirement} years old</span>)}
                                    {getTableRow(faShieldHalved, (player) => <CityLink city={player.city} />)}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                    {props.isLoading === false &&
                        <Grid.Row style={{paddingBottom: 0}}>
                            <Grid.Column width={16}>
                                <p>
                                    <ShortenedPlayerName player={props.player} /> retired on <FormattedDate value={props.player.retirementSeason.endDate} timeZone="UTC" />
                                    {!!props.player.staff &&
                                        <> and became a <Link to={{pathname: generatePath(props.player.staff.isRetired === true ? URL.retiredStaffProfile : URL.staffProfile, {staffId: props.player.staff.id})}}><StaffRoleName role={props.player.staff.role} /></Link></>
                                    }
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            </TitledSegment>
        </NarrowLayout>
    );

    function getTableRow(icon, getValue) {
        if(props.isLoading) {
            return(
                <Table.Row>
                    <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={icon} /></Table.Cell>
                    <Table.Cell textAlign="right" style={{maxWidth: 0, display: 'table-cell'}} className='noTextwrap'>
                        <Placeholder><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                    </Table.Cell>
                </Table.Row>
            );
        } else {
            const value = getValue(props.player)

            if(value === null) return null;

            return(
                <Table.Row>
                    <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={icon} /></Table.Cell>
                    <Table.Cell textAlign="right" style={{maxWidth: 0, display: 'table-cell'}} className='noTextwrap'>
                        {value}
                    </Table.Cell>
                </Table.Row>
            )
        }
    }
}