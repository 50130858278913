import {Menu, Dropdown} from "semantic-ui-react";
import {useHistory, useLocation} from 'react-router-dom';
import { cloneElement } from "react";

import {URL} from '../../common/lib/paths';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleQuestion, faEllipsisV, faQuestion, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef } from "react";
import { useTutorial } from "../../tutorial/hooks/useTutorial";

export default function Toolbar(props) {
    const history = useHistory();
    const location = useLocation();
    const activeToolbarItemRef = useRef({active: null, glow: null});
    const {goNextStep, checkTutorial} = useTutorial();

    useEffect(() => {
        if(activeToolbarItemRef.current.glow !== null) {
            activeToolbarItemRef.current.glow.scrollIntoView({inline: 'center', behavior: 'smooth'});
        } else if(activeToolbarItemRef.current.active !== null) {
            activeToolbarItemRef.current.active.scrollIntoView({inline: 'center', behavior: 'smooth'});
        }
    }, [
        props.toolbar.navigation,
        location.pathname,
        location.search
    ]);

    const isTutorial1 = checkTutorial('intro', 0);
    const isTutorial2 = checkTutorial('intro', 2);
    const isTutorial3 = checkTutorial('messages', 2);

    if(props.isLoading) return null;

    return(
        <>
            <Menu secondary inverted fluid color="blue" className='toolbar'>
                <Menu.Item link style={{margin: 0, borderRadius: 0, zIndex: 100, padding: '.92857143em .92857143em'}} onClick={handleBackClick.bind(this)} className={(isTutorial2 || isTutorial3) && 'glow'}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Menu.Item>
                <Menu.Item header fitted="horizontally" style={{position: 'unset'}}>
                    <div style={{position: 'absolute', left: 0, right: 0, textAlign: 'center'}}>
                        <span>{props.toolbar.title}</span>
                    </div>
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Dropdown item icon={<FontAwesomeIcon icon={faEllipsisV} />} style={{borderRadius: 0}} open={isTutorial1 ? true : undefined}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={handleTutorialClick.bind(this)}
                                icon={<FontAwesomeIcon icon={faCircleQuestion} style={{marginRight: '12px'}} />}
                                className={isTutorial1 && 'glow'}
                                text='Tutorial'
                            />
                            <Dropdown.Item
                                onClick={() => history.push(URL.logout)}
                                icon={<FontAwesomeIcon icon={faSignOut} style={{marginRight: '12px'}} />}
                                text='Logout'
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </Menu>
            {props.toolbar.navigation !== null && props.toolbar.navigation.length !== 0 &&
                <Menu secondary inverted color="blue" style={{margin: 0}} className='subnav'>
                    <Menu.Menu className="hideScroll" style={{overflow: "auto"}}>
                        {props.toolbar.navigation.map((toolbarItem) => 
                            cloneElement(toolbarItem, {
                                activeToolbarItemRef
                            })
                        )}
                    </Menu.Menu>
                </Menu>
            }
        </>
    );

    function handleTutorialClick() {
        if(isTutorial1) {
            goNextStep();
        } else {
            history.push({
                pathname: URL.tutorialMenu
            })
        }
    }

    function handleBackClick() {
        if(isTutorial2 || isTutorial3) {
            goNextStep();
        } else {
            history.goBack();
        }
    }
}