import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';

const initialFilterState = {
    filterOverlayOpen: false,
    currentPage: 1,
    cursor: 0,
    cursorPageMap: {1: 0},
    maxPage: undefined,
    filterRole: null,
    filterNationShortcode: null,
    filterRating: {minimum: 0, maximum: 100},
    filterContractStatus: false,
    filterContractLength: null,
    filterAge: {minimum: 30, maximum: 60},
    filterUninterested: false
}

function filterStateReducer(currentFilterState, action) {
    let newFilterState = cloneDeep(currentFilterState);

    let isStateInvalid = false;

    switch(action.name) {
        case 'toggleFilterOverlay':
            newFilterState.filterOverlayOpen = !currentFilterState.filterOverlayOpen;
            break;
        case 'openFilterOverlay':
            newFilterState.filterOverlayOpen = true;
            break;
        case 'updateFilters':
            console.log(action.value);
            newFilterState.filterRole = action.value.filterRole;
            newFilterState.filterNationShortcode = action.value.filterNationShortcode;
            newFilterState.filterRating = action.value.filterRating;
            newFilterState.filterContractStatus = action.value.filterContractStatus;
            newFilterState.filterContractLength = action.value.filterContractLength;
            newFilterState.filterAge = action.value.filterAge;
            newFilterState.filterUninterested = action.value.filterUninterested;
            newFilterState.filterOverlayOpen = false;
            newFilterState.currentPage = 1;
            newFilterState.cursor = 0;
            newFilterState.cursorPageMap = {1: 0};
            break;
        case 'nextPage':
            const nextPage = currentFilterState.currentPage + 1;

            newFilterState.currentPage = nextPage;
            newFilterState.cursorPageMap[nextPage] = action.value.fromCARank;
            newFilterState.cursor = newFilterState.cursorPageMap[nextPage];
            break;
        case 'previousPage':
            const prevPage = currentFilterState.currentPage - 1;

            if(typeof newFilterState.cursorPageMap[prevPage] !== 'number') {
                isStateInvalid = true;
            } else {
                newFilterState.currentPage = prevPage;
                newFilterState.cursor = newFilterState.cursorPageMap[prevPage];
            }

            if(action.isMaxPage === true) {
                newFilterState.maxPage = prevPage;
            }

            break;
    }

    if(isStateInvalid === true || action.name === 'clearFilters') {
        newFilterState = {...newFilterState, ...initialFilterState};
    }

    return newFilterState;
}

function getFilterQueryParams(filterState, maxReputationAbs, favStaff = false) {
    const params = [`favStaff=${favStaff}`];

    if(filterState.cursor !== null) {
        params.push(`fromCARank=${filterState.cursor}`);
    }

    if(filterState.filterRole !== null) {
        params.push(`role=${filterState.filterRole}`);
    }

    if(!!filterState.filterNationShortcode) {
        params.push(`nationGroupShortcode=${filterState.filterNationShortcode}`);
    }

    if(filterState.filterRating.minimum !== 0 || filterState.filterRating.maximum !== 100) {
        params.push(`ratingMin=${filterState.filterRating.minimum}`);
        params.push(`ratingMax=${filterState.filterRating.maximum}`);
    }

    if(filterState.filterAge.minimum !== 16 || filterState.filterAge.maximum !== 38) {
        params.push(`ageMin=${filterState.filterAge.minimum}`);
        params.push(`ageMax=${filterState.filterAge.maximum}`);
    }

    if(filterState.filterContractLength !== null) {
        params.push(`contractExpiry=${filterState.filterContractLength}`);
    }

    if(filterState.filterUninterested) {
        if(maxReputationAbs === undefined) {
            throw new Error('missing maxReputationAbs');
        }

        params.push(`maxReputationAbs=${maxReputationAbs}`);
    }

    if(filterState.filterContractStatus) {
        params.push(`freeAgent=true`);
    }

    return params;
}

const filterProperties = [
    'filterRole',
    'filterNationShortcode',
    'filterRating',
    'filterContractStatus',
    'filterContractLength',
    'filterAge',
    'filterUninterested'
];

function getFilterStateFromPersistedState(filterState) {
    return ['cursor', ...filterProperties].map((property) => filterState[property]);
}

function isFilterActive(filterState) {
    const a = pick(filterState, filterProperties);
    const b = omit(initialFilterState, ['filterOverlayOpen', 'cursor', 'currentPage', 'cursorPageMap', 'maxPage']);

    return !isEqual(
        a,
        b
    );
}

export {
    initialFilterState,
    filterStateReducer,
    isFilterActive,
    getFilterQueryParams,
    getFilterStateFromPersistedState
}