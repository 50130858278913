import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generatePath, Link } from "react-router-dom";
import TeamName from "../../../team/common/TeamName";
import { URL } from "../../lib/paths";

function teamName(
    resolver,
    fallback,
    hide = 500
) {
    return {
        id: 'teamName',
        name: <FontAwesomeIcon icon={faShieldHalved} />,
        selector: (rowData) => {
            const team = resolver(rowData);

            if(!team.id) return fallback;

            return(
                <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                    <Link
                        to={generatePath(URL.clubProfile, {clubId: team.club.id})}
                    >
                        <TeamName team={team} nation={true} />
                    </Link>
                </div>
            )
        },
        wrap: false,
        allowOverflow: false,
        compact: true,
        grow: 2,
        hide,
        ignoreRowClick: true
    }
}

export {
    teamName
}