import {useEffect} from "react";
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {useLocation, useParams} from 'react-router-dom';

import LeagueTable_interface from "./interface.js";
import LeagueName from '../../league/components/common/Name';
import TeamPlayersToolbarItem from "../../club/components/toolbar/TeamPlayers";
import TeamLeagueToolbarItem from "../../club/components/toolbar/TeamLeague";
import TeamFixturesToolbarItem from "../../club/components/toolbar/TeamFixtures";
import TeamLeagueSeasonsToolbarItem from "../../club/components/toolbar/TeamLeagueSeasons";
import { FormattedMessage } from "react-intl";
import ClubProfileToolbarItem from "../../club/components/toolbar/ClubProfile";
import ClubStaffToolbarItem from "../../club/components/toolbar/ClubStaff";
import ClubTransfersToolbarItem from "../../club/components/toolbar/ClubTransfers";

export default function TeamLeagueSeason(props) {
    const location = useLocation();
    const {clubId, teamType} = useParams();

    const leagueSeasonTLSQuery = useQuery(
        ['getLeagueSeasonTLS', location.state?.leagueSeasonId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagueSeasons/${location.state?.leagueSeasonId}/teamLeagueSeasons`),
        {
            enabled: location.state?.leagueSeasonId !== undefined
        }
    );

    useEffect(() => {
        if(!!leagueSeasonTLSQuery.data?.data?.success) {
            const isMyLS = location.state?.leagueSeasonId === props.manager.club?.firstTeam.currentTLS.LS.id || location.state?.leagueSeasonId === props.manager.club?.youthTeam.currentTLS.LS.id;
            
            if(!!clubId && !!teamType) {
                var navigation = [
                    <ClubProfileToolbarItem clubId={clubId} key={1} />,
                    <TeamPlayersToolbarItem clubId={clubId} teamType={teamType} key={2} />,
                    <TeamFixturesToolbarItem clubId={clubId} teamType={teamType} key={5} />,
                    <TeamLeagueToolbarItem clubId={clubId} teamType={teamType} key={6} />,
                    <ClubStaffToolbarItem clubId={clubId} key={7} />,
                    <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                    <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType={teamType} key={9} />
                ]
            } else {
                var navigation = [];
            }

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{leagueName: <LeagueName league={leagueSeasonTLSQuery.data.data.response.league} />}} />,
                navigation
            });
        }
    }, [location.state?.leagueSeasonId, leagueSeasonTLSQuery.data, clubId, teamType]);

    return(
        <LeagueTable_interface
            leagueSeasonTLSQueryIsLoading={leagueSeasonTLSQuery.isLoading}
            leagueSeason={leagueSeasonTLSQuery.data?.data?.response}
        />
    );
}

const messages = {
    screenTitle: {
        id: 'test',
        defaultMessage: '{leagueName} table',
        description: 'todo'
    }
}