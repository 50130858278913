export const URL = {
    budget: '/budget',
    home: "/",
    newManager: "/new-manager",
    jobs: "/jobs",
    messages: "/messages",
    message: "/messages/:messageId",
    playerCompare: '/players/compare',
    playerProfile: '/players/:playerId/profile',
    retiredPlayerProfile: '/retiredPlayers/:playerId/profile',
    playerContractOfferNew: '/players/:playerId/playerContract',
    playerStatus: "/players/:playerId/status",
    playerHistory: '/players/:playerId/history',
    retiredPlayerHistory: '/retiredPlayers/:playerId/history',
    playerAuction: '/players/:playerId/playerAuction',
    playerTransferOrLoanOffer: '/players/:playerId/playerTransferOrLoanOffer',
    playerTransferAuctionFree: '/players/:playerId/playerFreeAuction/:playerFreeAuctionId?',
    playerTransferAuctionFreeHistory: '/playerTransferAuctionFreeHistory/:playerTransferAuctionFreeHistoryId?',
    viewPlayerTransferOfferPaid: '/playerTransferOfferPaid/:playerTransferOfferPaidId',
    viewPlayerTransferOfferPaidHistory: '/playerTransferOfferPaidHistory/:playerTransferOfferPaidHistoryId',
    viewPlayerTransferOfferLoan: '/playerTransferOfferLoan/:playerTransferOfferLoanId',
    viewPlayerTransferOfferLoanHistory: '/playerTransferOfferLoanHistory/:playerTransferOfferLoanHistoryId',
    playerPromotion: '/players/:playerId/promote',
    clubProfile: "/club/:clubId/profile",
    teamProfile: '/club/:clubId/team/:teamType',
    teamPlayers: '/club/:clubId/team/:teamType/players',
    teamTactics: '/team/:teamType/tactics',
    teamTacticsBuilder: '/teamTactics/:teamTacticId',
    teamFixtures: "/club/:clubId/team/:teamType/fixtures",
    teamTraining: "/team/:teamType/training",
    teamHistory: "/club/:clubId/team/:teamType/history",
    editClubStadium: '/club/stadium',
    viewClubStadium: '/club/:clubId/stadium',
    editClubFacilities: '/club/facilities',
    viewClubFacilities: '/club/:clubId/facilities',
    clubStaff: "/club/:clubId/staff",
    clubFinances: "/finances",
    clubPlayerTransferOffers: '/club/playerTransferProposals',
    clubTransferHistory: '/club/:clubId/transferHistory',
    worldClubs: '/world/clubs',
    worldCups: '/world/cups',
    league: '/league/:leagueId', //these are two ways to getting to the same view (league table)
    teamLeague: '/club/:clubId/team/:teamType/league', //these are two ways to getting to the same view (league table)
    worldRecords: '/world/records',
    playerAppearanceRecords: '/world/records/player/appearances',
    playerAssistRecords: '/world/records/player/assists',
    playerCleanSheetRecords: '/world/records/player/cleanSheets',
    playerGoalsScoredRecords: '/world/records/player/goalsScored',
    playerGoalContributionRecords: '/world/records/player/goalContributions',
    playerCurrentAbilityRecords: '/world/records/player/rating',
    playerTransferFeeRecords: '/world/records/player/transferFee',
    clubWinRateRecords: '/world/records/club/winRate',
    clubRatingRecords: '/world/records/club/rating',
    clubBalanceRecords: '/world/records/club/balance',
    transferSearchPlayers: "/transfers/search/players",
    transferSearchStaff: "/transfers/search/staff",
    playerTransferAuctions: '/transfers/search/auctions',
    playerTransferAuction: '/playerTransferAuction/:playerTransferAuctionId',
    playerTransferAuctionBid: '/playerTransferAuction/:playerTransferAuctionId/bid',
    playerTransferAuctionPaidHistory: '/playerTransferAuctionPaidHistory/:playerTransferAuctionPaidHistoryId',
    favPlayers: "/favourites/players",
    favClubs: '/favourites/clubs',
    favStaff: '/favourites/staff',
    favRetired: '/favourites/retired',
    transferScouting: '/transfers/scouting',
    transferHistory: '/transfers/history',
    managerProfile: "/manager/:managerId/profile",
    managerHistory: "/manager/:managerId/history",
    cupFixtures: '/cup/:cupId/fixtures',
    cupStats: '/cup/:cupId/stats',
    cupHistory: '/cup/:cupId/history',
    nationList: '/nations',
    nation: '/nations/:nationId',
    nationLeagues: '/nations/:nationId/leagues',
    nationClubs: '/nations/:nationId/clubs',
    nationPlayers: '/nations/:nationId/players',
    city: '/city/:cityId',
    cityPlayers: '/city/:cityId/players',
    staffProfile: '/staff/:staffId/profile',
    retiredStaffProfile: '/retiredStaff/:staffId/profile',
    staffContract: "/staff/:staffId/contract",
    staffContractOffer: "/staff/:staffId/staffContract",
    staffTransferOffer: '/staff/:staffId/transfer',
    staffTransferAuctionFree: "/staff/:staffId/staffFreeAuction/:staffTransferAuctionFreeId?",
    staffTransferAuctionFreeHistory: '/staffTransferAuctionFreeHistory/:staffTransferAuctionFreeHistoryId',
    staffHistory: "/staff/:staffId/history",
    retiredStaffHistory: '/retiredStaff/:staffId/history',
    leagueFixture: "/leagueFixtures/:fixtureId",
    leagueFixtureHighlights: "/leagueFixtures/:fixtureId/highlights",
    cupFixture: "/cupFixtures/:fixtureId",
    cupFixtureHighlights: "/cupFixtures/:fixtureId/highlights",
    settings: "/settings/locale",
    login: "/login",
    register: "/register",
    logout: '/logout',
    tutorialMenu: '/tutorials',
    messagesTutorial: '/tutorials/messages',
    financesTutorial: '/tutorials/finances',
    newsTutorial: '/tutorials/news',
    playersTutorial: '/tutorials/players',
    tacticsTutorial: '/tutorials/tactics',
    trainingTutorial: '/tutorials/training',
    clubsTutorial: '/tutorials/clubs',
    staffTutorial: '/tutorials/staff',
    fixturesTutorial: '/tutorials/fixtures',
    leagueTutorial: '/tutorials/league',
    transfersTutorial: '/tutorials/transfers',
    worldTutorial: '/tutorials/world',
    introTutorial: '/tutorials/intro'
}