import { generatePath, Redirect, useLocation, useParams } from "react-router";
import PlayerHistoryOverallInterface from "./interface";
import { useEffect } from "react";
import ShortenedPlayerName from "../../common/ShortenedName";

import Profile from "../../toolbar/Profile.js";
import History from "../../toolbar/History.js";
import TransferOffer from "../../toolbar/Transfer.js";
import ContractOffer from "../../toolbar/Contract.js";
import { URL } from "../../../common/lib/paths.js";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export default function PlayerHistoryOverall(props) {
    const {playerId} = useParams();
    const location = useLocation();

    const getPlayerOverallHistoryQuery = useQuery(
        ['getPlayerTeamLeagueSeasons', playerId, false, true],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/playerTeamLeagueSeasons?aggregated=true`)
    );

    const getPlayerMeta = useQuery(
        ['getPlayerMeta', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}?meta=true`)
    );

    useEffect(() => {
        if(!!getPlayerMeta.data) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={getPlayerMeta.data.data.response} /> history</>,
                navigation: [
                    <Profile key={1} playerId={playerId} />,
                    <ContractOffer key={4} playerId={playerId} isMyPlayer={getPlayerMeta.data.data.response.isMyPlayer} />,
                    <TransferOffer key={3} playerId={playerId} isMyPlayer={getPlayerMeta.data.data.response.isMyPlayer} />,
                    <History key={5} playerId={playerId} />,
                ]
            });
        }
    }, [getPlayerMeta.data?.data.response]);

    if(getPlayerMeta.isLoading === false && getPlayerMeta.data.data.response.key === 'RESOURCE_DOES_NOT_EXIST') {
        return(
            <Redirect to={{pathname: generatePath(URL.retiredPlayerHistory, {playerId: playerId}), state: location.state}} />
        );
    }

    return(
        <PlayerHistoryOverallInterface
            isLoading={getPlayerOverallHistoryQuery.isLoading}
            history={getPlayerOverallHistoryQuery.data?.data.response || []}
        />
    );
}