import { Button, Grid, Message } from "semantic-ui-react";
import NarrowLayout from "../../../common/components/NarrowLayout";
import TitledSegment from "../../../common/components/TitledSegment";
import StaffBriefing from "../common/new/StaffBriefing";
import { defineMessages, FormattedMessage } from "react-intl";
import ContractLengthSelection from "../common/new/ContractLengthSelection";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import ContractValue from "../common/new/ContractValue";
import { useParams } from "react-router";
import LoadedStatistic from "../../../common/components/LoadedStatistic";
import ContractSummary from "../../common/ContractSummary";
import SalaryOffer from "../common/new/SalaryOffer";

export default function PlayerContractOfferInterface(props) {
    const [salaryOffer, setSalaryOffer] = useState(null);
    const [selectedContractLengthIndex, setSelectedContractLengthIndex] = useState(null);
    const [isNegotiating, setIsNegotiating] = useState(false);

    const {playerId} = useParams();
    const queryClient = useQueryClient();

    const createPlayerContractOffer = useMutation(
        ({salaryOffer, endSeasonIdOffer}) => axios.patch(`${process.env.REACT_APP_APPHOST}/playerContracts/${props.player.playerContract.id}`, {
            salary: salaryOffer,
            endSeasonId: endSeasonIdOffer
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getPlayerSalaryDemand');
                queryClient.invalidateQueries('getPlayerContractDemands');
            }
        }
    );

    const getSalaryDemandQuery = useQuery(
        ['getPlayerSalaryDemand', playerId, selectedContractLengthIndex],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/contractDemands?proposedContractEndSeasonId=${props.contractLengthOptions[selectedContractLengthIndex].seasonId}`),
        {
            enabled: selectedContractLengthIndex !== null
        }
    );

    useEffect(() => {
        if(selectedContractLengthIndex === null && props.defaultContractLengthIndex !== undefined) {
            setSelectedContractLengthIndex(props.defaultContractLengthIndex);
        }
    }, [props.defaultContractLengthIndex]);

    useEffect(() => {
        if(salaryOffer === null && getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand !== undefined) {
            setSalaryOffer(getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand);
        }
    }, [getSalaryDemandQuery.data?.data.response]);

    if(createPlayerContractOffer.isSuccess === true) {
        return(
            <Message positive>
                <Message.Header>Contract accepted</Message.Header>
                <Message.Content>Your contract offer has been accepted by the player and they have successfully renewed.</Message.Content>
            </Message>
        )
    }

    if(props.player?.isRetiring) {
        return(
            <Message negative>
                <Message.Header>Player retiring</Message.Header>
                <Message.Content>You cannot offer this player a new contract because they have decided to retire.</Message.Content>
            </Message>
        );
    }

    if(props.isInterested === false) {
        return(
            <Message negative>
                <Message.Header>Player not interested</Message.Header>
                <Message.Content>You cannot offer this player a new contract because they are not currently interested in renewing their contract.</Message.Content>
            </Message>
        );
    }

    const isValidContractLength = props.contractLengthOptions?.[selectedContractLengthIndex]?.isValid === true;

    return(
        <NarrowLayout>
            <TitledSegment
                title='Budgets'
            >
                <Grid className='gridReset'>
                    <Grid.Row columns={2} style={{padding: 0}}>
                        <Grid.Column style={{padding: 0}}>
                            <LoadedStatistic
                                label='Transfer budget'
                                change={props.finances?.remainingTransferBudget}
                                value={props.finances?.transferBudget}
                                isCurrency={true}
                            />
                        </Grid.Column>
                        <Grid.Column style={{padding: 0}}>
                            <LoadedStatistic
                                label='Wage budget'
                                change={props.finances?.remainingWageBudget}
                                value={props.finances?.totalWageBudget}
                                isCurrency={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </TitledSegment>
            <TitledSegment
                title='Contract offer'
            >
                <ContractLengthSelection
                    isLoading={props.isLoading}
                    contractLengthOptions={props.contractLengthOptions}
                    selectedContractLengthIndex={selectedContractLengthIndex}
                    setSelectedContractLengthIndex={handleSelectContractLengthIndex.bind(this)}
                />
                <ContractSummary
                    salary={getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand}
                    salaryLabel='Salary demand'
                    contractEndDate={getSalaryDemandQuery.data?.data.response.proposedContractEndSeason.endDate}
                    contractExpiryLabel='Contract expiry'
                />
                {getSalaryDemandQuery.data?.data.response.baseSalaryDemand !== null &&
                    <ContractValue
                        baseSalaryDemand={getSalaryDemandQuery.data?.data.response.baseSalaryDemand || 0}
                        salaryOffer={salaryOffer || 0}
                    />
                }
                {isNegotiating === true ?
                    <>
                        <SalaryOffer
                            salaryOffer={salaryOffer}
                            setSalaryOffer={setSalaryOffer}
                            resetSalaryOffer={resetSalaryOffer.bind(this)}
                            handleSubmit={handleSubmit.bind(this)}
                            increment={getSalaryDemandQuery.data?.data.response.salaryIncrement}
                        />
                        <Button
                            primary
                            fluid
                            onClick={handleSubmit.bind(this)}
                            disabled={!isValidContractLength}
                            size='tiny'
                        >
                            Submit offer
                        </Button>
                    </>
                    :
                    <Grid className='gridReset'>
                        <Grid.Row columns={2} style={{padding: 0}}>
                            <Grid.Column style={{paddingRight: '0.5em', paddingLeft: 0}}>
                                <Button
                                    size='tiny'
                                    fluid
                                    onClick={() => setIsNegotiating(true)}
                                    disabled={!isValidContractLength}
                                >
                                    Negotiate
                                </Button>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: '0.5em', paddingRight: 0}}>
                                <Button
                                    size='tiny'
                                    fluid
                                    primary
                                    onClick={handleSubmit.bind(this)}
                                    disabled={!isValidContractLength}
                                >
                                    Accept demands
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
                {!isValidContractLength &&
                    <Message negative>
                        <p>Your contract offer must be longer than the player's existing contract</p>
                    </Message>
                }
                {!!createPlayerContractOffer.error?.response?.data &&
                    <Message negative>
                        <FormattedMessage
                            {...(submitOfferErrorMessages[createPlayerContractOffer.error?.response?.data.response?.key] || submitOfferErrorMessages['unknown'])}
                            values={{key: createPlayerContractOffer.error?.response?.data.response?.key || '-1'}}
                        />
                    </Message>
                }
            </TitledSegment>
            {props.negotiationFactors !== null &&
                <TitledSegment
                    title='Staff briefing'
                >
                    <StaffBriefing
                        isLoading={props.isLoading}
                        negotiationFactors={props.negotiationFactors}
                        existingContract={props.existingContract}
                    />
                    {props.isYouthTeamPlayer === true &&
                        <Message negative>
                            <FormattedMessage
                                {...messages.willPromoteToFirstTeam}
                            />
                        </Message>
                    }
                </TitledSegment>
            }
        </NarrowLayout>
    );

    function handleSelectContractLengthIndex(contractLengthIndex) {
        if(contractLengthIndex === selectedContractLengthIndex) return;

        setSelectedContractLengthIndex(contractLengthIndex);
        setSalaryOffer(null);
    }

    function resetSalaryOffer() {
        setSalaryOffer(getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand);
    }

    function handleSubmit() {
        createPlayerContractOffer.mutate({
            salaryOffer: parseInt(salaryOffer),
            endSeasonIdOffer: parseInt(props.contractLengthOptions[selectedContractLengthIndex].seasonId)
        });
    }
}

const messages = {
    willPromoteToFirstTeam: {
        id: 'playerContract:staffBriefing:willPromoteToFirstTeam',
        defaultMessage: 'If this player accepts the contract offer they will automatically be moved to the first team',
        description: 'Warning message shown if you try to offer a new contract to a youth player warning that the player will be moved to the first team if they accept'
    }
}

const submitOfferErrorMessages = defineMessages({
    'unknown': {
        id: 'player:transferOffer:create:error:general',
        defaultMessage: 'An unknown error occured, please report this ({key})',
        description: 'An unknown error occured'
    },
    'CONTRACT_VALUE_LOW': {
        id: 'player:transferOffer:create:error:contractValueLow',
        defaultMessage: 'The player has rejected your contract offer. Try increasing the salary offer.'
    },
    'CONTRACT_VALUE_HIGH': {
        id: 'player:transferOffer:create:error:contractValueHigh',
        defaultMessage: 'The board feel this contract offer is too generous. Try lowering the salary offer.'
    },
    'INSUFFICIENT_CONTRACT_LENGTH': {
        id: 'player:transferOffer:create:error:contractLength',
        defaultMessage: 'Your contract offer must be longer than the player\'s existing contract'
    },
})