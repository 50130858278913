import {useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {Form, Menu, Message} from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive'
import {useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import DataTable from "../common/components/DataTable";
import PlayerLink from "../player/common/Link";
import InfoLabel from "../player/common/InfoLabel";
import AttributeTable from '../player/common/PlayerExpander/AttributeTable';
import TrainingChart from './Chart';
import RetrainPosition from './RetrainPosition';

import { hsvToRgb, ratingToHsv, getColor } from '../common/lib/color';
import { getStyle } from '../player/common/positionColour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faCakeCandles, faPlus, faStar, faStreetView, faTag, faFireFlameCurved, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import colours from '../common/colours';
import { useTutorial } from '../tutorial/hooks/useTutorial';
import { playerName } from '../common/dataTableColumns/player/playerName';
import { playerPosition } from '../common/dataTableColumns/player/playerPosition';

const positionSortOrder = [
    'GK',
    'CB',
    'LB',
    'RB',
    'LWB',
    'RWB',
    'CDM',
    'CM',
    'LM',
    'RM',
    'CAM',
    'LW',
    'RW',
    'ST'
]

function positionSorter(a, b) {
    const aIndex = positionSortOrder.indexOf(a.playerContract.player.position);
    const bIndex = positionSortOrder.indexOf(b.playerContract.player.position);

    if(aIndex > bIndex) return 1;
    if(aIndex < bIndex) return -1;
    return 0;
}

function changeSorter(a, b) {
    if(a.playerContract.player.CAChangeThisSeason > b.playerContract.player.CAChangeThisSeason) return 1;
    if(a.playerContract.player.CAChangeThisSeason < b.playerContract.player.CAChangeThisSeason) return -1;
    return 0;
}

export default function TrainingInterface(props) {
    const intl = useIntl();

    const { checkTutorial } = useTutorial();
    const isTutorial = checkTutorial('training', 0);

    const columns = [
        playerName(
            (playerReg) => playerReg.playerContract.player,
            (playerReg) => playerReg.playerContract,
            (playerReg) => playerReg,
            (playerReg) => playerReg.teamTacticPosition,
            undefined,
            undefined,
            undefined,
            undefined
        ),
        playerPosition(
            (playerReg) => playerReg.playerContract.player.position,
            false
        ),
        {
            name: <FontAwesomeIcon icon={faTag} />,
            selector: playerRegistration => (<FormattedMessage {...messages[`playerType_${playerRegistration.playerContract.player.type}`]} />),
            sortable: true,
            wrap: false,
            allowOverflow: false,
            center: true,
            compact: true,
            minWidth: '40px',
            maxWidth: '120px',
            grow: 2,
            hide: 600
        },
        {
            name: <FontAwesomeIcon icon={faBullseye} />,
            selector: playerRegistration => <span style={{padding: '0 5px'}}>{intl.formatMessage(messages.trainingFocus[playerRegistration.playerContract.trainingFocus])}</span>,
            sortable: true,
            wrap: false,
            allowOverflow: false,
            center: true,
            compact: true,
            minWidth: '40px',
            maxWidth: '120px',
            grow: 2
        },
        {
            name: <FontAwesomeIcon icon={faCakeCandles} />,
            selector: playerRegistration => playerRegistration.playerContract.player.age,
            sortable: true,
            wrap: false,
            center: true,
            allowOverflow: false,
            compact: true,
            grow: 1,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 500
        },
        {
            name: <FontAwesomeIcon icon={faStar} />,
            selector: playerRegistration => playerRegistration.playerContract.player.currentAbility,
            sortable: true,
            wrap: false,
            allowOverflow: false,
            center: true,
            compact: true,
            minWidth: '30px',
            maxWidth: '40px',
            grow: 1,
            conditionalCellStyles: [
                {
                    when: playerRegistration => !!playerRegistration.playerContract.player,
                    style: playerRegistration => ({
                        backgroundColor: hsvToRgb(ratingToHsv(playerRegistration.playerContract.player.currentAbility)),
                        color: getColor(hsvToRgb(ratingToHsv(playerRegistration.playerContract.player.currentAbility)).substr(1)),
                        fontWeight: '700'
                    })
                }
            ]
        },
        {
            name: <FontAwesomeIcon className={isTutorial === true ? 'glow' : ''} icon={faPlus} />,
            selector: playerRegistration => 
                <span style={{fontWeight: 'bold'}}>
                    {Math.abs(playerRegistration.playerContract.player.CAChangeThisSeason)}
                    {playerRegistration.playerContract.player.CAChangeThisSeason > 0 ?
                        <FontAwesomeIcon icon={faCaretUp} color='#21ba45' />
                        :
                        playerRegistration.playerContract.player.CAChangeThisSeason < 0 ?
                            <FontAwesomeIcon icon={faCaretDown} color='#DB3B30' />
                            :
                            null
                    }
                </span>,
            sortable: true,
            sortFunction: changeSorter,
            wrap: false,
            allowOverflow: true,
            center: true,
            compact: true,
            minWidth: '35px',
            maxWidth: '45px',
            grow: 1
        }
    ]

    return(
        <div>
            <DataTable
                columns={columns}
                data={props.playerRegistrations}
                isLoading={props.isLoading}
                placeholderCount={14}
                expandableRows
                expandableRowsComponent={TrainingExpander}
                expandableRowsComponentProps={{updateTrainingFocus: props.updateTrainingFocus, clubId: props.clubId}}
                defaultSortFieldId={7}
                defaultSortAsc={false}
                keyField='id'
            />
        </div>
    )
}

const desktopStyles = {
    wrapper: {
        backgroundColor: '#f2f2f2'
    },
    container: {
        maxWidth: '350px',
        margin: '0 auto',
        backgroundColor: '#FFF',
        padding: '0'
    },
    menu: {
        justifyContent: 'center'
    },
    form: {
        padding: '1em'
    }
}

const mobileStyles = {
    wrapper: {
        backgroundColor: '#f2f2f2'
    },
    container: {
        maxWidth: '350px',
        margin: '0 auto',
        backgroundColor: '#FFF'
    },
    menu: {
        justifyContent: 'center'
    },
    form: {
        padding: '0 1em'
    }
}

function TrainingExpander(props) {
    const [view, setView] = useState('focus');

    const compactView = useMediaQuery({
        query: '(max-width: 500px)'
    });

    const queryClient = useQueryClient();

    const styles = compactView || props.fullWidth === true ? mobileStyles : desktopStyles;

    const setNewPosition = useMutation(({playerContractId, newPosition}) =>
        axios.patch(`${process.env.REACT_APP_APPHOST}/playerContracts/${playerContractId}`, {
            newPosition: newPosition
        }), {
            onSuccess: () => {
                queryClient.invalidateQueries('getSquad');
            }
        }
    );

    const isOnLoan = props.data.playerContract.clubId !== props.clubId;

    return(
        <div style={styles.wrapper}>
            <div style={styles.container}>
                <Menu
                    secondary
                    widths={3}
                    style={{marginBottom: '0.5em'}}
                    className='expanderMenu'
                >
                    <Menu.Item
                        name='Attributes'
                        active={view==='attributes'}
                        onClick={() => setView('attributes')}
                    />
                    <Menu.Item
                        name='Focus'
                        active={view==='focus'}
                        onClick={() => setView('focus')}
                    />
                    <Menu.Item
                        name='Growth'
                        active={view==='stats'}
                        onClick={() => setView('stats')}
                    />
                </Menu>
                {view === 'focus' && 
                    <Form style={styles.form}>
                        {isOnLoan === true &&
                            <Message negative>
                                You cannot set training orders for loaned players
                            </Message>
                        }
                        <Form.Group widths='equal' style={{margin: 0}}>
                            <Form.Select
                                fluid
                                label='Focus'
                                options={props.data.playerContract.player.position !== 'GK' ? focusOptions : [focusOptions[1]]}
                                value={props.data.playerContract.trainingFocus}
                                onChange={(e, {value}) => props.updateTrainingFocus(props.data.playerContract.id, value)}
                                disabled={props.data.playerContract.newPosition !== null || isOnLoan === true}
                            />
                            <RetrainPosition
                                playerRegistration={props.data}
                                isOnLoan={isOnLoan}
                                setNewPosition={setNewPosition}
                            />
                        </Form.Group>
                    </Form>
                }
                {view === 'attributes' &&
                    <AttributeTable player={props.data} />
                }
                {view === 'stats' &&
                    <TrainingChart playerId={props.data.id} />
                }
            </div>
        </div>
    );
}

const focusOptions = [
    {
        key: 'defending',
        text: 'Defending',
        value: 'defend'
    },
    {
        key: 'balanced',
        text: 'Balanced',
        value: 'balanced'
    },
    {
        key: 'attacking',
        text: 'Attacking',
        value: 'create'
    },
    {
        key: 'finishing',
        text: 'Finishing',
        value: 'finish'
    }
];

const positionOptions = [
    "GK",
    "LB",
    "LWB",
    "CB",
    "RB",
    "RWB",
    "CDM",
    "LM",
    "CM",
    "RM",
    "LW",
    "CAM",
    "RW",
    "ST"
].map((position) => ({key: position, text: position, value: position}));

const messages = {
    playerNameColumnLabel: {
        id: 'club:squad:selectionView:playerNameColumn',
        defaultMessage: 'Name',
        description: 'Name of player name column in player selection table'
    },
    trainingFocus: {
        create: {
            id: 'player:trainingFocus:attack',
            defaultMessage: 'Attacking',
            description: 'Attacking training focus (passing, dribbling, etc)'
        },
        defend: {
            id: 'player:trainingFocus:defend',
            defaultMessage: 'Defending',
            description: 'Defending training focus (tackling, marking, etc)'
        },
        finish: {
            id: 'player:trainingFocus:finish',
            defaultMessage: 'Finishing',
            description: 'Finishing training focus (shooting)'
        },
        balanced: {
            id: 'player:trainingFocus:balanced',
            defaultMessage: 'Balanced',
            description: 'Balanced training focus (trains everything in proportion for the position)'
        }
    },
    playerType_defensive: {
        id: "playerType_defensive",
        defaultMessage: "Defensive",
        description: "Description for a player with high defensive attributes"
    },
    playerType_standard: {
        id: "playerType_standard",
        defaultMessage: "Standard",
        description: "Description for a player with balanced  attributes"
    },
    playerType_attacking: {
        id: "playerType_attacking",
        defaultMessage: "Attacking",
        description: "Description for a player with high attacking attributes"
    }
}