import { useEffect, useState } from "react";
import NarrowLayout from "../../../../common/components/NarrowLayout";
import TitledSegment from "../../../../common/components/TitledSegment";
import { generatePath, useHistory, useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { URL } from "../../../../common/lib/paths";
import PlayerTransferAuctionStatus from "../status";
import BudgetSummary from "../../../../club/components/common/BudgetSummary";
import TransferOffer from "../../common/TransferOffer";
import ContractLengthSelection from "../../../playerContract/common/new/ContractLengthSelection";
import ContractSummary from "../../../common/ContractSummary";
import ContractValue from "../../../playerContract/common/new/ContractValue";
import SalaryOffer from "../../../playerContract/common/new/SalaryOffer";
import { Button, Form, Grid, Message } from "semantic-ui-react";
import StaffBriefing from "../../../playerContract/common/new/StaffBriefing";
import { defineMessages, FormattedMessage } from "react-intl";

export default function CreatePlayerTransferAuctionBidPaidInterface(props) {
    const [salaryOffer, setSalaryOffer] = useState(null);
    const [transferOffer, setTransferOffer] = useState(null);
    const [selectedContractLengthIndex, setSelectedContractLengthIndex] = useState(null);
    const [isNegotiating, setIsNegotiating] = useState(false);

    const {playerTransferAuctionId} = useParams();
    const queryClient = useQueryClient();
    const history = useHistory();

    const submitBidMutation = useMutation(({playerTransferAuctionId, compensation, contractSalaryOffer, contractEndSeasonIdOffer}) =>
        axios.post(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionBidPaid`, {
            playerTransferAuctionId,
            compensation,
            contractSalaryOffer,
            contractEndSeasonIdOffer
        }), {
            onSuccess: (data) => {
                queryClient.invalidateQueries('getPlayerTransferAuction');
                history.push(generatePath(URL.playerTransferAuction, {playerTransferAuctionId}))
            }
        }
    );

    useEffect(() => {
        if(transferOffer === null && props.playerTransferAuctionPaid?.nextBidMinimum !== undefined) {
            setTransferOffer(props.playerTransferAuctionPaid.nextBidMinimum);
        }
    }, [props.playerTransferAuctionPaid?.nextBidMinimum]);

    const getSalaryDemandQuery = useQuery(
        ['getPlayerSalaryDemand', props.player?.id, selectedContractLengthIndex],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${props.player.id}/contractDemands?proposedContractEndSeasonId=${props.contractLengthOptions[selectedContractLengthIndex].seasonId}`),
        {
            enabled: selectedContractLengthIndex !== null && props.player?.id !== undefined
        }
    );

    useEffect(() => {
        if(salaryOffer === null && getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand !== undefined) {
            setSalaryOffer(getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand);
        }
    }, [getSalaryDemandQuery.data?.data.response]);

    useEffect(() => {
        if(selectedContractLengthIndex === null && props.defaultContractLengthIndex !== undefined) {
            setSelectedContractLengthIndex(props.defaultContractLengthIndex);
        }
    }, [props.defaultContractLengthIndex]);

    if(props.isInterested === false) {
        return(
            <Message negative>
                <Message.Header>Player not interested</Message.Header>
                <Message.Content>You cannot submit a transfer offer for this player because they are not currently interested in joining your club.</Message.Content>
            </Message>
        );
    }

    return(
        <NarrowLayout>
            <PlayerTransferAuctionStatus
                isLoading={props.isLoading}
                playerTransferAuction={props.playerTransferAuctionPaid}
            />
            <BudgetSummary
                finances={props.finances}
                isEditable={true}
            />
            <TitledSegment
                title='Auction bid'
            >
                <Form
                    isSubmitting={submitBidMutation.isLoading}
                    onSubmit={handleSubmit}
                >
                    <TransferOffer
                        transferOffer={transferOffer}
                        setTransferOffer={setTransferOffer}
                        resetTransferOffer={resetTransferOffer.bind(this)}
                    />
                    <ContractLengthSelection
                        isLoading={props.isLoading}
                        contractLengthOptions={props.contractLengthOptions}
                        selectedContractLengthIndex={selectedContractLengthIndex}
                        setSelectedContractLengthIndex={handleSelectContractEndSeason.bind(this)}
                    />
                    <ContractSummary
                        salary={getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand}
                        salaryLabel='Salary demand'
                        contractEndDate={getSalaryDemandQuery.data?.data.response.proposedContractEndSeason.endDate}
                        contractExpiryLabel='Contract expiry'
                    />
                    <ContractValue
                        baseSalaryDemand={getSalaryDemandQuery.data?.data.response.baseSalaryDemand || 0}
                        salaryOffer={salaryOffer || 0}
                        isLocked={getSalaryDemandQuery.data?.data.response.baseSalaryDemand === null}
                    />
                    {isNegotiating === true ?
                        <>
                            <SalaryOffer
                                salaryOffer={salaryOffer}
                                setSalaryOffer={setSalaryOffer}
                                resetSalaryOffer={resetSalaryOffer.bind(this)}
                                increment={getSalaryDemandQuery.data?.data.response.salaryIncrement}
                            />
                            <Button
                                primary
                                fluid
                                disabled={submitBidMutation.isLoading}
                                loading={submitBidMutation.isLoading}
                                type='submit'
                                size='tiny'
                            >
                                Submit offer
                            </Button>
                        </>
                        :
                        <Grid className='gridReset'>
                            <Grid.Row columns={2} style={{padding: 0}}>
                                <Grid.Column style={{paddingRight: '0.5em', paddingLeft: 0}}>
                                    <Button
                                        size='tiny'
                                        fluid
                                        onClick={() => setIsNegotiating(true)}
                                    >
                                        Negotiate
                                    </Button>
                                </Grid.Column>
                                <Grid.Column style={{paddingLeft: '0.5em', paddingRight: 0}}>
                                    <Button
                                        size='tiny'
                                        fluid
                                        primary
                                        onClick={handleSubmit.bind(this)}
                                    >
                                        Submit offer
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    }
                </Form>
                {submitBidMutation.error &&
                    <Message negative>
                        <FormattedMessage
                            {...submitBidErrorMessages[submitBidMutation.error?.response?.data?.response?.key || 'unknown']}
                            values={{key: submitBidMutation.error?.response?.data?.response?.key || '-1'}}
                        />
                    </Message>
                }
            </TitledSegment>
            <TitledSegment
                title='Staff briefing'
            >
                <StaffBriefing
                    isLoading={props.isLoading}
                    negotiationFactors={props.negotiationFactors}
                    existingContract={props.existingContract}
                />
            </TitledSegment>
        </NarrowLayout>
    );

    function handleSelectContractEndSeason(endSeasonId) {
        if(endSeasonId === selectedContractLengthIndex) return;

        setSelectedContractLengthIndex(endSeasonId);
        setSalaryOffer(null);
    }

    function resetTransferOffer() {
        setTransferOffer(props.playerTransferAuctionPaid.nextBidMinimum);
    }

    function resetSalaryOffer() {
        setSalaryOffer(getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand);
    }

    function handleSubmit() {
        submitBidMutation.mutate({
            playerTransferAuctionId: parseInt(playerTransferAuctionId),
            compensation: parseInt(transferOffer),
            contractSalaryOffer: parseInt(salaryOffer),
            contractEndSeasonIdOffer: parseInt(props.contractLengthOptions[selectedContractLengthIndex].seasonId)
        });
    }
}

const submitBidErrorMessages = defineMessages({
    'unknown': {
        id: 'player:loanOffer:create:error:general',
        defaultMessage: 'An unknown error occured, please report this ({key})',
        description: 'An unknown error occured'
    },
    'EXPIRED': {
        id: 'playerTransferAuctionBid:expired',
        defaultMessage: 'You cannot bid for this player because the auction has ended',
        description: 'Error message shown if a bid is made but the auction has already ended'
    },
    'INSUFFICIENT_COMPENSATION': {
        id: 'playerTransferAuctionBid:expired',
        defaultMessage: 'Your bid is not high enough, try again with a higher bid',
        description: 'Error message shown if a bid but the offer is too low'
    },
    'CONTRACT_VALUE_LOW': {
        id: 'contractValueLow',
        defaultMessage: 'The player has rejected your contract offer, try increasing your offer',
        description: 'Error message shown when player rejects contract as it is not high enough value'
    },
    'INSUFFICIENT_TRANSFER_BUDGET': {
        id: 'player:transferOffer:create:error:transferBudget',
        defaultMessage: 'Your transfer budget is not sufficient for this offer. Try lowering your bid.',
        description: 'Error message shown if a transfer bid cannot be submitted due to the club not being able to afford the offer (transfer budget)'
    },
    'INSUFFICIENT_WAGE_BUDGET': {
        id: 'player:transferOffer:create:error:wageBudget',
        defaultMessage: 'Your wage budget is not sufficient for this offer. Try lowering your contract offer.',
        description: 'Error message shown if a transfer bid cannot be submitted due to the club not being able to afford the offer (Wage budget)'
    },
    'TOO_MANY_PLAYERS': {
        id: 'player:transferOffer:create:error:tooManyPlayers',
        defaultMessage: 'You cannot bid for this player because you have too many players',
        description: 'Error message shown if a transfer bid cannot be submitted because the club already has the maximum number of players'
    },
    'REPUTATION_MISMATCH': {
        id: 'player:transferOffer:create:error:reputationAbs',
        defaultMessage: 'This player is not interested in joining your club',
    },
    'FREQUENCY_ERROR': {
        id: 'player:transferOffer:create:error:pendingBid',
        defaultMessage: 'You cannot bid again for this player because you are already the highest bidder'
    },
    'MODIFY_RESOURCE_PERMISSION_DENIED': {
        id: 'player:transferOffer:create:error:permission',
        defaultMessage: 'You cannot bid for this player because they already play for your club'
    },
    'PLAYER_RETIRING': {
        id: 'player:transferOffer:create:error:playerRetiring',
        defaultMessage: 'You cannot bid for this player because they are retired or retiring',
        description: 'Error message shown if a transfer bid cannot be submitted because the player is retired/retiring'
    },
    'LENGTH_MAX_CONSTRAINT': {
        id: 'player:transferOffer:create:error:maxContractLengthExceeded',
        defaultMessage: 'The contract you have offered is too long. Please try again with a shorter offer.'
    },
    'CONTRACT_VALUE_HIGH': {
        id: 'player:transferOffer:create:error:contractValueHigh',
        defaultMessage: 'The board feel this contract offer is too generous . Please try again with a lower contract offer.'
    }
});