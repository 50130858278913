import { Button } from "semantic-ui-react";
import WideLayout from "../../common/components/WideLayout";
import FilterButton from "../../player/filter/button/interface";
import { filterStateReducer, initialFilterState, isFilterActive } from "../../player/filter/lib";
import usePersistedState from "../../common/lib/usePersistedState";
import SUIIconShim from "../../common/components/SUIIconShim";
import useCompare from "../../player/compare/useCompare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons";
import PlayerListTransferView from "../../player/lists/PlayerListTransferView";
import FavPlayersToolbarItem from '../toolbar/FavPlayers';
import FavClubsToolbarItem from '../toolbar/FavClubs';
import FavStaffToolbarItem from '../toolbar/FavStaff';
import useGetPlayers from "../../transfer/playerSearch/useGetPlayers";
import { useEffect } from "react";
import FavRetiredToolbarItem from "../toolbar/FavRetired";

const PERSISTED_STATE_KEY = 'favPlayersFilter';

export default function FavPlayers(props) {
    const {persistedState, updatePersistedState} = usePersistedState(PERSISTED_STATE_KEY, initialFilterState);
    const {addPlayerIds} = useCompare();

    useEffect(() => {
        props.setToolbar({
            title: 'Favourite players',
            navigation: [
                <FavPlayersToolbarItem key={1} />,
                <FavClubsToolbarItem key={2} />,
                <FavStaffToolbarItem key={3} />,
                <FavRetiredToolbarItem key={4} />
            ]
        });
    }, []);

    const {isLoading, data} = useGetPlayers(persistedState, props.manager.club?.maxPlayerReputationAbs, true, true);

    useEffect(() => {
        if(
            data?.data?.response.length === 0
        ) {
            updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage', isMaxPage: true}));
        }
    }, [data?.data?.response.length]);

    return(
        <WideLayout>
            <FilterButton
                loading={isLoading}
                isFilterActive={isFilterActive(persistedState)}
                persistedStateKey={PERSISTED_STATE_KEY}
            />
            <Button
                disabled={isLoading}
                color='black'
                style={{backgroundColor: 'rgba(15, 16, 17)'}}
                floated='right'
                icon
                onClick={() => addPlayerIds(data.data.response.map((player) => player.id))}
            >
                <SUIIconShim>
                    <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} transform={{rotate: 45}} />
                </SUIIconShim>
            </Button>
            <PlayerListTransferView
                isLoading={isLoading}
                players={data?.data?.response}
            />
            <Button.Group>
                <Button
                    icon
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage'}))}
                    color='black'
                    disabled={persistedState.currentPage === 1}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </SUIIconShim>
                </Button>
                <Button
                    disabled
                    className="opacity-override"
                    color='black'
                    style={{width: '10em'}}
                >
                    Page {persistedState.currentPage}
                </Button>
                <Button
                    icon
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'nextPage', value: {fromCARank: data.data?.response[data.data?.response.length-1].CARank}}))}
                    color='black'
                    disabled={persistedState.currentPage === persistedState.maxPage}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </SUIIconShim>
                </Button>
            </Button.Group>
        </WideLayout>
    )
}