import { FormattedPlural } from "react-intl";
import { Button } from "semantic-ui-react";

export default function ContractLengthSelection(props) {
    if(!!props.isLoading) return null;

    return(
        <>
            <Button.Group widths={5} size='mini' className='contrast'>
                {props.contractLengthOptions.map((option, index) => (
                    <Button
                        active={props.selectedContractLengthIndex === index}
                        onClick={() => props.setSelectedContractLengthIndex(index)}
                        style={{paddingLeft: 0, paddingRight: 0}}
                    >
                        <p style={{margin: 0, fontSize: '12px'}}>{option.length}</p>
                        <p style={{fontWeight: 900, textTransform: 'uppercase', fontSize: '8px'}}><FormattedPlural value={option.length} one={'season'} other={'seasons'} /></p>
                    </Button>
                ))}
            </Button.Group>
        </>
    );
}