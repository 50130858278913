import {Grid, Icon} from 'semantic-ui-react';
import RatingRoundall from '../../../player/common/RatingRoundall2';
import shirtGK from './shirt2gk.png';
import shirt from './shirt2.png';
import assistIcon from '../../../player/common/assists-icon.png';
import outOfPositionPenalties from './outOfPositionPenalties';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faRightLeft, faSuitcaseMedical } from '@fortawesome/free-solid-svg-icons';
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep';
import { positionColors } from '../../lib/color';

export default function PlayerShirt(props) {
    let styles = {
        condition: {},
        column: {},
        positionLabel: {},
        label: {},
        image: {},
    }

    if(props.condition !== undefined) {
        if(props.condition < 80) {
            styles.condition.color = '#db2828';
        } else if(props.condition < 90) {
            styles.condition.color = '#f27b1d';
        }
    }

    if(props.position !== 'GK') {
        if(props.reverse === true) {
            styles.column.transform = `translateY(${(props.mentality === 'attacking' ? '-20px' : props.mentality === 'defensive' ? '20px' : '0px')})`;
        } else {
            styles.column.transform = `translateY(${(props.mentality === 'attacking' ? '-20px' : props.mentality === 'defensive' ? '20px' : '0px')})`;
        }
    } else {
        styles.positionLabel.color = 'rgba(255, 255, 255, 0.9)';
        styles.condition.color = 'rgba(255, 255, 255, 0.9)';
    }

    if(props.occupied && props.position !== props.playerNaturalPosition) {
        const penaltySize = outOfPositionPenalties[props.playerNaturalPosition]?.[props.position] || 4;

        switch(penaltySize) {
            case 1:
                styles.label.backgroundColor = '#fbe80a';
                styles.label.color = 'rgba(0, 0, 0, 0.87)';
                break;
            case 2:
                styles.label.backgroundColor = '#f2b61d';
                styles.label.color = 'rgba(0, 0, 0, 0.87)';
                break;
            case 3:
                styles.label.backgroundColor = '#f27b1d';
                styles.label.color = 'rgba(0, 0, 0, 0.87)';
                break;
            case 4:
                styles.label.backgroundColor = '#db2828';
                break;
        }
    }

    if(props.injured === true) {
        styles.label.backgroundColor = '#db2828';
        styles.label.color = 'rgba(255, 255, 255, 0.9)';
    }
    
    if(props.occupied === false || props.injured === true) {
        styles.image.opacity = 0.7;
    }

    styles = merge(cloneDeep(baseStyles), styles);

    return(
        <Grid.Column width={3} style={styles.column} onClick={props.handleShirtClick} className={props.glow && 'glow'}>
            <img src={props.position === 'GK' ? shirtGK : shirt} style={styles.image} />
            <span style={styles.positionLabel}>{props.position}</span>
            {props.injured === false ?
                <>
                    <span style={styles.rating}>
                        {props.rating !== undefined ?
                            <RatingRoundall rating={props.rating}  />
                            :
                            null
                        }
                    </span>
                    {props.condition !== undefined ?
                        <span style={styles.condition}>
                            {props.condition}%
                        </span>
                        :
                        !!props.goals || !!props.assists ?
                            <span style={styles.condition}>
                                {Array.apply(null, Array(props.goals)).map((goal, i) => 
                                    <FontAwesomeIcon icon={faFutbol} key={`g_${i}`} style={{marginRight: '0.05rem'}} />
                                )}
                                {Array.apply(null, Array(props.goals)).map((goal, i) => 
                                    <img src={assistIcon} style={{marginRight: '0.2rem', width: '12px', height: '12px', verticalAlign: 'sub'}} key={`a_${i}`} />
                                )}
                            </span>
                            :
                            null
                    }
                </>
                :
                null
            }
            {props.injured === true ?
                <span style={styles.injury}>
                    <FontAwesomeIcon icon={faSuitcaseMedical} color='#DB2828' size='lg' />
                </span>
                :
                null
            }
            {props.playerName ?
                <p style={{...styles.label, backgroundColor: '#000'}}>{props.wasSubbed === true ? <FontAwesomeIcon icon={faRightLeft} size='sm' color='#DB2828' /> : null}{props.playerName}</p>
                :
                null
            }
            <div style={styles.pusher}>&nbsp;</div>
        </Grid.Column>
    );
}

//positionColors[props.position]

let baseStyles = {
    pusher: {
        height: '22px'
    },
    image: {
        width: '100%',
        opacity: 1
    },
    column: {
        position: 'relative',
        padding: '0 0.5em',
        cursor: 'pointer'
    },
    positionLabel: {
        position: 'absolute',
        top: '5%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        color: 'rgba(0,0,0,.87)'
    },
    condition: {
        position: 'absolute',
        top: '49%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        color: 'rgba(0,0,0,.87)'
    },
    rating: {
        position: 'absolute',
        top: '25%',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    label: {
        color: "rgba(255, 255, 255, 0.9)",
        display: "block",
        margin: "0 auto",
        padding: "1px 4px",
        textAlign: 'center',
        fontSize: '80%',
        fontWeight: "bold",
        borderRadius: "25px",
        maxWidth: '100%',
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        position: 'absolute',
        right: '50%',
        transform: 'translateX(50%)'
    },
    injury: {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translateX(-50%)'
    }
}