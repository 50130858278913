import {Table} from 'semantic-ui-react';
import {FormattedMessage, FormattedDate, FormattedPlural} from 'react-intl';
import Currency from './Currency';
import {default as getContractLength} from '../../player/common/ContractLength';

export default function ContractTable(props) {
    if(props.isLoading) return null;

    if(props.remainingContractLength !== undefined) {
        var contractLength = props.remainingContractLength;
    } else {
        var contractLength = getContractLength(props.contractExpiry, props.currentSeason.endDate);
    }

    return(
        <Table unstackable definition compact collapsing>
            <Table.Body>
                <Table.Row>
                    <Table.Cell><FormattedMessage {...messages.contractWageLabel} /></Table.Cell>
                    <Table.Cell><Currency value={props.contractSalary} />/season</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell><FormattedMessage {...messages.contractLengthLabel} /></Table.Cell>
                    <Table.Cell>
                        <FormattedMessage
                            {...messages.contractLengthValue}
                            values={{
                                date: (
                                    <FormattedDate
                                        value={props.contractExpiry}
                                        day='numeric'
                                        month='short'
                                        year='numeric'
                                        timeZone='UTC'
                                    />
                                ),
                                length: <FormattedPlural
                                    value={contractLength}
                                    zero={
                                        <FormattedMessage {...messages.thisSeasonText} values={{length: contractLength}} />
                                    }
                                    one={
                                        <FormattedMessage {...messages.nextSeasonText} values={{length: contractLength}} />
                                    }
                                    other={
                                        <FormattedMessage {...messages.otherSeasonText} values={{length: contractLength}} />
                                    }
                                />
                            }}    
                        />
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

const messages = {
    contractWageLabel: {
        id: "Layout_PersonContractOffer.contractWageLabel",
        defaultMessage: "Wage",
        description: "Label for the table cell which contains the person's contract wage/salary demands"
    },
    contractLengthLabel: {
        id: "Layout_PersonContractOffer.contractLengthLabel",
        defaultMessage: "Expiry",
        description: "Label for the table cell which contains the person's contract length demands"
    },
    contractLengthValue: {
        id: "ContractOfferInterface:contractLengthDemandsDescriptor",
        defaultMessage: "{date} ({length})"
    },
    thisSeasonText: {
        id: "Layout_PersonContractOffer.seasonPluralText",
        defaultMessage: "This season",
        description: "TODO"
    },
    nextSeasonText: {
        id: "Layout_PersonContractOffer.seasonPluralText",
        defaultMessage: "Next season",
        description: "TODO"
    },
    otherSeasonText: {
        id: "Layout_PersonContractOffer.seasonPluralText",
        defaultMessage: "{length} seasons",
        description: "Plural form of the word 'season'. Lowercase."
    }
}