import { Grid, Placeholder, Segment } from "semantic-ui-react";

export default function LoadingCards(props) {
    return(
        <>
            {props.keys.map((i) =>
                <Segment key={i}>
                    <Placeholder style={{width: '50%', margin: '0 auto'}}>
                        <Placeholder.Header>
                            <Placeholder.Line length='full' />
                        </Placeholder.Header>
                    </Placeholder>
                    <Grid style={{marginTop: '0.5em'}}>
                        <Grid.Row>
                            <Grid.Column width={6} style={{textAlign: 'right', padding: 0}}>
                                <Placeholder style={{width: '80%', margin: '0 auto'}}>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                            </Grid.Column>
                            <Grid.Column width={4} style={{textAlign: 'center', padding: 0}}>
                                <Placeholder style={{width: '60%', margin: '0 auto'}}>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                            </Grid.Column>
                            <Grid.Column width={6} style={{padding: 0}}>
                                <Placeholder style={{width: '80%', margin: '0 auto'}}>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            )}
        </>
    )
}