import { Grid, Statistic } from "semantic-ui-react";
import { FormattedDate } from "react-intl";
import Currency from "../../common/components/Currency";

export default function ContractSummary(props) {
    let classNames = ['newStatistic'];

    if(props.small !== false) {
        classNames.push('small')
    }

    classNames = classNames.join(' ');

    let rowStyle = {}

    if(props.noPadding === true) {
        rowStyle.padding = 0;
    }

    return(
        <Grid style={{margin: 0}}>
            <Grid.Row columns={2} style={rowStyle}>
                <Grid.Column style={{padding: 0}}>
                    <Statistic className={classNames}>
                        <Statistic.Value>
                            <span><Currency value={props.salary || 0} /></span>
                            <span style={{fontSize: '10px'}}>/season</span>
                        </Statistic.Value>
                        <Statistic.Label>
                            {props.salaryLabel}
                        </Statistic.Label>
                    </Statistic>
                </Grid.Column>
                <Grid.Column style={{padding: 0}}>
                    <Statistic className={classNames}>
                        <Statistic.Value>
                            <span><FormattedDate timeZone="UTC" value={props.contractEndDate || 0} month='short' day='numeric' /></span>
                        </Statistic.Value>
                        <Statistic.Label>
                            {props.contractExpiryLabel}
                        </Statistic.Label>
                    </Statistic>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}