import { defineMessages } from "react-intl";

export default defineMessages({
    1: {
        id: 'leaguePositions:first',
        defaultMessage: '{position}st position'
    },
    2: {
        id: 'leaguePositions:second',
        defaultMessage: '{position}nd position'
    },
    3: {
        id: 'leaguePositions:third',
        defaultMessage: '{position}rd position'
    },
    4: {
        id: 'leaguePositions:forth',
        defaultMessage: '{position}th position'
    },
    5: {
        id: 'leaguePositions:fifth',
        defaultMessage: '{position}th position'
    },
    6: {
        id: 'leaguePositions:sixth',
        defaultMessage: '{position}th position'
    },
    7: {
        id: 'leaguePositions:seventh',
        defaultMessage: '{position}th position'
    },
    8: {
        id: 'leaguePositions:eight',
        defaultMessage: '{position}th position'
    },
    9: {
        id: 'leaguePositions:ninth',
        defaultMessage: '{position}th position'
    },
    10: {
        id: 'leaguePositions:tenth',
        defaultMessage: '{position}th position'
    }
})