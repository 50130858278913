import { FormattedMessage, defineMessages } from "react-intl"
import { clubName } from "../../common/dataTableColumns/club/clubName"
import { seasonName } from "../../common/dataTableColumns/date"
import { statColumn } from "../../common/dataTableColumns/player/PTLSStat"
import WideLayout from "../../common/components/WideLayout"
import DataTable from "../../common/components/DataTable"

export default function CupSeasonHistoryInterface(props) {
    const columns = [
        seasonName(
            'seasonName',
            (CS) => CS.season.startDate,
            false,
            undefined,
            true
        ),
        clubName(
            (CS) => CS.TCS[0].team.club,
            false
        ),
        statColumn(
            'gamesPlayed',
            (CS) => CS.TCS[0].gamesPlayed,
            <FormattedMessage {...messages.gamesPlayedLabel} />,
            500
        ),
        statColumn(
            'gamesWon',
            (CS) => CS.TCS[0].gamesWon,
            <FormattedMessage {...messages.gamesWonLabel} />,
            580
        ),
        statColumn(
            'gamesDrawn',
            (CS) => CS.TCS[0].gamesDrawn,
            <FormattedMessage {...messages.gamesDrawnLabel} />,
            580
        ),
        statColumn(
            'goalsScored',
            (CS) => CS.TCS[0].goalsScored,
            <FormattedMessage {...messages.goalsScoredLabel} />,
            750
        ),
        statColumn(
            'goalsConceded',
            (CS) => CS.TCS[0].goalsConceded,
            <FormattedMessage {...messages.goalsConcededLabel} />,
            750
        ),
        statColumn(
            'goalDifference',
            (CS) => (CS.TCS[0].goalsScored+(CS.TCS[0].goalsConceded*-1)),
            <FormattedMessage {...messages.goalDifferenceLabel} />,
            340
        )
    ];

	return(
		<WideLayout>
			<DataTable
				columns={columns}
				data={props.cupSeasons}
				isLoading={props.isLoading}
				placeholderCount={5}
			/>
		</WideLayout>
	);
}

const messages = defineMessages({
	seasonLabel: {
		id: "Layout_ClubHistory.seasonLabel",
		defaultMessage: "Season",
		description: "Header for the column in the club history which states the season number"
	},
	gamesPlayedLabel: {
		id: "Layout_LeagueTable.playedLabel",
		defaultMessage: "P",
		description: "Header for the column in the league table which states how many games the club has played. 6 characters maximum (abbreviations allowed)"
	},
	gamesWonLabel: {
		id: "league:table:gamesWonLabel",
		defaultMessage: "W",
		description: "Header for the column in the league table which states how many games the club has won. 6 characters maximum (abbreviations allowed)"
	},
	gamesDrawnLabel: {
		id: "league:table:gamesDrawnLabel",
		defaultMessage: "D",
		description: "Header for the column in the league table which states how many games the club has drawn. 6 characters maximum (abbreviations allowed)"
	},
	goalsScoredLabel: {
		id: 'league:table:goalsScoredLabel',
		defaultMessage: 'F',
		description: 'Header for the column in the league table which states how many goals a team has scored'
	},
	goalsConcededLabel: {
		id: 'league:table:goalsConcededLabel',
		defaultMessage: 'A',
		description: 'Header for the column in the league table which states how many goals a team has conceded'
	},
	goalDifferenceLabel: {
		id: "Layout_LeagueTable.goalDifferenceLabel",
		defaultMessage: "+/-",
		description: "Header for the column in the league table which states the difference between how many goals the club has scored and conceded. 6 characters maximum (abbreviations allowed)"
	},
});