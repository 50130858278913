import { useQuery } from "@tanstack/react-query";
import LoadHighlights from "./LoadHighlights";
import { useParams } from "react-router";
import axios from "axios";
import { Loader } from "semantic-ui-react";
import { useEffect } from "react";

//Takes a leagueFixtureId or cupFixtureId and gets all the corresponding highlightIds for the fixture
export default function FindHighlights(props) {
    const {fixtureId} = useParams();

    const findHighlightsQuery = useQuery(
        ['findHighlights', props.type, fixtureId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/${props.type}Fixtures/${fixtureId}/highlights`),
        {
            enabled: false
        }
    );

    useEffect(() => {
        findHighlightsQuery.refetch(); //only run query once
    }, []);

    if(findHighlightsQuery.isLoading) {
        return(
            <Loader active />
        );
    } else {
        return(
            <LoadHighlights
                highlights={findHighlightsQuery.data.data.response}
                type={props.type}
            />
        )
    }
}