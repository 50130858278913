import colours from "../colours";

const SimpleRatingBar = ({ value }) => {
    // Ensure the value is between 0 and 100
    const clampedValue = Math.max(0, Math.min(value, 100));
  
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        {/* Rating bar container */}
        <div
          style={{
            position: "relative",
            height: "10px",
            backgroundColor: colours.grey,
            borderRadius: "5px",
            margin: "20px auto",
            width: "calc(100% - 60px)",
          }}
        >
          {/* Marker for the 0% position */}
          <div
            style={{
              position: "absolute",
              left: "0%",
              top: "50%",
              transform: "translateY(-50%)",
              height: "20px",
              width: "2px",
              backgroundColor: "black",
            }}
          />
          {/* Marker for the 50% position */}
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translateY(-50%)",
              height: "20px",
              width: "2px",
              backgroundColor: "black",
            }}
          />
          {/* Marker for the 100% position */}
          <div
            style={{
              position: "absolute",
              right: "0%",
              top: "50%",
              transform: "translateY(-50%)",
              height: "20px",
              width: "2px",
              backgroundColor: "black",
            }}
          />
          {/* Marker for the input value */}
          <div
            style={{
              position: "absolute",
              left: `${clampedValue}%`,
              top: "50%",
              transform: "translate(-50%, -50%)",
              height: "15px",
              width: "15px",
              backgroundColor: colours.navy,
              borderColor: '#FFF',
              borderRadius: "50%",
            }}
          />
        </div>
        {/* Labels */}
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: "0 auto" }}>
          <span className='boldLabel large'>Defensive</span>
          <span className='boldLabel large'>Attacking</span>
        </div>
      </div>
    );
  };
  
  export default SimpleRatingBar;