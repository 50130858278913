import { Canvas, useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

export default function ClubFaciltiesViewer(props) {
    const facilities = useLoader(GLTFLoader, `${process.env.PUBLIC_URL}/facilities/${props.rating}_${props.isUnderConstruction ? 'construction' : 'constructed'}.glb`, (loader) => {
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/')
        loader.setDRACOLoader(dracoLoader)
      });

    return(
        <div className='canvasContainer'>
            <Canvas
                camera={{
                    fov: 24,
                    near: 1,
                    far: 1000,
                    position: [120, 60, 0]
                }}
            >
                <ambientLight color='white' intensity={0.6} />
                <directionalLight color='white' intensity={0.6} position={[0, 1, 0]} />
                <OrbitControls
                    autoRotate={props.rotation}
                    autoRotateSpeed={3}
                    maxPolarAngle={Math.PI/2.25}
                    minPolarAngle={Math.PI/4}
                    minDistance={50}
                    maxDistance={150}
                />
                <primitive
                    object={facilities.scene}
                />
            </Canvas>
        </div>
    )
}