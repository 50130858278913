import { faCakeCandles, faFlag, faShieldHalved, faTag, faUserTie } from "@fortawesome/free-solid-svg-icons";
import TitledSegment from "../../common/components/TitledSegment";
import { Grid, Placeholder, Table } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShortenedPlayerName from "../../player/common/ShortenedName";
import { URL } from "../../common/lib/paths";
import StaffRoleName from "../../staff/common/RoleName";
import NationGroupLinkWithFlag from "../../nationGroup/common/NationGroupLinkWithFlag";
import CityLink from "../../city/common/CityLink";
import { FormattedDate } from "react-intl";
import { generatePath, Link } from "react-router-dom";

export default function RetiredStaffInterface(props) {
    return(
        <TitledSegment
            title={!props.isLoading && `${props.staff.firstName} ${props.staff.lastName}`}
            icon={faUserTie}
            noPadding={true}
        >
            <Grid style={{width: '100%', margin: 0, padding: '0.5em 0 0.5em 0'}}>
                <Grid.Row style={{padding: 0}}>
                    <Grid.Column width={8}>
                        <Table basic='very' compact='very' unstackable style={{width: '100%'}}>
                            <Table.Body>
                                {getProfileRow(faTag, (staff) => <StaffRoleName role={staff.role} />)}
                                {getProfileRow(faFlag, (staff) => <NationGroupLinkWithFlag shortcode={staff.nation.nationGroupShortcode} />)}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Table basic='very' compact='very' unstackable style={{width: '100%'}}>
                            <Table.Body>
                                {getProfileRow(faCakeCandles, (staff) => <span>{staff.ageAtRetirement} years old</span>)}
                                {getProfileRow(faShieldHalved, (staff) => <CityLink city={staff.city} />)}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                {props.isLoading === false &&
                    <Grid.Row style={{paddingBottom: 0}}>
                        <Grid.Column width={16}>
                            <p>
                                <ShortenedPlayerName player={props.staff} /> retired on <FormattedDate value={props.staff.retirementSeason.endDate} timeZone="UTC" />
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                }
                {props.isLoading === false && !!props.staff.retiredPlayerId &&
                    <Grid.Row style={{paddingBottom: 0}}>
                        <Grid.Column width={16}>
                            <p>
                                <ShortenedPlayerName player={props.staff} /> was a former player. <Link to={{pathname: generatePath(URL.retiredPlayerProfile, {playerId: props.staff.retiredPlayerId})}}>Click here</Link> to view their player profile.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                }
            </Grid>
        </TitledSegment>
    );

    function getProfileRow(icon, getValue) {
        return(
            <Table.Row>
                <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={icon} /></Table.Cell>
                <Table.Cell>
                    {props.isLoading ?
                        <Placeholder><Placeholder.Line length='short' style={{height: 'unset'}} /></Placeholder>
                        :
                        getValue(props.staff)
                    }
                </Table.Cell>
            </Table.Row>
        )
    }
}