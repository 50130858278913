import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { generatePath, Redirect, useParams } from "react-router";
import { URL } from "../../common/lib/paths";

/*

    Purpose of this component is to resolve a city ID to a club ID and redirect to club profile

    Use case: view original club of a player without having to join original club (being lazy)

*/

export default function CityProfile(props) {
    const {cityId} = useParams();

    const getCityQuery = useQuery(
        ['getCity', cityId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/cities/${cityId}`)
    );

    if(!!getCityQuery.isLoading) {
        return null
    } else {
        return(
            <Redirect to={{pathname: generatePath(URL.clubProfile, {clubId: getCityQuery.data?.data.response.clubId})}} />
        )
    }
}