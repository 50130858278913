import {Table, Menu, Container, Dropdown} from 'semantic-ui-react';
import {useLocation, useHistory, Link, generatePath} from 'react-router-dom';

import PlayerLink from '../../../player/common/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faAward, faBriefcaseMedical, faFutbol, faKitMedical, faPeopleArrows, faSquare, faStreetView, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import pitchSectionPositions from '../../../common/lib/player';
import { URL } from '../../../common/lib/paths';

export default function Ratings(props) {
    const history = useHistory();
    const location = useLocation();

    const selectedSide = location.state?.side || 'home';
    const playerStatIndex = props.playerStatData[selectedSide].map((playerStat) => playerStat.playerIndex);

    return(
        <>
            <Container fluid textAlign='center' style={{marginTop: '1em'}}>
                <Menu compact widths={2} size='small' style={{maxWidth: '200px', marginBottom: '1em'}}>
                    <Menu.Item link active={location.state?.side === 'home'} onClick={() => history.replace({pathname: location.pathname, state: {view: 'ratings', side: 'home', playerStat: location.state?.playerStat}})} className='menuItemPaddingFix' style={{display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.homeClubName}</Menu.Item>
                    <Menu.Item link active={location.state?.side === 'away'} onClick={() => history.replace({pathname: location.pathname, state: {view: 'ratings', side: 'away', playerStat: location.state?.playerStat}})} className='menuItemPaddingFix' style={{display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.awayClubName}</Menu.Item>
                </Menu>
                <Dropdown
                    selection
                    value={location.state?.playerStat || 'rating'}
                    onChange={
                        (e, {value}) => history.replace(
                            {
                                pathname: location.pathname,
                                state: {
                                    view: 'ratings',
                                    side: location.state?.side,
                                    playerStat: value
                                }
                            }
                        )
                    }
                    options={[
                        {
                            key: 'rating',
                            text: 'Match performance',
                            value: 'rating'
                        },
                        {
                            key: 'chancesCreated',
                            text: 'Chances created',
                            value: 'chancesCreated'
                        },
                        {
                            key: 'shots',
                            text: 'Shots',
                            value: 'shots'
                        },
                        {
                            key: 'shotsOnTarget',
                            text: 'Shots on target',
                            value: 'shotsOnTarget'
                        },
                        {
                            key: 'shotsOnTargetP',
                            text: 'Shots on target %',
                            value: 'shotsOnTargetP'
                        },
                        {
                            key: 'shotConversion',
                            text: 'Shot conversion',
                            value: 'shotConversion'
                        },
                        {
                            key: 'saves',
                            text: 'Saves',
                            value: 'saves'
                        },
                    ]}
                />
            </Container>
            <Table basic='very' compact='very' unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell><FontAwesomeIcon icon={faStreetView} /></Table.HeaderCell>
                        <Table.HeaderCell>Player</Table.HeaderCell>
                        <Table.HeaderCell><FontAwesomeIcon icon={faThumbsUp} /></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.tacticData[selectedSide].positions.map((tacticPosition) => 
                        tacticPosition.playerIndexes.map((playerIndex, i) => {

                            const player = props.playerData[selectedSide][playerIndex];
                            const playerStats = props.playerStatData[selectedSide][playerStatIndex.indexOf(playerIndex)];

                            return (
                                <Table.Row>
                                    {tacticPosition.playerIndexes.length > 1 ?
                                        i === 0 ?
                                            <Table.Cell collapsing rowSpan={tacticPosition.playerIndexes.length} style={{verticalAlign: 'middle'}}>{pitchSectionPositions[tacticPosition.pitchHalfSectionIndex]}</Table.Cell>
                                            :
                                            null
                                        :
                                        <Table.Cell collapsing>{pitchSectionPositions[tacticPosition.pitchHalfSectionIndex]}</Table.Cell>
                                    }
                                    <Table.Cell style={{paddingLeft: '0.6em'}}>
                                        {props.teamStatData.MOTM.side === selectedSide && props.teamStatData.MOTM.playerIndex === playerIndex ? <FontAwesomeIcon icon={faAward} className='inlineIcon' /> : null}
                                        <Link to={{pathname: generatePath(URL.playerProfile, {playerId: player.playerId})}}>{player.initial}. {player.lastName}</Link>
                                        <span style={{marginLeft: '0.25rem'}}>
                                            {[...Array(playerStats.goals || 0)].map((a, i) => 
                                                <FontAwesomeIcon icon={faFutbol} className='inlineIcon' key={`g_${i}`} />
                                            )}
                                            {[...Array(playerStats.assists || 0)].map((a, i) =>
                                                <FontAwesomeIcon icon={faPeopleArrows} className='inlineIcon' key={`a_${i}`} />
                                            )}
                                            {playerStats.card === 'red' &&
                                                <FontAwesomeIcon icon={faSquare} color='#DB2828' className='inlineIcon' />
                                            }
                                            {playerStats.card === 'yellow' &&
                                                <FontAwesomeIcon icon={faSquare} color='#FBBD08' className='inlineIcon' />
                                            }
                                            {playerStats.timeOnPitch !== 90 && playerStats.startedMatch &&
                                                <>{playerStats.gotInjured && <FontAwesomeIcon icon={faBriefcaseMedical} className='inlineIcon' />}<FontAwesomeIcon icon={faArrowDown} color='#DB2828' className='inlineIcon' /><span style={{fontSize: '0.7em'}}>{playerStats.timeOnPitch}'</span></>
                                            }
                                            {playerStats.timeOnPitch !== 90 && !playerStats.startedMatch &&
                                                <><FontAwesomeIcon icon={faArrowUp} color='#21BA45' /><span style={{fontSize: '0.7em'}}>{90-playerStats.timeOnPitch}'</span></>
                                            }
                                        </span>
                                    </Table.Cell>
                                    {!location.state?.playerStat || location.state?.playerStat === 'rating' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{playerStats.performanceRating === 10 ? playerStats.performanceRating : playerStats.performanceRating.toFixed(1)}</Table.Cell> : null}
                                    {location.state?.playerStat === 'shots' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{playerStats.totalShots || '-'}</Table.Cell> : null}
                                    {location.state?.playerStat === 'shotsOnTarget' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{playerStats.totalShotsOnTarget || '-'}</Table.Cell> : null}
                                    {location.state?.playerStat === 'shotsOnTargetP' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{playerStats.totalShots ? (Math.round(playerStats.totalShotsOnTarget / playerStats.totalShots * 100) + "%") : '-'}</Table.Cell> : null}
                                    {location.state?.playerStat === 'shotConversion' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{playerStats.totalShots ? (Math.round(playerStats.goals / playerStats.totalShots * 100) + "%") : '-'}</Table.Cell> : null}
                                    {location.state?.playerStat === 'chancesCreated' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{playerStats.chancesCreated || '-'}</Table.Cell> : null}
                                    {location.state?.playerStat === 'saves' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{playerStats.shotsSaved}</Table.Cell> : null}
                                </Table.Row>
                            )
                        })
                    )}
                </Table.Body>
            </Table>
        </>
    );
}