import axios from 'axios';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import {Segment, Header, Button, Grid, Table, Progress} from 'semantic-ui-react';
import {useIntl, FormattedMessage} from 'react-intl';
import RatingRoundall from '../../player/common/RatingRoundall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faCakeCandles, faFireFlameCurved, faLock, faPlus, faStar, faStreetView, faTag, faUser } from '@fortawesome/free-solid-svg-icons';
import SUIIconShim from '../../common/components/SUIIconShim';
import PlayerRegistrationSelector from '../../club/components/players/PlayerRegistrationSelector';
import ShortenedPlayerName from '../../player/common/ShortenedName';
import colours from '../../common/colours';
import { useHistory, useLocation } from 'react-router'
import {useTutorial} from '../../tutorial/hooks/useTutorial';

function PlayerTrainingBoost(props) {
    const queryClient = useQueryClient();
    const intl = useIntl();
    const location = useLocation();
    const history = useHistory();
    const {checkTutorial, getSearchParamsString, tutorialStep} = useTutorial()

    const setPlayerTrainingBoost = useMutation(playerRegId => 
        axios.patch(`${process.env.REACT_APP_APPHOST}/playerTrainingBoosts/${props.playerTrainingBoost.id}`, {
            playerRegId: playerRegId
        }), {
            onSuccess: () => {
                queryClient.invalidateQueries('getPlayerTrainingBoosts')
            }
        }
    );

    function handleSelectPlayerRegistration(playerRegistrationId, nextTutorialStep) {
        setPlayerTrainingBoost.mutate(playerRegistrationId);
        triggerModal(false, nextTutorialStep);
    }

    function triggerModal(open, nextTutorialStep) {
        history.push({
            pathname: location.pathname,
            state: {
                ...location.state,
                boostModal: open
            },
            search: nextTutorialStep && getSearchParamsString('training', tutorialStep+1)
        });
    }

    const isTutorial1 = checkTutorial('training', 2);

    return(
        <>
            <Segment textAlign='center'>
                <Header icon>
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faFireFlameCurved} color='#db2828' />
                    </SUIIconShim>
                    Training boost
                </Header>
                <p>Select a player to boost in the next training session</p>
                {!props.playerTrainingBoost.playerReg ?
                    <Button
                        className={isTutorial1 && 'glow'}
                        primary
                        onClick={() => triggerModal(props.playerTrainingBoost.id, isTutorial1)}
                    >
                        Select player
                    </Button>
                    :
                    <>
                        <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)', textAlign: 'left'}}>
                            <ShortenedPlayerName player={props.playerTrainingBoost.playerReg.playerContract.player} />
                            <FontAwesomeIcon icon={faUser} style={{float: 'right'}} />
                        </Header>
                        <Segment attached className='attachedInvertedSegment' style={{marginBottom: '1em'}}>
                            <Grid style={{width: '100%', margin: 0}}>
                                <Grid.Row style={{padding: 0}}>
                                    <Grid.Column width={8}>
                                        <Table basic='very' compact='very' unstackable style={{width: '100%'}}>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={faStreetView} /></Table.Cell>
                                                    <Table.Cell>
                                                        {props.playerTrainingBoost.playerReg.playerContract.player.position}
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={faTag} /></Table.Cell>
                                                    <Table.Cell>
                                                        <FormattedMessage {...messages[`playerType_${props.playerTrainingBoost.playerReg.playerContract.player.type}`]} />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={faCakeCandles} /></Table.Cell>
                                                    <Table.Cell>
                                                        Age {props.playerTrainingBoost.playerReg.playerContract.player.age}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Table basic='very' compact='very' unstackable style={{width: '100%'}}>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={faStar} /></Table.Cell>
                                                    <Table.Cell>
                                                        <RatingRoundall rating={props.playerTrainingBoost.playerReg.playerContract.player.currentAbility} disableFloat={true} />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={faBullseye} /></Table.Cell>
                                                    <Table.Cell>
                                                        {intl.formatMessage(messages.trainingFocus[props.playerTrainingBoost.playerReg.playerContract.trainingFocus])}
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={faPlus} /></Table.Cell>
                                                    <Table.Cell>
                                                        {props.playerTrainingBoost.playerReg.playerContract.player.CAChangeThisSeason}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                        <Button
                            primary
                            onClick={() => triggerModal(props.playerTrainingBoost.id)}
                        >
                            Change player
                        </Button>
                    </>
                }
            </Segment>
            <PlayerRegistrationSelector
                playerRegistrations={props.playerRegistrations}
                open={location.state?.boostModal === props.playerTrainingBoost.id}
                setOpen={triggerModal}
                handleSelectPlayerRegistration={handleSelectPlayerRegistration}
                selectedPlayerRegistrationId={props.playerTrainingBoost.playerReg?.id}
                additionalColumns={[[faPlus, 'CAChangeThisSeason']]}
                manager={props.manager}
            />
        </>
    );
}

function BonusBoost(props) {
    return(
        <Segment textAlign='center' secondary>
            <Header icon>
                <SUIIconShim>
                    <FontAwesomeIcon icon={faLock} color={colours.darkGrey} />
                </SUIIconShim>
                Bonus boost
            </Header>
            <p>Get an extra boost for every 10 points you win in the youth league</p>
            <Progress value={10-props.pointsRequired} total={10} progress='ratio' style={{margin: 0}} />
        </Segment>
    );
}

export {
    PlayerTrainingBoost,
    BonusBoost
}

const messages = {
    trainingFocus: {
        create: {
            id: 'player:trainingFocus:attack',
            defaultMessage: 'Attacking',
            description: 'Attacking training focus (passing, dribbling, etc)'
        },
        defend: {
            id: 'player:trainingFocus:defend',
            defaultMessage: 'Defending',
            description: 'Defending training focus (tackling, marking, etc)'
        },
        finish: {
            id: 'player:trainingFocus:finish',
            defaultMessage: 'Finishing',
            description: 'Finishing training focus (shooting)'
        },
        balanced: {
            id: 'player:trainingFocus:balanced',
            defaultMessage: 'Balanced',
            description: 'Balanced training focus (trains everything in proportion for the position)'
        }
    },
    playerType_defensive: {
        id: "playerType_defensive",
        defaultMessage: "Defensive",
        description: "Description for a player with high defensive attributes"
    },
    playerType_standard: {
        id: "playerType_standard",
        defaultMessage: "Standard",
        description: "Description for a player with balanced  attributes"
    },
    playerType_attacking: {
        id: "playerType_attacking",
        defaultMessage: "Attacking",
        description: "Description for a player with high attacking attributes"
    }
}