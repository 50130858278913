export default function ShortenedPlayerName(props) {
    if(props.player.fullName) {
        return(
            <span style={props.emphasise === true ? {fontWeight: 'bold'} : undefined}>{props.player.fullName}</span>
        )
    } else {
        return(
            <span style={props.emphasise === true ? {fontWeight: 'bold'} : undefined}>{`${props.player.firstName.charAt(0)}. ${props.player.lastName}`}</span>
        )
    }
}
