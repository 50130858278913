import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import NarrowLayout from "../../../../common/components/NarrowLayout";
import TitledSegment from "../../../../common/components/TitledSegment";
import { Button, Form, Grid, Message } from "semantic-ui-react";
import ContractLengthSelection from "../../../playerContract/common/new/ContractLengthSelection";
import ContractValue from "../../../playerContract/common/new/ContractValue";
import SalaryOffer from "../../../playerContract/common/new/SalaryOffer";
import { defineMessages, FormattedMessage } from "react-intl";
import StaffBriefing from "../../../playerContract/common/new/StaffBriefing";
import { URL } from "../../../../common/lib/paths";
import TransferInfo from "../common/TransferInfo";
import ContractSummary from "../../../common/ContractSummary";
import BudgetSummary from "../../../../club/components/common/BudgetSummary";
import { faSignature } from "@fortawesome/free-solid-svg-icons";

export default function PlayerFreeAuctionCreateOfferInterface(props) {
    const [salaryOffer, setSalaryOffer] = useState(null);
    const [selectedContractLengthIndex, setSelectedContractLengthIndex] = useState(null);
    const [isNegotiating, setIsNegotiating] = useState(false);

    const {playerId} = useParams();
    const history = useHistory();
    const queryClient = useQueryClient();

    const createPlayerFreeAuctionOffer = useMutation(
        ({salaryOffer, endSeasonIdOffer}) => axios.post(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionBidFree`, {
            playerId: parseInt(playerId),
            salaryOffer,
            endSeasonIdOffer
        }),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['getPlayerFreeAuction']);
                history.replace(generatePath(URL.playerTransferAuctionFree, {playerId: playerId, playerFreeAuctionId: data.data.response.playerTransferAuctionFreeId}));
            }
        }
    );

    const getSalaryDemandQuery = useQuery(
        ['getPlayerSalaryDemand', playerId, selectedContractLengthIndex],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/contractDemands?proposedContractEndSeasonId=${props.contractLengthOptions[selectedContractLengthIndex].seasonId}`),
        {
            enabled: selectedContractLengthIndex !== null
        }
    );

    useEffect(() => {
        if(selectedContractLengthIndex === null && props.defaultContractLengthIndex !== undefined) {
            setSelectedContractLengthIndex(props.defaultContractLengthIndex);
        }
    }, [props.defaultContractLengthIndex]);

    useEffect(() => {
        if(salaryOffer === null && getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand !== undefined) {
            setSalaryOffer(getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand);
        }
    }, [getSalaryDemandQuery.data?.data.response]);

    if(props.isInterested === false) {
        return(
            <Message negative>
                <Message.Header>Player not interested</Message.Header>
                <Message.Content>You cannot offer this player a contract because they are not currently interested in joining your club.</Message.Content>
            </Message>
        );
    }

    return(
        <NarrowLayout>
            <TransferInfo
                player={props.player}
                playerFreeAuction={props.playerFreeAuction}
            />
            <BudgetSummary
                finances={props.finances}
                isEditable={true}
            />
            <TitledSegment
                title='Contract offer'
                icon={faSignature}
            >
                <ContractLengthSelection
                    isLoading={props.isLoading}
                    contractLengthOptions={props.contractLengthOptions}
                    selectedContractLengthIndex={selectedContractLengthIndex}
                    setSelectedContractLengthIndex={handleSelectContractEndSeason.bind(this)}
                />
                <ContractSummary
                    salary={getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand}
                    salaryLabel='Salary demand'
                    contractEndDate={getSalaryDemandQuery.data?.data.response.proposedContractEndSeason.endDate}
                    contractExpiryLabel='Contract expiry'
                />
                <ContractValue
                    baseSalaryDemand={getSalaryDemandQuery.data?.data.response.baseSalaryDemand || 0}
                    salaryOffer={salaryOffer || 0}
                    isLocked={getSalaryDemandQuery.data?.data.response.baseSalaryDemand === null}
                />
                {isNegotiating === true ?
                    <Form
                        isSubmitting={createPlayerFreeAuctionOffer.isLoading}
                        onSubmit={handleSubmit}
                    >
                        <SalaryOffer
                            salaryOffer={salaryOffer}
                            setSalaryOffer={setSalaryOffer}
                            resetSalaryOffer={resetSalaryOffer.bind(this)}
                            increment={getSalaryDemandQuery.data?.data.response.salaryIncrement}
                        />
                        <Button
                            primary
                            fluid
                            disabled={createPlayerFreeAuctionOffer.isLoading}
                            loading={createPlayerFreeAuctionOffer.isLoading}
                            type='submit'
                            size='tiny'
                        >
                            Submit offer
                        </Button>
                    </Form>
                    :
                    <Grid className='gridReset'>
                        <Grid.Row columns={2} style={{padding: 0}}>
                            <Grid.Column style={{paddingRight: '0.5em', paddingLeft: 0}}>
                                <Button
                                    size='tiny'
                                    fluid
                                    onClick={() => setIsNegotiating(true)}
                                >
                                    Negotiate
                                </Button>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: '0.5em', paddingRight: 0}}>
                                <Button
                                    size='tiny'
                                    fluid
                                    primary
                                    onClick={handleSubmit.bind(this)}
                                >
                                    Submit offer
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
                {!!createPlayerFreeAuctionOffer.error?.response?.data &&
                    <Message negative>
                        <FormattedMessage
                            {...errorMessages[createPlayerFreeAuctionOffer.error?.response?.data?.response?.key || 'unknown']}
                            values={{key: createPlayerFreeAuctionOffer.error?.response?.data?.response?.key || '-1'}}    
                        />
                    </Message>
                }
            </TitledSegment>
            <TitledSegment
                title='Staff briefing'
            >
                <StaffBriefing
                    isLoading={props.isLoading}
                    negotiationFactors={props.negotiationFactors}
                    existingContract={props.existingContract}
                />
            </TitledSegment>
        </NarrowLayout>
    );

    function handleSelectContractEndSeason(endSeasonId) {
        if(endSeasonId === selectedContractLengthIndex) return;

        setSelectedContractLengthIndex(endSeasonId);
        setSalaryOffer(null);
    }

    function resetSalaryOffer() {
        setSalaryOffer(getSalaryDemandQuery.data?.data.response.adjustedSalaryDemand);
    }

    function handleSubmit() {
        createPlayerFreeAuctionOffer.mutate({
            salaryOffer: parseInt(salaryOffer),
            endSeasonIdOffer: parseInt(props.contractLengthOptions[selectedContractLengthIndex].seasonId)
        });
    }
}

const errorMessages = defineMessages({
    'unknown': {
        id: 'player:loanOffer:create:error:general',
        defaultMessage: 'An unknown error occured, please report this ({key})',
        description: 'An unknown error occured'
    },
    'EXPIRED': {
        id: 'playerTransferAuctionBid:expired',
        defaultMessage: 'You cannot bid for this player because the auction has ended',
        description: 'Error message shown if a bid is made but the auction has already ended'
    },
    'CONTRACT_VALUE_LOW': {
        id: 'contractValueLow',
        defaultMessage: 'The player has rejected your contract offer, try increasing your offer',
        description: 'Error message shown when player rejects contract as it is not high enough value'
    },
    'INSUFFICIENT_WAGE_BUDGET': {
        id: 'player:transferOffer:create:error:wageBudget',
        defaultMessage: 'Your wage budget is not sufficient for this offer. Try lowering your contract offer.',
        description: 'Error message shown if a transfer bid cannot be submitted due to the club not being able to afford the offer (Wage budget)'
    },
    'TOO_MANY_PLAYERS': {
        id: 'player:transferOffer:create:error:tooManyPlayers',
        defaultMessage: 'You cannot bid for this player because you have too many players',
        description: 'Error message shown if a transfer bid cannot be submitted because the club already has the maximum number of players'
    },
    'PLAYER_RETIRING': {
        id: 'player:transferOffer:create:error:playerRetiring',
        defaultMessage: 'You cannot bid for this player because they are retired or retiring',
        description: 'Error message shown if a transfer bid cannot be submitted because the player is retired/retiring'
    },
    'REPUTATION_MISMATCH': {
        id: 'player:transferOffer:create:error:reputationAbs',
        defaultMessage: 'This player is not interested in joining your club',
    },
    'FREQUENCY_ERROR': {
        id: 'player:transferOffer:create:error:pendingBid',
        defaultMessage: 'You have already submitted a bid for this player, please wait for the other club to respond.'
    },
    'MODIFY_RESOURCE_PERMISSION_DENIED': {
        id: 'player:transferOffer:create:error:permission',
        defaultMessage: 'You cannot bid for this player because they are not a free agent'
    },
    'LENGTH_MAX_CONSTRAINT': {
        id: 'player:transferOffer:create:error:maxContractLengthExceeded',
        defaultMessage: 'The contract you have offered is too long. Please try again with a shorter offer.'
    },
    'CONTRACT_VALUE_HIGH': {
        id: 'player:transferOffer:create:error:contractValueHigh',
        defaultMessage: 'The board feel this contract offer is too generous . Please try again with a lower contract offer.'
    },
    'CONSTRAINTS_FAILED': {
        id: 'player:transferOffer:create:error:constraintsFailed',
        defaultMessage: 'An unknown error occured. Please try again.'
    }
});