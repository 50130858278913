import { Button, Grid } from "semantic-ui-react";
import TitledSegment from "../../../common/components/TitledSegment";
import { generatePath, useHistory } from "react-router";
import { URL } from "../../../common/lib/paths";
import LoadedStatistic from "../../../common/components/LoadedStatistic";

export default function BudgetSummary(props) {
    const history = useHistory();

    return(
        <TitledSegment
            title='Budgets'
            button={props.isEditable ?
                <Button
                    size='tiny'
                    onClick={() => history.push(generatePath(URL.clubFinances))}
                >
                    Edit
                </Button>
                :
                null
            }
        >
            <Grid className='gridReset'>
                <Grid.Row columns={2} style={{padding: 0}}>
                    <Grid.Column style={{padding: 0}}>
                        <LoadedStatistic
                            label='Transfer budget'
                            change={props.finances?.remainingTransferBudget}
                            value={props.finances?.transferBudget}
                            isCurrency={true}
                        />
                    </Grid.Column>
                    <Grid.Column style={{padding: 0}}>
                        <LoadedStatistic
                            label='Wage budget'
                            change={props.finances?.remainingWageBudget}
                            value={props.finances?.totalWageBudget}
                            isCurrency={true}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </TitledSegment>
    )
}