import { useLocation } from "react-router";

import PlayerHistorySeason from './season/data';
import PlayerHistoryOverall from './overall/data';

export default function RetiredPlayerHistoryRouter(props) {
    const location = useLocation();

    if(location.state?.view === undefined || location.state.view === 'season') {
        return(
            <PlayerHistorySeason {...props} />
        );
    } else if(location.state?.view === 'overall') {
        return (
            <PlayerHistoryOverall {...props} />
        );
    }
}