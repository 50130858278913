import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router";
import PlayerHistoryOverallInterface from "../../../player/history/overall/interface";
import { useEffect } from "react";
import ShortenedPlayerName from "../../../player/common/ShortenedName";
import RetiredPlayerProfileToolbarItem from '../../toolbar/Profile';
import RetiredPlayerHistoryToolbarItem from '../../toolbar/History';

export default function RetiredPlayerOverallHistory(props) {
    const {playerId} = useParams();

    const {isLoading, data, isSuccess} = useQuery(
        ['getRetiredPlayerTeamLeagueSeasons', playerId, true],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/retiredPlayers/${playerId}/playerTeamLeagueSeasons?aggregate`)
    );

    useEffect(() => {
        if(!!data && isSuccess) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={data.data.response.retiredPlayer} /> history</>,
                navigation: [
                    <RetiredPlayerProfileToolbarItem playerId={playerId} />,
                    <RetiredPlayerHistoryToolbarItem playerId={playerId} />,
                ]
            });
        }
    }, [data?.data.response]);

    return(
        <PlayerHistoryOverallInterface
            isLoading={isLoading}
            history={data?.data?.response.history || []}
        />
    )
}