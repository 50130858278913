import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Divider, Grid, Header, Statistic } from "semantic-ui-react"
import colours from "../../../common/colours"
import SUIIconShim from "../../../common/components/SUIIconShim"
import TitledSegment from "../../../common/components/TitledSegment"
import Component_Currency from "../../../common/components/Currency"
import PlayerLink from "../../common/Link"
import ContractSummary from "../../common/ContractSummary"

export default function PlayerTransferAuctionBidStatus(props) {
    return(
        <TitledSegment
            title='Your bid'
            noPadding={true}
        >
            <Grid className='gridReset transferAuctionBid'>
                <Grid.Row columns={1} style={{paddingBottom: 0}}>
                    <Grid.Column>
                    {props.myLatestBid !== undefined ?
                        <LatestBid
                            isWinning={props.isWinning}
                            myLatestBid={props.myLatestBid}
                        />
                        :
                        <p>Click the button below to make your first bid</p>
                    }
                    </Grid.Column>
                </Grid.Row>
                {props.isWinning === false &&
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Button
                                loading={props.isLoading}
                                disabled={props.isWinning === true || props.playerTransferAuction?.isFinished === true}
                                onClick={props.handleCreateBid}
                            >
                                New bid
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                }
            </Grid>
        </TitledSegment>
    )
}

function LatestBid(props) {
    return(
        <>
            <Header icon style={{margin: 0}}>
                {props.isWinning === true ?
                    <>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faCheckCircle} color={colours.positive} />
                        </SUIIconShim>
                        You are winning the auction
                    </>
                    :
                    <>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faExclamationCircle} color={colours.negative} />
                        </SUIIconShim>
                        You've been outbid
                    </>
                }
            </Header>
            <Divider />
            <Statistic className='newStatistic'>
                <Statistic.Value>
                    <Component_Currency value={props.myLatestBid.maxBid} />
                </Statistic.Value>
                <Statistic.Label>
                    Maximum bid
                </Statistic.Label>
            </Statistic>
            <ContractSummary
                small={true}
                noPadding={true}
                salary={props.myLatestBid.contractSalaryOffer}
                salaryLabel='Salary offer'
                contractEndDate={props.myLatestBid.endSeasonOffer.endDate}
                contractExpiryLabel='Contract expiry'
            />
            <div style={{height: '1em'}}></div>
        </>
    );
}