import React from "react";
import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faPersonCane } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
    text: {
        id: "Component_Toolbar_TransfersShortlist.text",
        defaultMessage: "Retired",
        description: "Link for favourite retired favorite players and staff page"
    }
});

export default function FavRetiredToolbarItem(props) {
    return(
        <ToolbarItem
            path={generatePath(URL.favRetired)}
            icon={faPersonCane}
            messages={messages}
            activeToolbarItemRef={props.activeToolbarItemRef}
        />
    )
}