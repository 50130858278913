import DataTable from "../../common/components/DataTable";
import {useTutorial} from '../../tutorial/hooks/useTutorial';
import { staffName } from '../../common/dataTableColumns/staff/staffName';
import { staffRole } from '../../common/dataTableColumns/staff/staffRole';
import { clubName } from '../../common/dataTableColumns/club/clubName';
import { personAge } from '../../common/dataTableColumns/person/personAge';
import { contractExpiry } from '../../common/dataTableColumns/date';
import { currentAbility } from '../../common/dataTableColumns/currentAbility';

export default function StaffListView(props) {
    const {checkTutorial, getSearchParamsString} = useTutorial(); 

    const isTutorial1 = checkTutorial('staff', 8);

    const columns = [
        staffName(
            (staff) => staff,
            (rowData, index) => isTutorial1 && index === 0 && getSearchParamsString('staff', 9),
            (rowData, index) => isTutorial1 && index === 0 && 'glow'
        ),
        staffRole(
            (staff) => staff
        ),
        clubName(
            (staff) => staff.staffContract?.club,
            600
        ),
        personAge(
            (staff) => staff.age,
            450
        ),
        contractExpiry(
            (staff) => staff.staffContract?.endSeason.endDate,
            false,
            650
        ),
        currentAbility(
            (staff) => staff.currentAbility,
            undefined
        )
    ];

    return(
        <DataTable
            columns={columns}
            data={props.staff}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    );
}