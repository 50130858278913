import { defineMessages } from "@formatjs/intl";
import {compileMessage} from './util.js';

const messages = {
    'staffTransferAuctionFree:started': defineMessages(compileMessage({
        subject: {
            id: 'staffTransferAuctionFree:started',
            defaultMessage: "{staffRole} {staffName} nearing move",
            description: "Message subject for the system message template which a user receives when a free agent on their favourites will soon pick a new team"
        },
        body: {
            id: 'staffTransferAuctionFree:started',
            defaultMessage: "{staffRole} {staffName} has today announced that he will decide which club he will be joining on a free transfer between {endDateMin} and {endDateMax}",
            description: "Message body for the system message template which a user receives when a free agent on their favourites will soon pick a new team"
        }
    })),
    'staffTransferAuctionFree:finished': defineMessages(compileMessage({
        subject: {
            id: 'staffTransferAuctionFree:finished',
            defaultMessage: "Staff {staffName} decides next club",
            description: "Message subject for the system message template which a user receives when a staff free agent chooses next team"
        },
        body: {
            id: 'staffTransferAuctionFree:finished',
            defaultMessage: "After considering offers from {bidCount} clubs, {staffName} has announced the club that he has decided to join on a free transfer. Click the button below to find out who.\n\nThe announcement was made at {auctionEndDate}.",
            description: "Message body for the system message template which a user receives when a free agent chooses next team"
        }
    })),
}

export default messages;