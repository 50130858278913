import axios from "axios";
import CreatePlayerTransferOfferInterface from "./interface";
import {useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';

export default function CreatePlayerTransferOffer(props) {
    const {playerId} = useParams();

    const contractDemandsQuery = useQuery(
        ['getPlayerContractDemands', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/contractDemands`)
    );

    const clubBudgetQuery = useQuery(
        ['getClubBudgets'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/budgets`)
    );

    return(
        <CreatePlayerTransferOfferInterface
            isLoading={contractDemandsQuery.isLoading || clubBudgetQuery.isLoading}
            contractLengthOptions={contractDemandsQuery.data?.data.response.contractLengthOptions}
            defaultContractLengthIndex={contractDemandsQuery.data?.data.response.defaultContractLengthIndex}
            negotiationFactors={contractDemandsQuery.data?.data.response.negotiationFactors}
            existingContract={contractDemandsQuery.data?.data.response.player.playerContract}
            finances={clubBudgetQuery.data?.data.response}
            isInterested={contractDemandsQuery.data?.data?.response.isInterested}
            isTransferBlocked={contractDemandsQuery.data?.data?.response.isTransferBlocked}
            player={props.player}
        />
    )
}