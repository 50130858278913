import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { generatePath, useHistory, useLocation, useRouteMatch } from "react-router";
import { Modal, Button, Menu, Form } from "semantic-ui-react";
import {useQuery} from '@tanstack/react-query';
import SelectLeagueSeason from "./SelectLeagueSeason";
import SelectLeagueType from "./SelectLeagueType";
import SelectLeagueLevel from "./SelectLeagueLevel";
import NarrowLayout from "../../common/components/NarrowLayout";
import axios from "axios";
import SelectNation from "./SelectNation";
import SUIIconShim from "../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectLeague from "./SelectLeague";
import { URL } from "../../common/lib/paths";
import { useTutorial } from "../../tutorial/hooks/useTutorial";

export default function ChangeLeagueModal(props) {
    const history = useHistory();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [selectedNationShortcode, setSelectedNationShortcode] = useState();
    const [selectedLeagueLevel, setSelectedLeagueLevel] = useState();
    const [selectedLeagueType, setSelectedLeagueType] = useState();
    const [selectedLeagueId, setSelectedLeagueId] = useState();
    const [selectedLeagueSeasonId, setSelectedLeagueSeasonId] = useState(location.state?.leagueSeasonId)

    useQuery(
        ['getLeagueSeason', location.state?.leagueSeasonId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagueSeasons/${location.state?.leagueSeasonId}`),
        {
            enabled:
                location.state?.leagueSeasonId !== undefined &&
                (
                    selectedNationShortcode === undefined &&
                    selectedLeagueLevel === undefined &&
                    selectedLeagueType === undefined &&
                    selectedLeagueId === undefined
                ),
            onSuccess: (data) => {
                setSelectedNationShortcode(data.data.response.league.leagueLevel.nation.id);
                setSelectedLeagueLevel(data.data.response.league.leagueLevel.level);
                setSelectedLeagueType(data.data.response.league.leagueLevel.type);
                setSelectedLeagueId(data.data.response.league.id);
            }
        }
    );

    function handleSelectLeagueType(newLeagueType) {
        setSelectedLeagueType(newLeagueType);
        setSelectedLeagueLevel(null);
        setSelectedLeagueId(undefined);
        setSelectedLeagueSeasonId(undefined);
    }

    function handleSelectNationShortcode(newNationShortcode) {
        setSelectedNationShortcode(newNationShortcode);
        setSelectedLeagueLevel(null);
        setSelectedLeagueId(undefined);
        setSelectedLeagueSeasonId(undefined);
    }

    function handleSelectLeagueLevel(newLeagueLevel) {
        setSelectedLeagueLevel(newLeagueLevel);
        setSelectedLeagueId(undefined);
        setSelectedLeagueSeasonId(undefined);
    }

    function handleSelectLeagueId(newLeagueId) {
        setSelectedLeagueId(newLeagueId);
        setSelectedLeagueSeasonId(undefined);
    }

    function handleSelectLeagueSeasonId(newLeagueSeasonId) {
        setSelectedLeagueSeasonId(newLeagueSeasonId);
    }
    
    function handleChangeLeagueSeasonId() {
        history.replace({
            pathname: generatePath(URL.league, {leagueId: selectedLeagueId}),
            state: {...location.state, leagueSeasonId: selectedLeagueSeasonId}
        });

        setOpen(false);
    }

    return(
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Trigger />}
        >
            <Modal.Header>
                Select league
            </Modal.Header>
            <Modal.Content>
                <NarrowLayout>
                    <Form>
                        <SelectLeagueType
                            selectedLeagueType={selectedLeagueType}
                            setSelectedLeagueType={handleSelectLeagueType}
                        />
                        <SelectNation
                            selectedNationShortcode={selectedNationShortcode}
                            setSelectedNationShortcode={handleSelectNationShortcode}
                        />
                        <SelectLeagueLevel
                            nationId={selectedNationShortcode}
                            selectedLeagueLevel={selectedLeagueLevel}
                            setSelectedLeagueLevel={handleSelectLeagueLevel}
                        />
                        <SelectLeague
                            selectedLeagueId={selectedLeagueId}
                            setSelectedLeagueId={handleSelectLeagueId}
                            nationId={selectedNationShortcode}
                            leagueLevel={selectedLeagueLevel}
                            leagueType={selectedLeagueType}
                        />
                        <SelectLeagueSeason
                            selectedLeagueSeasonId={selectedLeagueSeasonId}
                            setSelectedLeagueSeasonId={handleSelectLeagueSeasonId}
                            leagueId={selectedLeagueId}
                        />
                    </Form>
                </NarrowLayout>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    positive
                    onClick={handleChangeLeagueSeasonId}
                    disabled={!selectedLeagueSeasonId}
                >
                    Confirm
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const Trigger = (props) => {
    const {checkTutorial, goNextStep} = useTutorial();
    const isTutorial1 = checkTutorial('league', 9);

    return(
        <Menu.Item
            onClick={isTutorial1 ? goNextStep : props.onClick}
            className={isTutorial1 && 'glow'}
        >
            <SUIIconShim>
                <FontAwesomeIcon icon={faPencil} />
            </SUIIconShim>
        </Menu.Item>
    );
}