import { Button, Header, Grid, Table } from "semantic-ui-react";
import {FormattedMessage} from 'react-intl';
import {generatePath, useHistory} from 'react-router';

import Component_RatingRoundall from "../../../../player/common/RatingRoundall";
import Component_Currency from "../../../../common/components/Currency";
import StaffLink from "../../../../staff/common/Link";
import StaffRoleName from "../../../../staff/common/RoleName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCakeCandles, faClock, faCoins } from "@fortawesome/free-solid-svg-icons";
import StaffContractExpiry from "../../../../staff/common/ContractExpiry";
import {URL} from '../../../../common/lib/paths';

export default function DepartmentStaffList(props) {
    const history = useHistory();

    return(
        <>
            <Grid.Row columns={1} style={{paddingTop: 0}}>
                <Grid.Column>
                    <Table
                        basic='very'
                        compact='very'
                        unstackable
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={5}>Coaches</Table.HeaderCell>
                                <Table.HeaderCell><FontAwesomeIcon icon={faCakeCandles} /></Table.HeaderCell>
                                <Table.HeaderCell><FontAwesomeIcon icon={faClock} /></Table.HeaderCell>
                                <Table.HeaderCell><FontAwesomeIcon icon={faCoins} /></Table.HeaderCell>
                                <Table.HeaderCell><FontAwesomeIcon icon={faStar} /></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {props.staffContracts.map((staffContract) => 
                                staffContract !== null ?
                                    getStaffRow(staffContract)
                                    :
                                    getVacancyRow(props.role, false)
                            )}
                        </Table.Body>
                    </Table>

                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} style={{paddingTop: 0}}>
                <Grid.Column style={{paddingRight: '0.5em'}}>
                    <Button
                        size='mini'
                        fluid
                        onClick={() => history.push(generatePath(URL.transferSearchStaff), {filterRole: props.role, partial: true})}
                    >
                        Find <StaffRoleName role={props.role} plural={true} lowercase={true} />
                    </Button>
                </Grid.Column>
                <Grid.Column style={{paddingLeft: '0.5em'}}>
                    <Button
                        size='mini'
                        fluid
                        onClick={() => history.push(generatePath(URL.editClubFacilities))}
                    >
                        Upgrade facilities
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </>
    );

    function getStaffRow(staffContract) {
        return(
            <Table.Row>
                <Table.Cell width={5}>
                    <StaffLink staff={staffContract.staff} />
                </Table.Cell>
                <Table.Cell>
                    {staffContract.staff.age}
                </Table.Cell>
                <Table.Cell>
                    <StaffContractExpiry staffContract={staffContract} />
                </Table.Cell>
                <Table.Cell>
                    <Component_Currency value={staffContract.salary} />
                </Table.Cell>
                <Table.Cell>
                    <Component_RatingRoundall rating={staffContract.staff.currentAbility} disableFloat={true} />
                </Table.Cell>
            </Table.Row>
        )
    }

    function getVacancyRow(role) {
        return(
            <Table.Row>
                <Table.Cell colSpan={5}>
                    Vacancy
                </Table.Cell>
            </Table.Row>
        )
    }
}

const messages = {
    coaching: {
        id: 'departments:coaching',
        defaultMessage: 'Coaching',
        description: 'Name for the department responsible for training players'
    },
    coaching_description: {
        id: 'staff:coach:description',
        defaultMessage: 'Your coaching team are responsible for developing first team players in training. Better coaches grow players faster.'
    },
    youth: {
        id: 'departments:youthAcademy',
        defaultMessage: 'Youth academy',
        description: 'Name for the department responsible for youth players'
    },
    youth_description: {
        id: 'staff:youth:description',
        defaultMessage: 'Your youth team are responsible for developing youth team players in training. Better youth staff grow youth players faster.'
    },
    scouting: {
        id: 'department:scouting',
        defaultMessage: 'Scout network',
        description: 'Name for the department responsible for scouting new players'
    },
    scouting_description: {
        id: 'staff:scout:description',
        defaultMessage: 'Your scouting team assess transfer targets and provide valuable analysis. The more scouts you have the more reports get completed.'
    },
    physio: {
        id: 'department:physio',
        defaultMessage: 'Physiotherapy',
        description: 'Name for the department responsible for treating player injuries'
    },
    physio_description: {
        id: 'staff:physio:description',
        defaultMessage: 'Your physio team are responsible for preventing and treating injuries. Better physios reduce the risk of injury.'
    },
}