import { Form } from "semantic-ui-react";
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

import FullNationNameWithFlag from "../../nation/common/FullNationNameWithFlag";

export default function SelectNation(props) {
    const nationsQuery = useQuery(
        ['getNations'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nations`)
    );

    console.log(props.selectedNationShortcode);

	return(
        <Form.Select
            options={nationsQuery.data?.data?.response.map((nation) => (
                {
                    key: nation.id,
                    text: (<FullNationNameWithFlag nation={nation} />),
                    value: nation.id
                }
            ))}
            value={props.selectedNationShortcode}
            loading={nationsQuery.isLoading}
            disabled={nationsQuery.isLoading}
            onChange={
                (e, {value}) => props.setSelectedNationShortcode(value)
            }
            label='Nation'
            fluid
        />
	);
}
