import axios from "axios";
import {useParams} from 'react-router';
import {useQuery} from '@tanstack/react-query';
import PlayerTransferAuctionInterface from "./interface";
import { useEffect } from "react";

import Profile from "../../toolbar/Profile";
import ContractOffer from '../../toolbar/Contract';
import TransferOfferNew from "../../toolbar/Transfer";
import History from "../../toolbar/History";
import ShortenedPlayerName from "../../common/ShortenedName";

export default function PlayerTransferAuction(props) {
    const {playerTransferAuctionId} = useParams();

    const getPlayerTransferAuction = useQuery(
        ['getPlayerTransferAuction', playerTransferAuctionId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionPaid/${playerTransferAuctionId}`)
    );

    useEffect(() => {
        if(getPlayerTransferAuction.data?.data?.response) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={getPlayerTransferAuction.data.data.response.playerContract.player} /> auction</>,
                navigation: [
                    <Profile key={1} playerId={getPlayerTransferAuction.data.data.response.playerContract.player.id} />,
                    <ContractOffer key={2} playerId={getPlayerTransferAuction.data.data.response.playerContract.player.id} myClubId={props.manager.club?.id} playerClubId={getPlayerTransferAuction.data.data.response.playerContract?.clubId} />,
                    <TransferOfferNew key={3} playerId={getPlayerTransferAuction.data.data.response.playerContract.player.id} myClubId={props.manager.club?.id} playerClubId={getPlayerTransferAuction.data.data.response.playerContract?.clubId} />,
                    <History key={4} playerId={getPlayerTransferAuction.data.data.response.playerContract.player.id} />
                ]
            });
        }
    }, [getPlayerTransferAuction.data]);
    
    return(
        <PlayerTransferAuctionInterface
            isLoading={getPlayerTransferAuction.isLoading}
            playerTransferAuction={getPlayerTransferAuction.data?.data?.response}
            isMyPlayer={getPlayerTransferAuction.data?.data?.response.playerContract.clubId === props.manager.club?.id}
            manager={props.manager}
        />
    )
}