import colours from "../colours";

export default function RatingBar(props) {
    const segments = [];

    if(props.value >= 70) {
        var progressBgColor = colours.positive;
    } else if(props.value <= 30) {
        var progressBgColor = colours.negative;
    } else {
        var progressBgColor = colours.orange;
    }

    for(let i=0; i<10; i++) {
        let segmentStart = i * 10;
        let segmentEnd = (i + 1) * 10;

        if(props.value >= segmentEnd) {
            var segmentFill = 100;
        } else if(props.value > segmentStart) {
            var segmentFill = ((props.value - segmentStart) / 10) * 100;
        } else {
            var segmentFill = 0;
        }

        segments.push(
            <span key={i} className={'segment'}><span className={'progress'} style={{backgroundColor: `${progressBgColor}`, width: `${segmentFill}%`}}></span></span>
        );
    }

    return(
        <span
            className={'ratingBar'}
        >
            {segments}
            {!!props.baseline &&
                <span style={{position: 'absolute', left: `${props.baseline}%`, width: '2px', height: '100%', backgroundColor: '#000'}} />
            }
        </span>
    )
}