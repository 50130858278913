import {useIntl, defineMessages} from 'react-intl';
import {Menu, Table} from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive'
import mean from 'lodash/mean';

import assistIcon from '../../common/assists-icon.png';
import appearancesIcon from '../../../common/appearances-icon.png';
import DataTable from '../../../common/components/DataTable';
import LeagueLink from '../../../league/components/common/Link';
import Currency from '../../../common/components/Currency';
import WideLayout from '../../../common/components/WideLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faThumbsUp, faTrophy, faSquare } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import ClubLink from '../../../club/components/common/Link';
import {SeasonName} from '../../../common/components/SeasonName';
import { useHistory, useLocation } from 'react-router';

export default function PlayerHistorySeasonInterface(props) {
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();
    const useExpanderControl = useMediaQuery({
        query: '(max-width: 599px)'
    });

    const columns = [
        {
            name: intl.formatMessage(messages.seasonColumnLabel),
            cell: playerClubLeagueSeason => playerClubLeagueSeason.TLS ?
                <SeasonName season={playerClubLeagueSeason.TLS.LS.season} />
                :
                'Total',
            wrap: false,
            allowOverflow: false,
            compact: true,
            minWidth: '80px',
            maxWidth: '100px',
            grow: 1
        },
        {
            name: intl.formatMessage(messages.clubNameColumnLabel),
            cell: playerClubLeagueSeason =>
                <>
                    {playerClubLeagueSeason.TLS ?
                        <>
                            <div className='playerName' style={{justifyContent: 'space-between'}}>
                                <ClubLink club={playerClubLeagueSeason.TLS.team.club} />
                                {!!playerClubLeagueSeason.playerTransferHistory &&
                                    <span style={{marginRight: '1em', whiteSpace: 'nowrap'}}>
                                        {playerClubLeagueSeason.playerTransferHistory.compensation !== null ?
                                            <Currency value={playerClubLeagueSeason.playerTransferHistory.compensation} />
                                            :
                                            playerClubLeagueSeason.playerTransferHistory.isLoan === true ?
                                                'Loan'
                                                :
                                                'Free'
                                        }
                                    </span>
                                }
                            </div>
                        </>
                        :
                        '-'
                    }
                </>,
			wrap: false,
			allowOverflow: true,
            compact: true,
            grow: 4
        },
        {
            name: intl.formatMessage(messages.leagueNameColumnLabel),
            cell: playerClubLeagueSeason =>
                <>
                    {playerClubLeagueSeason.TLS ?
                        <div className='playerName'>
                            <LeagueLink league={playerClubLeagueSeason.TLS.LS.league} />
                        </div>
                        :
                        '-'
                    }
                </>,
			wrap: false,
			allowOverflow: true,
            compact: true,
            grow: 2,
            hide: 550,
        },
        {
            name: <img src={appearancesIcon} style={{width: '16px', height: '16px'}} />,
            cell: PTLS => PTLS.appearances,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 350,
            grow: 1
        },
        {
            name: <FontAwesomeIcon icon={faFutbol} />,
            cell: PTLS => PTLS.goalsScored,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 350,
            grow: 1
        },
        {
            name: <img src={assistIcon} style={{width: '16px', height: '16px'}} />,
            cell: PTLS => PTLS.assists,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 370,
            grow: 1
        },
        {
            name: <FontAwesomeIcon icon={faThumbsUp} />,
            cell: PTLS => <span>{PTLS.averageMatchRating || '-'}</span>,
            wrap: false,
            allowOverflow: false,
            center: true,
            compact: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 700,
            grow: 1
        },
        {
            name: <FontAwesomeIcon icon={faTrophy} />,
            cell: PTLS => PTLS.totalMOTM,
            wrap: false,
            allowOverflow: false,
            center: true,
            compact: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 750,
            grow: 1
        },
        {
            name: <FontAwesomeIcon icon={faSquare} color='#FBBD08' />,
            cell: PTLS => PTLS.yellowCards,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 800,
            grow: 1
        },
        {
            name: <FontAwesomeIcon icon={faSquare} color='#DB2828' />,
            cell: PTLS => PTLS.redCards,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            minWidth: '30px',
            maxWidth: '60px',
            hide: 800,
            grow: 1
        }
    ];

    const totals = useMemo(() => {
        if(!props.playerClubLeagueSeasons) return [];

        const total = props.playerClubLeagueSeasons.reduce((total, PTLS) => {
            total.appearances += PTLS.appearances;
            total.goalsScored += PTLS.goalsScored;
            total.assists += PTLS.assists;
            total.averageMatchRating.push(PTLS.averageMatchRating);
            total.totalMOTM += PTLS.totalMOTM;
            total.yellowCards += PTLS.yellowCards;
            total.redCards += PTLS.redCards;
            
            return total;
        }, {
            appearances: 0,
            goalsScored: 0,
            assists: 0,
            averageMatchRating: [],
            totalMOTM: 0,
            yellowCards: 0,
            redCards: 0,
            isTotal: true
        });

        total.averageMatchRating = mean(total.averageMatchRating);

        return [total];
    }, [props.playerClubLeagueSeasons]);

    return(
        <WideLayout>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Menu
                    inverted
                    compact
                    size='tiny'
                >
                    <Menu.Item active>Seasons</Menu.Item>
                    <Menu.Item onClick={() => history.replace({pathname: location.pathname, state: {...location.state, view: 'overall'}})}>Overall</Menu.Item>
                </Menu>
            </div>
            <DataTable
                columns={columns}
                data={[...props.playerClubLeagueSeasons, ...totals]}
                isLoading={props.isLoading}
                placeholderCount={3}
                expandableRows={useExpanderControl}
                expandableRowsComponent={PlayerSeasonHistoryExpander}
                conditionalRowStyles={[
                    {
                        when: row => row.isTotal === true,
                        style: {
                            fontWeight: 'bold'
                        }
                    }
                ]}
            />
        </WideLayout>
    );
}

function PlayerSeasonHistoryExpander(props) {
    return(
        <Table
            unstackable
            definition
            compact='very'
            striped
            columns={2}
        >
            <Table.Body>
                {!!props.data.playerTransferHistory ?
                    <Table.Row>
                        <Table.Cell>Transfer fee</Table.Cell>
                        <Table.Cell>
                            {props.data.playerTransferHistory.compensation !== null ?
                                <Currency value={props.data.playerTransferHistory.compensation} />
                                :
                                props.data.playerTransferHistory.isLoan === true ?
                                    'Loan'
                                    :
                                    'Free'
                            }
                        </Table.Cell>
                    </Table.Row>
                    :
                    null
                }
                <Table.Row>
                    <Table.Cell>Appearances</Table.Cell>
                    <Table.Cell>{props.data.appearances}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Goals</Table.Cell>
                    <Table.Cell>{props.data.goalsScored}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Assists</Table.Cell>
                    <Table.Cell>{props.data.assists}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Yellow cards</Table.Cell>
                    <Table.Cell>{props.data.yellowCards}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Red cards</Table.Cell>
                    <Table.Cell>{props.data.redCards}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    )
}

const messages = defineMessages({
    seasonColumnLabel: {
        id: "Layout_PlayerHistory.seasonLabel",
        defaultMessage: "Season",
        description: "Header for the column in the player history table which states the season number"
    },
    leagueNameColumnLabel: {
        id: "Layout_PlayerHistory.leagueLabel",
        defaultMessage: "League",
        description: "Header for the column in the player history table which states the league name"
    },
    clubNameColumnLabel: {
        id: "Layout_PlayerHistory.clubNameLabel",
        defaultMessage: "Club",
        description: "Header for the 'club name' column in the player history table"
    },
    appearancesColumnLabel: {
        id: "Layout_PlayerHistory.appearancesLabel",
        defaultMessage: "Apps",
        description: "Header for the appearances column in the player history/form table. Use an abbreviated form (around 4 characters) if possible"
    },
    goalsColumnLabel: {
        id: "Layout_PlayerHistory.goalsLabel",
        defaultMessage: "Gls",
        description: "Header for the goals column in the player history table. Use an abbreviation where possible (around 3/4 characters)"
    },
    assistsColumnLabel: {
        id: "Layout_PlayerHistory.assistsLabel",
        defaultMessage: "Asst",
        description: "Header for the assist column in the player history table. Use an abbreviation where possible (around 3/4 characters)"
    },
    yellowCardsColumnLabel: {
        id: "Layout_PlayerHistory.yellowCardsLabel",
        defaultMessage: "Yllw",
        description: "Header for the yellow cards column in the player history table. Use an abbreviation where possible (3/4 characters)."
    },
    redCardsColumnLabel: {
        id: "Layout_PlayerHistory.redCardsLabel",
        defaultMessage: "Red",
        description: "Header for the red cards column in the player history table. Use an abbreviation where possible (3/4 characters)."
    },
    injuriesColumnLabel: {
        id: "Layout_PlayerHistory.injuriesLabel",
        defaultMessage: "Inj",
        description: "Header for the injuries column in the player histroy table. Use an abbreviation where possible (3/4 characters)"
    }
});