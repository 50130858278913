import {Segment, Form} from 'semantic-ui-react';

import NarrowLayout from '../../common/components/NarrowLayout';
import WideLayout from '../../common/components/WideLayout';
import usePersistedState from '../../common/lib/usePersistedState';
import { useTutorial } from '../../tutorial/hooks/useTutorial';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useLocation, useParams } from 'react-router';

import LeagueName from '../../league/components/common/Name';

import ClubProfileToolbarItem from '../../club/components/toolbar/ClubProfile';
import TeamPlayersToolbarItem from '../../club/components/toolbar/TeamPlayers';
import TeamFixturesToolbarItem from '../../club/components/toolbar/TeamFixtures';
import TeamLeagueToolbarItem from '../../club/components/toolbar/TeamLeague';
import ClubStaffToolbarItem from '../../club/components/toolbar/ClubStaff';
import ClubTransfersToolbarItem from '../../club/components/toolbar/ClubTransfers';
import TeamLeagueSeasonsToolbarItem from '../../club/components/toolbar/TeamLeagueSeasons';
import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';

export default function PlayerTeamLeagueSeasonsInterface(props) {
    const {
        persistedState,
        updatePersistedState
    } = usePersistedState(props.persistedStateKey, {page: 1, stat: 'goalsScored'});
    const {clubId, teamType} = useParams();
    const location = useLocation();

    const {checkTutorial} = useTutorial();

    const getLeagueSeasonQuery = useQuery(
        ['getLeagueSeason', location.state.leagueSeasonId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagueSeasons/${location.state.leagueSeasonId}`)
    );

    useEffect(() => {
        if(!!getLeagueSeasonQuery.data?.data?.response) {
            const isMyLS = location.state?.leagueSeasonId === props.manager.club.firstTeam.currentTLS.LS.id || location.state?.leagueSeasonId === props.manager.club.youthTeam.currentTLS.LS.id;

            if(!!clubId && !!teamType) {
                var navigation = [
                    <ClubProfileToolbarItem clubId={clubId} key={1} />,
                    <TeamPlayersToolbarItem clubId={clubId} teamType={teamType} key={2} />,
                    <TeamFixturesToolbarItem clubId={clubId} teamType={teamType} key={5} />,
                    <TeamLeagueToolbarItem clubId={clubId} teamType={teamType} key={6} />,
                    <ClubStaffToolbarItem clubId={clubId} key={7} />,
                    <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                    <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType={teamType} key={9} />
                ]
            } else {
                var navigation = [];
            }

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{leagueName: <LeagueName league={getLeagueSeasonQuery.data.data.response.league} />}} />,
                navigation
            });
        }
    }, [getLeagueSeasonQuery?.data?.data?.response]);

    const isTutorial1 = checkTutorial('league', 4);

    return(
        <div>
            <NarrowLayout>
                <Segment inverted style={{margin: '1em 0'}}>
                    <Form inverted>
                        <Form.Select
                            options={
                                [
                                    {
                                        key: 'goalsScored',
                                        text: 'Total goals',
                                        value: 'goalsScored'
                                    },
                                    {
                                        key: 'assists',
                                        text: 'Total assists',
                                        value: 'assists'
                                    },
                                    {
                                        key: 'averageMatchRating',
                                        text: 'Average rating',
                                        value: 'averageMatchRating'
                                    },
                                    {
                                        key: 'chancesCreated90',
                                        text: 'Chances created / 90',
                                        value: 'chancesCreated90'
                                    },
                                    {
                                        key: 'totalShots90',
                                        text: 'Shots / 90',
                                        value: 'totalShots90'
                                    },
                                    {
                                        key: 'totalShotsOnTarget90',
                                        text: 'Shots on target / 90',
                                        value: 'totalShotsOnTarget90'
                                    },
                                    {
                                        key: 'shotsOnTargetP',
                                        text: 'Shots on target %',
                                        value: 'shotsOnTargetP'
                                    },
                                    {
                                        key: 'shotConversion',
                                        text: 'Shot conversion %',
                                        value: 'shotConversion'
                                    },
                                    {
                                        key: 'totalCleanSheets',
                                        text: 'Clean sheets',
                                        value: 'totalCleanSheets'  
                                    },
                                    {
                                        key: 'goalsConceded90',
                                        text: 'Goals conceded / 90',
                                        value: 'goalsConceded90'
                                    },
                                    {
                                        key: 'shotsFaced90',
                                        text: 'Shots faced / 90',
                                        value: 'shotsFaced90'
                                    },
                                    {
                                        key: 'totalSaves90',
                                        text: 'Saves / 90',
                                        value: 'totalSaves90'
                                    },
                                    {
                                        key: 'savesToShotsFaced',
                                        text: 'Save %',
                                        value: 'savesToShotsFaced'
                                    }
                                ]
                            }
                            value={persistedState.stat}
                            onChange={
                                (e, {value}) => updatePersistedState({page: persistedState.page, stat: value})
                            }
                            label='Statistic'
                            fluid
                            className={isTutorial1 && 'glow'}
                        />
                    </Form>
                </Segment>
            </NarrowLayout>
            <WideLayout>
                {props.children}
            </WideLayout>
        </div>
    )
}

const messages = {
    screenTitle: {
        id: 'test',
        defaultMessage: '{leagueName} stats',
        description: 'todo'
    }
}