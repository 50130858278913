import { Loader, Table } from "semantic-ui-react"
import NarrowLayout from "../../../common/components/NarrowLayout";
import { FormattedDate } from "react-intl";
import ClubFaciltiesViewer from "./ClubFacilitiesViewer";
import StarRating from "../../../common/components/StarRating";
import RatingBar from "../../../common/components/RatingBar";

export default function ClubFacilitiesInterface(props) {
    if(props.isLoading === true) {
        return(
            <Loader active />
        );
    }

    return(
        <NarrowLayout>
            <ClubFaciltiesViewer
                rotation={true}
                rating={props.clubFacilities.clubFacilitiesUpgrade?.newRating || props.clubFacilities.rating}
                isUnderConstruction={!!props.clubFacilities.clubFacilitiesUpgrade}
            />
                <Table
                    definition
                    unstackable
                    verticalAlign="middle"
                >
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={8}>
                                Rating
                            </Table.Cell>
                            <Table.Cell width={8} textAlign="right">
                                <StarRating rating={props.clubFacilities.clubFacilitiesUpgrade?.newRating || props.clubFacilities.rating} />
                            </Table.Cell>
                        </Table.Row>
                        {!!props.clubFacilities.clubFacilitiesUpgrade ?
                            <>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Build completion
                                    </Table.Cell>
                                    <Table.Cell width={8} textAlign="right">
                                        <FormattedDate value={props.clubFacilities.clubFacilitiesUpgrade.completionGameJob.startDate} month='long' day='numeric' weekday='long' />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Player training
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar baseline={props.coachDept.trainingRating} value={calculateRating(props.coachDept.staffRatings.trainingRating, props.clubFacilities.clubFacilitiesUpgrade.newRating)} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Player conditioning
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar baseline={props.coachDept.conditioningRating} value={calculateRating(props.coachDept.staffRatings.conditioningRating, props.clubFacilities.clubFacilitiesUpgrade.newRating)} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Injury prevention
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar baseline={props.physioDept.injuryPreventionRating} value={calculateRating(props.physioDept.staffRatings.injuryPreventionRating, props.clubFacilities.clubFacilitiesUpgrade.newRating)} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Injury recovery
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar baseline={props.physioDept.injuryRecoveryRating} value={calculateRating(props.physioDept.staffRatings.injuryRecoveryRating, props.clubFacilities.clubFacilitiesUpgrade.newRating)} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Youth training
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar baseline={props.youthDept.youthRecruitmentRating} value={calculateRating(props.youthDept.staffRatings.youthTrainingRating, props.clubFacilities.clubFacilitiesUpgrade.newRating)} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Youth recruitment
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar baseline={props.youthDept.youthTrainingRating} value={calculateRating(props.youthDept.staffRatings.youthRecruitmentRating, props.clubFacilities.clubFacilitiesUpgrade.newRating)} />
                                    </Table.Cell>
                                </Table.Row>
                            </>
                            :
                            <>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Build date
                                    </Table.Cell>
                                    <Table.Cell width={8} textAlign="right">
                                        <FormattedDate value={props.clubFacilities.constructionDate} day="numeric" month="long" year="numeric'" />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Player training
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar value={props.coachDept.trainingRating} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Player conditioning
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar value={props.coachDept.conditioningRating} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Injury prevention
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar value={props.physioDept.injuryPreventionRating} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Injury recovery
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar value={props.physioDept.injuryRecoveryRating} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Youth training
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar value={props.youthDept.youthRecruitmentRating} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={8}>
                                        Youth recruitment
                                    </Table.Cell>
                                    <Table.Cell width={8}>
                                        <RatingBar value={props.youthDept.youthTrainingRating} />
                                    </Table.Cell>
                                </Table.Row>
                            </>
                        }
                    </Table.Body>
                </Table>
        </NarrowLayout>
    );

    function calculateRating(staffRating, facilityRating) {
        if(facilityRating === null) {
            return staffRating;
        }

        return (staffRating * 0.5) + (normaliseFacilityRating(facilityRating) * 0.5);

        function normaliseFacilityRating(
            facilityRating
        ) {
            return 50 + (facilityRating * 10); //0-100 range
        }
    }
}