import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import DepartmentStaffList from "./StaffList";
import TitledSegment from "../../../../common/components/TitledSegment";
import { Grid, Table, TableCell, TableRow } from "semantic-ui-react";
import RatingBar from "../../../../common/components/RatingBar";

export default function CoachDept(props) {
    if(props.isLoading) return null;

    return(
        <TitledSegment
            title={'Scouting Department'}
            icon={faMagnifyingGlass}
            noPadding={true}
        >
            <Grid style={{margin: 0}}>
                <ScoutingDeptOverview scoutingDept={props.scoutingDept} />
                <DepartmentStaffList
                    role={'scout'}
                    staffContracts={props.scoutingDept.staffContracts}
                />
            </Grid>
        </TitledSegment>
    );
}

function ScoutingDeptOverview(props) {
    return(
        <Grid.Row columns={1}>
            <Grid.Column>
                <p>The scouting department assesses the ability and potential of transfer targets.</p>
                <Table
                    basic='very'
                    unstackable
                    compact
                >
                    <TableRow>
                        <TableCell width={6} style={{fontWeight: 'bold'}}>Report frequency</TableCell>
                        <TableCell width={6} textAlign="right">{props.scoutingDept.scoutingVelocity} per day</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={6} style={{fontWeight: 'bold'}}>Judging ability</TableCell>
                        <TableCell width={6}><RatingBar value={props.scoutingDept.judgingCARating} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={6} style={{fontWeight: 'bold'}}>Judging potential</TableCell>
                        <TableCell width={6}><RatingBar value={props.scoutingDept.judgingPARating} /></TableCell>
                    </TableRow>
                </Table>
            </Grid.Column>
        </Grid.Row>
    )
}