import axios from "axios";
import { getFilterQueryParams, getFilterStateFromPersistedState } from "../../player/filter/lib";
import { useQuery } from "@tanstack/react-query";

export default function useGetPlayers(filterState, maxReputationAbs, favPlayers, enabled, nationId) {
    return useQuery(
        [
            'searchPlayers',
            ...getFilterStateFromPersistedState(filterState)
        ],
        () => fetchPlayers(filterState, maxReputationAbs, favPlayers, nationId),
        {
            keepPreviousData: true,
            enabled
        }
    );
}

function fetchPlayers(filterState, maxReputationAbs, favPlayers, nationId) {
    const filterParams = getFilterQueryParams(filterState, maxReputationAbs, favPlayers, nationId);

    return axios.get(`${process.env.REACT_APP_APPHOST}/players?${filterParams.join('&')}`);
}