import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedDate } from "react-intl";

function contractExpiry(
    dateResolver,
    sortable,
    hide
) {
    return date(
        'contractExpiry',
        dateResolver,
        sortable,
        hide,
        'numeric',
        'short',
        undefined,
        'UTC'
    );
}

function seasonName(
    id,
    dateResolver,
    sortable,
    hide,
    left,
    name
) {
    return date(
        id,
        dateResolver,
        sortable,
        hide,
        'numeric',
        'short',
        undefined,
        'UTC',
        left,
        undefined,
        name
    );
}

function date(
    id,
    dateResolver,
    sortable,
    hide,
    day='numeric',
    month='short',
    year='2-digit',
    timeZone,
    left = false,
    omit,
    name
) {
    return {
        id,
        name: name || <FontAwesomeIcon icon={faClock} />,
        selector: (rowData) => {
            const d = dateResolver(rowData);
            return !!d ? <FormattedDate value={d} day={day} month={month} year={year} timeZone={timeZone} /> : '-'
        },
        sortable,
        sortFunction: sorter,
        wrap: false,
        allowOverflow: false,
        compact: true,
        center: !left,
        grow: 1,
        minWidth: '60px',
        maxWidth: '80px',
        hide,
        left,
        omit
    }

    function sorter(a, b) {
        a = new Date(dateResolver(a));
        b = new Date(dateResolver(b));

        return a-b;
    }
}

function dateRange(
    id,
    dateResolver,
    hide,
    day='numeric',
    month='short',
    year='2-digit',
    timeZone,
    left = true,
    omit
) {
    return {
        id,
        name: <FontAwesomeIcon icon={faClock} />,
        selector: (rowData) => {
            let {startDate, endDate} = dateResolver(rowData);

            if(!startDate || !endDate) {
                return '-';
            }

            startDate = <FormattedDate value={startDate} day={day} month={month} year={year} timeZone={timeZone} />;
            endDate = <FormattedDate value={endDate} day={day} month={month} year={year} timeZone={timeZone} />;

            return (<>{startDate} - {endDate}</>);
        },
        wrap: false,
        allowOverflow: false,
        compact: true,
        center: !left,
        grow: 1,
        minWidth: '160px',
        maxWidth: '180px',
        hide,
        left,
        omit
    }
}

export {
    contractExpiry,
    seasonName,
    date,
    dateRange
}