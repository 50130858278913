import {Grid, Progress, Placeholder} from 'semantic-ui-react';

export default function Stats(props) {
    return(
        <Grid columns='equal' style={{margin: 0}}>
            <Grid.Row>
                <p style={{textAlign: 'center', width: '100%', marginBottom:'0.5em'}}>Shots</p>
                {props.isLoading ?
                    <Placeholder style={{width: '100%', margin: '0 auto', height: '12px'}}>
                        <Placeholder.Header>
                            <Placeholder length='long' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <div style={{position: 'relative', width: '100%'}}>
                        <Progress percent={Math.round((props.teamStatData.home.shots/(props.teamStatData.home.shots+props.teamStatData.away.shots))*100)} style={{width: '100%', margin: 0}} className='stat' />
                        <span style={{position: 'absolute', left: '6px', top: '3px', color:'white', fontWeight: 'bold'}}>{props.teamStatData.home.shots}</span>
                        <span style={{position: 'absolute', right: '6px', top: '3px', color:'white', fontWeight: 'bold'}}>{props.teamStatData.away.shots}</span>
                    </div>
                }
            </Grid.Row>
            <Grid.Row>
                <p style={{textAlign: 'center', width: '100%', marginBottom:'0.5em'}}>Shots on target</p>
                {props.isLoading ?
                    <Placeholder style={{width: '100%', margin: '0 auto', height: '12px'}}>
                        <Placeholder.Header>
                            <Placeholder length='long' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <div style={{position: 'relative', width: '100%'}}>
                        <Progress percent={Math.round((props.teamStatData.home.shotsOnTarget/(props.teamStatData.home.shotOnTarget+props.teamStatData.away.shotsOnTarget))*100)} style={{width: '100%', margin: 0}} className='stat' />
                        <span style={{position: 'absolute', left: '6px', top: '3px', color:'white', fontWeight: 'bold'}}>{props.teamStatData.home.shotsOnTarget}</span>
                        <span style={{position: 'absolute', right: '6px', top: '3px', color:'white', fontWeight: 'bold'}}>{props.teamStatData.away.shotsOnTarget}</span>
                    </div>
                }
            </Grid.Row>
            <Grid.Row>
                <p style={{textAlign: 'center', width: '100%', marginBottom:'0.5em'}}>Attack rating</p>
                {props.isLoading ?
                    <Placeholder style={{width: '100%', margin: '0 auto', height: '12px'}}>
                        <Placeholder.Header>
                            <Placeholder length='long' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <div style={{position: 'relative', width: '100%'}}>
                        <Progress percent={props.teamStatData.attackShare} style={{width: '100%', margin: 0}} className='stat' />
                        <span style={{position: 'absolute', left: '6px', top: '2px', color:'white', fontWeight: 'bold'}}>{props.teamStatData.attackShare}%</span>
                        <span style={{position: 'absolute', right: '6px', top: '2px', color:'white', fontWeight: 'bold'}}>{100-props.teamStatData.attackShare}%</span>
                    </div>
                }
            </Grid.Row>
            <Grid.Row>
                <p style={{textAlign: 'center', width: '100%', marginBottom:'0.5em'}}>Defence rating</p>
                {props.isLoading ?
                    <Placeholder style={{width: '100%', margin: '0 auto', height: '12px'}}>
                        <Placeholder.Header>
                            <Placeholder length='long' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <div style={{position: 'relative', width: '100%'}}>
                        <Progress percent={props.teamStatData.defenceShare} style={{width: '100%', margin: 0}} className='stat' />
                        <span style={{position: 'absolute', left: '6px', top: '2px', color:'white', fontWeight: 'bold'}}>{props.teamStatData.defenceShare}%</span>
                        <span style={{position: 'absolute', right: '6px', top: '2px', color:'white', fontWeight: 'bold'}}>{100-props.teamStatData.defenceShare}%</span>
                    </div>
                }
            </Grid.Row>
            <Grid.Row>
                {props.isLoading ?
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder length='short' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <Grid.Column width={3} textAlign='left' style={{paddingLeft: 0, fontWeight: 'bold'}}>{props.teamStatData.home.avgMatchRating}</Grid.Column>
                }
                <Grid.Column textAlign='center'><span style={{fontWeight: '0.8rem'}}>Average performance</span></Grid.Column>
                {props.isLoading ?
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder length='short' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <Grid.Column width={3} textAlign='right' style={{paddingRight: 0, fontWeight: 'bold'}}>{props.teamStatData.away.avgMatchRating}</Grid.Column>
                }
            </Grid.Row>
            <Grid.Row>
                {props.isLoading ?
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder length='short' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <Grid.Column width={3} textAlign='left' style={{paddingLeft: 0, fontWeight: 'bold'}}>{props.teamStatData.home.avgCA} ({props.teamStatData.home.avgEffCA})</Grid.Column>
                }
                <Grid.Column textAlign='center'><span style={{fontWeight: '0.8rem'}}>Average rating</span></Grid.Column>
                {props.isLoading ?
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder length='short' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <Grid.Column width={3} textAlign='right' style={{paddingRight: 0, fontWeight: 'bold'}}>{props.teamStatData.away.avgCA} ({props.teamStatData.away.avgEffCA})</Grid.Column>
                }
            </Grid.Row>
        </Grid>
    );
}