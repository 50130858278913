import { sizes } from "./config.js";
import msgpack from 'tiny-msgpack';

export default class MediaPlayer {
    static deadZoneX = 60/1270;
    static deadZoneY = 50/776;

    constructor(
        lockOrientation,
        updateCommentary
    ) {
        this.frameBuffer = null;
        this.numberOfFrames = -1;
        this.currentFrame = -1;
        this.lockOrientation = lockOrientation;
        this.elapsedMS = 0;
        this.isInProgress = false;

        this.updateCommentary = updateCommentary;
    }

    loadBuffer(highlightBuffer, mediaFinishedCallback) {
        if(this.isInProgress === true) {
            throw new Error('playback in progress');
        }

        this.isInProgress = true;

        this.buffer = highlightBuffer;

        this.frameBufferOffset = new Uint16Array(this.buffer, 0, 3);
        const metaDataBuffer = new Uint8Array(this.buffer, 6, this.frameBufferOffset[0]);

        this.metaData = msgpack.decode(metaDataBuffer);
        console.log(this.metaData);

        this.frameBuffer = new Float32Array(this.buffer, this.frameBufferOffset[1], this.frameBufferOffset[3]);
        console.log(this.frameBuffer.length);

        this.currentFrame = -1;
        this.numberOfFrames = (this.frameBuffer.length / 2) / 23;
        this.elapsedMS = 0;

        if(Number.isInteger(this.numberOfFrames) === false) {
            throw new Error('could not determine number of frames');
        }
        
        this.version = this.metaData.version;
        this.FPS = this.metaData.FPS;
        this.sourceWidth = this.metaData.sourceWidth;
        this.sourceHeight = this.metaData.sourceHeight;
        this.mediaFinishedCallback = mediaFinishedCallback;

        this.MSPerFrame = 1000 / this.FPS;
        this.app.ticker.maxFPS = this.FPS;

        this.updateGraphics(this.metaData.homeTeam, this.metaData.awayTeam)

        console.log(`loaded frame buffer (${this.numberOfFrames} total frames)`);
        this.app.ticker.start();
    }

    createGraphics(
        app,
        Graphics, //PIXI.Graphics
        BitmapText, //PIXI.BitmapText
        Container, //PIXI.Container
    ) {
        this.app = app;

        this.ball = {};
        this.leftTeam = {
            name: 'Home',
            colour: '#AA336A',
            goalkeeper: {name: 'GK', position: 'GK'},
            players: [
                {name: 'LB', position: 'LB'},
                {name: 'CB', position: 'CB'},
                {name: 'CB', position: 'CB'},
                {name: 'RB', position: 'RB'},
                {name: 'LM', position: 'LM'},
                {name: 'CM', position: 'CM'},
                {name: 'CM', position: 'CM'},
                {name: 'RM', position: 'RM'},
                {name: 'ST', position: 'ST'},
                {name: 'ST', position: 'ST'}
            ]
        }
        this.rightTeam = {
            name: 'Away',
            colour: '#AA336A',
            goalkeeper: {name: 'GK', position: 'GK'},
            players: [
                {name: 'LB', position: 'LB'},
                {name: 'CB', position: 'CB'},
                {name: 'CB', position: 'CB'},
                {name: 'RB', position: 'RB'},
                {name: 'LM', position: 'LM'},
                {name: 'CM', position: 'CM'},
                {name: 'CM', position: 'CM'},
                {name: 'RM', position: 'RM'},
                {name: 'ST', position: 'ST'},
                {name: 'ST', position: 'ST'}
            ]
        }

        _createPlayerGraphicsForTeam.call(this, this.leftTeam);
        _createPlayerGraphicsForTeam.call(this, this.rightTeam);
        _createBallGraphics.call(this, this.ball);

        this.app.ticker.add(this.nextFrame.bind(this));
        
        function _createBallGraphics(ball) {
            ball.graphics = new Graphics()
                .circle(0, 0, sizes.ballRadius)
                .fill('#000')
                .stroke('#FFF');

            this.app.stage.addChild(ball.graphics);
        }

        function _createPlayerGraphicsForTeam(team) {
            if(!!team.goalkeeper) {
                _createPlayerGraphics.call(this, team, team.goalkeeper);
            }

            for(let i=0; i<team.players.length; i++) {
                _createPlayerGraphics.call(this, team, team.players[i]);
            }
        }

        function _createPlayerGraphics(team, player) {
            player.graphics = {};

            _createPuckGraphics.call(this, team, player);
            _createNameGraphics.call(this, player);

            function _createPuckGraphics(team, player) {
                let puckSize;
                if(window.innerWidth > window.innerHeight || this.lockOrientation === true) {
                    puckSize = Math.round(window.innerWidth * 0.013);
                } else {
                    puckSize = Math.round(window.innerHeight * 0.013);
                }

                puckSize = Math.min(Math.max(puckSize, 8), 18);
            
                player.graphics.puck = new Graphics()
                    .circle(0, 0, puckSize)
                    .fill('#AA336A');

                player.graphics.positionLabel = new BitmapText({
                    text: player.position,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: Math.min(16, Math.round(puckSize * 1.2)),
                        fill: team.colour === '#0000ff' ? '#FFFFFF' : '#000000',
                        fontWeight: '900'
                    },
                    anchor: 0.5
                });

                player.graphics.positionContainer = new Container();
                player.graphics.positionContainer.addChild(player.graphics.puck, player.graphics.positionLabel);

                this.app.stage?.addChild(player.graphics.positionContainer);
            }
    
            function _createNameGraphics(player) {
                player.graphics.nameLabel = new BitmapText({
                    text: player.name,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 12,
                        fill: '#fff'
                    },
                    anchor: 0.5
                });
            
                player.graphics.nameLabelBg = new Graphics()
                    .roundRect(0, 0, player.graphics.nameLabel.width+8, player.graphics.nameLabel.height+2, 5)
                    .fill({
                        color: '#000',
                        alpha: 0.6
                    });
            
                player.graphics.nameContainer = new Container();
                player.graphics.nameContainer.addChild(player.graphics.nameLabelBg, player.graphics.nameLabel);

                this.app.stage.addChild(player.graphics.nameContainer);
            }
        }
    }

    updateGraphics(leftTeam, rightTeam) {
        this.leftTeam.name = leftTeam.name;
        this.rightTeam.name = rightTeam.name;

        this.leftTeam.colour = leftTeam.colour;
        this.rightTeam.colour = rightTeam.colour;

        this.leftTeam.goalkeeper.name = leftTeam.goalkeeper.name;
        this.leftTeam.goalkeeper.graphics.nameLabel.text = this.leftTeam.goalkeeper.name;
        this.leftTeam.goalkeeper.graphics.nameLabelBg.width = this.leftTeam.goalkeeper.graphics.nameLabel.width+8;
        this.leftTeam.goalkeeper.graphics.nameLabelBg.height = this.leftTeam.goalkeeper.graphics.nameLabel.height+2;
        this.leftTeam.goalkeeper.graphics.positionLabel.style.fill = '#FFF';

        this.leftTeam.goalkeeper.graphics.nameContainer.y = (this.leftTeam.goalkeeper.graphics.puck.height / 2) - 12;

        this.rightTeam.goalkeeper.name = rightTeam.goalkeeper.name;
        this.rightTeam.goalkeeper.graphics.nameLabel.text = this.rightTeam.goalkeeper.name;
        this.rightTeam.goalkeeper.graphics.nameLabelBg.width = this.rightTeam.goalkeeper.graphics.nameLabel.width+8;
        this.rightTeam.goalkeeper.graphics.nameLabelBg.height = this.rightTeam.goalkeeper.graphics.nameLabel.height+2;
        this.rightTeam.goalkeeper.graphics.positionLabel.style.fill = '#FFF';

        this.rightTeam.goalkeeper.graphics.nameContainer.y = (this.rightTeam.goalkeeper.graphics.puck.height / 2) - 12;

        const leftTeamPositionLabelTextColour = colorIsDarkSimple(this.leftTeam.colour) ? '#FFF' : '#000';
        const rightTeamPositionLabelTextColour = colorIsDarkSimple(this.rightTeam.colour) ? '#FFF' : '#000';

        for(let i=0; i<10; i++) {
            if(!!leftTeam.players[i]) {
                this.leftTeam.players[i].name = leftTeam.players[i].name;
                this.leftTeam.players[i].position = leftTeam.players[i].position;

                const r = this.leftTeam.players[i].graphics.puck.width / 2;
                this.leftTeam.players[i].graphics.puck.clear().circle(0, 0, r).fill({color: this.leftTeam.colour}).stroke('#000');
                
                this.leftTeam.players[i].graphics.puck.visible = true;
                this.leftTeam.players[i].graphics.nameContainer.visible = true;
                this.leftTeam.players[i].graphics.positionLabel.visible = true;
                
                this.leftTeam.players[i].graphics.positionLabel.text = positionNames[this.leftTeam.players[i].position];
                this.leftTeam.players[i].graphics.positionLabel.style.fill = leftTeamPositionLabelTextColour;
                this.leftTeam.players[i].graphics.nameLabel.text = this.leftTeam.players[i].name;
                this.leftTeam.players[i].graphics.nameLabelBg.width = this.leftTeam.players[i].graphics.nameLabel.width+8;
                this.leftTeam.players[i].graphics.nameLabelBg.height = this.leftTeam.players[i].graphics.nameLabel.height+2;

                this.leftTeam.players[i].graphics.nameContainer.y = (this.leftTeam.players[i].graphics.puck.height / 2) - 12;
            } else {
                this.leftTeam.players[i].graphics.puck.visible = false;
                this.leftTeam.players[i].graphics.nameContainer.visible = false;
                this.leftTeam.players[i].graphics.positionLabel.visible = false;
            }

            if(!!rightTeam.players[i]) {
                this.rightTeam.players[i].name = rightTeam.players[i].name;
                this.rightTeam.players[i].position = rightTeam.players[i].position;

                const r = this.rightTeam.players[i].graphics.puck.width / 2;
                this.rightTeam.players[i].graphics.puck.clear().circle(0, 0, r).fill({color: this.rightTeam.colour}).stroke('#000');
                
                this.rightTeam.players[i].graphics.puck.visible = true;
                this.rightTeam.players[i].graphics.nameContainer.visible = true;
                this.rightTeam.players[i].graphics.positionLabel.visible = true;
                
                this.rightTeam.players[i].graphics.positionLabel.text = positionNames[this.rightTeam.players[i].position];
                this.rightTeam.players[i].graphics.positionLabel.style.fill = rightTeamPositionLabelTextColour;
                this.rightTeam.players[i].graphics.nameLabel.text = this.rightTeam.players[i].name;
                this.rightTeam.players[i].graphics.nameLabelBg.width = this.rightTeam.players[i].graphics.nameLabel.width+8;
                this.rightTeam.players[i].graphics.nameLabelBg.height = this.rightTeam.players[i].graphics.nameLabel.height+2;

                this.rightTeam.players[i].graphics.nameContainer.y = (this.rightTeam.players[i].graphics.puck.height / 2) - 12;
            } else {
                this.rightTeam.players[i].graphics.puck.visible = false;
                this.rightTeam.players[i].graphics.nameContainer.visible = false;
                this.rightTeam.players[i].graphics.positionLabel.visible = false;
            }
        }

        function colorIsDarkSimple(bgColor) {
            let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
            let r = parseInt(color.substring(0, 2), 16); // hexToR
            let g = parseInt(color.substring(2, 4), 16); // hexToG
            let b = parseInt(color.substring(4, 6), 16); // hexToB
            return ((r * 0.299) + (g * 0.587) + (b * 0.114)) <= 186;
        }
    }

    setBallPosition(x, y) {
        this.ball.graphics.position = this.transform(x, y);
    }


    setLeftGoalkeeperPosition(x, y) {
        const transformedPosition = this.transform(x, y);

        this.leftTeam.goalkeeper.graphics.puck.position = transformedPosition;
        this.leftTeam.goalkeeper.graphics.positionLabel.position = transformedPosition;

        this.leftTeam.goalkeeper.graphics.nameLabelBg.position = {
            x: transformedPosition.x - this.leftTeam.goalkeeper.graphics.nameLabelBg.width/2,
            y: transformedPosition.y + 15
        }

        this.leftTeam.goalkeeper.graphics.nameLabel.position = {
            x: transformedPosition.x,
            y: transformedPosition.y + 23
        }
    }

    setRightGoalkeeperPosition(x, y) {
        const transformedPosition = this.transform(x, y);

        this.rightTeam.goalkeeper.graphics.puck.position = transformedPosition;
        this.rightTeam.goalkeeper.graphics.positionLabel.position = transformedPosition;

        this.rightTeam.goalkeeper.graphics.nameLabelBg.position = {
            x: transformedPosition.x - this.rightTeam.goalkeeper.graphics.nameLabelBg.width/2,
            y: transformedPosition.y + 15
        }

        this.rightTeam.goalkeeper.graphics.nameLabel.position = {
            x: transformedPosition.x,
            y: transformedPosition.y + 23
        }
    }

    setLeftPlayerPosition(index, x, y) {
        if(index+1 > this.metaData.homeTeam.players.length) return;

        if(x === 404 || y === 404) throw new Error('oops');

        const transformedPosition = this.transform(x, y);
        
        this.leftTeam.players[index].graphics.puck.position = transformedPosition;
        this.leftTeam.players[index].graphics.positionLabel.position = transformedPosition;

        this.leftTeam.players[index].graphics.nameLabelBg.position = {
            x: transformedPosition.x - this.leftTeam.players[index].graphics.nameLabelBg.width/2,
            y: transformedPosition.y + 15
        }

        this.leftTeam.players[index].graphics.nameLabel.position = {
            x: transformedPosition.x,
            y: transformedPosition.y + 23
        }
    }

    setRightPlayerPosition(index, x, y) {
        if(index+1 > this.metaData.awayTeam.players.length) return;

        if(x === 404 || y === 404) throw new Error('oops');

        const transformedPosition = this.transform(x, y);
        
        this.rightTeam.players[index].graphics.puck.position = transformedPosition;
        this.rightTeam.players[index].graphics.positionLabel.position = transformedPosition;

        this.rightTeam.players[index].graphics.nameLabelBg.position = {
            x: transformedPosition.x - this.rightTeam.players[index].graphics.nameLabelBg.width/2,
            y: transformedPosition.y + 15
        }

        this.rightTeam.players[index].graphics.nameLabel.position = {
            x: transformedPosition.x,
            y: transformedPosition.y + 23
        }
    }

    nextFrame(timer) {
        // this.app.ticker.stop();

        if(this.currentFrame >= this.numberOfFrames-1) {
            console.log('reached end of frame buffer');
            this.app.ticker.stop();
            return;
        }

        this.elapsedMS = this.elapsedMS + timer.deltaMS;

        const nextFrame = Math.round(this.elapsedMS / this.MSPerFrame);

        const nextFrameBufferOffset = (nextFrame * 23 * 2);

        this.setBallPosition(this.frameBuffer[nextFrameBufferOffset], this.frameBuffer[nextFrameBufferOffset+1]);

        this.setLeftGoalkeeperPosition(this.frameBuffer[nextFrameBufferOffset+2], this.frameBuffer[nextFrameBufferOffset+3]);

        this.setLeftPlayerPosition(0, this.frameBuffer[nextFrameBufferOffset+4], this.frameBuffer[nextFrameBufferOffset+5]);
        this.setLeftPlayerPosition(1, this.frameBuffer[nextFrameBufferOffset+6], this.frameBuffer[nextFrameBufferOffset+7]);
        this.setLeftPlayerPosition(2, this.frameBuffer[nextFrameBufferOffset+8], this.frameBuffer[nextFrameBufferOffset+9]);
        this.setLeftPlayerPosition(3, this.frameBuffer[nextFrameBufferOffset+10], this.frameBuffer[nextFrameBufferOffset+11]);
        this.setLeftPlayerPosition(4, this.frameBuffer[nextFrameBufferOffset+12], this.frameBuffer[nextFrameBufferOffset+13]);
        this.setLeftPlayerPosition(5, this.frameBuffer[nextFrameBufferOffset+14], this.frameBuffer[nextFrameBufferOffset+15]);
        this.setLeftPlayerPosition(6, this.frameBuffer[nextFrameBufferOffset+16], this.frameBuffer[nextFrameBufferOffset+17]);
        this.setLeftPlayerPosition(7, this.frameBuffer[nextFrameBufferOffset+18], this.frameBuffer[nextFrameBufferOffset+19]);
        this.setLeftPlayerPosition(8, this.frameBuffer[nextFrameBufferOffset+20], this.frameBuffer[nextFrameBufferOffset+21]);
        this.setLeftPlayerPosition(9, this.frameBuffer[nextFrameBufferOffset+22], this.frameBuffer[nextFrameBufferOffset+23]);
        
        this.setRightGoalkeeperPosition(this.frameBuffer[nextFrameBufferOffset+24], this.frameBuffer[nextFrameBufferOffset+25]);

        this.setRightPlayerPosition(0, this.frameBuffer[nextFrameBufferOffset+26], this.frameBuffer[nextFrameBufferOffset+27]);
        this.setRightPlayerPosition(1, this.frameBuffer[nextFrameBufferOffset+28], this.frameBuffer[nextFrameBufferOffset+29]);
        this.setRightPlayerPosition(2, this.frameBuffer[nextFrameBufferOffset+30], this.frameBuffer[nextFrameBufferOffset+31]);
        this.setRightPlayerPosition(3, this.frameBuffer[nextFrameBufferOffset+32], this.frameBuffer[nextFrameBufferOffset+33]);
        this.setRightPlayerPosition(4, this.frameBuffer[nextFrameBufferOffset+34], this.frameBuffer[nextFrameBufferOffset+35]);
        this.setRightPlayerPosition(5, this.frameBuffer[nextFrameBufferOffset+36], this.frameBuffer[nextFrameBufferOffset+37]);
        this.setRightPlayerPosition(6, this.frameBuffer[nextFrameBufferOffset+38], this.frameBuffer[nextFrameBufferOffset+39]);
        this.setRightPlayerPosition(7, this.frameBuffer[nextFrameBufferOffset+40], this.frameBuffer[nextFrameBufferOffset+41]);
        this.setRightPlayerPosition(8, this.frameBuffer[nextFrameBufferOffset+42], this.frameBuffer[nextFrameBufferOffset+43]);
        this.setRightPlayerPosition(9, this.frameBuffer[nextFrameBufferOffset+44], this.frameBuffer[nextFrameBufferOffset+45]);

        //commentary
        const commentaryItem = this.getCommentaryItem(nextFrame);

        if(!!commentaryItem) {
            this.updateCommentary(commentaryItem); //TODO - consider a direct DOM update here as I think setState is causing out of sync issues
        }

        this.currentFrame = nextFrame;

        if(
            this.currentFrame >= this.numberOfFrames-1 &&
            !!this.mediaFinishedCallback
        ) {
            this.handleEnd();
        }
    }

    getCommentaryItem(frameNumber) {
        let commentaryItem;

        for(let i=this.metaData.commentaryEvents.length-1; i>=0; i--) {
            if(this.metaData.commentaryEvents[i].frameNumber <= frameNumber) {
                commentaryItem = this.metaData.commentaryEvents[i];
                
                if(commentaryItem.side === 'home') {
                    if(commentaryItem.playerIndex === 'GK') {
                        commentaryItem.playerName = this.metaData.homeTeam.goalkeeper.name;
                    } else {
                        commentaryItem.playerName = this.metaData.homeTeam.players[commentaryItem.playerIndex].name;
                    }
                    commentaryItem.color = this.metaData.homeTeam.colour;
                } else if(commentaryItem.side === 'away') {
                    if(commentaryItem.playerIndex === 'GK') {
                        commentaryItem.playerName = this.metaData.awayTeam.goalkeeper.name;
                    } else {
                        commentaryItem.playerName = this.metaData.awayTeam.players[commentaryItem.playerIndex].name;
                    }                    
                    commentaryItem.color = this.metaData.awayTeam.colour;
                }

                return commentaryItem;
            }
        }

        return false;
    }

    handleEnd() {
        this.frameBuffer = null;
        this.numberOfFrames = -1;
        this.currentFrame = -1;
        this.elapsedMS = 0;

        this.isInProgress = false;

        this.mediaFinishedCallback();
        this.mediaFinishedCallback = null;
    }

    /*

    Transforms a point from global space to viewport according to viewport orientation

    e.g. position 50, 0 in global space translates to (400x200 screen res):
        200x0 (landscape)
        0x200 (portrait)

    */
    transform(x, y) {
        const orientation = getOrientation(this.lockOrientation);

        if(orientation === 'portrait') {
            return {
                x: this.transformX(this.sourceHeight-y, this.sourceHeight), //30
                y: this.transformY(x, this.sourceWidth) //100
            }
        } else if(orientation === 'landscape') {
            return {
                x: this.transformX(x, this.sourceWidth),
                y: this.transformY(y, this.sourceHeight)
            }
        }
    }

    transformX(x, dimension) {
        const deadZoneX = this.app.renderer.width * MediaPlayer.deadZoneX;
        return (( (this.app.renderer.width-deadZoneX) / dimension ) * x) + (deadZoneX / 2);
    }

    transformY(y, dimension) {
        const deadZoneY = this.app.renderer.height * MediaPlayer.deadZoneY;
        return (( (this.app.renderer.height-deadZoneY) / dimension ) * y) + (deadZoneY / 2);
    }
}

function getOrientation(lock) {
    if(lock === true || window.innerWidth >= window.innerHeight) {
        return 'landscape';
    } else {
        return 'portrait';
    }
}

const positionNames = {
    LB: 'LB',
    CB: 'CB',
    RB: 'RB',
    LWB: 'WB',
    CDM: 'DM',
    RWB: 'WB',
    LM: 'LM',
    CM: 'CM',
    RM: 'RM',
    LW: 'LW',
    CAM: 'AM',
    RW: 'RW',
    ST: 'ST'
}