import {Link as RRLink, generatePath} from "react-router-dom";
import Flag from '../../common/components/Flag';

import {URL} from "../../common/lib/paths.js";
import ShortenedPlayerName from "./ShortenedName.js";

export default function PlayerLink(props) {
    return(
        <RRLink
            style={props.highlight ? {fontWeight: 'bold'} : {}}
            to={{
                pathname: generatePath(props.player.isRetired === true ? URL.retiredPlayerProfile : URL.playerProfile, {playerId: props.player.id}),
                search: props.search
            }}
            className={!props.inline ? `noTextwrap  ${props.className}` : `${props.className}`}
        >
            {!!props.player.nation?.nationGroupShortcode &&
                <Flag name={props.player.nation.nationGroupShortcode} className={!props.nationFlagClassName ? 'nationFlag nationFlag-tablet' : `nationFlag ${props.nationFlagClassName}`} />
            }
            {props.fullName ?
                props.fullName
                :
                <ShortenedPlayerName player={props.player} />
            }
            {props.icon || null}
        </RRLink>
    );
}

