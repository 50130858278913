import { useParams } from "react-router";
import axios from "axios";

import RetiredStaffProfileToolbarItem from '../toolbar/Profile';
import RetiredStaffHistoryToolbarItem from '../toolbar/History';
import { useEffect } from "react";
import ShortenedPlayerName from "../../player/common/ShortenedName";
import { useQuery } from "@tanstack/react-query";
import StaffHistoryInterface from '../../staff/history/interface';

export default function RetiredStaffHistory(props) {
    const {staffId} = useParams();

    const {isLoading, data, isSuccess} = useQuery(
        ['getRetiredStaffTeamLeagueSeasons', staffId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/retiredStaff/${staffId}/staffTeamLeagueSeasons`)
    );
    
    useEffect(() => {
        if(!!data && isSuccess) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={data.data.response} /> history</>,
                navigation: [
                    <RetiredStaffProfileToolbarItem staffId={staffId} />,
                    <RetiredStaffHistoryToolbarItem staffId={staffId} />,
                ]
            });
        }
    }, [data?.data.response]);

    return(
        <StaffHistoryInterface
            isLoading={isLoading}
            STLS={data?.data?.response.retiredStaffTeamLeagueSeason || []}
        />
    )
}