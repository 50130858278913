function getEntityRankingString(percentile) {
    percentile = roundEntityRanking(percentile); //round ranking to nearest 5

    if(percentile <= 50) {
        return `Top ${Math.max(percentile, 1)}%`;
    } else {
        return `Bottom ${Math.max(100-percentile, 1)}%`;
    }
}

function roundEntityRanking(ranking) {
    return Math.ceil(ranking / 5) * 5
}

function roundSalary(salary) {
    let rf;

    if(salary<1000) {
        rf = 10;
    } else if(salary<5000) {
        rf = 100;
    } else if(salary<10000) {
        rf = 250;
    } else if(salary<25000) {
        rf = 500;
    } else if(salary<50000) {
        rf = 1000;
    } else if(salary<100000) {
        rf = 5000;
    } else {
        rf = 10000;
    }

    return Math.round(salary / rf) * rf;
}

export {getEntityRankingString, roundEntityRanking, roundSalary}