import { Accordion, List } from "semantic-ui-react";
import SUIIconShim from "../../../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faCircle, faLock, faMinus, faPlus, faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import colours from "../../../../common/colours";
import { useState } from "react";
import ContractTable from "../../../../common/components/ContractTable";

export default function StaffBriefing(props) {
    if(props.isLoading) return null;

    if(props.negotiationFactors === null) {
        return(
            <p className='boldLabel large' style={{width: '100%', textAlign: 'center'}}><FontAwesomeIcon className='inlineIcon' icon={faLock} /> Scout player to unlock staff briefing</p>
        );
    }

    return(
        <>
            {props.negotiationFactors.length === 0 ?
                <p>Your staff have not noted any relevant factors to consider during negotiations</p>
                :
                <>
                    <p>Your staff have compiled some important factors to consider during negotiations</p>
                    <List
                        divided
                        relaxed
                    >
                        {props.negotiationFactors.map((factor) => (
                            <List.Item key={factor}>
                                <List.Icon>
                                    <SUIIconShim>
                                        <span className="fa-layers fa-fw fa-2xl">
                                            <FontAwesomeIcon fixedWidth icon={faCircle} color={factors[factor].color} />
                                            <FontAwesomeIcon fixedWidth icon={factors[factor].icon} inverse transform='shrink-6' />
                                        </span>
                                    </SUIIconShim>
                                </List.Icon>
                                <List.Content>
                                    <List.Header style={{color: factors[factor].color}} className='boldLabel large'>
                                        <FormattedMessage {...factors[factor].header} />
                                    </List.Header>
                                    <List.Description>
                                        <FormattedMessage {...factors[factor].message} />
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        ))}
                    </List>
                </>
            }
            <ExistingContract
                playerContract={props.existingContract}
            />
        </>
    );
}

function ExistingContract(props) {
    const [isExpanded, setIsExpanded] = useState(false);

    if(!props.playerContract) return null;
    
    return(
        <Accordion>
            <Accordion.Title
                active={isExpanded}
                onClick={() => setIsExpanded(isExpanded => !isExpanded)}
                index={0}
            >
                <SUIIconShim>
                    <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight} className='inlineIcon' />
                    Existing contract
                </SUIIconShim>
            </Accordion.Title>
            <Accordion.Content
                active={isExpanded}
            >
                <ContractTable
                    contractSalary={props.playerContract.salary}
                    contractExpiry={props.playerContract.endSeason.endDate}
                    remainingContractLength={props.playerContract.remainingContractLength}
                />
            </Accordion.Content>
        </Accordion>
    );
}

const factors = {
    HOMEGROWN: {
        icon: faThumbsUp,
        color: colours.positive,
        header: {
            id: 'playerContract:staffBriefing:HOMEGROWN:header',
            defaultMessage: 'Homegrown',
            description: 'TODO'
        },
        message: {
            id: 'playerContract:staffBriefing:HOMEGROWN',
            defaultMessage: 'As a youth team graduate, the player has reduced their demands by 20%',
            description: 'TODO'
        }
    },
    LOYAL: {
        icon: faThumbsUp,
        color: colours.positive,
        header: {
            id: 'playerContract:staffBriefing:HOMEGROWN:header',
            defaultMessage: 'Loyal',
            description: 'TODO'
        },
        message: {
            id: 'playerContract:staffBriefing:LOYAL',
            defaultMessage: 'The player will accept a lower salary due to their loyalty to the club',
            description: 'TODO'
        }
    },
    OLD: {
        icon: faThumbsUp,
        color: colours.positive,
        header: {
            id: 'playerContract:staffBriefing:HOMEGROWN:header',
            defaultMessage: 'Contract length',
            description: 'TODO'
        },
        message: {
            id: 'playerContract:staffBriefing:OLD',
            defaultMessage: 'Older players will trade salary for a longer contract',
            description: 'TODO'
        }
    },
    VALUE: {
        icon: faThumbsDown,
        color: colours.negative,
        header: {
            id: 'playerContract:staffBriefing:HOMEGROWN:header',
            defaultMessage: 'Value for money',
            description: 'TODO'
        },
        message: {
            id: 'playerContract:staffBriefing:VALUE',
            defaultMessage: 'The player\’s salary demands are high compared to their rating',
            description: 'TODO'
        }
    },
    YOUNG: {
        icon: faThumbsDown,
        color: colours.negative,
        header: {
            id: 'playerContract:staffBriefing:HOMEGROWN:header',
            defaultMessage: 'Contract length',
            description: 'TODO'
        },
        message: {
            id: 'playerContract:staffBriefing:YOUNG',
            defaultMessage: 'Younger players will demand a higher salary for a longer contract',
            description: 'TODO'
        }
    }
}