import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faChild } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: 'toolbarItem:youthTeamTactics',
		defaultMessage: "Youth team",
		description: "Link for youth team tactics page"
	}
});

export default function YouthTeamTacticsToolbarItem(props) {
	return(
		<ToolbarItem
			path={generatePath(URL.teamTactics, {teamType: 'youth'})}
			icon={faChild} messages={messages}
		/>
	);
}

